import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SnackbarProvider } from './context/snackbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

/* if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
      console.log('Service Worker registrado!');
    }).catch(err => console.error('Erro no registro do Service Worker:', err));
  });
} */

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);