import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { BsEye } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import userGeneric from '../../../assets/img_avatar.png';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { Container, tr0, tr1, tr2, trHora, trOcupado, trVazio } from './styles';

/**
 * Componente AgendaPacienteResponsavel
 * 
 * Este componente exibe a agenda de um paciente para o responsável.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {Object} ref - Referência do componente.
 * 
 * @returns {JSX.Element} O componente AgendaPacienteResponsavel.
 * 
 * @component
 * 
 * @example
 * return (
 *   <AgendaPacienteResponsavel />
 * )
 * 
 * @requires useAuth - Hook para obter informações do usuário autenticado.
 * @requires useParams - Hook para obter parâmetros da URL.
 * @requires useSnackbar - Hook para exibir mensagens de snackbar.
 * @requires useState - Hook para gerenciar o estado do componente.
 * @requires useEffect - Hook para efeitos colaterais no componente.
 * @requires api - Instância do axios para fazer requisições à API.
 * @requires moment - Biblioteca para manipulação de datas e horários.
 * @requires Container - Componente de layout.
 * @requires Link - Componente de navegação.
 * @requires DetalhesDoAgendamento - Componente para exibir detalhes do agendamento.
 * 
 * @function modalDetalhesDoAtendimento - Função para abrir o modal de detalhes do atendimento.
 * @function abreviarNome - Função para abreviar o nome completo.
 * @function carregaPaciente - Função para carregar os dados do paciente.
 * 
 * @typedef {Object} Paciente - Objeto que representa um paciente.
 * @property {number} id - ID do paciente.
 * @property {string} nome - Nome do paciente.
 * @property {string} foto_perfil - URL da foto de perfil do paciente.
 * @property {Date} nascimento - Data de nascimento do paciente.
 * @property {Object} diagnostico1 - Primeiro diagnóstico do paciente.
 * @property {Object} diagnostico2 - Segundo diagnóstico do paciente.
 * 
 * @typedef {Object} Agendamento - Objeto que representa um agendamento.
 * @property {number} id - ID do agendamento.
 * @property {number} dia_semana - Dia da semana do agendamento.
 * @property {string} hora_agendamento - Hora do agendamento.
 * @property {number} reserva - Indica se o agendamento é uma reserva.
 * @property {number} tipo_atendimento_id - Tipo de atendimento do agendamento.
 * @property {string} sala - Sala do atendimento.
 * @property {string} estacao - Estação do atendimento.
 * @property {string} terapeuta_nome - Nome do terapeuta responsável pelo atendimento.
 */
export default function BlzAgendaPacienteResponsavel(props, ref) {
    const { user } = useAuth();
    const { id } = useParams();
    const snackbar = useSnackbar();
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [atendimentoId, setAtendimentoId] = useState();

    const LongLabelBase = (props) => {
        return (
            <span>
                <b className='font-12px'>{props.dayName[0]}</b>
                <span className='font-8px'>{props.dayName.slice(1, props.dayName.length)}</span>
            </span>
        );
    }

    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'S',
            longLabel: <LongLabelBase dayName='Segunda-Feira' />,
        },
        {
            value: 2,
            label: 'T',
            longLabel: <LongLabelBase dayName='Terça-Feira' />,
        },
        {
            value: 3,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quarta-Feira' />,
        },
        {
            value: 4,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quinta-Feira' />,
        },
        {
            value: 5,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sexta-Feira' />,
        },
        {
            value: 6,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sábado' />,
        },
    ];

    //#region FUNCOES
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    //#endregion
    //#region CARREGADORES
    const carregaPaciente = () => {
        api.get(`/api/agenda/paciente/${id}`)
            .then((response) => {
                let paciente = response.data;
                const { agendamentos, lista_horarios } = response.data;
                if (response.status === 200) {
                    setHorarios(lista_horarios);
                    setAgendamentos(agendamentos);
                }
                setPaciente(paciente);
            })
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao carregar paciente', 'error');
            });
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        carregaPaciente();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            <div className='w-100 d-flex flex-row align-center justify-center p-2'>
                <span className='ns-span-novidade'>
                    Uma nova versão da agenda do paciente está disponível.<br></br>Clique <Link to={'/dashboard/responsavel/nova/agenda/paciente/' + id}>aqui</Link> para acessar.
                </span>
            </div>
            {/* AGENDA */}
            <ul className='w-100 m-0 p-0'>
                <li className='d-flex'>
                    <div style={tr0} align={'center'}></div>
                    {diasDaSemanaArray.map(dia => (
                        <div key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? tr2 : tr1}>{dia.label}</div>
                    ))}
                </li>
            </ul>
            <ul className='w-100 m-0 p-0 overflow-y-no-scroll h-100vh-170px'>
                {horarios.map((horario, horarioIdx) => {
                    const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm:ss');
                    const horasPrint = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                    return (
                        <li className='d-flex' key={`linha-horario-${horarioIdx}`}>
                            <div align={'center'} style={trHora}>{horasPrint}</div>
                            {
                                diasDaSemanaArray !== null && agendamentos !== null
                                    ? diasDaSemanaArray.map(dia => {
                                        let atendimentoEncontrado = agendamentos.filter(atendimento => {
                                            return Number(atendimento.dia_semana) === Number(dia.value) && atendimento.hora_agendamento === horasExtenso && Number(atendimento.reserva) === 0;
                                        });
                                        if (atendimentoEncontrado.length > 0) {
                                            atendimentoEncontrado = atendimentoEncontrado[0];
                                            return (
                                                <div className='text-center p-0 m-0 d-grid' onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                    key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                    style={{ ...trOcupado, ...atendimentoEncontrado?.tipo_atendimento_id === 8 ? { backgroundColor: '#2781DA' } : {} }}>
                                                    <span> Sala: {atendimentoEncontrado?.sala} </span>
                                                    <span>Estação: {atendimentoEncontrado?.estacao}</span>
                                                    <span className='ns-abrevia-texto'>
                                                        {atendimentoEncontrado?.terapeuta_nome}
                                                    </span>
                                                </div>
                                            );
                                        }

                                        return (
                                            <div style={trVazio} key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></div>
                                        );
                                    })
                                    : <></>
                            }
                        </li>
                    );
                })}
            </ul>
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento
                    agendamentoId={atendimentoId}
                    onClose={() => { setIsModalDetalhesDoAtendimentoVisible(false); carregaPaciente(); }}
                /> : (<></>)}
        </Container>
    );
    //#endregion
}