import { BsSearch } from "react-icons/bs";
import { ScaleLoader } from "react-spinners";

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

const CarregandoRelatorio = ({ loading }) => {
    if (!loading) return null
    return (
        <div className='row'>
            <div className='col-12 text-center'>
                <div className='d-flex flex-row w-150px text-center align-items-center'>
                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                    <BsSearch className='icone manual-searching' size={30} />
                </div>
            </div>
        </div>
    )
}

export default CarregandoRelatorio;