import styled from 'styled-components';
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

// Estilo para o destaque
const Highlight = styled.div`
    position: absolute;
    border: 4px solid #4caf50;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease;
    pointer-events: none;
    animation: pulse 1.5s infinite;
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.9);
        }
        70% {
            box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
        }
    }
`;

// Estilo para o conteúdo do tutorial
const Content = styled.div`
  position: absolute;
  background: white;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1002;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: #71FF77;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ButtonFinalizar = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #B14C4C;
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: #FF7171;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const bgActive = '#4caf50';
const bgActiveHover = '#71FF77';
const bgInactive = '#3F7041';
const bgInactiveHover = '#5FA061';

const Badge = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #4caf50;
  color: white;
  padding: 0px;
  cursor: pointer;
  z-index: 1002;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #71FF77;
    color: yellow;
  }
`;

const ListagemTopicos = styled.div`
    position: fixed;
    top: 100px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px;
    z-index: 1002;
    border-radius: 10px;
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    font-size: 16px;
    font-weight: bold;
    transition: left .3s ease, top .3s ease;
    .topico-step:hover {
        background-color: #71FF77 !important;
        color: black !important;
    }

    h3 {
        margin: 0;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;
    }

    p {
        margin: 5px 0;
        font-size: 14px;
    }

    div {
        cursor: pointer;
        padding: 5px;
        border-bottom: 1px solid #71FF77;
    }
`;

export { ListagemTopicos, Badge, bgInactiveHover, bgInactive, bgActiveHover, bgActive, ButtonFinalizar, Button, Controls, Content, Highlight, Overlay };