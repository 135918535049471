import React, { useEffect, useRef, useState } from 'react';
import { Modal } from './styles';
import { Form } from '@unform/web';
import Input from '../../../../../components/Input';
import Select, { SelectPlusButton } from '../../../../../components/Select';
import api from '../../../../../services/api';
import moment from 'moment';
import { useSnackbar } from '../../../../../context/snackbar';
import Swal from 'sweetalert2';
import AgendamentoService from '../../../../../services/AgendamentoService';
import { ClipLoader } from 'react-spinners';
import './styles.css';
import { Tooltip } from 'react-tooltip';
import { HiExclamation } from 'react-icons/hi';
import TextArea from '../../../../../components/TextArea';
import { BsX } from 'react-icons/bs';
import SelectPaciente from 'components/Select/SelectPaciente';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import AtendimentoService from 'services/AtendimentoService';
import { useAuth } from 'context/auth';

/**
 * AdicionarAgendamento component handles the scheduling of appointments.
 * 
 * @param {Object} props - The properties object.
 * @param {string} [props.id='modal'] - The ID of the modal.
 * @param {string|null} [props.hour=null] - The hour of the appointment.
 * @param {number} props.idHorario - The ID of the schedule.
 * @param {string} props.date - The date of the appointment.
 * @param {Object|null} [props.estacao=null] - The station information.
 * @param {Array} props.agendamentos - The list of appointments.
 * @param {boolean} props.reservaSelecionada - Indicates if the reservation is selected.
 * @param {number} props.tipoAtendimentoSelecionado - The selected type of service.
 * @param {Array|null} [props.tiposAtendimentoOptions=null] - The options for types of service.
 * @param {Array|null} [props.modalidadesOptions=null] - The options for modalities.
 * @param {number|null} [props.agendamentoId=null] - The ID of the appointment.
 * @param {number|null} [props.controleDiarioId=null] - The ID of the daily control.
 * @param {boolean} [props.incluirAtendimento=false] - Indicates if the service should be included.
 * @param {boolean} [props.esperaMode=false] - Indicates if the wait mode is enabled.
 * @param {Function} [props.onClose=() => {}] - The function to call when the modal is closed.
 * @param {Function} [props.atualizaSocket=() => {}] - The function to call to update the socket.
 * @param {Function} [props.updateAgenda=() => {}] - The function to call to update the agenda.
 * 
 * @returns {JSX.Element} The rendered component.
 */
export default function AdicionarAgendamento({
    id = 'modal',
    momentHour = null,
    hour = null,
    idHorario,
    date,
    estacao = null,
    agendamentos,
    reservaSelecionada,
    tipoAtendimentoSelecionado,
    tiposAtendimentoOptions = null,
    modalidadesOptions = null,
    agendamentoId = null,
    controleDiarioId = null,
    incluirAtendimento = false,
    esperaMode = false,
    solicitacaoId = null,
    onClose = () => { },
    atualizaSocket = () => { },
    updateAgenda = () => { },
}) {
    //#region VARIAVEIS
    /*==INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'MODAL DETALHES AGENDAMENTO';
    // eslint-disable-next-line no-unused-vars
    const rota = ['dashboard/{modulo}/agenda', ''];
    // eslint-disable-next-line no-unused-vars
    const descricao = 'Modal que permite cadastrar, visualizar e editar o agendamento através do identificador.';

    // Hooks
    const { user } = useAuth();
    // Agendamento
    const [agendamentoIdLocal, setAgendamentoIdLocal] = useState(agendamentoId);
    const [selectedAgendamento, setSelectedAgendamento] = useState(null);

    // Agendamentos/Atendimentos para testar
    const [agendamentosLocal, setAgendamentosLocal] = useState(agendamentos);

    // Solicitacao
    const fetchSolicitaca = async (solicitacaoId) => {
        return await api.get(`api/solicitacao-alteracao/${solicitacaoId}?id=${solicitacaoId}&with=user`)
            .then(({ data }) => data)
            .catch(error => {
                console.error('Error fetching solicitacao:', error);
                return null;
            });
    };
    const [solicitacao, setSolicitacao] = useState(null);
    useEffect(() => {
        if (solicitacaoId) {
            fetchSolicitaca(solicitacaoId).then(data => {
                setSolicitacao(data);
            });
        }
    }, [solicitacaoId]);

    // Terapeutas
    const [terapeutasOptions, setTerapeutas] = useState([]);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [terapeutaOptSelecionado, setTerapeutaOptSelecionado] = useState([]);

    // Modalidades
    const [modalidadesOptionsInterno, setModalidadesOptionsInterno] = useState([{ value: 1, label: 'Presencial' }, { value: 2, label: 'Domiciliar' }]);
    const [modalidadeOptSelecionado, setModalidadeOptSelecionado] = useState({ value: 1, label: 'Presencial' });
    const [modalidadeSelecionado, setModalidadesSelecionada] = useState(1);

    // Tipo Atendimento
    const [tiposAtendimentoOptionsInterno, setTiposAtendimentoOptionsInterno] = useState([]);
    const [tipoAtendimentoOptSelecionado, setTipoAtendimentoOptSelecionado] = useState([]);
    const [tipoAgendamentoSelecionado, setTipoAgendamentoSelecionado] = useState(null);

    // Terapias Paciente
    const [terapiasPacienteOptions, setTerapiasPaciente] = useState([]);
    const [terapiasPacienteOptSelecionado, setTerapiasPacienteOptSelecionado] = useState([]);
    const [terapiasPacienteSelecionado, setTerapiasPacienteSelecionado] = useState([]);
    const [limiteAtingido, setLimiteAtingido] = useState(false);

    // Paciente
    const [pacienteOptions, setPacienteOptions] = useState([]);
    const [idPacienteSelecionado, setIdPacienteSelecionado] = useState(null);
    const [pacienteOptSelecionado, setPacienteOptSelecionado] = useState([]);

    // Reserva
    const [reservaOptSelecionado, setReservaOptSelecionado] = useState({ value: false, label: 'Não' });
    const [reservaSelecionado, setReservaSelecionada] = useState(false);

    // Listas
    const [horariosOptions, setHorasOptions] = useState([]);
    const [estacoesOptions, setEstacoesOptions] = useState([]);

    // Seleções
    const [horarioSelected, setHorarioSelected] = useState([]);
    const [estacaoSelected, setEstacaoSelected] = useState([]);

    // Utilitários da Página
    const formRef = useRef(null);
    const snackbar = useSnackbar();
    const [oneLoad, setOneLoad] = useState(true);
    const [loading, setloading] = useState(false);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);

    const reservaOptions = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ];
    //#endregion
    //#region GETTERS
    const getEstacoes = () => {
        api.get('api/estacao?with=sala').then(response => {
            const estacoes = response?.data?.map(d => ({
                sala_id: d.sala_id,
                value: d.id,
                label: `${d.sala.sala} / ${d.estacao}`,
            }));
            setEstacoesOptions(estacoes);
        });
    };
    const getHorarios = () => {
        api.get(`/api/horario-atendimento?filter[dia_semana]=${moment().weekday()}`).then(response => {
            const horarios = response?.data?.map(horario => {
                let horaAtual = moment(horario.horario.split('T')[1], 'HH:mm');
                return {
                    id: horario.id,
                    value: horaAtual.format('HH:mm'),
                    label: horaAtual.format('HH:mm') + ' - ' + horaAtual.add(40, 'minutes').format('HH:mm'),
                    horaFim: horaAtual.add(40, 'minutes').format('HH:mm'),
                    hour: moment.utc(horario.horario)
                };
            });
            setHorasOptions(horarios);
        });
    };
    /**
     * Função que coleta os terapeutas disponíveis para as especialidades e terapia.
     * @param {Number} especialidade_id Identificador da especialidade.
     * @param {Number} terapia_id Identificador da terapia.
     * @returns {void}
     */
    const getTerapeutas = async (especialidade_id, terapia_id, horario) => {
        if (especialidade_id !== null && terapia_id !== null) {
            let hora = horario;
            const params = new URLSearchParams({
                dia: date,
                hora,
                especialidade_id,
                terapia_id,
                horarios_atendimento_id: idHorario ? idHorario : null,
                atendimento: incluirAtendimento ? 1 : 0,
            });
            let link = `api/terapeuta/disponivel/${date}/${hora}?${params.toString()}`;
            await api.get(link).then(({ data }) => {
                let terapeutaOptions = [];
                for (let index = 0; index < data.length; index++) {
                    const terapeuta = data[index];
                    terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome, pos: !(terapeuta.nome.includes('(') || terapeuta.nome.includes(')')) ? 0 : 1 });
                }
                if (terapeutaOptions.length === 0) {
                    snackbar.displayMessage('Não há terapeutas disponíveis para essa Especialidade e Terapia.', 'warning');
                }
                let terapeutaOptionsDisponivel = terapeutaOptions.filter((filter) => filter.pos === 0);
                terapeutaOptionsDisponivel = terapeutaOptionsDisponivel.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                let terapeutaOptionsIndisponivel = terapeutaOptions.filter((filter) => filter.pos === 1);
                terapeutaOptionsIndisponivel = terapeutaOptionsIndisponivel.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                let mergedObj = [];
                terapeutaOptionsDisponivel.forEach((e) => mergedObj.push(e));
                terapeutaOptionsIndisponivel.forEach((e) => mergedObj.push(e));
                setTerapeutas(mergedObj);
            });
        }
    };
    /**
     * Função que coleta as modalidades disponíveis.
     * @returns {Promise<Array>} Lista de modalidades.
     */
    const getModalidades = async () => {
        const returns = modalidadesOptions ? modalidadesOptions : await api.get('api/modalidade').then(({ data }) => {
            let modalidadeOptions = [];
            data.forEach((modalidade) => {
                modalidadeOptions.push({ value: modalidade.id, label: modalidade.modalidade });
            });
            return modalidadeOptions;
        });
        setModalidadesOptionsInterno(returns);
        return returns;
    };
    /**
     * Função que coleta a lista de pacientes.
     * @returns {Promise<Array>} Lista de pacientes.
     */
    const getPacientes = async () => {
        let pacientes = null;
        await api.get('api/paciente/lista/simples?with=terapia_paciente&select=terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,id,nome,identificador,status,aviso_agendamento_check').then(({ data }) => {
            let pacienteOptions = [];
            data.forEach((paciente, index) => {
                const addon = checkAgendamentosPaciente(paciente.id, hour)
                    ? '(Indisponível)'
                    : paciente.status === 'Ativo'
                        ? ''
                        : `(${paciente.status})`;
                pacienteOptions.push({
                    key: index,
                    value: paciente.id,
                    nome: paciente.nome,
                    identificador: paciente.identificador,
                    label: `(${paciente.identificador}) ${paciente.nome} ${addon}`,
                    aviso: paciente.aviso_agendamento_check,
                    terapia_paciente: paciente.terapia_paciente,
                });
            });
            pacienteOptions.sort(function (a, b) {
                if (a.nome < b.nome) {
                    return -1;
                }
                if (a.nome > b.nome) {
                    return 1;
                }
                return 0;
            });
            setPacienteOptions(pacienteOptions.sort());
            pacientes = data;
        });
        return pacientes;
    };

    /**
     * Função que coleta os tipos de atendimento disponíveis.
     * @returns {Promise<Array>} Lista de tipos de atendimento.
     */
    const getTipoAtendimento = async () => {
        const returns = tiposAtendimentoOptions ? tiposAtendimentoOptions : await api.get('api/tipo-atendimento').then(({ data }) => {
            let tipoAtendimentoOptions = [];

            data.forEach((tipoAtendimento) => {
                if (tipoAtendimento.id !== 4 && tipoAtendimento.id !== 6 && tipoAtendimento.id !== 7)
                    tipoAtendimentoOptions.push({ value: tipoAtendimento.id, label: tipoAtendimento.tipo_atendimento });
            });

            return tipoAtendimentoOptions;
        });
        setTiposAtendimentoOptionsInterno(returns);
        return returns;
    };
    /**
     * Função que carrega o agendamento selecionado para edição.
     * @returns {Promise<Object>} Dados do agendamento selecionado.
     */
    const getSelectedeAgendamento = async () => {
        let obj = null;
        let id = agendamentoIdLocal;
        if (id !== null) {
            await AgendamentoService.show(id, 'with=estacao,estacao.sala,paciente.terapia_pacientes,paciente').then(({ data }) => {
                setSelectedAgendamento(data);
                obj = data;
            }).catch((erro) => {
                snackbar.displayMessage('Erro ao carregar agendamento.', 'error');
                console.error(erro);
            });
        }
        return obj;
    };
    //#endregion
    //#region FUNCOEs
    /**
     * Função que carrega todas as informações necessárias para a modal.
     * @returns {void}
     */
    const carregaTudo = async () => {
        if (oneLoad) {
            setOneLoad(false);
            const tipoAtendimentoLocal = await getTipoAtendimento();  //Coleta os tipos de atendimento
            //let modalidadeOptionsLocal = await getModalidades()  //Coleta as modalidades
            const modalidadeOptionsLocal = modalidadesOptionsInterno;  //Coleta as modalidades
            const pacientesResponse = await getPacientes();//Coleta os pacientes
            if (agendamentoIdLocal !== null) {
                await getSelectedeAgendamento().then(async (response) => {
                    let pacienteData = pacientesResponse.find((el) => Number(el.id) === Number(response.paciente_id));
                    if (incluirAtendimento) {
                        if (horarioSelected) {
                            await getTerapeutas(response.especialidade_id, response.terapia_id, horarioSelected.value+':00');
                        } else {
                            snackbar.displayMessage('Selecione um horário para encontrar terapeutas', 'error');
                        }
                    } else {
                        await getTerapeutas(response.especialidade_id, response.terapia_id, hour);
                    }

                    setIdPacienteSelecionado(response.paciente_id);

                    let terapiaPacientesList = [];
                    if (pacienteData.terapia_paciente !== undefined) {
                        for (let index = 0; index < pacienteData.terapia_paciente.length; index++) {
                            const el = pacienteData.terapia_paciente[index];
                            const item = {
                                value: el.id,
                                label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                                terapia_id: el.terapia_id,
                                especialidade_id: el.especialidade_id,
                                plano_saude_id: el.plano_saude_id,
                                limite_diario_terapias: el.limite_diario_terapias,
                            };
                            terapiaPacientesList.push(item);
                            if (el.id === response.terapia_paciente_id) {
                                setTerapiasPacienteSelecionado(el.id);
                                setTerapiasPacienteOptSelecionado(item);
                            }
                        }
                    }
                    setTerapiasPaciente(terapiaPacientesList);

                    setTerapeutaSelecionado(response.terapeuta_id);
                    setReservaSelecionada(response.reserva);
                    setReservaOptSelecionado(reservaOptions.find((value) => value.value === Boolean(response.reserva)));

                    setTipoAgendamentoSelecionado(response.tipo_atendimento_id);
                    setTipoAtendimentoOptSelecionado(tipoAtendimentoLocal.find((value) => value.value === response.tipo_atendimento_id));

                    setModalidadesSelecionada(response.modalidade_id);
                    setModalidadeOptSelecionado(modalidadeOptionsLocal.find((value) => value.value === response.modalidade_id));

                    setAllLoaded(true);
                });
            } else {
                setAllLoaded(true);
            }
        }
    };
    const temConflitoHorarioSalaAgendamento = (agendamentoAtual) => {
        const agendamento_id = agendamentoAtual?.id;
        const tipo_atendimento_id = agendamentoAtual.tipo_atendimento_id;
        const estacao_id = agendamentoAtual.estacao_id;
        const horarios_atendimento_id = agendamentoAtual.horarios_atendimento_id;
        const isReserva = agendamentoAtual.reserva == 1;
        return agendamentosLocal.some(agenda => {
            let verificaAguardando;
            if (agenda.id !== agendamento_id) {
                switch (tipo_atendimento_id) {
                    case 8:
                        verificaAguardando = agenda.tipo_atendimento_id === 8;
                        return agenda.horarios_atendimento_id === horarios_atendimento_id && agenda.estacao_id === estacao_id && verificaAguardando;
                    case 5 || 7:
                        return (agenda.tipo_atendimento_id === 5 || agenda.tipo_atendimento_id == 4) && agenda.horarios_atendimento_id === horarios_atendimento_id && agenda.estacao_id === estacao_id && verificaAguardando && verificaAgendamento;
                    default:
                        verificaAguardando = agenda.tipo_atendimento_id !== 8;
                        const verificaAgendamento = isReserva ? agenda.reserva === 1 : agenda.reserva === 0;
                        return agenda.horarios_atendimento_id === horarios_atendimento_id && agenda.estacao_id === estacao_id && verificaAguardando && verificaAgendamento;
                }
            }
        });
    };
    /**
     * Função que verifica a disponibilidade do paciente no horário através do identificador.
     * @param {Number} paciente_id Identificador do paciente.
     * @param {String} horario Horário que será utilizado para conferir a disponibilidade.
     * @returns {boolean}
     */
    const checkAgendamentosPaciente = (paciente_id, horario) => {
        let isPaciente = false;
        if (horario) {
            agendamentosLocal.forEach((agendamento) => {
                if (agendamento.paciente_id === paciente_id && agendamento.data_atendimento_inicial === horario && agendamento.id !== agendamentoIdLocal) isPaciente = true;
            });
        }
        return isPaciente;
    };
    //#endregion
    //#region HANDLES
    const handleChangeHorarioSelected = async (e) => {
        if (terapiasPacienteOptSelecionado) {
            await getTerapeutas(terapiasPacienteOptSelecionado.especialidade_id, terapiasPacienteOptSelecionado.terapia_id, e.hour || hour);
        }
        setHorarioSelected(e);
    };
    const handleReprovarSolicitacao = async () => {
        setloading(true);
        try {
            await api.put(`api/solicitacao-alteracao/${solicitacaoId}`, { status: 'Reprovado', user_id_analista: user.id }).then(() => {
                snackbar.displayMessage('Solicitação de alteração de atendimento reprovada.', 'success');
            }).catch(() => {
                snackbar.displayMessage('Erro ao reprovar solicitação de alteração de atendimento.', 'error');
            });
        } catch (error) {
            setloading(false);
            Swal.fire('Erro :(', 'Um erro ocorreu ao reprovar a solicitação de alteração de atendimento, envie essa informação ao suporte do sistema. ' + error, 'error');
            console.error(error);
        } finally {
            setloading(false);
            onClose(null);
        }
    };
    /**
     * Função que lida com a mudança de opção selecionada.
     * @param {String} opcao Opção selecionada.
     * @returns {void}
     */
    const handleChangeOpcaoBotoes = async (opcao) => {
        setOpcaoSelecionada(opcao);
        switch (opcao) {
            case 'editar':
                setAgendamentoIdLocal(agendamentoId);
                break;
            case 'reserva':
                setAgendamentoIdLocal(null);
                agendamentoId = null;
                break;
            case 'subistituicao':
                setAgendamentoIdLocal(agendamentoId);
                break;
            case 'novo':
                setAgendamentoIdLocal(null);
                agendamentoId = null;
                break;
            default:
                break;
        }
    };
    const confereTemConflito = () => {
        let tipoConflito = '';
        let dadosConflito = {};
        const temConflito = agendamentosLocal.some((atendimento) => {
            const horarioPraConferir = horarioSelected.value;
            const hora_atendimento = atendimento.hora_atendimento ? atendimento.hora_atendimento.replace(':00', '') : moment(atendimento.data_atendimento_inicial).format('HH:mm');

            if (hora_atendimento === horarioPraConferir) {
                if (atendimento.paciente_id === idPacienteSelecionado) {
                    dadosConflito = atendimento;
                    tipoConflito = 'paciente';
                    return true;
                }
                if (atendimento.terapeuta_id === terapeutaSelecionado) {
                    dadosConflito = atendimento;
                    tipoConflito = 'terapeuta';
                    return true;
                }
            }
            return false;
        });

        return { temConflito, tipoConflito, dadosConflito };
    }
    /**
     * Função que envia os dados do formulário para criar um novo agendamento.
     * @param {Object} data Dados do formulário.
     * @returns {void}
     */
    const handleSubmit = async (data) => {
        const cadastra = async () => {
            let novoAgenda = incluirAtendimento
                ? {
                    'paciente_id': idPacienteSelecionado,
                    'terapeuta_id': terapeutaSelecionado,
                    'terapia_id': terapiasPacienteOptSelecionado.terapia_id,
                    'terapia_paciente_id': terapiasPacienteSelecionado,
                    'especialidade_id': terapiasPacienteOptSelecionado.especialidade_id,
                    'modalidade_id': modalidadeSelecionado,
                    'tipo_atendimento_id': tipoAgendamentoSelecionado,
                    'horarios_atendimento_id': horarioSelected.id,
                    'reserva': reservaSelecionado,
                    'data_atendimento_inicial': horarioSelected.hour.format('YYYY-MM-DD HH:mm'),
                    'data_atendimento_final': horarioSelected.hour.add(40, 'minutes').format('YYYY-MM-DD HH:mm'),
                    'comentario': data.comentario,
                    'estacao_id': estacaoSelected.value,
                    'sugestao_recepcao': null,
                    'sugestao_terapeuta': null,
                    'controle_diario_id': controleDiarioId,
                    'status_aprovacao': solicitacaoId ? 1 : 0,
                }
                : {
                    'paciente_id': idPacienteSelecionado,
                    'terapeuta_id': terapeutaSelecionado,
                    'terapia_id': terapiasPacienteOptSelecionado.terapia_id,
                    'terapia_paciente_id': terapiasPacienteSelecionado,
                    'especialidade_id': terapiasPacienteOptSelecionado.especialidade_id,
                    'modalidade_id': modalidadeSelecionado,
                    'tipo_atendimento_id': tipoAgendamentoSelecionado,
                    'horarios_atendimento_id': idHorario,
                    'reserva': reservaSelecionado,
                    'data_atendimento_inicial': moment(date + 'T' + data.horaInicial + ':00').format('YYYY-MM-DD HH:mm'),
                    'data_atendimento_final': moment(date + 'T' + data.horaFinal + ':00').format('YYYY-MM-DD HH:mm'),
                    'comentario': data.comentario,
                    'estacao_id': estacao.id,
                };
            if (!incluirAtendimento && temConflitoHorarioSalaAgendamento(novoAgenda)) {
                Swal.fire('Erro :(', 'Já existe um agendamento nesse horário e estação.', 'error');
                setloading(false);
                return;
            }
            try {
                await api.post(`api/${incluirAtendimento ? 'atendimento' : 'agendamento'}`, novoAgenda).then((response) => {
                    if (response.status === 201) {
                        if (solicitacaoId) {
                            api.put(`api/solicitacao-alteracao/${solicitacaoId}?id=${solicitacaoId}`, { status: 'Aprovado', user_id_analista: user.id }).then(() => {
                                snackbar.displayMessage('Solicitação de alteração de atendimento aprovada.', 'success');
                            }).catch(() => {
                                snackbar.displayMessage('Erro ao aprovar solicitação de alteração de atendimento.', 'error');
                            });
                        }
                        snackbar.displayMessage('O agendamento foi adicionado com sucesso.', 'success');
                        updateAgenda();
                        if (incluirAtendimento) {
                            atualizaSocket();
                        }
                        let paciente_nome = pacienteOptSelecionado.label;
                        let paciente_identificador = pacienteOptSelecionado.label.match(/\(([^)]+)\)/)[1];
                        let terapeuta_nome_curto = terapeutaOptSelecionado ? terapeutaOptSelecionado.label : 'Sem Terapeuta';
                        onClose({
                            ...response.data,
                            paciente_nome: paciente_nome.replace(/\(.*?\)/g, '').trim(),
                            paciente_identificador: paciente_identificador,
                            terapeuta_nome_curto: terapeuta_nome_curto.replace(/\(.*?\)/g, '').trim(),
                        });
                    }
                });
            } catch (error) {
                setloading(false);
                Swal.fire('Erro :(', 'Um erro ocorreu ao inserir o agendamento, envie essa informação ao suporte do sistema. ' + error, 'error');
                console.error(error);
            } finally {
                setloading(false);
                onClose(null);
            }
        }
        setloading(true);
        if (!idPacienteSelecionado ||
            (!esperaMode && !terapeutaSelecionado) ||
            !terapiasPacienteOptSelecionado ||
            !modalidadeSelecionado ||
            !tipoAgendamentoSelecionado ||
            reservaSelecionado === null ||
            (incluirAtendimento
                && (
                    !horarioSelected ||
                    !estacaoSelected
                ))) {
            setloading(false);
            Swal.fire('Erro :(', 'Por favor, preencha todos os campos obrigatórios.', 'error');
            return;
        }
        const { temConflito, tipoConflito, dadosConflito } = confereTemConflito();
        if (temConflito) {
            Swal.fire({
                title: `Conflito de ${incluirAtendimento ? 'Atendimento' : 'Agendamento'}`,
                html: `
                <div class="w-400px">
                    <p>Este ${tipoConflito} já possui um atendimento agendado para este horário.</br>
                        Deseja adicionar este ${incluirAtendimento ? 'atendimento' : 'agendamento'} em conflito?</br>
                        ${dadosConflito
                        ? `
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th><b>Paciente:</b></th>
                                            <th><b>Terapeuta:</b></th>
                                            <th><b>Horário:</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>(${dadosConflito.paciente_identificador}) ${dadosConflito.paciente_nome}</td>
                                            <td>${dadosConflito.terapeuta_nome}</td>
                                            <td>${dadosConflito.hora_atendimento} - ${moment(dadosConflito.data_atendimento_final).format('HH:mm')}</td>
                                        </tr>
                                    </tbody>
                                </table>`
                        : ''
                    }
                        ${incluirAtendimento
                        ? 'LEMBRE-SE: Caso seja uma alteração, acesse a opção <b>Consultar e Alterar Atendimentos</b> e realize a alteração.</br>'
                        : ''
                    }

                    </p>
                </div>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    cadastra();
                }
                if (result.isDismissed) {
                    setloading(false);
                }
            })
        } else {
            cadastra();
        }
    };
    /**
     * Função que envia os dados do formulário para editar um agendamento existente.
     * @param {Object} data Dados do formulário.
     * @returns {void}
     */
    const handleSubmitEdit = async (data) => {
        setloading(true);
        let horaFinal = null;
        if (data.horaFinal.split(':').length > 1) {
            horaFinal = moment(date + 'T' + data.horaFinal).format('YYYY-MM-DD HH:mm');
        } else {
            horaFinal = moment(date + 'T' + data.horaFinal + ':00').format('YYYY-MM-DD HH:mm');
        }
        var dadosAgendamento = {
            'id': agendamentoIdLocal,
            'paciente_id': idPacienteSelecionado,
            'terapeuta_id': terapeutaSelecionado,
            'terapia_paciente_id': terapiasPacienteSelecionado,
            'terapia_id': terapiasPacienteOptSelecionado.terapia_id,
            'especialidade_id': terapiasPacienteOptSelecionado.especialidade_id,
            'modalidade_id': modalidadeSelecionado,
            'tipo_atendimento_id': tipoAgendamentoSelecionado,
            'reserva': reservaSelecionado,
            'data_atendimento_inicial': moment(date + 'T' + data.horaInicial + ':00').format('YYYY-MM-DD HH:mm'),
            'data_atendimento_final': horaFinal,
            'comentario': data.comentario,
            'estacao_id': estacao.id,
        };
        if (!incluirAtendimento && temConflitoHorarioSalaAgendamento({ ...dadosAgendamento, horarios_atendimento_id: idHorario })) {
            Swal.fire('Erro :(', 'Já existe um agendamento nesse horário e estação.', 'error');
            setloading(false);
            return;
        }
        try {
            await AgendamentoService.update(dadosAgendamento).then(snackbar.displayMessage('O agendamento foi atualizado com sucesso.', 'success'));
            updateAgenda();
            onClose();
        } catch (error) {
            setloading(false);
            Swal.fire('Erro :(', 'Um erro ocorreu ao inserir o agendamento, envie essa informação ao suporte do sistema. ' + error, 'error');
            console.error(error);
        } finally {
            setloading(false);
            onClose();
        }
    };
    /**
     * Função que lida com a mudança de paciente selecionado.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangePaciente = (e) => {
        setAlgoCarregando(true);
        const pacienteData = e;
        let terapiaPacientesList = [];
        if (pacienteData.terapia_paciente !== undefined) {
            for (let index = 0; index < pacienteData.terapia_paciente.length; index++) {
                const el = pacienteData.terapia_paciente[index];
                terapiaPacientesList.push({
                    value: el.id,
                    label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                    terapia_id: el.terapia_id,
                    especialidade_id: el.especialidade_id,
                    limite_diario_terapias: el.limite_diario_terapias,
                });
            }
        }
        setTerapiasPaciente(terapiaPacientesList);
        setTerapiasPacienteSelecionado(null);
        setTerapiasPacienteOptSelecionado(null);

        setTerapeutaSelecionado(null);
        setTerapeutaOptSelecionado(null);

        setIdPacienteSelecionado((e.value === undefined) ? e.target.value : e.value);
        setPacienteOptSelecionado(pacienteData);
        setAlgoCarregando(false);
    };
    /**
     * Função que lida com a mudança de terapia do paciente selecionada.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangeTerapiaPacientes = async (e) => {
        setAlgoCarregando(true);
        await getTerapeutas(e.especialidade_id, e.terapia_id, horarioSelected.value || hour);
        setTerapiasPacienteOptSelecionado(e);
        setTerapiasPacienteSelecionado(e.value);
        setAlgoCarregando(false);
    };
    /**
     * Função que lida com a mudança de terapeuta selecionado.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangeTerapeuta = (e) => {
        setTerapeutaSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTerapeutaOptSelecionado(e);
    };
    /**
     * Função que lida com a mudança de modalidade selecionada.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangeModalidades = (e) => {
        setModalidadesSelecionada((e.value === undefined) ? e.target.value : e.value);
        setModalidadeOptSelecionado(e);
    };
    /**
     * Função que lida com a mudança de tipo de atendimento selecionado.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangeTipoAtendimento = (e) => {
        setTipoAgendamentoSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTipoAtendimentoOptSelecionado(e);
    };
    /**
     * Função que lida com a mudança de reserva selecionada.
     * @param {Object} e Evento de mudança.
     * @returns {void}
     */
    const handleChangeReserva = (e) => {
        setReservaSelecionada((e.value === undefined) ? e.target.value : e.value);
        setReservaOptSelecionado(e);
    };
    /**
     * Função que lida com cliques fora da modal para fechá-la.
     * @param {Object} e Evento de clique.
     * @returns {void}
     */
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    //#endregion
    //#region USE EFFECTS

    useEffect(() => {// ON LOAD
        if (incluirAtendimento) {
            if (agendamentosLocal === null) {
                AtendimentoService.getByControleDiarioId(controleDiarioId, null, null, null, null).then(({ data }) => {
                    setAgendamentosLocal(data);
                });
            }
        } else {
            const horarioExterno = moment(`${date}T${hour}`);
            const defaultHorarioSelected = {
                value: horarioExterno.format('HH:mm'),
                label: horarioExterno.format('HH:mm') + ' - ' + horarioExterno.clone().add(40, 'minutes').format('HH:mm'),
                horaFim: horarioExterno.clone().add(40, 'minutes').format('HH:mm'),
                hour: horarioExterno,
            };
            setHorarioSelected(defaultHorarioSelected);
        }
        if (agendamentoIdLocal === null) {
            handleChangeOpcaoBotoes('novo');
        }
        if (esperaMode) {
            setTipoAtendimentoOptSelecionado({
                value: 8,
                label: 'Aguardando Terapeuta',
            });
            setTipoAgendamentoSelecionado(8);
            setOpcaoSelecionada('editar');
        }
    }, []);

    useEffect(async () => {
        switch (opcaoSelecionada) {
            case 'editar':
                carregaTudo();
                break;
            case 'reserva':
                await carregaTudo();
                setReservaOptSelecionado(reservaOptions[0]);
                setReservaSelecionada(reservaOptions[0].value);
                break;
            case 'subistituicao':
                await carregaTudo();
                setTipoAgendamentoSelecionado(5);
                setTipoAtendimentoOptSelecionado({ value: 5, label: 'Substituição' });
                setAgendamentoIdLocal(null);
                break;
            case 'novo':
                carregaTudo();
                break;
            default: break;
        }
    }, [agendamentoIdLocal, opcaoSelecionada]);

    useEffect(() => {
        if (terapeutaSelecionado && terapeutasOptions.length > 0) {
            setTerapeutaOptSelecionado(terapeutasOptions.find((value) => value.value === terapeutaSelecionado));
        }
    }, [terapeutaSelecionado, terapeutasOptions]);

    useEffect(() => {
        if (idPacienteSelecionado) {
            const localPacienteData = pacienteOptions.find((value) => value.value === idPacienteSelecionado);
            setPacienteOptSelecionado(localPacienteData);
        }
    }, [idPacienteSelecionado]);

    useEffect(() => {
        if (incluirAtendimento) {
            getEstacoes();
            getHorarios();
        }
    }, [incluirAtendimento]);
    useEffect(() => {
        if (terapiasPacienteOptSelecionado && pacienteOptSelecionado) {
            const limite = terapiasPacienteOptSelecionado.limite_diario_terapias;
            if (limite === 0 || limite === null || limite === undefined) {
                setLimiteAtingido(false);
            } else {
                const agendadas = agendamentosLocal.filter((filter) => {
                    return filter.paciente_id === pacienteOptSelecionado.value && filter.terapia_paciente_plano_saude_id === terapiasPacienteOptSelecionado.plano_saude_id;
                });
                if (agendadas.length > limite) {
                    setLimiteAtingido(true);
                } else {
                    setLimiteAtingido(false);
                }
            }
        }
    }, [terapiasPacienteOptSelecionado, pacienteOptSelecionado]);
    //#endregion
    //#region HTML
    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <div className='div-modal pt-0' style={{ backgroundColor: esperaMode ? '#DEE1ED' : 'white' }}>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'><ClipLoader size={22} color={'#000'} loading={algoCarregando} /></div>
                        <ModalTitulo>{(agendamentoIdLocal === null) ? 'Adicionar' : 'Editar'} {incluirAtendimento ? 'Atendimento' : esperaMode ? 'Agendamento de Espera' : 'Agendamento'}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                {(agendamentoId !== null && opcaoSelecionada === null && !esperaMode) ?
                    <div className='w-100per d-inline-flex justify-between'>
                        <button className='btn-padrao' onClick={() => handleChangeOpcaoBotoes('editar')}>Editar Agendamento</button>
                        {reservaSelecionada !== 1 &&
                            <button className='btn-padrao' onClick={() => handleChangeOpcaoBotoes('reserva')}>Adicionar Reserva</button>}
                        {tipoAtendimentoSelecionado !== 5 &&
                            <button className='btn-padrao' onClick={() => handleChangeOpcaoBotoes('subistituicao')}>Adicionar Subistituição</button>}
                    </div>
                    :
                    allLoaded ?
                        <Form onSubmit={agendamentoIdLocal === null ? handleSubmit : handleSubmitEdit} ref={formRef}>
                            {solicitacaoId && solicitacao &&
                                <div className='row'>
                                    <div className='col-12'>
                                        <Input disabled title='Solicitante: ' name='_nome_solicitante' value={solicitacao.user.nome} />
                                    </div>
                                    <div className='col-6'>
                                        <TextArea disabled title='Solicitação de Alteração' name='_descricao' value={solicitacao.descricao} />
                                    </div>
                                    <div className='col-6'>
                                        <TextArea disabled title='Justificativa' name='_motivo' value={solicitacao.motivo} />
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-12 d-flex align-items-end flex-wrap'>
                                    <SelectPaciente
                                        options={pacienteOptions}
                                        label='Paciente:'
                                        id='paciente_select'
                                        containerClass={pacienteOptSelecionado?.aviso ? 'w-95per-i m-0' : 'w-100per-i'}
                                        name='paciente'
                                        value={pacienteOptSelecionado}
                                        agendamentoId={agendamentoIdLocal}
                                        hour={momentHour}
                                        agendamentos={agendamentosLocal}
                                        setCarregando={setAlgoCarregando}
                                        setPaciente={(paciente) => {
                                            setIdPacienteSelecionado((paciente.value === undefined) ? paciente.target.value : paciente.value);
                                            setPacienteOptSelecionado(paciente);
                                            setAlgoCarregando(false);
                                        }}
                                        setTerapias={(terapias) => {
                                            setTerapiasPaciente(terapias);
                                            setTerapiasPacienteSelecionado(null);
                                            setTerapiasPacienteOptSelecionado(null);

                                            setTerapeutaSelecionado(null);
                                            setTerapeutaOptSelecionado(null);
                                        }}
                                        required={true}
                                    />
                                    {pacienteOptSelecionado?.aviso ?
                                        <button type='button' className='btn btn-outline-danger m-0 p-0 align-items-center justify-content-center d-flex border-none' data-tooltip-id='aviso-tooltip' style={{ height: '38px', width: '5%', fontSize: '18pt' }}>
                                            <HiExclamation size={35} />
                                            <Tooltip id='aviso-tooltip' className='w-400px text-left p-2' place='right' effect='solid' style={{ backgroundColor: 'darkred', fontSize: '14pt' }}>
                                                Você não deve agendar este paciente, converse com a coordenação para mais informações!
                                            </Tooltip>
                                        </button>
                                        : null
                                    }
                                </div>
                                {incluirAtendimento &&
                                    <>
                                        <div className='col-lg-6 col-sm-12'>
                                            <Select
                                                id='horaInicial'
                                                name='horaInicial'
                                                type='time'
                                                label='Hora inicial'
                                                options={horariosOptions}
                                                value={horarioSelected}
                                                onChange={handleChangeHorarioSelected}
                                                required={true} />
                                        </div>
                                        <div className='col-lg-6 col-sm-12'>
                                            <Select
                                                id='sala'
                                                name='sala'
                                                label='Sala / Estação'
                                                options={estacoesOptions}
                                                value={estacaoSelected}
                                                onChange={(e) => setEstacaoSelected(e)}
                                                required={true} />
                                        </div>
                                    </>
                                }
                                <div className='col-12 d-flex align-items-end flex-wrap'>
                                    <Select
                                        containerClass={limiteAtingido ? 'w-95per-i m-0' : 'w-100per-i'}
                                        label='Terapia do Paciente:'
                                        name='terapia_paciente'
                                        options={terapiasPacienteOptions}
                                        value={terapiasPacienteOptSelecionado}
                                        onChange={handleChangeTerapiaPacientes}
                                        required={true}
                                    />
                                    {limiteAtingido ?
                                        <button type='button' className='btn btn-outline-danger m-0 p-0 align-items-center justify-content-center d-flex border-none' data-tooltip-id='limite-tooltip' style={{ height: '38px', width: '5%', fontSize: '18pt' }}>
                                            <HiExclamation size={35} />
                                            <Tooltip id='limite-tooltip' className='w-400px text-left p-2' place='right' effect='solid' style={{ backgroundColor: 'darkred', fontSize: '14pt' }}>
                                                Limite de agendamento diário do plano atingido.
                                            </Tooltip>
                                        </button>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-8 col-sm-12 d-flex position-relative'>
                                    <SelectPlusButton
                                        label='Terapeuta:'
                                        name='Terapeuta'
                                        options={terapeutasOptions}
                                        value={terapeutaOptSelecionado}
                                        onChange={handleChangeTerapeuta}
                                        button={
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn-icone'
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    <svg style={{ alignItems: 'center' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                                        <text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
                                                    </svg>
                                                </button>
                                                <div className="dropdown-menu w-100 p-4 text-wrap" style={{ maxWidth: '400px' }}>
                                                    <p>Significados dos Status</p>
                                                    <p>
                                                        <b>Inativo:</b> Terapeuta com cadastro inativo;<br></br>
                                                        <b>Indisponível:</b> Não há horários no registro do terapeuta;<br></br>
                                                        <b>Ocupado:</b> Já existe um atendimento no mesmo horário para esse terapeuta.<br></br>
                                                    </p>
                                                </div>
                                            </>
                                        }
                                        required={true}
                                    />
                                    {esperaMode &&
                                        <button
                                            className='btn-icone position-absolute'
                                            type='button'
                                            style={{ right: '23.5%', top: '33.5px' }}
                                            onClick={() => { setTerapeutaOptSelecionado(null); setTerapeutaSelecionado(null); }}
                                        >
                                            <BsX size={22} />
                                        </button>
                                    }
                                </div>
                                <div className='col-lg-4 col-sm-12'>
                                    <Select
                                        label='Modalidade:'
                                        name='Modalidade'
                                        options={modalidadesOptionsInterno}
                                        value={modalidadeOptSelecionado}
                                        onChange={handleChangeModalidades}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12'>
                                    <Select
                                        label='Tipo de Atendimento:'
                                        name='tipoAtendimento'
                                        options={incluirAtendimento ? tiposAtendimentoOptionsInterno.filter((value) => value.value !== 8) : tiposAtendimentoOptionsInterno}
                                        value={tipoAtendimentoOptSelecionado}
                                        onChange={handleChangeTipoAtendimento}
                                        readOnly={opcaoSelecionada === 'subistituicao' || esperaMode}
                                        required={true}
                                    />
                                </div>
                                <div className='col-lg-6 col-sm-12'>
                                    <Select
                                        label='Reserva:'
                                        name='reserva'
                                        options={reservaOptions}
                                        value={reservaOptSelecionado}
                                        onChange={handleChangeReserva}
                                        readOnly={opcaoSelecionada === 'reserva' || esperaMode}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className={`col-lg-${incluirAtendimento ? '12' : '4'} col-sm-12`}>
                                    <Input
                                        defaultValue={moment(date).format('dddd').toLocaleUpperCase()}
                                        readOnly={'readOnly'}
                                        title='Dia:'
                                        name='data'
                                        required={true}
                                    />
                                </div>
                                {!incluirAtendimento &&
                                    <>
                                        <div className='col-lg-4 col-sm-12'>
                                            <Input
                                                defaultValue={moment(momentHour).format('HH:mm')}
                                                type='time'
                                                title='Hora inicial'
                                                name='horaInicial'
                                                required={true}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-sm-12'>
                                            <Input
                                                defaultValue={moment(momentHour).add(40, 'Minutes').format('HH:mm')}
                                                type='time'
                                                title='Hora final'
                                                name='horaFinal'
                                                required={true}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-sm-12'>
                                            <Input
                                                readOnly={'readOnly'}
                                                title='Sala:'
                                                name='sala'
                                                defaultValue={estacao?.sala?.sala}
                                                required={true}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-sm-12'>
                                            <Input
                                                readOnly={'readOnly'}
                                                title='Estação:'
                                                name='estacao'
                                                defaultValue={estacao?.estacao}
                                                required={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <TextArea
                                title='Comentário:'
                                name='comentario'
                                defaultValue={selectedAgendamento?.comentario}
                            />
                            <div className='col-12 mt-20px d-flex justify-content-between'>
                                {solicitacaoId && solicitacao &&
                                    <button className='btn-padrao btn-vermelho' type='button' onClick={handleReprovarSolicitacao}>
                                        Reprovar Solicitação
                                    </button>
                                }
                                <button className='btn-padrao' type='submit' disabled={loading}>
                                    {loading ? <ClipLoader size={22} /> : 'Confirmar'}
                                </button>
                            </div>
                        </Form>
                        : <ClipLoader />
                }
            </div>
        </Modal >
    );
    //#endregion=====================
}