import React, { useImperativeHandle, useState, useEffect, forwardRef, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

//#region ESTILOS
const MultiSelectSwitchContainer = styled.div`
    color: black;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 10px;

    .ms-title {
        margin: 10px 0px 10px 10px;

        .ms-error{
            color: orange;
        }
    }

    .ms-div-filter {
        margin-bottom: 10px;
    }

    .ms-container-options {
        max-height: 200px;
        overflow-y: auto;

        .ms-group-title{
            // Organização interna
            display: flex;
            align-items: center;
            justify-content: space-between;
            // Espaçamento
            margin: 0px;
            padding: 5px 10px 5px 10px;
            // Estilo
            background-color: #eff7ff;
            border: 1px solid #dee2e6;
            border-radius: 5px;
        }

        .ms-ul-options {
            // Organização interna
            display: grid;
            grid-template-columns: repeat(${props => Math.min(Math.max(props.colunas, 1), 4)}, 1fr);
            // Estilo
            list-style-type: none;
            border-left: 1px solid #dee2e6;
            // Espaçamento
            gap: 10px;
            margin: 0;
            padding: 0;
            margin-left: 30px;
            li {
                // Espaçamento
                padding: 5px 10px 5px 10px;
                margin-bottom: 10px;
            }
        }
    }
`;
//#endregion

const MultiSelectSwitch = forwardRef(({
    id = 'default-multi-select-switch',
    name = 'default-multi-select-switch',
    options,
    label,
    containerClass = 'w-100 d-flex flex-column',
    labelClass = 'mt-2 font-size-13px',
    required,
    colunas = 1,
    agrupaOpcoes = false,
    carregando = false
}, ref) => {
    //#region VARIAVEIS
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filter, setFilter] = useState('');
    const [groupedOptions, setGroupedOptions] = useState();

    const filteredOptions = useMemo(() => (
        groupedOptions
            ? Object.keys(groupedOptions).reduce((acc, group) => {
                if (group.toLowerCase().includes(filter.toLowerCase())) {
                    acc[group] = groupedOptions[group];
                } else {
                    const filteredGroup = groupedOptions[group].filter(option => option.name.toLowerCase().includes(filter.toLowerCase()));
                    if (filteredGroup.length) {
                        acc[group] = filteredGroup;
                    }
                }
                return acc;
            }, {})
            : []
    ), [groupedOptions, filter]);

    const flatFilteredOptions = useMemo(() => (
        groupedOptions && groupedOptions['sem_grupos']
            ? groupedOptions['sem_grupos'].filter(option => option.name.toLowerCase().includes(filter.toLowerCase()))
            : []
    ), [groupedOptions, filter]);

    //#endregion

    //#region EXPORT REFERENCE
    useImperativeHandle(ref, () => ({
        getSelectedOptions: () => selectedOptions,
        setSelectedOptions: (options) => setSelectedOptions(options),
        reset: () => {
            setSelectedOptions([]);
            setFilter('');
            handleCriaInicial();
        }
    }));
    //#endregion

    //#region HANDLES
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleGroupChange = (group, selectAll) => {
        if (group === 'sem_grupos') {
            setGroupedOptions(prevGroupedOptions => {
                const updatedGroup = prevGroupedOptions[group].map(option => ({
                    ...option,
                    checked: selectAll
                }));
                return {
                    ...prevGroupedOptions,
                    [group]: updatedGroup
                };
            });

            setSelectedOptions(prevSelectedOptions => {
                if (selectAll) {
                    const newOptions = groupedOptions[group].filter(option => !prevSelectedOptions.includes(option.value));
                    return [...prevSelectedOptions, ...newOptions.map(option => option.value)];
                } else {
                    return prevSelectedOptions.filter(option => !groupedOptions[group].some(groupOption => groupOption.value === option));
                }
            });
        } else {
            setGroupedOptions(prevGroupedOptions => {
                const updatedGroup = prevGroupedOptions[group].map(option => ({
                    ...option,
                    checked: selectAll
                }));
                return {
                    ...prevGroupedOptions,
                    [group]: updatedGroup
                };
            });

            setSelectedOptions(prevSelectedOptions => {
                if (selectAll) {
                    const newOptions = groupedOptions[group].filter(option => !prevSelectedOptions.includes(option.value));
                    return [...prevSelectedOptions, ...newOptions.map(option => option.value)];
                } else {
                    return prevSelectedOptions.filter(option => !groupedOptions[group].some(groupOption => groupOption.value === option));
                }
            });
        }
    };

    const handleOptionChange = (option) => {
        setGroupedOptions(prevGroupedOptions => {
            if (option.group) {
                const updatedGroup = prevGroupedOptions[option.group]?.map(opt =>
                    opt.value === option.value ? { ...opt, checked: !opt.checked } : opt
                );
                return {
                    ...prevGroupedOptions,
                    [option.group]: updatedGroup
                };
            } else {
                const updatedOptions = prevGroupedOptions['sem_grupos']?.map(opt =>
                    opt.value === option.value ? { ...opt, checked: !opt.checked } : opt
                );
                return {
                    ...prevGroupedOptions,
                    'sem_grupos': updatedOptions
                };
            }
        });

        setSelectedOptions(prevSelectedOptions =>
            option.checked
                ? [...prevSelectedOptions, option.value]
                : prevSelectedOptions.filter(opt => opt !== option.value)
        );
    };

    const handleSelectAllChange = (selectAll) => {
        if (groupedOptions['sem_grupos']) {
            const updatedGroupedOptions = {
                'sem_grupos': groupedOptions['sem_grupos'].map(option => ({
                    ...option,
                    checked: selectAll
                }))
            };

            setGroupedOptions(updatedGroupedOptions);

            if (selectAll) {
                const allOptions = updatedGroupedOptions['sem_grupos'].map(option => option.value);
                setSelectedOptions(allOptions);
            } else {
                setSelectedOptions([]);
            }
        } else {
            const updatedGroupedOptions = Object.keys(filteredOptions).reduce((acc, group) => {
                const updatedGroup = filteredOptions[group].map(option => ({
                    ...option,
                    checked: selectAll
                }));
                acc[group] = updatedGroup;
                return acc;
            }, {});

            setGroupedOptions(updatedGroupedOptions);

            if (selectAll) {
                const allOptions = Object.values(updatedGroupedOptions).flat().map(option => option.value);
                setSelectedOptions(allOptions);
            } else {
                setSelectedOptions([]);
            }
        }
    };

    const handleCriaInicial = () => {
        if (options && Array.isArray(options)) {
            if (agrupaOpcoes) {
                const mappedOptions = options.map((map) => ({
                    ...map,
                    name: map.name,
                    checked: map.defaultChecked || false
                }));
                const groupedOptions = mappedOptions.reduce((groups, option) => {
                    const group = option.group || 'Outros';
                    if (!groups[group]) {
                        groups[group] = [];
                    }
                    groups[group].push(option);
                    return groups;
                }, {});
                setGroupedOptions(groupedOptions);
            } else {
                const mappedOptions = options.map((map) => ({
                    ...map,
                    name: `${map.group ? `${map.group}/` : ''}${map.name || map.label}`,
                    checked: map.defaultChecked || false
                }));
                setGroupedOptions({ 'sem_grupos': mappedOptions });
            }
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        handleCriaInicial();
    }, [options, agrupaOpcoes]);
    //#endregion

    //#region RENDER
    return (
        <MultiSelectSwitchContainer className={containerClass} colunas={colunas} id={id} data-name={name}>
            {label && <label className={labelClass + ' ms-title'}><b className='ms-error' style={{ color: 'orange' }}>{required ? '*' : ''}</b>{label}</label>}
            <div className='ms-div-filter'>
                <div className="ms-filter1">
                    <input
                        id={`${id}.ms-switch-filter`}
                        name='ms-switch-filter'
                        type="text"
                        className="form-control"
                        placeholder={'Filtrar por opção ou grupo...'}
                        value={filter}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="form-check form-switch me-2">
                    <label className="form-check-label" htmlFor="select-all">Todos</label>
                    <input
                        id={`${id}.select-all`}
                        name="select-all"
                        className="form-check-input switch-roxo"
                        type="checkbox"
                        role="switch"
                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                </div>
            </div>
            <div className='ms-container-options'>
                {
                    carregando
                        ? <span>
                            Carregando... <ClipLoader color={'#7340BD'} loading={carregando} size={20} />
                        </span>
                        :
                        agrupaOpcoes && filteredOptions && Object.keys(filteredOptions).length > 0
                            ? Object.keys(filteredOptions).map((group, index) => {
                                const hasGroup = group && group !== 'sem_grupos';
                                return (
                                    <div key={index}>
                                        {hasGroup && (
                                            <div className="ms-group-title form-check form-switch me-2">
                                                <div className="form-check form-switch me-2">
                                                    <label className="form-check-label" htmlFor={`${id}.g${group}`}> {group} </label>
                                                    <input
                                                        id={`${id}.g${group}`}
                                                        name={`group-${group}`}
                                                        className="form-check-input switch-roxo"
                                                        type="checkbox"
                                                        role="switch"
                                                        onChange={(e) => handleGroupChange(group, e.target.checked)}
                                                        defaultChecked={!filteredOptions[group]?.some(some => some.checked === false)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <ul className='ms-ul-options'>
                                            {filteredOptions[group]?.map((option, index) => (
                                                <li key={index}>
                                                    <div className={`form-check form-switch me-2`}>
                                                        <label className="form-check-label" htmlFor={`${id}.o${index}`}> {option.name} </label>
                                                        <input
                                                            className="form-check-input switch-roxo"
                                                            type="checkbox"
                                                            role="switch"
                                                            id={`${id}.o${index}`}
                                                            name={option.name}
                                                            value={option.value}
                                                            checked={option.checked}
                                                            onChange={() => handleOptionChange(option)}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                );
                            })
                            : flatFilteredOptions.length > 0
                                ? <ul className='ms-ul-options'>
                                    {flatFilteredOptions.map((option, index) => (
                                        <li key={index}>
                                            <div className={`form-check form-switch me-2`}>
                                                <label className="form-check-label" htmlFor={`${id}.o${index}`}> {option.name} </label>
                                                <input
                                                    className="form-check-input switch-roxo"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`${id}.o${index}`}
                                                    name={option.name}
                                                    value={option.value}
                                                    checked={option.checked}
                                                    onChange={() => handleOptionChange(option)}
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                : <span>
                                    Sem opções
                                </span>
                }
            </div>
        </MultiSelectSwitchContainer >
    );
    //#endregion
});

export { MultiSelectSwitch };