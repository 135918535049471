import { Form } from '@unform/web';
import Button from 'components/Button';
import A4Page from 'components/Folha/A4-Retrato';
import AssinaturaA4 from 'components/Folha/Modulos/Assinatura';
import Input from 'components/Input';
import Select from 'components/Select';
import { useEffect, useMemo, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { ClipLoader, ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import BloqueiaArea from 'components/BloqueiaArea';
import useIcons from 'context/icons';

const LongLabelBase = (props) => {
    return (
        <span>
            <b className={props.className[0] !== undefined ? props.className[0] : 'font-12px'}>{props.dayName[0]}</b>
            <span className={props.className[1] !== undefined ? props.className[1] : 'font-8px'}>{props.dayName.slice(1, props.dayName.length)}</span>
        </span>
    );
};

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

const DeclaracoesDemonstracao = () => {
    const { iconArray } = useIcons();
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [pacienteId, setPacienteId] = useState(null);
    const [paciente, setPaciente] = useState(null);
    const [agendamentos, setAgendamentos] = useState(null);
    const [atendimentos, setAtendimentos] = useState(null);
    const [loading, setLoading] = useState(false);
    const [carregadoDados, setCarregadoDados] = useState(false);
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [orientation, setOrientation] = useState('portrait');

    const diasDaSemanaArray = [
        { value: 1, label: 'S', longLabel: <LongLabelBase dayName='Segunda-Feira' className={['', '']} /> },
        { value: 2, label: 'T', longLabel: <LongLabelBase dayName='Terça-Feira' className={['', '']} /> },
        { value: 3, label: 'Q', longLabel: <LongLabelBase dayName='Quarta-Feira' className={['', '']} /> },
        { value: 4, label: 'Q', longLabel: <LongLabelBase dayName='Quinta-Feira' className={['', '']} /> },
        { value: 5, label: 'S', longLabel: <LongLabelBase dayName='Sexta-Feira' className={['', '']} /> },
        { value: 6, label: 'S', longLabel: <LongLabelBase dayName='Sábado' className={['', '']} /> },
    ];

    const pacienteOptions = [
        { value: 1, label: 'Paciente 1' },
        { value: 2, label: 'Paciente 2' },
        { value: 3, label: 'Paciente 3' },
    ];

    const handleLimpaConsulta = () => {
        setDataInicio('');
        setDataFim('');
        setPacienteId(null);
        setPaciente(null);
        setAgendamentos(null);
        setAtendimentos(null);
        setCarregadoDados(null);
    };

    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        setAtendimentos(null);

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                break;
            case 'data_fim':
                setDataFim(value);
                break;
            case 'paciente_id':
                setPacienteId(pacienteOptions.find(option => option.value === value));
                break;
            default:
                break;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!pacienteId) {
                Swal.fire('Erro', 'Selecione um paciente!', 'error');
                setLoading(false);
                setCarregadoDados(null);
                return;
            }
            // Simula carregamento de dados
            setPaciente({ nome: 'Paciente Exemplo' });
            setAtendimentos([
                { id: 1, data_do_atendimento: '01/01/2023', hora_atendimento: '10:00', terapia: 'Terapia 1', terapeuta_nome: 'Terapeuta 1' },
                { id: 2, data_do_atendimento: '02/01/2023', hora_atendimento: '11:00', terapia: 'Terapia 2', terapeuta_nome: 'Terapeuta 2' },
                { id: 3, data_do_atendimento: '03/01/2023', hora_atendimento: '12:00', terapia: 'Terapia 3', terapeuta_nome: 'Terapeuta 3' },
                { id: 4, data_do_atendimento: '04/01/2023', hora_atendimento: '13:00', terapia: 'Terapia 4', terapeuta_nome: 'Terapeuta 4' },
                { id: 5, data_do_atendimento: '05/01/2023', hora_atendimento: '14:00', terapia: 'Terapia 5', terapeuta_nome: 'Terapeuta 5' },
                { id: 6, data_do_atendimento: '06/01/2023', hora_atendimento: '15:00', terapia: 'Terapia 6', terapeuta_nome: 'Terapeuta 6' },
                { id: 7, data_do_atendimento: '07/01/2023', hora_atendimento: '16:00', terapia: 'Terapia 7', terapeuta_nome: 'Terapeuta 7' },
                { id: 8, data_do_atendimento: '08/01/2023', hora_atendimento: '17:00', terapia: 'Terapia 8', terapeuta_nome: 'Terapeuta 8' },
                { id: 9, data_do_atendimento: '09/01/2023', hora_atendimento: '18:00', terapia: 'Terapia 9', terapeuta_nome: 'Terapeuta 9' },
                { id: 10, data_do_atendimento: '10/01/2023', hora_atendimento: '19:00', terapia: 'Terapia 10', terapeuta_nome: 'Terapeuta 10' },
                { id: 11, data_do_atendimento: '11/01/2023', hora_atendimento: '10:00', terapia: 'Terapia 11', terapeuta_nome: 'Terapeuta 11' },
                { id: 12, data_do_atendimento: '12/01/2023', hora_atendimento: '11:00', terapia: 'Terapia 12', terapeuta_nome: 'Terapeuta 12' },
                { id: 13, data_do_atendimento: '13/01/2023', hora_atendimento: '12:00', terapia: 'Terapia 13', terapeuta_nome: 'Terapeuta 13' },
                { id: 14, data_do_atendimento: '14/01/2023', hora_atendimento: '13:00', terapia: 'Terapia 14', terapeuta_nome: 'Terapeuta 14' },
                { id: 15, data_do_atendimento: '15/01/2023', hora_atendimento: '14:00', terapia: 'Terapia 15', terapeuta_nome: 'Terapeuta 15' },
                { id: 16, data_do_atendimento: '16/01/2023', hora_atendimento: '15:00', terapia: 'Terapia 16', terapeuta_nome: 'Terapeuta 16' },
                { id: 17, data_do_atendimento: '17/01/2023', hora_atendimento: '16:00', terapia: 'Terapia 17', terapeuta_nome: 'Terapeuta 17' },
            ]);
            setAgendamentos([
                { dia_semana_index: 1, terapia: 'Terapia 1', terapeuta_nome: 'Terapeuta 1' },
                { dia_semana_index: 2, terapia: 'Terapia 2', terapeuta_nome: 'Terapeuta 2' },
            ]);
            setLoading(false);
            setCarregadoDados(true);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setCarregadoDados(false);
        }
    };

    const handleExport = (mode) => {
        const title = mode === 'comparecimento' ? 'Declaração de Comparecimento' : 'Declaração de Atendimentos Feitos';
        const content = (
            <div className='w-100'>
                <p className='font-size-24px'>
                    Declaramos para os devidos fins que o(a) paciente: {paciente?.nome} realizou os atendimentos terapêuticos nas datas e horários listados abaixo:
                </p>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Horário</th>
                            <th>Terapia</th>
                            <th>Terapeuta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {atendimentos.map((atendimento) => (
                            <tr key={atendimento.id}>
                                <td>{atendimento.data_do_atendimento}</td>
                                <td>{atendimento.hora_atendimento}</td>
                                <td>{atendimento.terapia}</td>
                                <td>{atendimento.terapeuta_nome}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <AssinaturaA4 className="col-8 mx-auto" />
            </div>
        );

        setTitle(title);
        setContent(content);
        setFilename(`${title} - ${paciente?.nome}`);
        setOrientation('portrait');
        setIsGeraDocumentoOpen(true);
    };

    useEffect(() => {
        // Simula carregamento de opções de paciente
    }, []);

    return (
        <>
            {isGeraDocumentoOpen === true
                ? <A4Page
                    hasWord={false}
                    onCloseClick={() => setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                    hasScrollBar={false}
                    orientation={orientation}
                />
                : <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mx-auto'>
                        <Form onSubmit={handleSubmit} className='d-flex flex-col d-flex flex-wrap gy-4 p-4 justify-content-center col-12'>
                            <fieldset className="ns-fieldset col-12">
                                <legend>Parâmetros</legend>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-12' id='filter_data_inicio'>
                                        <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" disabled={carregadoDados} value={dataInicio} onChange={handleChange} />
                                    </div>
                                    <div className='col-lg-6 col-sm-12' id='filter_data_fim'>
                                        <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" disabled={carregadoDados} value={dataFim} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12' id='filter_paciente_id'>
                                        <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" isDisabled={carregadoDados} options={pacienteOptions} isClearable={true} value={pacienteId} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} required />
                                    </div>
                                </div>
                            </fieldset>
                            <div className='col-12 d-flex justify-content-end mt-30px px-2'>
                                <Button type="submit" className={'searching border-radios-n-right'} disabled={loading || carregadoDados} title={loading ? 'Carregando...' : ''}>
                                    {loading
                                        ? <ClipLoader size={20} color={'white'} />
                                        : <span>
                                            {iconArray['pesquisar']} Pesquisar
                                        </span>}
                                </Button>
                                <Button type="button" className={'w-fit-content-i border-radios-n-left'} onClick={handleLimpaConsulta} disabled={!carregadoDados}>
                                    {iconArray['limpar']}
                                </Button>
                            </div>
                        </Form>
                        {loading &&
                            <div className='col-12 text-center'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                </div>
                            </div>
                        }
                        <BloqueiaArea bloqueado={!carregadoDados} bloqueadoTexto='Selecione um paciente e um período para gerar declarações.'>
                            <fieldset className="ns-fieldset col-12 bg-white mx-auto">
                                <legend>Declarações</legend>
                                <div className='row p-0 m-0 justify-content-center'>
                                    <Button
                                        className='w-fit-content-i flex-column align-items-center mx-4'
                                        onClick={() => handleExport('comparecimento')}
                                        disabled={!carregadoDados}>
                                        <FaFilePdf size={30} />Declaração 1
                                    </Button>
                                    <Button
                                        className='w-fit-content-i flex-column align-items-center mx-4'
                                        onClick={() => handleExport('comparecimento')}
                                        disabled={!carregadoDados}>
                                        <FaFilePdf size={30} />Declaração 2
                                    </Button>
                                    <Button
                                        className='w-fit-content-i flex-column align-items-center mx-4'
                                        onClick={() => handleExport('comparecimento')}
                                        disabled={!carregadoDados}>
                                        <FaFilePdf size={30} />Declaração 3
                                    </Button>
                                    <Button
                                        className='w-fit-content-i flex-column align-items-center mx-4'
                                        onClick={() => handleExport('atendimentos')}
                                        disabled={!carregadoDados}>
                                        <FaFilePdf size={30} />Declaração<br></br>Final
                                    </Button>
                                </div>
                            </fieldset>
                        </BloqueiaArea>
                    </div>
                </div>
            }
        </>
    );
};

export default DeclaracoesDemonstracao;
