import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsFullscreen } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { Container, DivContentDisplay, listOfStyles } from './styles';
import DemonstracaoTbodyAgendaSemanal from './tbody-agenda-semana';
import Carregamento from 'components/Carregamento';
import { usePageBase } from 'context/page-base';
import { getLegendaAgendamento } from 'helpers/agenda.helper';
import { styles_filtred } from './styles';
import { styles_escuro } from './styles';

const AgendaSemanalDemonstracao = () => {
    usePageBase({
        title: 'Agenda Semanal - Demonstração',
        description: 'Tela de demonstração da agenda semanal com dados falsos.',
        hasTitle: true,
        hasSubmenu: true
    });

    const stylesSize = [90, 70, 90, 70];
    const differenceBase = [8, 358];
    const stylesNormal = useMemo(() => listOfStyles({
        baseWidth: stylesSize[0],
        baseHeight: stylesSize[1],
        baseWidthHora: stylesSize[2],
        baseHeightHora: stylesSize[3],
        differenceBase
    }), [stylesSize]);

    const [styles, setStyles] = useState(stylesNormal);
    const [loading, setLoading] = useState(false);
    const [dataSave, setDataSave] = useState(moment());
    const [diaAtual, setDiaAtual] = useState(moment().day());
    const [agendamentos, setAgendamentos] = useState();
    const [newAgendamentos, setNewAgendamentos] = useState();
    const [horarios, setHorarios] = useState(generateFakeHorarios());
    const [estacaos, setEstacaos] = useState(generateFakeEstacaos());

    const fakeTerapeutas = [
        'Terapeuta 1', 'Terapeuta 2', 'Terapeuta 3', 'Terapeuta 4', 'Terapeuta 5',
        'Terapeuta 6', 'Terapeuta 7', 'Terapeuta 8', 'Terapeuta 9', 'Terapeuta 10'
    ];

    const fakePacientes = [
        'Paciente 1', 'Paciente 2', 'Paciente 3', 'Paciente 4', 'Paciente 5',
        'Paciente 6', 'Paciente 7', 'Paciente 8', 'Paciente 9', 'Paciente 10'
    ];

    function generateFakeAgendamentos() {
        const fakeAgendamentos = [];
        for (let index = 0; index < 100; index++) {
            const randomHorario = horarios[Math.floor(Math.random() * horarios.length)];
            const randomEstacao = estacaos[Math.floor(Math.random() * estacaos.length)];
            const randomTerapeuta = fakeTerapeutas[Math.floor(Math.random() * fakeTerapeutas.length)];
            const randomPaciente = fakePacientes[Math.floor(Math.random() * fakePacientes.length)];
            const existingAgendamentos = fakeAgendamentos.filter(agendamento =>
                agendamento.horarios_atendimento_id === randomHorario.id &&
                agendamento.estacao_id === randomEstacao.id
            );

            const sameHorarioTerapeuta = fakeAgendamentos.filter(agendamento =>
                agendamento.horarios_atendimento_id === randomHorario.id &&
                agendamento.terapeuta_nome === randomTerapeuta
            );

            if (existingAgendamentos.length < 2 && sameHorarioTerapeuta.length < 1) {
                fakeAgendamentos.push({
                    id: index,
                    paciente_id: randomEstacao.id,
                    paciente_nome: randomPaciente,
                    terapeuta_nome_curto: randomTerapeuta,
                    data_atendimento_inicial: randomHorario.horario,
                    data_atendimento_final: moment(randomHorario.horario).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
                    estacao_id: randomEstacao.id,
                    horarios_atendimento_id: randomHorario.id,
                    paciente_identificador: `P${randomEstacao.id}`,
                    terapeuta_nome: randomTerapeuta,
                    reserva: existingAgendamentos.length === 1 ? 1 : 0,
                    tipo_atendimento_id: 1,
                    paciente_status: '',
                    terapeuta_status: '',
                    paciente_aviso_agendamento_check: 0,
                    terapia_terapia: `Terapia ${randomEstacao.id}`,
                    especialidade_especialidade: `Especialidade ${randomEstacao.id}`,
                });
            }
        }

        const newAgendamentosL = {};
        const pacientes = [];

        for (let index = 0; index < fakeAgendamentos.length; index++) {
            const atendimento = fakeAgendamentos[index];
            if (pacientes.filter(filter => filter.value === atendimento?.paciente_id).length <= 0) {
                pacientes.push({
                    value: atendimento?.paciente_id,
                    nome: atendimento?.paciente_nome,
                    identificador: atendimento?.paciente_identificador,
                    label: `(${atendimento?.paciente_identificador}) ${atendimento?.paciente_nome}`
                });
            }
            if (!newAgendamentosL[atendimento.estacao_id]) {
                newAgendamentosL[atendimento.estacao_id] = {};
            }
            if (!newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id]) {
                newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id] = [];
            }
            newAgendamentosL[atendimento.estacao_id][atendimento.horarios_atendimento_id].push(
                (atendimento.prop && atendimento.prop.constructor === Array)
                    ? atendimento.reverse()
                    : atendimento
            );
        }

        setAgendamentos(fakeAgendamentos);
        setNewAgendamentos(newAgendamentosL);
    }

    function generateFakeHorarios() {
        return [
            { id: 1, horario: moment().format('YYYY-MM-DDTHH:mm:ss') },
            { id: 2, horario: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 3, horario: moment().add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 4, horario: moment().add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 5, horario: moment().add(4, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 6, horario: moment().add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 7, horario: moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 8, horario: moment().add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 9, horario: moment().add(8, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 10, horario: moment().add(9, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 11, horario: moment().add(10, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 12, horario: moment().add(11, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 13, horario: moment().add(12, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 14, horario: moment().add(13, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 15, horario: moment().add(14, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 16, horario: moment().add(15, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 17, horario: moment().add(16, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 18, horario: moment().add(17, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 19, horario: moment().add(18, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
            { id: 20, horario: moment().add(19, 'hours').format('YYYY-MM-DDTHH:mm:ss') },
        ];
    }

    function generateFakeEstacaos() {
        return [
            { id: 1, estacao: 'Estação 1', sala: { sala: 'Sala 1', responsavel_salas: [{ terapeutum: { nome: 'Responsável 1' } }] } },
            { id: 2, estacao: 'Estação 2', sala: { sala: 'Sala 2', responsavel_salas: [{ terapeutum: { nome: 'Responsável 2' } }] } },
            { id: 3, estacao: 'Estação 3', sala: { sala: 'Sala 3', responsavel_salas: [{ terapeutum: { nome: 'Responsável 3' } }] } },
            { id: 4, estacao: 'Estação 4', sala: { sala: 'Sala 4', responsavel_salas: [{ terapeutum: { nome: 'Responsável 4' } }] } },
            { id: 5, estacao: 'Estação 5', sala: { sala: 'Sala 5', responsavel_salas: [{ terapeutum: { nome: 'Responsável 5' } }] } },
            { id: 6, estacao: 'Estação 6', sala: { sala: 'Sala 6', responsavel_salas: [{ terapeutum: { nome: 'Responsável 6' } }] } },
            { id: 7, estacao: 'Estação 7', sala: { sala: 'Sala 7', responsavel_salas: [{ terapeutum: { nome: 'Responsável 7' } }] } },
            { id: 8, estacao: 'Estação 8', sala: { sala: 'Sala 8', responsavel_salas: [{ terapeutum: { nome: 'Responsável 8' } }] } },
            { id: 9, estacao: 'Estação 9', sala: { sala: 'Sala 9', responsavel_salas: [{ terapeutum: { nome: 'Responsável 9' } }] } },
            { id: 10, estacao: 'Estação 10', sala: { sala: 'Sala 10', responsavel_salas: [{ terapeutum: { nome: 'Responsável 10' } }] } },
            { id: 11, estacao: 'Estação 11', sala: { sala: 'Sala 11', responsavel_salas: [{ terapeutum: { nome: 'Responsável 11' } }] } },
            { id: 12, estacao: 'Estação 12', sala: { sala: 'Sala 12', responsavel_salas: [{ terapeutum: { nome: 'Responsável 12' } }] } },
            { id: 13, estacao: 'Estação 13', sala: { sala: 'Sala 13', responsavel_salas: [{ terapeutum: { nome: 'Responsável 13' } }] } },
            { id: 14, estacao: 'Estação 14', sala: { sala: 'Sala 14', responsavel_salas: [{ terapeutum: { nome: 'Responsável 14' } }] } },
            { id: 15, estacao: 'Estação 15', sala: { sala: 'Sala 15', responsavel_salas: [{ terapeutum: { nome: 'Responsável 15' } }] } },
            { id: 16, estacao: 'Estação 16', sala: { sala: 'Sala 16', responsavel_salas: [{ terapeutum: { nome: 'Responsável 16' } }] } },
            { id: 17, estacao: 'Estação 17', sala: { sala: 'Sala 17', responsavel_salas: [{ terapeutum: { nome: 'Responsável 17' } }] } },
            { id: 18, estacao: 'Estação 18', sala: { sala: 'Sala 18', responsavel_salas: [{ terapeutum: { nome: 'Responsável 18' } }] } },
            { id: 19, estacao: 'Estação 19', sala: { sala: 'Sala 19', responsavel_salas: [{ terapeutum: { nome: 'Responsável 19' } }] } },
            { id: 20, estacao: 'Estação 20', sala: { sala: 'Sala 20', responsavel_salas: [{ terapeutum: { nome: 'Responsável 20' } }] } },
        ];
    }

    const handleClickNextDay = () => {
        let dataSaveLocal = moment(dataSave).add(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).add(2, 'days');
        }
        setDataSave(dataSaveLocal);
        setDiaAtual(dataSaveLocal.day());
    };

    const handleClickPrevDay = () => {
        let dataSaveLocal = moment(dataSave).subtract(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).subtract(2, 'days');
        }
        setDataSave(dataSaveLocal);
        setDiaAtual(dataSaveLocal.day());
    };

    const handleSelectDay = (e) => {
        const dia = e.target.value;
        const dataSaveLocal = moment().day(dia);
        setDataSave(dataSaveLocal);
        setDiaAtual(dia);
    };

    useEffect(() => {
        generateFakeAgendamentos();
    }, []);

    return (
        <Container>
            {loading && <Carregamento />}
            <div style={styles.containerFlexColumn}>
                <div style={{ backgroundColor: '#f6f6f9 !important', maxWidth: '100vw' }}>
                    <div style={styles.tr0}>
                        <span style={{ width: 400 }}></span>
                        <div id='div-navegacao-dias' className='p-1 justify-content-around d-flex align-center' style={{ width: '300px' }}>
                            <AiOutlineLeft id='icone-navegacao-atras' className='pointer' color="black" size={30} onClick={handleClickPrevDay} />
                            <div id='input-navegacao-livre'>
                                <select className='texto-navegacao'
                                    style={{ border: 'none', backgroundColor: 'transparent', padding: '0', margin: '0' }}
                                    value={diaAtual}
                                    onChange={handleSelectDay}>
                                    {['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'].map((dia, index) => {
                                        return <option key={index} value={index}>{dia}</option>;
                                    })}
                                </select>
                                <br></br>
                            </div>
                            <AiOutlineRight id='icone-navegacao-frente' className='pointer' color="black" size={30} onClick={handleClickNextDay} />
                        </div>
                        <div id='caixa-de-ferramentas' className='d-inline-flex justify-content-end align-center' style={{ width: '400px' }}>
                            <div className='d-flex justify-center align-center px-2'>
                                <span className='font-roxo-claro3'>{agendamentos?.length}</span>
                            </div>
                            <button id='botao-refresh' type='button' className={'btn-outline roda-hover pointer mr-10px px-2'} onClick={() => setLoading(true)}>
                                <FiRefreshCcw className={`font-roxo-claro3${loading ? ' rodando' : ''}`} size={30} />
                            </button>
                            <button id='botao-fullscreen' className='px-2' onClick={() => setStyles(stylesNormal)}>
                                <BsFullscreen size={25} color='rgb(149, 134, 172)' stroke="rgb(149, 134, 172)" strokeWidth="2" fontWeight='bold' fontSize='14' />
                            </button>
                        </div>
                    </div>
                    <div id='div-agenda' className='d-inline-flex'>
                        <table className='table-agenda-terapeuta' id='label-e-horas' style={styles.table}>
                            <thead style={styles.thead}>
                                <tr key={'linha-salas'} style={styles.thead.trS}>
                                    <th key={'coluna-salas'} style={styles.tr1}>Salas</th>
                                </tr>
                                <tr key={'linha-estacoes'} style={styles.thead.trE}>
                                    <th key={'coluna-estacoes'} style={styles.tr1}>Estações</th>
                                </tr>
                                <tr key={'linha-responsaveis'} style={styles.thead.trR}>
                                    <th key={'coluna-responsaveis'} style={styles.tr1}>Resp.</th>
                                </tr>
                            </thead>
                            <tbody id='tbody-horarios' style={styles.tbody0}>
                                {horarios.map((horario, index) => (
                                    <tr key={'linha-horario-' + index} style={styles.tbody.tr}>
                                        <td key={'coluna-horario-' + index} id={'linha-horario-' + horario.id} style={styles.trHora} align={'center'}>
                                            {moment.utc(horario.horario).format('HH:mm')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className='table-agenda-terapeuta' id='salas-e-agendamentos' style={styles.table}>
                            <thead id='thead-sala-estacao' style={styles.thead}>
                                <tr key={'linha-sala-cont'} style={styles.thead.tr}>
                                    {estacaos.map((estacao, index) => (
                                        <td key={'coluna-sala-cont-' + index} id={'coluna-sala-cont-' + estacao.id} align={'center'} style={styles.tr2} className='font-14px'>
                                            {estacao.sala.sala}
                                        </td>
                                    ))}
                                </tr>
                                <tr key={'linha-estacao-cont'} style={styles.thead.tr}>
                                    {estacaos.map((estacao, index) => (
                                        <td key={'coluna-estacao-cont-' + index} id={'coluna-estacao-cont-' + estacao.id} align={'center'} style={styles.tr2} className='font-14px'>
                                            {estacao.estacao}
                                        </td>
                                    ))}
                                </tr>
                                <tr id={'linha-responsavel-cont'} key={'linha-responsavel-cont'} style={styles.thead.tr}>
                                    {estacaos.map((estacao, index) => (
                                        <td key={'coluna-responsavel-cont-' + index} id={'coluna-responsavel-cont-' + estacao.id} align={'center'} style={{ ...styles.tr2, height: '55px' }} className='font-14px'>
                                            {estacao.sala.responsavel_salas.map(responsaveis => responsaveis.terapeutum.nome.substring(0, 15) + (responsaveis.terapeutum.nome.length > 15 ? '...' : '')).join(', ')}
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <DemonstracaoTbodyAgendaSemanal
                                id='tbody-agendamentos'
                                agendamentos={agendamentos}
                                newAgendamentos={newAgendamentos}
                                getLegendaAgendamento={getLegendaAgendamento}
                                memorizedHorarios={horarios}
                                memorizedEstacaos={estacaos}
                                styles={styles}
                                styles_filtred={styles_filtred}
                                styles_escuro={styles_escuro}
                                showAgendamentos={true}
                            />
                        </table>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default AgendaSemanalDemonstracao;
