import React from 'react';
import { Container } from './styles';

export default function RecadosCard({ title, recado, style, className }) {
    const bemVindoPadrao = recado?.imagem ? recado?.imagem : '/bem_vindo_1.png';
    return (
        <Container className={`card d-flex p-0 w-100 h-auto min-h-300px${className ? ` ${className}` : ''}`} style={style}>
            <div className="w-100per d-flex h-85per p-0 m-0">
                <div className="col-lg-6 col-sm-12 h-100per pe-2">
                    <img src={bemVindoPadrao} className="w-100per h-100per" alt="Imgem do Recado" style={{ border: '0.5px solid lightgray', borderRadius: 5 }} />
                </div>
                <div className="col-lg-6 col-sm-12 h-100per ps-2">
                    <label className="col-12 font-roxo font-bold font-20px mb-2 mt-2">{title}</label>
                    <div className="col-12 text-justify">
                        {recado.conteudo ? recado.conteudo : 'Bem vindo ao Sistema Neuro Intensiva 😁'}
                    </div>
                </div>
            </div>
        </Container>
    );
}