import BottomStatusBar from 'components/BottomStatusBar';
import Button from 'components/Button';
import Container from 'components/Container';
import Header from 'components/Header';
import { useAuth } from 'context/auth';
import React, { useCallback, useMemo } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import bgImage from 'assets/background-image.jpeg';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const HeaderPadrao = ({ children }) => {
    //#region VARIAVEIS
    const { user, isAuthenticated } = useAuth();
    const [param, setParam] = React.useState('');
    const query = useQuery();
    const [noHeader, setNoHeader] = React.useState(query.get('no_header'));
    const isLoginScreen = useMemo(() => window.location.pathname === '/', [window.location.pathname]);
    const location = useLocation();
    const [isMobileView, setIsMobileView] = React.useState(false);
    //#endregion
    //#region FUNCOES
    const checkMobileView = useCallback(() => {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        setIsMobileView(isMobile);
    }, []);
    //#endregion
    //#region HANDLES
    const handleRetornar = () => {
        if (window.history.length > 2) {
            window.history.back();
            window.history.back();
        } else {
            window.close();
        }
    };
    //#endregion
    //#region USE EFFECTS
    React.useEffect(() => {
        if (param === 'true') {
            const newUrl = new URL(window.location);
            newUrl.searchParams.set('no_header', 'true');
            setNoHeader(true);
            window.history.pushState({}, '', newUrl);
        }
    }, [location]);
    React.useEffect(() => {
        checkMobileView();
        if (query.get('no_header') === 'true') {
            setParam('true');
        } else {
            setParam('false');
        }
    }, []);
    //#endregion
    //#region RENDER
    {/* <MainContainer> */ }
    return (
        <>
            {!isLoginScreen
                ? isAuthenticated
                    ? noHeader
                        ? <div className='w-100 p-2 mb-2'>
                            <Button className='w-20per-i' onClick={handleRetornar}>
                                <BsArrowLeft size={30} />
                                Retornar
                            </Button>
                        </div>
                        : <Header />
                    : null
                : null
            }
            <Container
                style={
                    user?.tipo_user?.toLowerCase() === 'responsavel'
                        ? {
                            backgroundImage: `url(${bgImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }
                        : {}
                }>
                {React.cloneElement(children, {})}
            </Container>
            {!isMobileView && <BottomStatusBar />}
        </>
    );
    {/*   <BottomStatusBar />
            </MainContainer> */}
    //#endregion
};

export default HeaderPadrao;