import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import useIcons from 'context/icons';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    label {
        font-size: 16px;
        color: #333;
        margin-bottom: 5px;
        width: 100%;
    }

    .input-line {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;

        .react-datepicker-wrapper {
            width: 50%;
        }
    }

    .limpar {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        color: #4906AB;
        padding: 0;

        &:hover {
            color: orange;
            font-weight: bold;
        }
    }
`;

const FakeInputBox = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: default;
    position: relative;
    box-sizing: border-box;
    width: 100%;

    background-color: hsl(0, 0%, 100%);
    border: 2px solid #ddd;
    border-radius: 8px;
    transition: all 100ms;
    outline: 0 !important;
    padding-left: 10px;
    margin: 0 !important;
    min-height: 38px;

    &.disabled {
        background-color: #ececec;
        border-radius: 10px;
        border-style: solid;
        border-width: 2px;
        border-color: #dddddd;
        min-height: 40px;
    }
`;

const StyledDatePicker = styled(DatePicker)`
    padding: 10px;
    border: none;
    border-radius: 0;
    font-size: 16px;
    margin: 0;
    width: 100%;

    &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: none;
    }

    .react-datepicker__input-container input {
        width: 100% !important;
    }

    .react-datepicker__input-container input {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0;
        padding: 10px;
        box-sizing: border-box;
    }

    .react-datepicker__day--selected {
        background-color: #4906AB;
        color: white;
    }

    .react-datepicker__day--in-range {
        background-color: #9B7ED8;
        color: black;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: #6a1b9a;
        color: white;
    }

    .react-datepicker__day--outside-month {
        color: #6c757d;
    }

    &.fake-input {
        &.inicio {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }

        &.validade {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
        display: flex;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border: 2px solid #ddd;
        border-radius: 8px;
        cursor: default;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        margin: 0 !important;
        padding-left: 10px;

        &.disabled {
            background-color: #ececec;
            border-radius: 10px;
            border-style: solid;
            border-width: 2px;
            border-color: #dddddd;
            min-height: 40px;
        }
    }
`;

const DateRangeSelector = ({ minDate = null, maxDate = null, onChange, label, disabled = false }) => {
    const { iconArray } = useIcons();
    const [startDate, setStartDate] = useState(minDate);
    const [endDate, setEndDate] = useState(maxDate);
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        onChange({ startDate, endDate });
        if (date) {
            endDatePickerRef.current.setFocus();
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        onChange({ startDate, endDate });
        if (date) {
            endDatePickerRef.current.setBlur();
        }
    };

    const handleConfirm = () => {
        if (endDate && startDate && endDate >= startDate) {
            onChange({ startDate, endDate });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Data de início deve ser anterior à data de fim.'
            });
        }
    };

    const handleClearDates = () => {
        setStartDate(null);
        setEndDate(null);
        onChange({ startDate: null, endDate: null });
    };

    return (
        <Container>
            {label && <label>{label}</label>}
            <div className="input-line">
                <StyledDatePicker
                    className={`fake-input inicio ${disabled ? 'disabled' : ''}`}
                    selected={startDate}
                    onChange={handleStartDateChange}
                    onBlur={handleConfirm}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Data de Início"
                    dateFormat="dd/MM/yyyy"
                    ref={startDatePickerRef}
                    disabled={disabled}
                />
                <StyledDatePicker
                    className={`fake-input validade ${disabled ? 'disabled' : ''}`}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    onBlur={handleConfirm}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Data de Fim"
                    dateFormat="dd/MM/yyyy"
                    ref={endDatePickerRef}
                    disabled={disabled}
                />
                <button type={'button'} onClick={handleClearDates} className="limpar" disabled={disabled}>
                    {React.cloneElement(iconArray['limpar'], { size: 25 })}
                </button>
            </div>
        </Container>
    );
};

export default DateRangeSelector;