import React, { useState } from 'react';
import { MultiSelect as MultiSelectPrime } from 'primereact/multiselect';

const MultiSelect = ({
    ref,
    id,
    name,
    options,
    placeholder,
    value,
    onChange,
    label,
    display = 'chip' || 'comma',
    optionLabel = 'name',
    optionGroupChildren,
    optionGroupLabel,
    containerClass = 'w-100 d-flex flex-column',
    labelClass = 'mt-2 font-size-13px',
    defaultValue,
    required
}) => {
    return (
        <div className={containerClass}>
            {label && <label className={labelClass} style={{ marginTop: 10, fontSize: 13, }}><b style={{ color: 'orange' }}>{required ? '*' : ''}</b>{label}</label>}
            <MultiSelectPrime
                id={id}
                name={name}
                ref={ref}
                className="p-multiselect-custom h-auto-i w-100"
                display={display}
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
                options={options}
                placeholder={placeholder}
                maxSelectedLabels={3}
                optionGroupChildren={optionGroupChildren}
                optionGroupLabel={optionGroupLabel}
                optionLabel={optionLabel}
            />
        </div>
    );
};

function BasicDemo() {

    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const statusOptions = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Ferias', label: 'Ferias' },
    ];
    const statesAndCities = [
        {
            label: 'Australia',
            code: 'AU',
            items: [
                { name: 'Sydney', code: 'SYD' },
                { name: 'Melbourne', code: 'MEL' },
                { name: 'Perth', code: 'PER' },
            ]
        },
        {
            label: 'Canada',
            code: 'CA',
            items: [
                { name: 'Toronto', code: 'TOR' },
                { name: 'Ottawa', code: 'OTT' },
            ]
        },
        {
            label: 'Japan',
            code: 'JP',
            items: [
                { name: 'Tokyo', code: 'TYO' },
                { name: 'Kyoto', code: 'KYO' },
            ]
        },
        {
            label: 'Brazil',
            code: 'BR',
            items: [
                { name: 'São Paulo', code: 'SAO' },
                { name: 'Rio de Janeiro', code: 'RIO' },
                { name: 'Brasília', code: 'BSB' },
            ]
        }
    ];

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-map-marker mr-2"></i>
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center">
            <MultiSelectPrime
                className="w-full md:w-20rem p-multiselect-custom"
                display="chip"
                onChange={(e) => setSelectedCities(e.value)}
                value={selectedCities}
                options={statusOptions}
                placeholder="Selecione o status"
                maxSelectedLabels={3}
                optionLabel="label"
            />

            <div className="mt-4">
                <MultiSelectPrime
                    className="w-full md:w-20rem p-multiselect-custom"
                    display="chip"
                    onChange={(e) => setSelectedStatus(e.value)}
                    value={selectedStatus}
                    options={statusOptions}
                    placeholder="Selecione o status"
                    maxSelectedLabels={3}
                    optionLabel="label"
                />
            </div>
        </div>
    );
}



export { MultiSelect, BasicDemo };