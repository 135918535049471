import { useState } from "react";

const { FiAlertTriangle } = require("react-icons/fi");
const { BalloonIcon } = require("./estilos");

const BalaoAviso = ({ aviso }) => {
    const [isBalloonVisible, setIsBalloonVisible] = useState(true);

    const handleBalloonClick = () => {
        setIsBalloonVisible(false);
    };

    return (
        <BalloonIcon onClick={handleBalloonClick}>
            <FiAlertTriangle className='icone' size={30} />
            {isBalloonVisible && <div className='balloon-border'>
                <div className="balloon">
                    {aviso}
                </div>
            </div>}
        </BalloonIcon>
    )
};

export default BalaoAviso;