import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Select from '../../Select';
import ResponsavelSalaService from '../../../services/ResponsavelSalaService';
import TableResponsavelSala from './TableResponsavelSala';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';
import { useSnackbar } from 'context/snackbar';
import { CgAdd, CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';

const customStyles = {
    overlay: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        zIndex: 1000,
        top: 0,
        left: 0,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        height: '50vh',
        width: '40vw',
        zIndex: 1002,
        padding: 0,
    },
};

const ResponsavelSalaModal = ({ modalIsOpen, setIsOpen, estacaoId, salaId }, ref) => {
    const title = 'Responsáveis da Estação';
    const snackbar = useSnackbar();
    const formRef = useRef(null);
    const [ResponsavelSalas, setResponsavelSalas] = useState([]);
    const [Terapeutas, setTerapeitas] = useState([]);
    const [TerapeutaId, setTerapeutaId] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadResponsavelSalas = () => {
        ResponsavelSalaService.index('with=sala,terapeutum&filter[estacao_id]=' + estacaoId).then(({ data }) => {
            setResponsavelSalas(data);
        }).catch((e) => {
            console.error(e);
            snackbar.displayMessage('Houve um erro ao consultar responsaveis!', 'error');
        });
    };

    const loadTerapeutas = () => {
        let terapeutas = [];
        api.get('api/terapeuta').then(({ data }) => {
            data.forEach((terapeuta) => {
                terapeutas.push({ value: terapeuta.id, label: terapeuta.nome });
            });
            setTerapeitas(terapeutas);
        }).catch((e) => {
            console.error(e);
            snackbar.displayMessage('Houve um erro ao consultar terapeutas!', 'error');
        });
    };

    useEffect(() => {
        loadTerapeutas();
        loadResponsavelSalas();
    }, []);

    function afterOpenModal() {

    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const storeResponsavelSala = () => {
        setLoading(true);
        if (TerapeutaId === null) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            if (ResponsavelSalas !== undefined && ResponsavelSalas.filter((e) => TerapeutaId === e.terapeuta_id).length > 0) {
                Swal.fire('Terapeuta já incluído!', '', 'error');
            } else {
                let postData = {
                    terapeuta_id: TerapeutaId,
                    sala_id: salaId,
                    estacao_id: estacaoId,
                    data_hora: new Date(),
                };
                ResponsavelSalaService.store(postData).then(({ data }) => {
                    loadResponsavelSalas();
                }).catch((e) => {
                    console.error(e);
                    snackbar.displayMessage('Houve um erro ao tentar adicionar o responsável!', 'error');
                })
                    .finally(() => setLoading(false));
            }
        }
    };

    async function handleSubmit() {
        storeResponsavelSala();
    }

    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} transparent shouldCloseOnOverlayClick={false} shouldCloseOnEsc={true} appElement={document.getElementById('root')}>
            <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div className='w-200px'>
                        {loading !== false ? loading : <ClipLoader size={22} color={'#000'} loading={loading} />}
                    </div>
                    <ModalTitulo>{title}</ModalTitulo>
                    <label className='btn-outline w-200px text-right' onClick={() => setIsOpen(false)}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            <br></br>
            <div className='px-2 d-flex flex-col'>
                <Form ref={formRef} onSubmit={handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}>
                    <div className='d-flex flex-nowrap flex-row align-items-end' style={{ paddingTop: 30 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <Select name={'responsavel'} label={'Responsavel'} onChange={(e) => setTerapeutaId(e.value)} options={Terapeutas}
                                style={{ position: 'static' }} />
                        </div>
                        <Button className='btn-padrao h-fit-content-i' style={{ width: '20%' }} type="submit" disabled={loading}>
                            <CgAdd size={22} />
                            {loading
                                ? <ClipLoader size={22} />
                                : 'Adicionar'
                            }
                        </Button>
                    </div>
                    <br></br>
                    <br></br>
                    <TableResponsavelSala source={ResponsavelSalas} callback={loadResponsavelSalas} />
                </Form>
            </div>
        </Modal>
    );
};

export default forwardRef(ResponsavelSalaModal);
