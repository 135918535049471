import React, { useEffect, useState, useRef } from 'react';
import { Modal, Container } from './styles';
import { Form } from '@unform/web';
import moment from 'moment';
import api from '../../../../../../services/api';
import Select from '../../../../../../components/Select';
import Input from '../../../../../../components/Input';
import { useSnackbar } from '../../../../../../context/snackbar';

export default function AdicionarAgendamento({ id = 'modal', hour, date, area, onClose, updateAgenda}) {
    
    const [terapias, setTerapias] = useState([]);
    const [terapeutas, setTerapeutas] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [planosSaude, setPlanosSaude] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [pacienteOptions, setPacienteOptions] = useState([]);
    const [tipoAtendimento, setTipoAtendimento] = useState([]);


    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [terapiaSelecionado, setTerapiaSelecionada] = useState(null);
    const [especialidadeSelecionado, setEspecialidadeSelecionada] = useState(null);
    const [modalidadeSelecionado, setModalidadesSelecionada] = useState(null);
    const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [usePaciente, setUsePaciente] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const handleSubmit = async (data) => {
        try {
            const response = await api.post('api/agenda-compartilhada',
            {
                'paciente_id': pacienteSelecionado,
                'paciente_id_plano_saude': planoSaudeSelecionado,
                'terapeuta_id': terapeutaSelecionado.value,
                'terapia_id': terapiaSelecionado,
                'especialidade_id': especialidadeSelecionado,
                'modalidade_id': modalidadeSelecionado.value,
                'tipo_atendimento_id': tipoAtendimentoSelecionado,
                'reserva': 0,
                'data_atendimento_inicial': moment(date + 'T' + hour).format('YYYY-MM-DD HH:mm'),
                'data_atendimento_final': moment(date + 'T' + hour).add(50, 'minutes').format('YYYY-MM-DD HH:mm'),
                'comentario': data.comentario,
                'area_compartilhada_id': area.id
            });
            updateAgenda();
            onClose();
        } catch (error) {
            console.error(error);
        }

    };

    const getTerapeutasList = () => {
        api.get(`api/terapeuta/disponivel/${date}/${hour}?&dia=${date}&hora${hour}`).then(({ data }) => {
            let terapeutaOptions = [];

            data.forEach((terapeuta) => {
                terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome });
            });
            setTerapeutas(terapeutaOptions);
        });
    };

    const getModalidades = () => {
        api.get('api/modalidade').then(({ data }) => {
            let modalidadeOptions = [];

            data.forEach((modalidade) => {
                modalidadeOptions.push({ value: modalidade.id, label: modalidade.modalidade });
            });

            setModalidades(modalidadeOptions);
        });
    };

    const getPlanos = () => {
        api.get('api/plano-saude').then(({ data }) => {
            let planoSaudeOptions = [];

            data.forEach((planoSaude) => {
                planoSaudeOptions.push({ value: planoSaude.id, label: planoSaude.plano });
            });
            setPlanosSaude(planoSaudeOptions);
        });
    };

    const getPacientes = () => {
        api.get(`api/paciente/disponivel/${date}/${hour}?with=terapia_pacientes.terapium,terapia_pacientes.especialidade`).then(({ data }) => {
            let pacienteOptions = [];
            data.forEach((paciente) => {
                pacienteOptions.push({ value: paciente.id, label: paciente.nome });
            });
            setPacientes(data);
            setPacienteOptions(pacienteOptions);
        });
    };

    const getTipoAtendimento = () => {
        api.get('api/tipo-atendimento').then(({ data }) => {
            let tipoAtendimentoOptions = [];

            data.forEach((tipoAtendimento) => {
                tipoAtendimentoOptions.push({ value: tipoAtendimento.id, label: tipoAtendimento.tipo_atendimento });
            });

            setTipoAtendimento(tipoAtendimentoOptions);
        });
    };

    const loadTerapiasEspecialidades = (paciente, plano) => {
        if (paciente !== null && plano !== null) {
            let terapiasList = [];
            let especialidadesList = [];
            if (paciente.terapia_pacientes.length > 0) {
              paciente.terapia_pacientes.forEach((terapia) => {
                if (terapia.plano_saude_id === plano.value) {
                    terapiasList.push({value: terapia.terapium.id, label: terapia.terapium.terapia});
                    especialidadesList.push({value: terapia.especialidade.id, label: terapia.especialidade.especialidade});
                }
              });
            }
            setTerapias(terapiasList);
            setEspecialidades(especialidadesList);
        } else {
            snackbar.displayMessage('Por favor selecione o paciente e plano de saúde!', 'warning');
        }
    };

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleChangeUsePatient = (e) => {
        if (e.target.checked) {
            setUsePaciente(true);
        } else {
            setUsePaciente(false);
            setTerapeutaSelecionado(null);
        }
    };

    const handleChangePaciente = (e) => {
        const pacienteData = pacientes.find(paciente => paciente.id === e.value);
        loadTerapiasEspecialidades(pacienteData, planoSaudeSelecionado);
        setTerapiaSelecionada(null);
        setEspecialidadeSelecionada(null);
        setPlanoSaudeSelecionado(null);
        setPacienteSelecionado(e);
    };

    const handlePlanoSaude = (e) => {
        const pacienteData = pacientes.find(paciente => paciente.id === pacienteSelecionado.value);
        loadTerapiasEspecialidades(pacienteData, e);
        setTerapiaSelecionada(null);
        setEspecialidadeSelecionada(null);
        setPlanoSaudeSelecionado(e);
    };

    const handleChangeTipoAtendimento = (e) => {
        setTipoAtendimentoSelecionado(e.value);
    };

    const getDefaultModalidade = (modalidade) => {
        if (!modalidades) return;
        const modalidadeDefault = modalidade.filter(data => {
            return data.value === 1;
        })[0];
        setModalidadesSelecionada(modalidadeDefault);
    };

    useEffect(() => {
        getTipoAtendimento();
        getTerapeutasList();
        getPacientes();
        getPlanos();
        getModalidades();
    }, []);

    useEffect(() => {
      getDefaultModalidade(modalidades);
    }, [modalidades]);

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <Container>
                <div style={{position: 'relative'}}>
                    <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
                      right: '10px', fontSize: '25px', top: '-10px' }} onClick={() => onClose()}>
                      x
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <label style={{ fontWeight: '900', fontSize: '18', color: '#6A3BAF' }}>Agendar Área Compartilhada</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            paddingTop: 10,
                        }}>
                            <Select label="Terapeuta:" name="Terapeuta" options={terapeutas} 
                                onChange={e => setTerapeutaSelecionado(e)} value={terapeutaSelecionado}/>
                    </div>
                    <label style={{fontWeight: '0.5', fontSize: '0.8rem', fontWeight: 'bold', marginTop: 10}}> 
                            Utilizar sem Paciente?
                    </label>
                    <div style={{backgroundColor: 'white', width: '50%', boxShadow: '0 0 3px 0px silver', borderRadius: 10, 
                                    padding: '4px 22px'}}>
                        <div>
                            <input name="use_paciente" type={'checkbox'} style={{marginRight: 10,}}
                            onClick={e => handleChangeUsePatient(e)}/>
                            <label> 
                                {usePaciente === false ? 'Não' : 'Sim'}
                            </label>
                        </div>
                    </div>
                    {
                        usePaciente === false ? 
                            <div style={{ marginBottom: 10 }}>
                                <Select label="Nome Paciente:" name="paciente" options={pacienteOptions} 
                                    onChange={(e) => handleChangePaciente(e)} />
                                    <Select label="Plano de saúde:" name="plano_saude" options={planosSaude} 
                                        onChange={(e) => handlePlanoSaude(e)} value={planoSaudeSelecionado} />
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            paddingTop: 10,
                                            paddingRight: 10
                                        }}>
                                            <Select label="Especialidade:" name="Especialidade" options={especialidades} 
                                                onChange={(e) => setEspecialidadeSelecionada(e)} value={especialidadeSelecionado} />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            paddingTop: 10,
                                            paddingRight: 10
                                        }}>
                                            <Select label="Terapia:" name="Terapia" options={terapias} 
                                                onChange={(e) => setTerapiaSelecionada(e)} value={terapiaSelecionado} />
                                        </div>
                                    </div>
                                </div> : ''
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            paddingTop: 10,
                            paddingRight: 10
                        }}>
                            <Input type={'date'} defaultValue={date} readOnly={'readOnly'} title="Data:" name="data" />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            paddingTop: 10,
                            paddingRight: 10
                        }}>
                            <Input type={'time'} defaultValue={hour} readOnly={'readOnly'} title="Horário:" name="horaInicial" />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            paddingTop: 10,
                            paddingRight: 10
                        }}>
                            <Select label="Modalidade:" name="Modalidade"
                                value={modalidadeSelecionado} disabled={'disabled'}/>
                        </div>
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                        {usePaciente === false ? <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            paddingTop: 10,
                            paddingRight: 10
                        }}>
                            <Select label="Tipo de Atendimento:" name="tipoAtendimento" options={tipoAtendimento} onChange={(e) => handleChangeTipoAtendimento(e)} />
                        </div> : ''}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: usePaciente === false ? '50%' : '100%',
                            paddingTop: 10,
                            paddingRight: 10
                        }}>
                            <Input title="Área:" name="area" defaultValue={area.area} readOnly={'readOnly'}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                        
                    </div>

                    <Input title="Comentário:" name="comentario" />

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <button type="submit" style={{
                            fontWeight: '600',
                            fontSize: '18', backgroundColor: '#6A3BAF', color: '#FFFFFF',
                            border: 'none', borderRadius: 8, padding: '4px 40px'
                        }}>Ok
                        </button>
                    </div>
                </Form>
            </Container>
        </Modal>
    );
}
