import Button from 'components/Button';
import Input, { RichTextEditor } from 'components/Input';
import Select from 'components/Select';
import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { BsEye } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { Container } from './styles';
import styled from 'styled-components';
import { ChangeCase } from 'services/ChangeCase';
import ReactModal from 'react-modal';

const UserSelectList = styled.div`
    --padding: 8px;
    --border-color: #ccc;
    --background-color: #fbfbfb;
    --hover-background-color: #eaffde;
    --hover-color: green;
    --secondary-font-color: #666;

    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .user-label {
        font-weight: bold;
        width: 100%;
        padding: var(--padding);
        background-color: var(--background-color);
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;

        button {
            width: fit-content;
        }
    }

    .user-list {
        display: flex;
        flex-direction: column;
        gap: var(--padding);
        height: 300px;
        overflow-y: auto;
        margin-left: 10px;
        margin-right: 0px;
        background-color: var(--background-color);

        .user-item {
            transition: padding-left 0.5s;
            display: flex;
            gap: var(--padding);
            align-items: center;
            user-select: none;

            input {
                display: none;
            }
            label {
                transition: padding-left 0.5s, background-color 0.5s, color 0.5s;

                cursor: pointer;
                padding: var(--padding);
                border-radius: 8px;
                background-color: #f0f0f0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                height: 100%;
                .coluna {
                    display: flex;
                    flex-direction: column;
                    .user-item-label-principal {
                        font-weight: normal;
                    }
        
                    .user-item-label-secundario {
                        font-size: 10px;
                        font-weight: normal;
                        color: var(--secondary-font-color);
                    }
                }
            }
    
            & input:checked + label{
                font-weight: bold;
                background-color: var(--hover-background-color);
                color: var(--hover-color);
                padding-left: 30px !important;
            }

            &:hover {
                background-color: var(--hover-background-color);
                font-weight: bold;
                color: var(--hover-color);
                padding-left: 11.2px !important;
            }
        }
    }
`;

const CustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        padding: '0',
        overflow: 'auto',
        borderRadius: '8px',
        border: '1px solid #ccc',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        zIndex: '1000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: '1000',
    },
};

const ModalNotificacao = forwardRef((props, ref) => {
    //#region VARIAVEIS
    const [modalData, setModalData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [salvando, setSalvando] = useState(false);
    const formRef = useRef(null);
    const [loading, setloading] = useState(false);
    const defaultNotificacaoContent = {
        user_id: '',
        titulo: '',
        conteudo: '',
        nivel_urgencia: '',
        tipo: '',
        modo_envio: ''
    };
    const [notificacao, setNotificacao] = useState(defaultNotificacaoContent);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const memoListaUsuarios = useMemo(() => listaUsuarios, [listaUsuarios]);
    const [listaUsuariosSelecionados, setListaUsuariosSelecionados] = useState([]);
    const urgenciaOptions = useMemo(() => [
        { value: 'baixa', label: 'Baixa', name: 'nivel_urgencia' },
        { value: 'média', label: 'Média', name: 'nivel_urgencia' },
        { value: 'alta', label: 'Alta', name: 'nivel_urgencia' }
    ], []);
    const tipoOptions = useMemo(() => [
        { value: 'warning', label: 'Aviso', name: 'tipo' },
        { value: 'alert', label: 'Alerta', name: 'tipo' },
        { value: 'question', label: 'Questionamento', name: 'tipo' },
        { value: 'error', label: 'Erro', name: 'tipo' }
    ], []);
    const modoEnvioOptions = useMemo(() => [
        { value: 'sistema', label: 'Sistema', name: 'modo_envio' },
        { value: 'email', label: 'E-mail', name: 'modo_envio' },
    ], []);
    const [tipoUserFilter, setTipoUserFilter] = useState('');
    const filteredUsers = useMemo(() => {
        return listaUsuarios.filter(user => user.tipo.includes(tipoUserFilter));
    }, [listaUsuarios, tipoUserFilter]);
    //#endregion
    //#region FUNÇÕES
    /**
     * Função de callback para pré-visualizar uma notificação.
     * 
     * Esta função utiliza a biblioteca SweetAlert2 para exibir uma notificação
     * com base no conteúdo e tipo fornecidos pelo objeto `notificacao`.
     * 
     * @function
     * @name previewNotificacao
     * @memberof Notificacoes/ModalNotificacao
     * @param {Object} notificacao - Objeto contendo os dados da notificação.
     * @param {string} notificacao.conteudo - Conteúdo HTML da notificação.
     * @param {string} notificacao.tipo - Tipo de ícone da notificação.
     * @returns {void}
     */
    const previewNotificacao = useCallback(() => {
        const { conteudo, tipo } = notificacao;
        const titulo = document.getElementById('titulo').value;
        let option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        };
        Swal.fire(option);
    }, [notificacao]);
    //#endregion
    //#region HANDLES
    /**
     * Função de callback para lidar com mudanças em campos de entrada.
     * Atualiza o estado da notificação com o novo valor do campo alterado.
     *
     * @param {Object} e - O evento de mudança ou um objeto contendo o nome e valor do campo.
     * @param {Object} e.target - O alvo do evento de mudança.
     * @param {string} e.target.name - O nome do campo alterado.
     * @param {string} e.target.value - O novo valor do campo alterado.
     * @param {string} e.name - O nome do campo alterado (caso não seja um evento).
     * @param {string} e.value - O novo valor do campo alterado (caso não seja um evento).
     */
    const handleChange = useCallback((e) => {
        let name;
        let value;
        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            name = e.name;
            value = e.value;
        }
        setNotificacao((prevNotificacao) => ({
            ...prevNotificacao,
            [name]: value
        }));
    }, []);
    /**
     * Função de callback para lidar com o envio de notificações.
     *
     * @param {Object} data - Dados do formulário contendo o título da notificação.
     * @throws {Error} - Lança um erro se houver um problema ao enviar as notificações.
     */
    const handleSubmit = useCallback((data) => {
        setSalvando(true);
        try {
            const dados = {
                ...notificacao,
                titulo: data.titulo
            };

            const added = listaUsuariosSelecionados;
            if (added.length === 0) {
                Swal.fire('Selecione ao menos um usuário para notificar...', '', 'warning');
                setSalvando(false);
                return;
            }

            const obj = {
                dados: dados,
                ids: added
            };

            api.post('api/notificacao/notifica/user/ids', obj)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('Notificações enviadas com sucesso!', '', 'success', 2000);
                    }
                    setSalvando(false);
                    closeModal();
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire('Houve um problema a enviar as notificações...', '', 'error');
                    setSalvando(false);
                });
        } catch (error) {
            console.error(error);
        }
    }, [notificacao, memoListaUsuarios]);

    const handleUserSelection = (userId) => {
        const antigo = listaUsuariosSelecionados;
        if (antigo.includes(userId)) {
            setListaUsuariosSelecionados(antigo.filter(id => id !== userId));
        } else {
            setListaUsuariosSelecionados([...antigo, userId]);
        }
    };
    //#endregion
    const openModal = useCallback((outModalData) => {
        if (outModalData) {
            setModalData(outModalData);
        }
        setListaUsuariosSelecionados([]);
        setSalvando(false);
        setNotificacao(defaultNotificacaoContent);
        setIsOpen(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalData(null);
        setIsOpen(false);
    }, []);
    //#region USE EFFECTS
    useEffect(() => {
        if (modalData) {
            setNotificacao(modalData);
        }
    }, [modalData]);
    useEffect(() => {
        setloading(true);
        if (listaUsuarios?.length === 0) {
            api.get('api/user')
                .then(({ data }) => {
                    let pickList = [];
                    data.forEach((user) => {
                        pickList.push({
                            value: user.id,
                            label: user.name,
                            secondLabel: user.tipo_user,
                            email: user.email,
                            tipo: ChangeCase.toUpperCase(user.tipo_user)
                        });
                    });

                    setListaUsuarios(pickList.sort((a, b) => {
                        if (a.tipo === b.tipo) {
                            return a.label.localeCompare(b.label);
                        } else {
                            return a.tipo.localeCompare(b.tipo);
                        }
                    }));

                    setloading(false);
                })
                .catch(error => console.error(error));
        }
    }, []);
    //#endregion
    //#region HTML
    const handleSelectAllFiltered = useCallback(() => {
        const filteredUserIds = filteredUsers.map(user => user.value);
        setListaUsuariosSelecionados(filteredUserIds);
    }, [filteredUsers]);

    useImperativeHandle(ref, () => ({
        open: openModal,
        close: closeModal
    }));

    return (
        <ReactModal
            style={CustomStyles}
            isOpen={isOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            id={'modal-notificacao'}
            onMouseDown={(e) => { if (e.target.id === 'modal-notificacao') setIsOpen(false) }}
        >

            <div className='div-titulo-fixo d-flex w-100' style={{ justifyContent: 'space-between', position: 'sticky' }}>
                <label>
                    <BsEye color='purple' className='pointer' size={25} onClick={() => previewNotificacao()}></BsEye>
                </label>
                <label>Adicionar/Editar Notificação</label>
                <label>
                    <CgClose color='red' className='pointer' size={25} onClick={() => closeModal()} />
                </label>
            </div>
            <ClipLoader size={30} className='m-auto' loading={loading} />
            <Form ref={formRef} onSubmit={handleSubmit} className={`w-100per d-flex flex-wrap px-4 ${loading ? 'some' : ''}`} autoComplete="off">
                <label className='col-12 mt-4'>
                    Título:
                    <Input type='text' id='titulo' name='titulo' defaultValue={notificacao.titulo} onChange={handleChange} />
                </label>
                <label className='col-12 mt-4'>
                    Conteúdo:
                    <RichTextEditor id='conteudo' name='conteudo' defaultValue={notificacao.conteudo} onChange={(e) => handleChange({ name: 'conteudo', value: e })} />
                </label>
                <label className='col-sm-12 col-lg-4 mt-4'>
                    Nível de Urgência:
                    <Select type='text' id='nivel_urgencia' name='nivel_urgencia' options={urgenciaOptions} defaultValue={notificacao.nivel_urgencia} onChange={handleChange} />
                </label>
                <label className='col-sm-12 col-lg-4 mt-4'>
                    Tipo:
                    <Select type='text' id='tipo' name='tipo' options={tipoOptions} defaultValue={notificacao.tipo} onChange={handleChange} />
                </label>
                <label className='col-sm-12 col-lg-4 mt-4'>
                    Modo de Envio:
                    <Select type='text' id='modo_envio' name='modo_envio' options={modoEnvioOptions} defaultValue={notificacao.modo_envio} onChange={handleChange} />
                </label>
                <label className='col-12 mt-4'>
                    Filtrar por Tipo de Usuário:
                    <Input type='text' id='tipo_user_filter' name='tipo_user_filter' value={tipoUserFilter} onChange={(e) => setTipoUserFilter(e.target.value)} list="user-types" />
                    <datalist id="user-types">
                        {[...new Set(listaUsuarios.map(user => user.tipo))].map(tipo => (
                            <option key={tipo} value={tipo} />
                        ))}
                    </datalist>
                </label>
                <UserSelectList className='col-12 mt-4'>
                    <label className='user-label'>
                        Usuários:
                        <Button className='w-fit-content' type='button' onClick={handleSelectAllFiltered}>Selecionar Todos</Button>
                    </label>
                    <div className='user-list'>
                        {filteredUsers.map(user => (
                            <div key={user.value} className='user-item'>
                                <input
                                    type='checkbox'
                                    id={`user-${user.value}`}
                                    name='users'
                                    value={user.value}
                                    checked={listaUsuariosSelecionados.includes(user.value)}
                                    onChange={() => handleUserSelection(user.value)}
                                />
                                <label htmlFor={`user-${user.value}`}>
                                    <span className='coluna'>
                                        <span className='user-item-label-principal'> {user.label} - {user.email}</span>
                                        <span className='user-item-label-secundario'> {user.tipo} </span>
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                </UserSelectList>
                <div className='w-100per d-flex justify-between' style={{ position: 'sticky', bottom: '0', backgroundColor: '#fff', padding: '10px' }}>
                    <Button className='w-300px-i' type='button' onClick={() => closeModal()}>Cancelar</Button>
                    <Button className='w-300px-i' type='submit' disabled={salvando}>Salvar</Button>
                </div>
            </Form>
        </ReactModal>
    );
    //#endregion
});

export { ModalNotificacao };