import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import RelatorioBase from 'context/relatorio';
import useResponsaveisData from 'context/responsavel';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const RelatoriosResponsaveis = () => {
    usePageBase({
        title: 'Relatório de Responsaveis',
        description: 'Tela onde é possível visualizar os responsaveis cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/responsaveis'
        ]
    });
    const { fetchResponsaveisFiltradosData } = useResponsaveisData();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();

    const columns = [
        'nome',
        'email',
        'cpf',
        'rg',
        'telefone1',
        'telefone2',
        'endereco',
        'estado',
        'bairro',
        'cep',
        'created_at',
        'user_id',
        'numero',
        'complemento',
        'responsavel_fiscal',
        'data_nascimento',
        'cidade',
        'paciente_nome',
        'paciente_identificador'
    ];
    const [filters, setFilters] = useState(null);
    const [loadingListas, setLoadingListas] = useState(true);
    const [plus55ToNumber, setPlus55ToNumber] = useState(false);

    const fetchData = useCallback(async () => {
        const response = await fetchResponsaveisFiltradosData(filters.reduce((acc, filter) => ({ ...acc, [filter.id]: filter.value }), {}));
        return response.map((responsavel) => {
            function anexar55(telefone) {
                if (telefone && telefone.startsWith('0')) {
                    return `1${telefone.slice(1)}`;
                }
                return telefone && !telefone.startsWith('55') ? `55${telefone}` : telefone;
            }
            const created_at = responsavel.created_at ? moment.utc(responsavel.created_at).format('DD/MM/YYYY') : '';
            const telefone1 = plus55ToNumber ? anexar55(responsavel.telefone1) : responsavel.telefone1;
            const telefone2 = plus55ToNumber ? anexar55(responsavel.telefone2) : responsavel.telefone2;

            return {
                ...responsavel,
                created_at,
                paciente_nome: responsavel.paciente_nome || '-',
                paciente_identificador: responsavel.paciente_identificador || '-',
                telefone1,
                telefone2
            };
        });
    }, [filters, plus55ToNumber]);

    const handlePlus55ToNumber = (e) => {
        const { checked } = e.target;
        setPlus55ToNumber(checked);
    };

    useEffect(() => {
        fetchPacienteOptionsData();
        setLoadingListas(false);
    }, []);

    useEffect(() => {
        if (pacienteOptions) {
            setFilters([
                { id: 'nome', label: 'Nome', value: '', type: 'text' },
                { id: 'email', label: 'Email', value: '', type: 'text' },
                { id: 'cpf', label: 'CPF', value: '', type: 'text' },
                { id: 'paciente_id', label: 'Paciente', value: '', type: 'select', options: pacienteOptions },
                { id: 'paciente_nome', label: 'Nome do Paciente', value: '', type: 'text' },
                { id: 'paciente_ativo', label: 'Apenas pacientes ativos', value: true, type: 'checkbox' },
            ]);
        }
    }, [pacienteOptions]);

    if (loadingListas) {
        return <div>Carregando...</div>;
    }

    return (
        <RelatorioBase
            fetchData={fetchData}
            columns={columns}
            filters={filters}
            setFilters={setFilters}
            preExtraOptions={
                <div className='col-12 d-flex align-items-end'>
                    <div className="ns-checkbox reverse">
                        <label htmlFor="plus_55">
                            Adicionar '55' ao Telefone
                        </label>
                        <input type="checkbox" role="switch" id="plus_55" checked={plus55ToNumber} onChange={handlePlus55ToNumber} />
                    </div>
                </div>
            }
        />
    );
};

export default RelatoriosResponsaveis;