import { useState } from 'react';
import api from 'services/api';

const useTipoDemandaData = () => {
    const [tipoDemandaOptions, setTipoDemandaOptions] = useState([]);
    const [tipoDemanda, setTipoDemanda] = useState([]);

    const mapTipoDemandaToOptions = (data) => {
        return data.map((tipoDemanda) => ({
            value: tipoDemanda.id,
            label: tipoDemanda.tipo_demanda,
            name: tipoDemanda.tipo_demanda,
        }));
    };

    const fetchTipoDemanda = async () => {
        try {
            const { data } = await api.get('api/tipo-demanda');
            setTipoDemanda(data);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde:', error);
        }
    };

    const fetchTipoDemandaOptions = async () => {
        try {
            const { data } = await api.get('api/tipo-demanda');
            const options = mapTipoDemandaToOptions(data);
            setTipoDemandaOptions(options);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde:', error);
        }
    };

    return { tipoDemanda, fetchTipoDemanda, tipoDemandaOptions, fetchTipoDemandaOptions, mapTipoDemandaToOptions };
};

export default useTipoDemandaData;