import Dropdown from 'components/Dropdown';
import useIcons from 'context/icons';
import React, { useEffect } from 'react';
import { BsArrowBarRight } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NotificacaoPendenteP } from '../../HeaderTerapeuta/styles';
import { ContainerButton } from '../styles';

const JanelaDesanexadaButton = styled.button`
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transition: color 0.3s;
    rotate: -45deg;
    &:hover {
        color: #f8b500;
    }
`;
const JanelaDesanexadaLineButton = styled.button`
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        color: #f8b500;
    }
`;

const NavLink = ({ link, name, icon, atualLocal, notificacao = 0, dropDownList = null, clickable = true, disabled = false }) => {
    const selected = {
        backgroundColor: '#5c3498',
    };
    const unselected = {};
    const [isTouchDevice, setIsTouchDevice] = React.useState(false);
    const { getIcon } = useIcons();

    const handleOpenModalEmOutraJanela = (outsideLink = null) => {
        const url = new URL(link || outsideLink, window.location.origin);
        url.searchParams.append('no_header', 'true');
        const windowFeatures = 'width=800,height=600,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no';
        window.open(url.toString(), '_blank', windowFeatures);
    };

    useEffect(() => {
        const checkIfTouchDevice = () => {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        };

        setIsTouchDevice(checkIfTouchDevice());
    }, []);

    const linkContent = (
        <>
            <span> {getIcon(icon, 18)} </span>
            <span>{name}</span>
        </>
    );
    const sublinkContent = (dropDownList !== null)
        ? <ul id={link} className='w-fit-content bg-roxo-dark-i px-2 py-2 ns-lista-sem-estilo'>
            {dropDownList.sort((a, b) => a.position - b.position).map((item, index) => (
                <li className='d-flex hl-some-interno' key={`l:${link}-d-item:${index}`}>
                    <Link key={index} to={item.link} className={'li-padrao font-branca hl-white-bg-laranja justify-content-start'}>
                        {item.icon &&
                            <span className='me-2'>
                                {getIcon(item.icon, 18, true)}
                            </span>
                        }
                        {item.label}
                    </Link>
                    <JanelaDesanexadaLineButton className='interno' type='button' onClick={() => handleOpenModalEmOutraJanela(item.link)}>
                        <BsArrowBarRight color={'white'} size={19} />
                    </JanelaDesanexadaLineButton>
                </li>
            ))}
        </ul>
        : null;
    const linkClass = `d-flex flex-col text-center w-100 text-decoration-none align-center justify-center ${disabled === true ? 'font-cinza pointer-none' : 'font-branca'}`;
    return (
        <Dropdown
            dropdownZIndex={100}
            clickMode={isTouchDevice}
            button={
                <ContainerButton className={`hl-some-interno ${disabled === true ? 'cursor-block' : clickable ? 'cursor-pointer' : 'cursor-default'}`} style={link.split('/')[3] === atualLocal ? selected : unselected}>
                    {notificacao > 0 &&
                        <NotificacaoPendenteP>{notificacao}</NotificacaoPendenteP>
                    }
                    {clickable ? (
                        <Link to={link} className={linkClass}>
                            {linkContent}
                        </Link>
                    ) : (
                        <div className={`${linkClass} cursor-default`}>
                            {linkContent}
                        </div>
                    )}
                    <JanelaDesanexadaButton className='interno' type='button' onClick={handleOpenModalEmOutraJanela}>
                        <BsArrowBarRight color={'white'} size={19} />
                    </JanelaDesanexadaButton>
                </ContainerButton>
            }
            content={sublinkContent} />
    );
};

export default NavLink;
