import { Form } from '@unform/web';
import Button from 'components/Button';
import Input from 'components/Input';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import Modal from 'react-modal';
import api from 'services/api';
import { CPFService } from 'services/CPFService';
import Swal from 'sweetalert2';


const RecuperarSenhaModal = (props, ref) => {
    //#region VARIAVEIS
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [nome, setNome] = React.useState('false');
    const [inputValue, setInputValue] = React.useState('');
    const [inputError, setInputError] = React.useState('');
    const [inputType, setInputType] = React.useState('');
    const [endpoint, setEndpoint] = React.useState(null);

    const [isMobile, setIsMobile] = React.useState(false);
    const customStyles = useMemo(() => ({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            width: isMobile ? '90%' : '50%',
            padding: '20px',
        },
    }), [isMobile]);
    //#endregion
    //#region FUNCOES MODAL
    /**
     * Função para abrir o modal de recuperação de senha.
     * Define o estado `isOpen` como verdadeiro.
     * Utiliza `useCallback` para memorizar a função e evitar recriações desnecessárias.
     */
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            openModal,
            nome
        };
    }, []);
    function afterOpenModal() { }
    /**
     * Fecha o modal de recuperação de senha.
     * Define o estado `isOpen` como `false`.
     */
    const closeModal = useCallback(() => {
        setEndpoint(null);
        setIsOpen(false);
    }, []);
    //#endregion


    //#region FUNCOES
    /**
     * Valida a entrada do usuário, verificando se é um e-mail ou CPF válido.
     *
     * @param {string} input - A entrada do usuário que pode ser um e-mail ou CPF.
     * @returns {string} - Retorna 'valid' se a entrada for válida, caso contrário, retorna 'invalid'.
     */
    const validateInput = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailTest = emailRegex.test(input);
        const cpfTest = CPFService.ValidaCPF(input).isValid;
        if (input === '') {
            setInputError('Por favor, digite o seu e-mail de acesso ou o seu CPF.');
            return 'invalid';
        }
        if (emailTest || cpfTest) {
            if (emailTest) {
                setInputType('email');
            } else {
                setInputType('cpf');
            }
            return 'valid';
        } else {
            setInputError('Por favor, insira um email ou CPF válido.');
            return 'invalid';
        }
    };
    //#endregion
    //#region HANDLES
    /**
     * Manipula a mudança de valor no campo de entrada.
     * Atualiza o valor do estado e valida a entrada.
     * 
     * @param {Object} e - O evento de mudança do campo de entrada.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O valor do campo de entrada.
     */
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        const validationResult = validateInput(value);
        if (validationResult === 'invalid') {
            setInputError('Por favor, insira um CPF ou email válido.');
        } else {
            setInputError('');
        }
    };
    /**
     * Função de envio do formulário de recuperação de senha.
     * 
     * @param {Event} e - O evento de submissão do formulário.
     * 
     * Valida a entrada do usuário e, se válida, envia uma solicitação POST para a API de recuperação de senha.
     * Exibe uma mensagem de sucesso se o e-mail for enviado com sucesso, ou uma mensagem de erro se não for possível encontrar um usuário com as informações fornecidas.
     */
    const handleSubmit = (e) => {
        const validationResult = validateInput(inputValue);
        if (validationResult === 'invalid') {
            setInputError('Por favor, insira um CPF ou email válido.');
            return;
        } else {
            setInputError('');
        }

        const sendObject = { [inputType]: inputValue };
        api.post(endpoint, sendObject).then((response) => {
            if (response.status === 200) {
                Swal.fire({
                    title: 'E-mail enviado',
                    icon: 'success',
                    text: 'Um e-mail foi enviado para você com as instruções para a recuperação de senha.',
                }).then(() => {
                    closeModal();
                });
            }
        }).catch((err) => {
            if (err.response.status === 400) {
                setInputError('Não foi possível encontrar um usuário com essas informações.');
            } else if (err.response.status === 422) {
                setInputError(err.response.data.message || 'Erro de validação. Por favor, verifique os dados e tente novamente.');
            }
        });
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => window.innerWidth <= 768, [window.innerWidth]);
    //#endregion
    //#region HTML
    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent >
            <div className='row'>
                <h3 className='font-roxo font-bold'>
                    {endpoint !== null ? 'Redefinição de senha' : 'Problemas ao acessar o sistema?'}
                </h3>
                {endpoint === null &&
                    <>
                        <span className='font-roxo'>Não se preocupe, vamos te ajudar!</span>
                        <br></br>
                        <br></br>
                        <span className='font-roxo'>Selecione a opção, que melhor corresponde ao seu problema:</span>
                        <br></br>
                        <div className='d-flex flex-col' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            {/* <Button type='button' onClick={() => setEndpoint('api/primeiro-acesso')} className='pendente w-100'>Primeiro Acesso</Button> */}
                            <Button type='button' onClick={() => setEndpoint('api/esqueci-senha')} className='pendente w-100'>Esqueci minha senha.</Button>
                        </div>
                    </>
                }
                {endpoint !== null &&
                    <Form onSubmit={handleSubmit} className='d-flex flex-col justify-between'>
                        <span>Insira seu Email cadastrado e enviaremos um link para a {endpoint === 'api/esqueci-senha' ? 'alteração de' : 'sua primeira'} senha.</span>
                        <span><b>Lembre-se de olhar na caixa de Spam 😁</b></span>
                        <br></br>
                        <div className='d-flex flex-col'>
                            <label style={{ color: '#442953', fontWeight: 'bold' }}>E-mail:</label>
                            <Input id='reset-info' name='reset-info' value={inputValue} onChange={handleInputChange} />
                            {inputError && <span className='font-vermelho'>{inputError}</span>}
                        </div>
                        <Button type={'submit'} className='pendente mt-4'>Enviar</Button>
                    </Form>
                }
            </div>
        </Modal>
    );
    //#endregion
};

export default forwardRef(RecuperarSenhaModal);
