import { usePageBase } from 'context/page-base';
import RelatorioBase from 'context/relatorio';
import useUsuariosData from 'context/users';
import { useCallback, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';

const RelatoriosUsuarios = () => {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Relatório de Usuarios',
        description: 'Tela onde é possível visualizar os usuarios cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/usuarios'
        ]
    });
    const { fetchUsuariosFiltradosData } = useUsuariosData();

    // Listas
    const [loadingListas, setLoadingListas] = useState(true);
    const [grupos, setGrupos] = useState([]);
    // Relatório
    const columns = [
        'name',
        'tipo_user',
        'group_id',
        'group_name', // Pós processada
        'email',
        'email_verified_at',
        'password',
        'remember_token',
        'reset_password_token',
        'reset_password_expires_at',
        'nome_curto',
        'data_nascimento',
        'cpf',
        'rg',
        'telefone',
        'telefone_2',
        'cep',
        'endereco',
        'estado',
        'bairro',
        'cidade',
        'numero',
        'complemento',
        'data_entrada',
        'data_saida',
        'lembretes',
        'plano_saude_id',
        'ultimo_acesso',
        'limite_acesso',
        'foto_perfil',
        'status',
        'cargo',
        'pendente_de_notificacao',
    ];
    // Filtros
    const [filters, setFilters] = useState(null);
    //#endregion

    //#region FUNCOES
    const fetchData = useCallback(async () => {
        const response = await fetchUsuariosFiltradosData(filters.reduce((acc, filter) => ({ ...acc, [filter.id]: filter.value }), {}));

        return response.map((usuario) => ({
            ...usuario,
            group_name: grupos.find((grupo) => grupo.value === usuario.group_id)?.label,
            created_at: new Date(usuario.created_at).toLocaleString(),
            updated_at: new Date(usuario.updated_at).toLocaleString(),
            deleted_at: usuario.deleted_at ? new Date(usuario.deleted_at).toLocaleString() : null,
            email_verified_at: usuario.email_verified_at ? new Date(usuario.email_verified_at).toLocaleString() : null,
            data_nascimento: usuario.data_nascimento ? new Date(usuario.data_nascimento).toLocaleString() : null,
            reset_password_expires_at: usuario.reset_password_expires_at ? new Date(usuario.reset_password_expires_at).toLocaleString() : null,
            data_entrada: usuario.data_entrada ? new Date(usuario.data_entrada).toLocaleString() : null,
            data_saida: usuario.data_saida ? new Date(usuario.data_saida).toLocaleString() : null,
            ultimo_acesso: usuario.ultimo_acesso ? new Date(usuario.ultimo_acesso).toLocaleString() : null,
            limite_acesso: usuario.limite_acesso ? new Date(usuario.limite_acesso).toLocaleString() : null,
        }));
    }, [filters]);

    const getGrupos = async () => {
        try {
            const { data } = await api.get('api/group');
            setGrupos(data.map((grupo) => ({ value: grupo.id, label: `(${grupo.id}) ${grupo.name}` })));
        } catch (error) {
            console.error('Erro ao carregar grupos:', error);
        }
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        setLoadingListas(false);
        getGrupos();
    }, []);

    useEffect(() => {
        if (grupos) {
            setFilters([
                { id: 'name', label: 'Nome', value: '', type: 'text' },
                { id: 'email', label: 'Email', value: '', type: 'text' },
                { id: 'cpf', label: 'CPF', value: '', type: 'text' },
                { id: 'group_id', label: 'Tipo do Usuário', value: '', type: 'multi_select', options: grupos },
            ]);
        }
    }, [grupos]);
    //#endregion

    //#region RENDERS
    if (loadingListas) {
        return <div>
            <h2>Carregando... <ClipLoader color={'#000'} loading={true} size={150} /></h2>
        </div>;
    }

    return (
        <RelatorioBase
            fetchData={fetchData}
            columns={columns}
            filters={filters}
            setFilters={setFilters}
        />
    );
    //#endregion
};

export default RelatoriosUsuarios;