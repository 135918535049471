import React, { useState, useEffect, useCallback } from 'react';
import RelatorioBase from 'context/relatorio';
import { usePageBase } from 'context/page-base';

const RelatorioPacienteDemonstracao = () => {
    usePageBase({
        title: 'Relatório de Pacientes (Demonstração)',
        hasTitle: true,
        description: 'Tela de demonstração com dados falsos dos pacientes.',
        routes: [
            'dashboard/[modulo]/relatorios/pacientes/demonstracao'
        ]
    });

    const statusOptions = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Ferias', label: 'Ferias' },
    ];
    const columns = [
        'nome',
        'email',
        'cpf',
        'status',
        'responsaveis',
        'terapias',
        'especialidades',
        'planos_saude'
    ];
    const columnsAndTypes = [
        { id: 'nome', label: 'Nome', type: 'text' },
        { id: 'email', label: 'Email', type: 'text' },
        { id: 'cpf', label: 'CPF', type: 'text' },
        { id: 'status', label: 'Status', type: 'text' },
        { id: 'responsaveis', label: 'Responsáveis', type: 'text' },
        { id: 'terapias', label: 'Terapias', type: 'text' },
        { id: 'especialidades', label: 'Especialidades', type: 'text' },
        { id: 'planos_saude', label: 'Planos de Saúde', type: 'text' },
    ];

    const [filters, setFilters] = useState(null);

    const fetchData = useCallback(async () => {
        const fakeData = Array.from({ length: 100 }, (_, index) => ({
            nome: `Paciente ${index + 1}`,
            email: `paciente${index + 1}@example.com`,
            cpf: `${Math.floor(Math.random() * 900000000 + 100000000)}-${Math.floor(Math.random() * 90 + 10)}`,
            status: ['Ativo', 'Inativo', 'Ferias'][Math.floor(Math.random() * 3)],
            responsaveis: `Responsável ${index + 1}`,
            terapias: ['Fisioterapia', 'Psicoterapia', 'Terapia Ocupacional'][Math.floor(Math.random() * 3)],
            especialidades: ['Cardiologia', 'Neurologia', 'Ortopedia'][Math.floor(Math.random() * 3)],
            planos_saude: ['Unimed', 'Amil', 'Bradesco Saúde'][Math.floor(Math.random() * 3)]
        }));
        return fakeData;
    }, [filters]);

    useEffect(() => {
        setFilters([
            { id: 'nome', label: 'Nome', value: '', type: 'text' },
            { id: 'email', label: 'Email', value: '', type: 'text' },
            { id: 'cpf', label: 'CPF', value: '', type: 'text' },
            { id: 'status', label: 'Status', value: '', type: 'multi_select', options: statusOptions },
            {
                id: 'especialidade_id', label: 'Especialidade', value: '', type: 'select', options: [
                    { value: 'Cardiologia', label: 'Cardiologia' },
                    { value: 'Neurologia', label: 'Neurologia' },
                    { value: 'Ortopedia', label: 'Ortopedia' },
                ]
            },
            {
                id: 'terapia_id', label: 'Terapia', value: '', type: 'select', options: [
                    { value: 'Fisioterapia', label: 'Fisioterapia' },
                    { value: 'Psicoterapia', label: 'Psicoterapia' },
                    { value: 'Terapia Ocupacional', label: 'Terapia Ocupacional' },
                ]
            },
            {
                id: 'plano_saude_id', label: 'Plano de Saúde', value: '', type: 'select', options: [
                    { value: 'Unimed', label: 'Unimed' },
                    { value: 'Amil', label: 'Amil' },
                    { value: 'Bradesco Saúde', label: 'Bradesco Saúde' },
                ]
            },
            {
                id: 'paciente_id', label: 'Paciente', value: '', type: 'select', options: [
                    { value: '1', label: 'João Silva' },
                    { value: '2', label: 'Ana Souza' },
                ]
            },
        ]);
    }, []);

    return (
        <RelatorioBase
            fetchData={fetchData}
            columns={columns}
            columnsAndTypes={columnsAndTypes}
            filters={filters}
            setFilters={setFilters}
        />
    );
};

export default RelatorioPacienteDemonstracao;
