import React, { useState, useRef, useEffect } from 'react';

/**
 * Componente de Dropdown que renderiza um botão que, ao ser clicado ou sobrevoado com o mouse,
 * exibe um conteúdo suspenso. O comportamento de exibição pode ser configurado para ser baseado
 * em cliques ou em eventos de mouse hover.
 *
 * @param {React.ReactNode} button - O conteúdo a ser renderizado dentro do botão do dropdown.
 * @param {string} buttonClass - Classe CSS a ser aplicada no botão do dropdown.
 * @param {string} buttonOverClass - Classe CSS adicional a ser aplicada quando o mouse está sobre o botão.
 * @param {boolean} substituicao - Define a posição do dropdown à direita se `true`.
 * @param {boolean} reserva - Define a posição do dropdown à esquerda se `true`.
 * @param {React.ReactNode} content - O conteúdo a ser exibido dentro do dropdown. Se vazio, o dropdown não será ativado.
 * @param {boolean} [clickMode=false] - Se `true`, ativa o dropdown com clique, caso contrário, ativa por hover.
 * @param {boolean} [buttonOver=false] - Se `true`, altera o comportamento de sobreposição do botão com o conteúdo do dropdown.
 * @param {boolean} [defaultOpen=false] - Define se o dropdown deve iniciar aberto.
 * @param {string|number} [dropdownZIndex='initial'] - Define o valor do z-index do dropdown.
 * @param {number} [closeDelay=200] - Tempo (em milissegundos) para fechar o dropdown após o mouse sair.
 * @param {Function} [setMostrando] - Callback que define se o dropdown está visível ou não, baseado no estado `isHovered`.
 * @param {string} [placeholder] - Placeholder para o container do dropdown.
 * @param {boolean} [openUp=false] - Define se o dropdown deve abrir para cima.
 *
 * @returns {JSX.Element} Componente de Dropdown.
 */
const Dropdown = ({
    button,
    buttonClass,
    buttonOverClass,
    substituicao,
    reserva,
    content,
    clickMode = false,
    buttonOver = false,
    defaultOpen = false,
    dropdownZIndex = 'initial',
    closeDelay = 200,
    setMostrando = () => { },
    placeholder,
    openUp = false
}) => {
    let timeoutId;
    const [isHovered, setIsHovered] = useState(defaultOpen ? true : false);
    const dropdownRef = useRef(null);

    // Se o conteúdo do dropdown for vazio ou null, o dropdown será desativado.
    const isDropdownActive = content !== null && content !== undefined && content !== '';

    const handleMouseEnter = () => {
        if (!isDropdownActive) return;
        clearTimeout(timeoutId);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isDropdownActive) return;
        timeoutId = setTimeout(() => {
            setIsHovered(false);
        }, closeDelay);
    };

    const handleClickToggle = () => {
        if (!isDropdownActive) return;
        isHovered ? handleMouseLeave() : handleMouseEnter();
    };

    useEffect(() => {
        setMostrando(isHovered);
    }, [isHovered]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsHovered(false);
            }
        };

        if (isDropdownActive) {
            document.addEventListener('click', handleDocumentClick);
        }

        return () => {
            if (isDropdownActive) {
                document.removeEventListener('click', handleDocumentClick);
            }
        };
    }, [isDropdownActive]);

    const getDropdownPositionClass = () => {
        if (openUp) {
            return `ns-dropdown-menu-up-${substituicao ? 'right' : reserva ? 'left' : ''}`;
        }
        return `ns-dropdown-menu-${substituicao ? 'right' : reserva ? 'left' : 'default'}`;
    };

    const dropdownButton = (
        <div
            type="button"
            className={`ns-dropdown-btn ${buttonClass}${buttonOver ? ' position-relative' : ''}`}
            style={
                (buttonOver && isHovered)
                    ? {
                        zIndex: 100,
                        transition: 'z-index 0.2s'
                    } : {
                        zIndex: 'auto',
                        transition: 'z-index 0.2s'
                    }}>
            {button}
        </div>
    );

    const dropdownContent = isDropdownActive && (
        <div
            className={`ns-dropdown-menu ${getDropdownPositionClass()}`}
            style={buttonOver
                ? {
                    zIndex: 99,
                    transition: 'z-index 0.2s',
                    position: 'absolute',
                } : {
                    zIndex: dropdownZIndex,
                    transition: 'z-index 0.2s'
                }}
            onClick={(e) => e.stopPropagation()}
        >
            {content}
        </div>
    );

    const eventHandlers = isDropdownActive
        ? (clickMode
            ? { onClick: handleClickToggle }
            : { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave })
        : {};

    return (
        <div
            placeholder={placeholder}
            className={`ns-dropdown-parent ${buttonOverClass}${isHovered ? ' hover' : ''}`}
            ref={dropdownRef}
            {...eventHandlers}
        >
            {dropdownButton}
            {dropdownContent}
        </div>
    );
};

export default Dropdown;