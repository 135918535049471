import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { AiFillHome, AiOutlineUser, AiOutlineCalendar, AiOutlinePaperClip } from 'react-icons/ai';

const open = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const MenuIcon = styled.div`
  transition: all .4s;
  cursor: pointer;
  &.open {
    transform: rotate(90deg);
  }
`;

export const Container = styled.div`
  padding: 12px 0;
  background: #7441BF;
`;

export const HeaderContent = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;

  &.is-mobile {
    transition: all .4s;
    opacity: 0;
    overflow: hidden;

    animation: ${open} .4s forwards;
  }

  > img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;


export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding: 10px; 
  margin: 5px;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: transparent;
  height: 60px;
  min-width: 80px;
  max-width: 150px;
  font-weight: 500;

  transition: background-color 0.2s;

  > div {
    display: flex;
    align-items: center;
  }

  > div span {
    margin-left: 5px;
    color: var(--senary);
  }

  &:hover,
  &.active {
    background: ${shade(0.2, '#7442BF')};

    > div span {
      color: var(--white);
    }
  }
`;

export const HomeIcon = styled(AiFillHome)`
  width: 20px;
  height: 20px;

  color: var(--symbol);
`;

export const AgendaIcon = styled(AiOutlineCalendar)`
  width: 20px;
  height: 20px;

  color: var(--symbol);
`;

export const UserIcon = styled(AiOutlineUser)`
  width: 20px;
  height: 20px;

  color: var(--symbol);
`;

export const DocumentIcon = styled(AiOutlinePaperClip)`
  width: 20px;
  height: 20px;

  color: var(--symbol);
`;