import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 5px 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
`;

const LiveBadgeContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border-radius: 50px;
`;

const LiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  animation: ${pulse} 1.5s infinite;
`;

const OfflineIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: gray;
  border-radius: 50%;
  margin-right: 10px;
`;

const LiveBadge = ({ sincronizado = false, texto = ['Sincronizado', 'Dessincronizado'], temTexto = true, tooltip = null, posicao = 'fixada', mostra = true }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (posicao === 'fixada') {
      setStyle({ position: 'fixed', bottom: '20px', right: '20px' });
    } else if (typeof posicao === 'object') {
      setStyle({ position: 'absolute', top: posicao[0], left: posicao[1] });
    } else if (posicao === 'relativa') {
      setStyle({ position: 'relative', top: posicao[0], left: posicao[1] });
    } else {
      setStyle({});
    }
  }, [posicao]);

  if (mostra === true) {
    return (
      <LiveBadgeContainer style={style}>
        {sincronizado ? <LiveIndicator /> : <OfflineIndicator />}
        {temTexto && <span>{sincronizado ? texto[0] : texto[1]}</span>}
        {tooltip && <Tooltip id="tooltip" place="top" effect="solid">{tooltip}</Tooltip>}
      </LiveBadgeContainer>
    );
  }else{
    return null;
  }
};

export default LiveBadge;