import React, { forwardRef } from 'react';
import moment from 'moment';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { BsCheck, BsTrashFill } from 'react-icons/bs';
import { ChangeCase } from 'services/ChangeCase';
import DropdownCSS from 'components/DropdownCSS';
import './agenda.css';

/**
 * TbodyAgendaSemanal is a React forwardRef component that renders a table body with scheduled appointments.
 * It processes and displays appointments, reservations, and substitutions with appropriate styles and tooltips.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.memorizedHorarios - Array of memorized schedules.
 * @param {Array} props.memorizedEstacaos - Array of memorized stations.
 * @param {Object} props.newAgendamentos - Object containing new appointments.
 * @param {Object} props.styles - Object containing styles for the component.
 * @param {Function} [props.getLegendaAgendamento] - Function to get the appointment legend.
 * @param {Function} [props.setMostrandoReserva] - Function to set the showing reservation state.
 * @param {Array} props.agendamentos - Array of appointments.
 * @param {Object} props.styles_filtred - Object containing filtered styles.
 * @param {Object} props.styles_escuro - Object containing dark styles.
 * @param {Object} props.filtered - Object containing filtered styles.
 * @param {Object} props.permissoes - Object containing user permissions.
 * @param {Function} props.setTerapeutaSelecionadoOpt - Function to set the selected therapist.
 * @param {Function} props.aprovaReserva - Function to approve a reservation.
 * @param {Function} props.excluirAgendamento - Function to delete an appointment.
 * @param {Function} props.modalEditar - Function to open the edit modal.
 * @param {Function} props.modalDetalhesAgendamento - Function to open the appointment details modal.
 * @param {boolean} props.esperaMode - Boolean indicating if the wait mode is active.
 * @param {Object} props.usuario - Object containing user information.
 * @param {Function} props.setPacienteSelecionadoOpt - Function to set the selected patient option.
 * @param {Function} [props.handleOpenAgendamento] - Function to handle opening an appointment.
 * @param {Function} [props.handleOnMouseEnter] - Function to handle mouse enter event.
 * @param {Function} [props.handleOnMouseLeave] - Function to handle mouse leave event.
 * @param {Function} [props.onScrollCapture] - Function to handle scroll capture event.
 * @param {Function} [props.endLoad] - Function to handle end load event.
 * @param {boolean} props.showAgendamentos - Boolean indicating if appointments should be shown.
 * @param {React.Ref} ref - React ref forwarded to the tbody element.
 * @returns {JSX.Element} A table body element with the processed appointment information.
 *
 * @example
 * <TbodyAgendaSemanal
 *   memorizedHorarios={memorizedHorarios}
 *   memorizedEstacaos={memorizedEstacaos}
 *   newAgendamentos={newAgendamentos}
 *   styles={styles}
 *   getLegendaAgendamento={getLegendaAgendamento}
 *   setMostrandoReserva={setMostrandoReserva}
 *   agendamentos={agendamentos}
 *   styles_filtred={styles_filtred}
 *   styles_escuro={styles_escuro}
 *   filtered={filtered}
 *   permissoes={permissoes}
 *   setTerapeutaSelecionadoOpt={setTerapeutaSelecionadoOpt}
 *   aprovaReserva={aprovaReserva}
 *   excluirAgendamento={excluirAgendamento}
 *   modalEditar={modalEditar}
 *   modalDetalhesAgendamento={modalDetalhesAgendamento}
 *   esperaMode={esperaMode}
 *   usuario={usuario}
 *   setPacienteSelecionadoOpt={setPacienteSelecionadoOpt}
 *   handleOpenAgendamento={handleOpenAgendamento}
 *   handleOnMouseEnter={handleOnMouseEnter}
 *   handleOnMouseLeave={handleOnMouseLeave}
 *   onScrollCapture={onScrollCapture}
 *   endLoad={endLoad}
 *   showAgendamentos={showAgendamentos}
 *   ref={ref}
 *   horariosEstacoesDisponiveis={horariosEstacoesDisponiveis}
 * />
 */
const DemonstracaoTbodyAgendaSemanal = forwardRef(({
    memorizedHorarios,
    memorizedEstacaos,
    newAgendamentos,
    styles,
    getLegendaAgendamento = () => { },
    setMostrandoReserva = () => { },
    agendamentos,
    styles_filtred,
    styles_escuro,
    filtered,
    setTerapeutaSelecionadoOpt,
    aprovaReserva,
    excluirAgendamento,
    modalEditar,
    modalDetalhesAgendamento,
    esperaMode,
    usuario,
    setPacienteSelecionadoOpt,
    handleOpenAgendamento = () => { },
    handleOnMouseEnter = () => { },
    handleOnMouseLeave = () => { },
    onScrollCapture = () => { },
    endLoad = () => { },
    showAgendamentos = false,
    horariosEstacoesDisponiveis
}, ref) => {
    /**
     * Generates a dropdown agenda component for a given appointment.
     *
     * @param {Object} a - The appointment object.
     * @param {string} tipo - The type of the appointment (e.g., 'reserva', 'substituicao').
     * @param {boolean} horaInteira - Indicates if the appointment is for a full hour.
     * @param {string} estacao - The station associated with the appointment.
     * @param {number} idHorario - The ID of the schedule.
     * @returns {JSX.Element} The dropdown agenda component.
     */
    const geraDropDownAgenda = (a, tipo, horaInteira, estacao, idHorario) => {
        console.log(agendamentos);
        const legenda = getLegendaAgendamento(a, agendamentos);
        let horaFinalDiferente;
        let horaFinal;
        if (a.data_atendimento_final) {
            if (a.data_atendimento_final.includes('T')) {
                horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
            } else {
                horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
            }
        }
        horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
        let div2style = {
            cursor: 'default',
            marginTop: '-10px',
            ...styles.trReserva
        };

        if (a.filtrado === undefined) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles[legenda],
            };
        } else if (a.filtrado === true) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles_filtred[legenda],
                ...filtered,
            };
        } else if (a.filtrado === false) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles_escuro[legenda],
            };
        }

        if (tipo === 'substituicao') {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
            };
        }

        return (
            <div style={styles.agendamentoExtras} key={a.id} onMouseEnter={() => setMostrandoReserva(true)} onMouseLeave={() => setMostrandoReserva(false)}>
                <DropdownCSS
                    substituicao={tipo !== 'reserva'}
                    reserva={tipo === 'reserva'}
                    setMostrando={setMostrandoReserva}
                    clickMode={false}
                    defaultOpen={false}
                    dropdownZIndex={100}
                    button={
                        <svg
                            className='p-0 m-0'
                            style={{
                                ...styles.extraIcon,
                                ...(tipo === 'reserva'
                                    ? styles.extraIcon.reserva
                                    : (a.tipo_atendimento_id === 7)
                                        ? styles.extraIcon.substituicaoFeito
                                        : styles.extraIcon.substituicao
                                )
                            }}
                            id={`${tipo === 'reserva' ? 'agendamento-reserva-icone' : 'agendamento-substituicao-icone'}${a.id}`}
                            width="20"
                            height="20"
                            fill="currentColor"
                        >
                            <polygon points="0,0 15,0 0,15" />
                        </svg>
                    }
                    content={
                        <div id={`${tipo === 'reserva' ? 'agendamento-reserva' : 'agendamento-substituicao'}${a.id}`} style={div2style}>
                            <div className={horaFinalDiferente ? 'd-flex h-100per flex-wrap p-1' : 'd-flex h-100per align-content-between flex-wrap'}>
                                <div className='col-12' style={a.paciente_status === 'Ferias' ? styles.fontFerias : {}}>
                                    {a.paciente_identificador}
                                </div>
                                <div className='col-12 d-flex justify-content-between align-items-center'>
                                    <AiFillEye className='pointer h-azul' onClick={() => modalDetalhesAgendamento(a)} />
                                    {true && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
                                        <AiFillEdit className='pointer h-azul' onClick={() => modalEditar(a, horaInteira, estacao, idHorario, a.reserva, a.tipo_atendimento_id)} />
                                    }
                                    {true &&
                                        <BsTrashFill className='pointer h-azul' onClick={() => excluirAgendamento(a.id)} />
                                    }
                                    {true && legenda === 'reserva' &&
                                        <BsCheck size={22} className='pointer h-azul' onClick={() => aprovaReserva(a)} />
                                    }
                                </div>
                                <div className='col-12' onClick={() => { setTerapeutaSelecionadoOpt({ value: a.terapeuta_id, label: a.terapeuta_nome }); }} style={a.terapeuta_status === 'Ferias' ? styles.fontFerias : {}}>
                                    {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 10) : 'Sem Terapeuta'}.
                                </div>
                                {!horaFinalDiferente &&
                                    <div className='hora-diferente d-inline-flex'>
                                        Hora Final: {horaFinal}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                />
            </div>
        );
        ;
    };
    /**
     * CellCss component renders a table cell with scheduling information for a specific station and time slot.
     * It handles different types of appointments, including regular appointments, reservations, and substitutions.
     * The component also displays tooltips with detailed information about the appointment when hovered over.
     *
     * @component
     * @param {Object} props - The properties object.
     * @param {Object} props.estacao - The station object containing information about the station.
     * @param {Object} props.horario - The time slot object containing information about the time slot.
     * @param {number} props.horarioIndex - The index of the time slot in the list of time slots.
     * @param {number} props.estacaoIndex - The index of the station in the list of stations.
     * @returns {JSX.Element} The rendered table cell component.
     */
    const CellCss = ({ estacao, horario, horarioIndex, estacaoIndex }) => {
        const horaInteira = moment.utc(horario.horario);
        const hora = moment.utc(horario.horario).format('HH:mm');
        const idHorario = horario.id;

        const agendamento = newAgendamentos && newAgendamentos[estacao.id] && newAgendamentos[estacao.id][horario.id] ? newAgendamentos[estacao.id][horario.id] : null;

        let agendaContent = null;
        let reservaNoTitulo = null;
        let substituicaoNoTitulo = null;
        let titulo;
        let tooltipInfo = (
            <ul>
                <li key={`tp:0|es:${estacao.id}|hor:${horario.id}`}>Sala/Estação: <b>{estacao.sala.sala}/{estacao.estacao}</b></li>
                <li key={`tp:1|es:${estacao.id}|hor:${horario.id}`}>Hora: <b>{hora}</b></li>
            </ul>
        );
        let div2styleAgenda = styles.tamanhoPadrao;

        const processAgendamento = (a) => {
            const aReserva = a.reserva === 0;
            const aTipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7;
            const aEspera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;

            if (aReserva && aTipo && aEspera) {
                agendaContent = [];
                console.log(agendamentos);
                const legenda = getLegendaAgendamento(a, agendamentos);
                let horaFinalDiferente;
                let horaFinal;
                if (a.data_atendimento_final) {
                    const finalTime = a.data_atendimento_final.includes('T') ?
                        String(a.data_atendimento_final).split('T')[1].split('.')[0] :
                        String(a.data_atendimento_final).split(' ')[1].split('.')[0];
                    horaFinalDiferente = finalTime === moment.utc(a.data_atendimento_inicial).add(60, 'Minutes').format('HH:mm:ss');
                    horaFinal = finalTime ? finalTime.split(':') : null;
                }
                horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';

                // Aplicando os estilos conforme as regras
                div2styleAgenda = {
                    cursor: 'default',
                    color: 'white',
                    ...styles.trOcupado,
                    ...div2styleAgenda,
                    ...(a.filtrado === undefined ? styles[legenda] : a.filtrado ? { ...styles_filtred[legenda], ...filtered } : styles_escuro[legenda]),
                    ...(a.paciente_aviso_agendamento_check === 1 ? { border: '1px solid red', boxShadow: '0 0 10px red' } : {}),
                };

                tooltipInfo = (
                    <ul>
                        {(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente' || legenda === 'conflitoTerapeutaSala' || legenda === 'duplicado') &&
                            <li key={`tp:0|es:${estacao.id}|hor:${horario.id}`}><h5>{ChangeCase.toTitleCase(legenda)}</h5></li>
                        }
                        <li key={`tp:1|es:${estacao.id}|hor:${horario.id}`}>Sala/Estação: {estacao.sala.sala}/{estacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${estacao.id})`}</li>
                        <li key={`tp:2|es:${estacao.id}|hor:${horario.id}`}>Paciente: {a.paciente_nome} {a.paciente_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}</li>
                        <li key={`tp:3|es:${estacao.id}|hor:${horario.id}`}>Terapeuta: {a.terapeuta_nome} {a.terapeuta_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}</li>
                        <li key={`tp:4|es:${estacao.id}|hor:${horario.id}`}>Terapia: {a.terapia_terapia}</li>
                        <li key={`tp:5|es:${estacao.id}|hor:${horario.id}`}>Especialidade: {a.especialidade_especialidade}</li>
                        <li key={`tp:6|es:${estacao.id}|hor:${horario.id}`}>Horário: {hora} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}</li>
                    </ul>
                );

                titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })} style={a.paciente_status === 'Ferias' ? styles.fontFerias : {}}>
                    {a.paciente_identificador}
                </p>;

                agendaContent.push(<>
                    <div className='col-12 d-flex justify-content-center align-items-center h-auto-i' id={'agendamento' + a.id}>
                        <AiFillEye className='pointer h-azul mx-1' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
                        {true && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
                            <AiFillEdit className='pointer h-azul mx-1' onClick={() => modalEditar(a, horaInteira, estacao, idHorario, a.reserva, a.tipo_atendimento_id)}></AiFillEdit>
                        }
                        {true &&
                            <BsTrashFill className='pointer h-azul mx-1' onClick={() => excluirAgendamento(a.id)} />
                        }
                    </div>
                    <div className='col-12 h-auto-i pointer ns-abrevia-texto' onClick={(e) => { setTerapeutaSelecionadoOpt({ value: a.terapeuta_id, label: a.terapeuta_nome }); }} style={a.terapeuta_status === 'Ferias' ? styles.fontFerias : {}}>
                        {a.terapeuta_nome_curto}
                    </div>
                    {!horaFinalDiferente &&
                        <div className='hora-diferente d-inline-flex' width={styles.width}>
                            Hora Final: {horaFinal}
                        </div>
                    }
                </>);
            }

            // Processamento de reserva
            const rReserva = a.reserva === 1;
            if (rReserva) {
                reservaNoTitulo = geraDropDownAgenda(a, 'reserva', horaInteira, estacao, idHorario);
            }

            // Processamento de substituição
            const sSubstituicao = !esperaMode ? a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7 : false;
            if (sSubstituicao && aReserva) {
                substituicaoNoTitulo = geraDropDownAgenda(a, 'substituicao', horaInteira, estacao, idHorario);
            }
        };

        // Verifica agendamentos
        if (agendamento?.length > 0) {
            agendamento.forEach(processAgendamento);
        }

        function getTooltipClass(horarioIndex, estacaoIndex) {
            const horarioInicio = horarioIndex === 0;
            const horarioFim = horarioIndex === memorizedHorarios.length - 1;
            const pertoTopo = horarioIndex === 0 || horarioIndex === 1;
            const horarioMeioCima = (memorizedHorarios.length - 1) / 2 > horarioIndex;
            const horarioMeioBaixo = (memorizedHorarios.length - 1) / 2 < horarioIndex;
            const estacaoInicio = estacaoIndex === 0;
            const estacaoFim = estacaoIndex === memorizedEstacaos.length - 1;
            if (horarioInicio && estacaoInicio) {
                return 'tooltip-right-bottom';
            }
            if (horarioInicio && estacaoFim) {
                return 'tooltip-left-bottom';
            }
            if (horarioFim && estacaoInicio) {
                return 'tooltip-right-top';
            }
            if (horarioFim && estacaoFim) {
                return 'tooltip-left-top';
            }
            if (estacaoInicio) {
                return 'tooltip-right';
            }
            if (estacaoFim) {
                if (pertoTopo) {
                    return 'tooltip-left-bottom';
                } else {
                    return 'tooltip-left';
                }
            }
            if (horarioInicio) {
                return 'tooltip-bottom';
            }
            if (horarioFim) {
                return 'tooltip-top';
            }
            if (!horarioInicio && !horarioFim && !estacaoInicio && !estacaoFim) {
                if (horarioMeioCima) {
                    return 'tooltip-bottom';
                }
                if (horarioMeioBaixo) {
                    return 'tooltip-top';
                }
            }
            return 'tooltip-top';
        }
        const tooltipClass = getTooltipClass(horarioIndex, estacaoIndex);
        return (
            <td className="tooltip-container tr-vazio m-0 p-0"
                style={{ cursor: true ? 'pointer' : 'default' }}
                id={`${estacao.id}|${horario.id}`}
                key={`${estacao.id}|${horario.id}`}
                onClick={(e) => { if (agendaContent === null) { handleOpenAgendamento(horaInteira, estacao, idHorario, e); } }}
                onMouseEnter={(e) => handleOnMouseEnter(horario.id, estacao.id)}
                onMouseLeave={(e) => handleOnMouseLeave(horario.id, estacao.id)}
                data-name='espaco-vazio'>
                <div data-name='espaco-vazio' className={'d-grid '} style={div2styleAgenda} id={`linha-agendamento-${estacao.id}-${horario.id}-div`}>
                    <div data-name='espaco-vazio' className='w-100per d-flex h-15px m-0 p-0'>
                        {reservaNoTitulo ? reservaNoTitulo : <div style={styles.agendamentoExtras}></div>}
                        <div style={styles.divTitulo}>{titulo || ' '}</div>
                        {substituicaoNoTitulo ? substituicaoNoTitulo : <div style={styles.agendamentoExtras}></div>}
                    </div>
                    {agendaContent}
                </div>
                {agendaContent && <div className={tooltipClass}>
                    <div className={'tooltip-text'}>
                        {tooltipInfo}
                        <div className="tooltip-arrow"></div>
                    </div>
                </div>
                }
            </td>
        );
    };
    /**
     * Handles the scroll event.
     *
     * @param {Event} e - The scroll event object.
     */
    const handleScroll = (e) => {
        onScrollCapture();
    };
    React.useEffect(() => {
        if (showAgendamentos === true) {
            endLoad(ref);
        }
    }, [showAgendamentos, memorizedHorarios, memorizedEstacaos]);

    return (
        <tbody
            ref={ref}
            id="tbody-grade-de-agendamentos"
            className="tbody-agenda-semanal"
            style={styles.tbody}
            onScrollCapture={(e) => handleScroll(e)}>
            {showAgendamentos && memorizedHorarios.map((horario, index1) => {
                return (
                    <tr key={`hora-${horario.id}`}>
                        {memorizedEstacaos.map((estacao, index2) => {
                            return <CellCss key={`es:${estacao.id}|ho:${horario.id}`} estacao={estacao} horario={horario} horarioIndex={index1} estacaoIndex={index2} />;
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
});
export default DemonstracaoTbodyAgendaSemanal;