//#region IMPORTAÇÕES
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import api from '../../../services/api';
import { TableTerapeutas } from './TableTerapeutas';
import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import FiltroTerapias from '../../../components/Filter';
import Carregamento from '../../../components/Carregamento';
import Button from '../../../components/Button';
import { usePageBase } from 'context/page-base';
import useIcons from 'context/icons';
import useTerapeutasData from 'context/terapeuta';
import Select from 'components/Select';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
//#endregion

/**
 * Componente que renderiza a listagem de terapeutas.
 *
 * @component
 * @returns {JSX.Element} O componente de listagem de terapeutas.
 *
 * @example
 * <ListagemTerapeutas />
 *
 * @description
 * Este componente utiliza o hook `usePageBase` para configurar a página com título e descrição.
 * Ele também utiliza o hook `useNavigate` para navegação e `useAuth` para obter informações do usuário autenticado.
 * O componente carrega a lista de terapeutas a partir de uma API e permite filtrar, visualizar, editar e cadastrar terapeutas.
 *
 * @function
 * @name ListagemTerapeutas
 *
 * @property {boolean} loadingDadosTerapeutas - Estado que indica se os dados dos terapeutas estão sendo carregados.
 * @property {Array} terapeutas - Lista de terapeutas filtrados.
 * @property {string} filterValue - Valor do filtro aplicado.
 * @property {Array} terapeutaOriginal - Lista original de terapeutas.
 * @property {Array} permissoes - Lista de permissões do usuário.
 *
 * @requires usePageBase
 * @requires useNavigate
 * @requires useAuth
 * @requires useState
 * @requires useEffect
 * @requires api
 * @requires Container
 * @requires Carregamento
 * @requires FiltroTerapias
 * @requires TableTerapeutas
 * @requires Button
 * @requires AiOutlinePlusCircle
 */
export default function ListagemTerapeutas() {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Terapeutas',
        description: 'Tela onde é possível visualizar, editar e cadastrar terapeutas.',
        hasTitle: true,
        hasSubMenu: false
    });
    const navigate = useNavigate();
    const { user, coletaPermissoesPagina } = useAuth();
    const usuario = useAuth();
    const { iconArray } = useIcons();
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();

    // Estados
    const [loadingDadosTerapeutas, setLoadingDadosTerapeutas] = useState(false);

    // Selecionado
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);

    // Listagens
    const [terapeutas, setTerapeutas] = useState([]);
    const [terapeutaOriginal, setTerapeutaOriginal] = useState([]);

    // Filtros
    const [filterValue, setFilter] = useState('');

    const [permissoes, setPermissoes] = useState([]);
    //#endregion

    //#region FUNÇÕES
    /**
     * Função que coleta permissões do usuário para a página atual.
     * 
     * A função percorre a lista de permissões do usuário e verifica se a permissão
     * é relevante para a página atual, baseada no caminho da URL. Se a permissão
     * for relevante, ela é adicionada a um objeto de permissões locais.
     * 
     * @returns {void}
     */
    const coletaPermissoes = () => {
        const localPermissoes = coletaPermissoesPagina();
        setPermissoes(localPermissoes);
    };
    /**
     * Função assíncrona para carregar dados dos terapeutas.
     * Define o estado de carregamento como verdadeiro, faz uma requisição à API para obter os dados dos terapeutas,
     * ordena os dados pela data de criação em ordem decrescente, e atualiza os estados com os dados recebidos.
     * Em caso de erro, registra o erro no console e define o estado de carregamento como falso.
     *
     * @async
     * @function carrregaTerapeutas
     * @returns {Promise<void>} Uma promessa que resolve quando os dados dos terapeutas são carregados e os estados são atualizados.
     */
    async function carrregaTerapeutas() {
        setLoadingDadosTerapeutas(true);
        await api.get(`api/terapeuta${terapeutaSelecionado?.value ? `/${terapeutaSelecionado?.value}` : ''}?with=terapeuta_terapia,terapeuta_especialidades,terapeuta_especialidades.especialidade,terapeuta_terapia.terapium`).then(({ data }) => {
            if (data.length > 0) {
            } else {
                data = [data];
            }
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });

            setTerapeutaOriginal(data);
            setTerapeutas(data);
            setLoadingDadosTerapeutas(false);
        }).catch((e) => {
            console.error(e);
            setLoadingDadosTerapeutas(false);
        });
    }
    //#endregion

    const handleLimparConsulta = () => {
        setTerapeutas(null);
        setTerapeutaSelecionado(null);
        setTerapeutaOriginal(null);
        setFilter('');
    }

    const handleConsultaTerapeuta = () => {
        setLoadingDadosTerapeutas(true);
        if (terapeutaSelecionado?.value) {
            carrregaTerapeutas(terapeutaSelecionado?.value);
        } else {
            Swal.fire({
                title: 'Consultar todos os terapeutas?',
                text: "Isso pode demorar um pouco. Deseja continuar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, consultar todos!'
            }).then((result) => {
                if (result.isConfirmed) {
                    carrregaTerapeutas();
                } else {
                    setLoadingDadosTerapeutas(false);
                }
            });
        }
    };

    //#region USE EFFECTS

    useEffect(() => {
        fetchTerapeutasData();
        coletaPermissoes();
    }, []);
    //#endregion

    //#region HTML
    return (
        <Container>
            {loadingDadosTerapeutas ? <Carregamento></Carregamento> : null}
            <Form className="row">
                <div className='col-6 d-flex flex-nowrap flex-row'>
                    <div className='m-0' style={{ width: '90%' }}>
                        <Select
                            id="terapeuta_id"
                            name="terapeuta_id"
                            label="Terapeuta:"
                            value={terapeutaSelecionado}
                            options={terapeutasOptions}
                            onChange={setTerapeutaSelecionado}
                        />
                    </div>
                    <div className='m-0 d-flex align-items-end' style={{ width: '10%' }}>
                        <Button onClick={() => handleLimparConsulta()}>{iconArray['limpar']}</Button>
                    </div>
                </div>
                <div className={`col-${permissoes['criação'] ? '2' : '6'} d-flex align-items-end`}>
                    <Button className="btn-padrao h-content-i" onClick={() => handleConsultaTerapeuta()}>
                        <span className='me-2'>{iconArray['pesquisar']}</span>
                        Consultar
                    </Button>
                </div>
                <div className={`col-${permissoes['edição'] ? '2' : '4'} d-flex align-items-end`}>
                    <Button className="btn-padrao h-content-i" disabled={terapeutaSelecionado?.value ? false : true} onClick={() => navigate(`/dashboard/${user.tipo_user.toLowerCase()}/terapeuta/perfil/` + terapeutaSelecionado?.value)}>
                        <span className='me-2'>{iconArray['editar']}</span>
                        Editar
                    </Button>
                </div>
                {permissoes['criação'] &&
                    <div className='col-2 d-flex align-items-end'>
                        <Button className="h-content-i" onClick={() => navigate(`/dashboard/${usuario.user.tipo_user.toLowerCase()}/terapeutas/novo`)}>
                            <AiOutlinePlusCircle size={22} style={{ marginRight: 5 }} />
                            Cadastrar
                        </Button>
                    </div>
                }
                {terapeutas && terapeutas.length > 0 ?
                    <div className="col-12 mb-2 mt-3">
                        {(loadingDadosTerapeutas === false && terapeutas && terapeutas?.length > 0)
                            ? <TableTerapeutas source={terapeutas} carregaTerapeuta={carrregaTerapeutas} tipo_usuario={user.tipo_user} permissoes={permissoes} createButton={
                                <FiltroTerapias
                                    resultRef={setTerapeutas}
                                    listaTerapeutas={terapeutas}
                                    listaOriginalTerapeutas={terapeutaOriginal}
                                    filtroNome={filterValue}
                                    sourceStatus={terapeutaOriginal}
                                    source={terapeutaOriginal}
                                    setTerapeutas={setTerapeutas}
                                    callBack={carrregaTerapeutas}
                                />
                            } />
                            : null
                        }
                    </div>
                    : null
                }
            </Form>
        </Container>
    );
    //#endregion
}
