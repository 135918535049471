import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../services/api';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { Container } from './styles';

const coresDiasSemana = [
    '#008369', // AZUL
    '#dc6f37', // LARANJA
    '#3a264d', // ROXO
    '#9a2322', // VERMELHO
    '#5c8634', // VERDE
    '#ab0d48', // ROSA
    '#a8a59c'  // CINZA
];

const alturaMenor = 60;
const largura = '15%';
const larguraSemanal = '14%';

const AgendaLista = styled.ul`
    font-family: 'Montserrat', sans-serif;

    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 6px;
    background-color: #F5F7FA;
    border: 1px solid #F5F7FA;
    margin-top: 10px;
    margin-bottom: 10px;

    .nome-paciente {
        width: 100%;
        padding: 10px;
        text-align: center;
        font-weight: bold;
        color: #c300d1;
        border-top-left-radius: 6px;
        border-top-right-radius
    }

    ${coresDiasSemana.map((cor, index) => `
        .dia-semana-${index} {
            background-color: ${cor} !important;
            color: #fff;
        }
    `).join('')}
`;

const AgendamentoLinha = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;

    .bordinha{
        height: 15px;
        background-color: #14617F;
        width: 50%;
        border: none;

        &-final{
            height: 15px;
            background-color: #14617F;
            width: 100%;
            border: none;
        }
    }

    li {
        border-bottom: 1px solid #14617F;
        background-color: #14617F;
    }
`;

const DiaSemanaLinha = styled.div`
    height: ${alturaMenor}px;
    width: 50%;
    background-color: #14617F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;

    border-top-right-radius: 20px;
    border: none;
`;


const EspacoHora = styled.div`
    width: ${largura};
    min-height: ${alturaMenor}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
`;

const ColunaAgendamentos = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    margin: 0px;
`;

const EspacoAgendamento = styled.div`
    font-size: 12px;
    color: #fff;
    padding: 0px;
    border-radius: 6px;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: zoom-in;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: 0.3s;

    &.borda-baixo {
        border-bottom: 1px solid #14617F;
    }

    &.vazio{
        background-color: #fff;
        margin-top: 0px;
        border: 1px solid #F5F7FA;

        .faixa-tipo{
            background-color: #fff;
        }
    }

    &.modo-dia {
        width: 100%;
        height: ${alturaMenor}px;
        min-height: ${alturaMenor}px;
        max-height: ${alturaMenor}px;
    }
    &.modo-semanal {
        min-width: ${larguraSemanal};
        max-width: ${larguraSemanal};
        width: ${larguraSemanal};
        height: ${alturaMenor}px;
        min-height: ${alturaMenor}px;
        max-height: ${alturaMenor}px;
    }
    
    .faixa-tipo{
        background-color: #35D058;
        height: 100%;
        width: 10px;
    }
    .coluna-dados{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 5px;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;

        font-weight: bold;
    }
    &.aguardando-terapeuta {
        .faixa-tipo{
            background-color: #2781DA;
        }
    }

    &:hover {
        background-color: #f0f0f0;
    }
`;

/**
 * Componente AgendaPacienteResponsavel
 * 
 * Este componente exibe a agenda de um paciente para o responsável.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {Object} ref - Referência do componente.
 * 
 * @returns {JSX.Element} O componente AgendaPacienteResponsavel.
 * 
 * @component
 * 
 * @example
 * return (
 *   <AgendaPacienteResponsavel />
 * )
 * 
 * @requires useAuth - Hook para obter informações do usuário autenticado.
 * @requires useParams - Hook para obter parâmetros da URL.
 * @requires useSnackbar - Hook para exibir mensagens de snackbar.
 * @requires useState - Hook para gerenciar o estado do componente.
 * @requires useEffect - Hook para efeitos colaterais no componente.
 * @requires api - Instância do axios para fazer requisições à API.
 * @requires moment - Biblioteca para manipulação de datas e horários.
 * @requires Container - Componente de layout.
 * @requires Link - Componente de navegação.
 * @requires DetalhesDoAgendamento - Componente para exibir detalhes do agendamento.
 * 
 * @function modalDetalhesDoAtendimento - Função para abrir o modal de detalhes do atendimento.
 * @function abreviarNome - Função para abreviar o nome completo.
 * @function carregaPaciente - Função para carregar os dados do paciente.
 * 
 * @typedef {Object} Paciente - Objeto que representa um paciente.
 * @property {number} id - ID do paciente.
 * @property {string} nome - Nome do paciente.
 * @property {string} foto_perfil - URL da foto de perfil do paciente.
 * @property {Date} nascimento - Data de nascimento do paciente.
 * @property {Object} diagnostico1 - Primeiro diagnóstico do paciente.
 * @property {Object} diagnostico2 - Segundo diagnóstico do paciente.
 * 
 * @typedef {Object} Agendamento - Objeto que representa um agendamento.
 * @property {number} id - ID do agendamento.
 * @property {number} dia_semana - Dia da semana do agendamento.
 * @property {string} hora_agendamento - Hora do agendamento.
 * @property {number} reserva - Indica se o agendamento é uma reserva.
 * @property {number} tipo_atendimento_id - Tipo de atendimento do agendamento.
 * @property {string} sala - Sala do atendimento.
 * @property {string} estacao - Estação do atendimento.
 * @property {string} terapeuta_nome - Nome do terapeuta responsável pelo atendimento.
 */
export default function NewAgendaPacienteResponsavel(props, ref) {
    const diaSemanaIndex = moment().weekday();
    const { id } = useParams();
    const snackbar = useSnackbar();
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [atendimentoId, setAtendimentoId] = useState();

    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'S',
            longLabel: 'Segunda-Feira',
        },
        {
            value: 2,
            label: 'T',
            longLabel: 'Terça-Feira',
        },
        {
            value: 3,
            label: 'Q',
            longLabel: 'Quarta-Feira',
        },
        {
            value: 4,
            label: 'Q',
            longLabel: 'Quinta-Feira',
        },
        {
            value: 5,
            label: 'S',
            longLabel: 'Sexta-Feira',
        },
        {
            value: 6,
            label: 'S',
            longLabel: 'Sábado',
        },
    ];

    //#region FUNCOES
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    //#endregion
    //#region CARREGADORES
    const carregaPaciente = () => {
        api.get(`/api/agenda/paciente/${id}`)
            .then((response) => {
                const { agendamentos, lista_horarios, paciente } = response.data;
                if (response.status === 200) {
                    setHorarios(lista_horarios);
                    setAgendamentos(agendamentos);
                }
                setPaciente(paciente);
            })
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao carregar paciente', 'error');
            });
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        carregaPaciente();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            {/* AGENDA */}
            <AgendaLista>
                <div className='nome-paciente'>
                    {paciente?.nome}
                </div>
                {diasDaSemanaArray
                    .filter((filter) => {
                        const temAtendimento = agendamentos.some(atendimento => Number(atendimento.dia_semana) === filter.value);
                        return temAtendimento;
                    })
                    .map((dia, diaIdx) => (
                        <AgendamentoLinha key={`dia-${diaIdx}`}>
                            <div className='d-flex w-100 align-items-end'>
                                <DiaSemanaLinha>
                                    {dia.longLabel}
                                </DiaSemanaLinha>
                                <span className='bordinha'></span>
                            </div>
                            {horarios.map((horario, horarioIdx) => {
                                const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm:ss');
                                const horasPrint = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                const agendamentosFiltrados = agendamentos.filter(atendimento =>
                                    Number(atendimento.dia_semana) === dia.value &&
                                    atendimento.hora_agendamento === horasExtenso &&
                                    Number(atendimento.reserva) === 0
                                );
                                if (agendamentosFiltrados.length === 0) return null;
                                return (
                                    <li className='d-flex' key={`linha-horario-${horarioIdx}`}>
                                        <EspacoHora className={`dia-semana-${diaIdx}`}>
                                            {horasPrint}
                                        </EspacoHora>
                                        <ColunaAgendamentos>
                                            {
                                                agendamentosFiltrados.map((atendimento, index) => (
                                                    <EspacoAgendamento
                                                        className={`dia-semana-${diaIdx} ${atendimento?.tipo_atendimento_id === 8 ? 'aguardando-terapeuta' : ''} modo-dia ${agendamentosFiltrados.length > 1 && index !== (agendamentosFiltrados.length - 1) ? 'borda-baixo' : ''}`}
                                                        onClick={() => modalDetalhesDoAtendimento(atendimento)}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}>
                                                        <div className='faixa-tipo'></div>
                                                        <div className='coluna-dados'>
                                                            <span> Sala: {atendimento?.sala} / Estação: {atendimento?.estacao}</span>
                                                            <span className='ns-abrevia-texto'> {atendimento?.terapeuta_nome} </span>
                                                            <span className='ns-abrevia-texto'> {atendimento?.terapia} - {atendimento?.especialidade} </span>
                                                        </div>
                                                    </EspacoAgendamento>
                                                ))
                                            }
                                        </ColunaAgendamentos>
                                    </li>
                                );
                            })}
                            <span className='bordinha-final'></span>
                        </AgendamentoLinha>
                    ))}
            </AgendaLista>
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento
                    agendamentoId={atendimentoId}
                    onClose={() => { setIsModalDetalhesDoAtendimentoVisible(false); carregaPaciente(); }}
                /> : (<></>)}
        </Container>
    );
    //#endregion
}