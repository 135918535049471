import { Form } from '@unform/web';
import { TutorialBadge } from 'components/AjudanteInicial';
import BloqueiaArea from 'components/BloqueiaArea';
import Button from 'components/Button';
import DateRangeSelector from 'components/DateRange';
import Input from 'components/Input';
import Select from 'components/Select';
import { useAuth } from 'context/auth';
import useGuiaData from 'context/guia';
import useIcons from 'context/icons';
import useListas from 'context/listas-de-selecao';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import { useSnackbar } from 'context/snackbar';
import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { FaBroom } from 'react-icons/fa';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import styled from 'styled-components';
import { Container } from 'styles/global-style';
import ModalGuias from '../Pacientes/Perfil/ModalGuias';
import { TableGuias } from './TableGuias';

const SwtchContainer = styled.div`
    padding: 4px 15px 4px 15px;
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-wrap: nowrap;
    font-size: 16px;
    font-weight: 500;
    color: black;
    .form-switch{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export default function Guias() {
    usePageBase({
        title: 'Guias',
        description: 'Aqui você pode visualizar todas as guias do sistema.',
        routes: ['/dashboard/{tipo_user}/guiasavencer/'],
        hasSubNavbar: false,
        hasTitle: true,
        conectaComSocket: false,
        socketLink: '',
        socketResponse: () => { },
    })
    //#region VARIÁVEIS
    // Hooks
    const { listas, fetchListas } = useListas([
        'especialidade',
        'terapia',
        'plano_saude',
    ]);
    const snackbar = useSnackbar();
    const { iconArray } = useIcons();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const user = useAuth();
    const { guias, fetchGuiaData, guiasOriginal, setGuias, setGuiasOriginal, loaded, setLoaded } = useGuiaData();
    //Permissoes
    const [permissoes, setPermissoes] = useState();
    //Url
    const url_atual = useLocation();
    const [searchParams] = useSearchParams();
    //Parâmetros
    const [loading, setloading] = useState(false);
    const [loadingListas, setloadingListas] = useState(false);
    //Filtro
    ///Listas
    const [terapiaOptions, setTerapiaOptions] = useState([]);
    const [especialidadeOptions, setEspecialidadeOptions] = useState([]);
    const [planoSaudeOptions, setPlanoSaudeOptions] = useState([]);
    ///Estados
    ///Selecionados
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState([]);
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState([]);
    const [terapiaSelecionadaOpt, setTerapiaSelecionadaOpt] = useState([]);
    const [especialidadeSelecionadaOpt, setEspecialidadeSelecionadaOpt] = useState([]);
    const [filtrando, setFiltrando] = useState(false);
    const defaultStatusGuiaFilter = [
        { checked: true, status: 'Ativo' },
        { checked: true, status: 'Espera' },
        { checked: true, status: 'Vencido' },
        { checked: true, status: 'Zerado' },
        { checked: true, status: 'Bloqueado' },
    ];
    const [selecionaTodosStatus, setSelecionaTodosStatus] = useState(true);
    const [statusGuia, setStatusGuia] = useState(defaultStatusGuiaFilter);
    const [expandFilter, setExpandFilter] = useState(false);
    const [filtroWidth, setFiltroWidth] = useState();
    const [pendenteSolicitacao, setPendenteSolicitacao] = useState(false);
    const [dataInicio, setDataInicio] = useState('');
    const [dataValidade, setDataValidade] = useState('');
    const [dataSolicitacao, setDataSolicitacao] = useState('');
    const [dataAceitacao, setDataAceitacao] = useState('');
    const [quantidadeRestante, setQuantidadeRestante] = useState('');
    //Refs
    const filtroDivRef = useRef();
    const modalTerapiaRef = useRef(null);
    const modalGuiaRef = useRef(null);
    //#endregion
    //#region FUNCOES*/
    const coletaPermissoes = () => {
        let permissoes = user.user.permissoes_por_grupo[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = 'guias';
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    };
    const expandFiltro = (e) => {
        if (expandFilter) {
            fechaFiltro();
        } else {
            abreFiltro();
        }
    };
    const abreFiltro = () => {
        setExpandFilter(true);
    };
    const fechaFiltro = () => {
        setExpandFilter(false);
    };
    //#endregion
    /**
     * Função que limpa os filtros
     * @return {void}
     */
    function handleLimpaFiltros() {
        setDataInicio(null);
        setDataValidade(null);
        setGuias(guiasOriginal);
        setStatusGuia(defaultStatusGuiaFilter);
        setSelecionaTodosStatus(true);
        setDataInicio(null);
        setDataValidade(null);
        setQuantidadeRestante('');
        setDataSolicitacao(null);
        setDataAceitacao(null);
        setPendenteSolicitacao(false);
    }
    function handleLimpaParametros() {
        setLoaded(false);
        setGuias([]);
        setGuiasOriginal([]);
        setStatusGuia(defaultStatusGuiaFilter);
        setSelecionaTodosStatus(true);
        setPacienteSelecionadoOpt([]);
        setPlanoSaudeSelecionadoOpt([]);
        setTerapiaSelecionadaOpt([]);
        setEspecialidadeSelecionadaOpt([]);
    }
    const handleChangeStatusGuia = (e) => {
        const { id, checked } = e.target;
        setStatusGuia((prev) => {
            let newPrev = [...prev];
            newPrev.map(find => {
                if (find.status === id) {
                    find.checked = checked;
                }
                return find;
            });
            return newPrev;
        });
    };
    const handleChangeSelecionaTodos = (e) => {
        const { checked } = e.target;
        setSelecionaTodosStatus(checked);
        let newStatusGuia = [...statusGuia];
        newStatusGuia = newStatusGuia.map(find => {
            find.checked = checked;
            return find;
        });
        setStatusGuia(newStatusGuia);
    };
    /**
     * Função que filtra baseado nos dados definidos pelos inputs, salvos no state.
     * @return {void}
     */
    async function filtrar() {
        setFiltrando(true);
        let lista = guiasOriginal;
        lista = lista.filter((item) => {
            let terapiaTeste = true;
            const passaStatus = statusGuia.filter(filter => filter.status === item.status && filter.checked).length > 0;
            const passaDataInicio = dataInicio ? new Date(item.inicio) >= new Date(dataInicio) : true;
            const passaDataValidade = dataValidade ? new Date(item.validade) <= new Date(dataValidade) : true;
            const passaQuantidadeRestante = quantidadeRestante ? Number(item.Saldo) <= Number(quantidadeRestante) : true;
            const passaDataSolicitacao = dataSolicitacao ? new Date(item.dataSolicitacao) >= new Date(dataSolicitacao) : true;
            const passaDataAceitacao = dataAceitacao ? new Date(item.dataAceitacao) <= new Date(dataAceitacao) : true;
            const passaApenasPendentes = pendenteSolicitacao ? item.pendente_solicitacao === 1 : true;
            const result = (passaStatus && terapiaTeste && passaDataInicio && passaDataValidade && passaQuantidadeRestante && passaDataSolicitacao && passaDataAceitacao && passaApenasPendentes);
            return result;
        });
        setFiltrando(false);
        setGuias(lista);
    }
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpandFilter(false);
        }
    };
    //#endregion
    //#region HANDLES
    const handlePesquisar = (paciente, plano, terapia, especialidade) => {
        console.log('paciente', paciente);
        carregaGuias(paciente, plano, terapia, especialidade);
    }
    /* Função que fecha a modal de guia. Chamada de dentro da modal Guias. */
    const handleFechaModalGuia = () => {
        setLoaded(false);
        carregaGuias();
    };
    /* Função que abre o modal de guia */
    const handleOpenGuia = useCallback((edit, dados) => {
        modalGuiaRef.current.openModal(dados, edit);
    }, [modalGuiaRef]);
    const handleClickAdicionaGuia = useCallback(() => {
        if (modalGuiaRef.current) {
            modalGuiaRef.current.openModal(null);
        }
    }, [modalGuiaRef]);
    //#endregion
    //#region GETTERS
    const carregaGuias = useCallback(async (paciente, plano, terapia, especialidade) => {
        console.log('pacienteSelecionadoOpt', pacienteSelecionadoOpt);
        const pacienteOpt = paciente === undefined ? pacienteSelecionadoOpt : paciente;
        const planoOpt = plano === undefined ? planoSaudeSelecionadoOpt : plano;
        const terapiaOpt = terapia === undefined ? terapiaSelecionadaOpt : terapia;
        const especialidadeOpt = especialidade === undefined ? especialidadeSelecionadaOpt : especialidade;

        console.log('pacienteOpt', pacienteOpt);

        if (!pacienteOpt.value && !planoOpt.value && !terapiaOpt.value && !especialidadeOpt.value) {
            snackbar.displayMessage('Por favor, selecione pelo menos um filtro.', 'warning');
            return;
        }
        setloading(true);
        const filter = [
            pacienteOpt.value ? `paciente_id=${pacienteOpt.value}` : '',
            planoOpt.value ? `plano_saude_id=${planoOpt.value}` : '',
            terapiaOpt.value ? `terapia_id=${terapiaOpt.value}` : '',
            especialidadeOpt.value ? `especialidade_id=${especialidadeOpt.value}` : ''
        ].filter(Boolean).join('&');
        await fetchGuiaData(
            'paciente,terapia,especialidade,plano_saude',
            'guia.*,pendente_solicitacao,paciente.nome,terapia.terapia,especialidade.especialidade,terapia_paciente.plano_saude.nome_reduzido',
            filter
        );
        setloading(false);
    }, [fetchGuiaData, pacienteSelecionadoOpt, planoSaudeSelecionadoOpt, terapiaSelecionadaOpt, especialidadeSelecionadaOpt, snackbar]);

    const carregaGuiasResponsavel = () => {
        api.get(`api/pacientes?with=guia.terapium,guia.especialidade,guia.plano_saude&filter[responsavels.id]=${user.user.responsavels[0].id}`).then(({ data }) => {
            let guiasList = [];
            data.forEach((paciente) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                if (paciente.status !== 'Inativo') {
                    if (paciente.terapia_pacientes.length > 0) {
                        paciente.terapia_pacientes.forEach((terapia) => guiasList.push({ ...terapia, paciente: paciente.nome, }));
                    };
                }
            });
            setGuias(guiasList);
            snackbar.displayMessage('Guias do responsável carregadas com sucesso!', 'success');
        }).catch((error) => {
            snackbar.displayMessage('Erro ao carregar guias do responsável.', 'error');
        });
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {/* ON LOAD */
        setloadingListas(true);
        fetchListas();
        coletaPermissoes();
        fetchPacienteOptionsData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
        setloadingListas(false);
        document.addEventListener('mousedown', handleClickOutside);
        if (url_atual.pathname.split('/')[2] === 'responsavel') {
            carregaGuiasResponsavel();
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {/* ON LISTAS LOAD */
        if (terapiaOptions?.length > 0 && especialidadeOptions?.length > 0 && planoSaudeOptions?.length > 0 && pacienteOptions?.length > 0) {
            const pacienteId = searchParams.get('paciente_id');
            const planoSaudeId = searchParams.get('plano_saude_id');
            const terapiaId = searchParams.get('terapia_id');
            const especialidadeId = searchParams.get('especialidade_id');

            if (pacienteId || planoSaudeId || terapiaId || especialidadeId) {
                let pacienteSelecionadoOpt = [];
                let planoSaudeSelecionadoOpt = [];
                let terapiaSelecionadaOpt = [];
                let especialidadeSelecionadaOpt = [];
                if (pacienteId) {
                    pacienteSelecionadoOpt = pacienteOptions.find(opt => Number(opt.value) === Number(pacienteId)) || [];
                    setPacienteSelecionadoOpt(pacienteSelecionadoOpt);
                }
                if (planoSaudeId) {
                    planoSaudeSelecionadoOpt = planoSaudeOptions.find(opt => Number(opt.value) === Number(planoSaudeId)) || [];
                    setPlanoSaudeSelecionadoOpt(planoSaudeSelecionadoOpt);
                }
                if (terapiaId) {
                    terapiaSelecionadaOpt = terapiaOptions.find(opt => Number(opt.value) === Number(terapiaId)) || [];
                    setTerapiaSelecionadaOpt(terapiaSelecionadaOpt);
                }
                if (especialidadeId) {
                    especialidadeSelecionadaOpt = especialidadeOptions.find(opt => Number(opt.value) === Number(especialidadeId)) || [];
                    setEspecialidadeSelecionadaOpt(especialidadeSelecionadaOpt);
                }
                handlePesquisar(pacienteSelecionadoOpt, planoSaudeSelecionadoOpt, terapiaSelecionadaOpt, especialidadeSelecionadaOpt);
            }
        }
    }, [terapiaOptions, especialidadeOptions, planoSaudeOptions, pacienteOptions]);
    useEffect(() => {// Listas Carregadas
        if (listas) {
            const { terapia, especialidade, plano_saude } = listas;
            setTerapiaOptions(terapia);
            setEspecialidadeOptions(especialidade);
            setPlanoSaudeOptions(plano_saude);
        }
    }, [listas]);
    useEffect(() => {
        if (guiasOriginal.length > 0) {
            filtrar();
        }
    }, [statusGuia, quantidadeRestante, dataInicio, dataValidade, dataSolicitacao, dataAceitacao, pendenteSolicitacao]);
    useEffect(() => {
        if (filtroDivRef.current) {
            setFiltroWidth(filtroDivRef.current.clientWidth - 10);
        }
    }, [filtroDivRef]);
    //#endregion
    //#region HTML
    return (
        <div>
            <Container>
                <Form className="row mb-4">
                    <div className='col-8'>
                        <div className='row'>
                            <div className='col-6'>
                                <Select
                                    id={'paciente_selecionado'}
                                    name={'paciente_selecionado'}
                                    label='Paciente'
                                    placeholder='Selecione o paciente..'
                                    onChange={setPacienteSelecionadoOpt}
                                    value={pacienteSelecionadoOpt}
                                    options={pacienteOptions}
                                    isDisabled={loading || guiasOriginal.length !== 0}
                                    required={true}
                                />
                            </div>
                            <div className='col-6'>
                                <Select
                                    id={'plano_saude_selecionada'}
                                    name={'plano_saude_selecionada'}
                                    label='Plano de Saúde'
                                    placeholder='Selecione o plano de saúde..'
                                    onChange={setPlanoSaudeSelecionadoOpt}
                                    value={planoSaudeSelecionadoOpt}
                                    options={planoSaudeOptions}
                                    isDisabled={loading || guiasOriginal.length !== 0}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <Select
                                    id={'terapia_selecionada'}
                                    name={'terapia_selecionada'}
                                    label='Terapia'
                                    placeholder='Selecione a terapia..'
                                    onChange={setTerapiaSelecionadaOpt}
                                    value={terapiaSelecionadaOpt}
                                    options={terapiaOptions}
                                    isDisabled={loading || guiasOriginal.length !== 0}
                                />
                            </div>
                            <div className='col-6'>
                                <Select
                                    id={'especialidade_selecionada'}
                                    name={'especialidade_selecionada'}
                                    label='Especialidade'
                                    placeholder='Selecione a especialidade..'
                                    onChange={setEspecialidadeSelecionadaOpt}
                                    value={especialidadeSelecionadaOpt}
                                    options={especialidadeOptions}
                                    isDisabled={loading || guiasOriginal.length !== 0}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row col-4'>
                        <div className='col-12 d-flex align-items-end'>
                            <Button type={'button'} className="h-38px w-fit-content-i" onClick={() => handleLimpaParametros()}>
                                {cloneElement(iconArray['limpar'], { size: 24 })}
                            </Button>
                            <Button type={'button'} className="h-38px" isDisabled={loading || guiasOriginal.length !== 0} onClick={() => handlePesquisar()}>
                                {loading
                                    ? <ClipLoader size={20} />
                                    : <> {cloneElement(iconArray['pesquisar'], { size: 24 })} Pesquisar </>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>
            <BloqueiaArea bloqueado={!loaded}>
                <div className='w-100 d-flex mb-4 align-center flex-wrap'>
                    <div className="col-8">
                        <div ref={filtroDivRef} id="div-filtro-guias" className='div-filter-style justify-content-start align-center w-100 h-38px py-0'>
                            <div id="div-expand-filter" className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }}
                                onClick={({ target }) => {
                                    if (target === document.getElementById('div-expand-filter')) {
                                        expandFiltro();
                                    }
                                }}>
                                <div id="blank" style={{ height: 'fit-content' }} onClick={(e) => expandFiltro()}>
                                    {!expandFilter
                                        ? <span>
                                            <span className='me-2 font-18px'>
                                                {iconArray['seta-baixo']}
                                            </span>
                                            Mais
                                        </span>
                                        : <span>
                                            <span className='me-2'>
                                                {iconArray['seta-cima']}
                                            </span>
                                            Menos
                                        </span>
                                    } Filtros
                                </div>
                                <div className='flex-nowrap'>
                                    {(
                                        defaultStatusGuiaFilter.length !== statusGuia.filter(filter => filter.checked === true).length ||
                                        dataInicio ||
                                        dataValidade ||
                                        quantidadeRestante ||
                                        dataSolicitacao ||
                                        dataAceitacao ||
                                        pendenteSolicitacao === true
                                    )
                                        ? <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros(); }} title='Conteúdo filtrado...' />
                                        : <div width='22' height='22'></div>
                                    }
                                    <AiFillCaretDown onClick={(e) => expandFiltro()} className='pointer' color="white" size={22} style={{ marginLeft: '15px' }} />
                                </div>
                            </div>
                            <div className='div-expand-over row' style={{ marginLeft: '-16px', marginTop: '-2px', borderRadiusTopRight: '0', width: filtroWidth, ...expandFilter ? {} : { display: 'none' } }}>
                                <Form className="col-lg-8 row">
                                    <div className="col-lg-6">
                                        <label className="font-16px mb-1 w-100 unselectable">Data de Solicitação</label>
                                        <Input
                                            id="data-solicitacao"
                                            name="data-solicitacao"
                                            type="date"
                                            value={dataSolicitacao}
                                            onChange={(e) => setDataSolicitacao(e.target.value)}
                                            placeholder="Selecione a data de solicitação"
                                            hasClearButton={true}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="font-16px mb-1 w-100 unselectable">Data de Aceitação</label>
                                        <Input
                                            id="data-aceitacao"
                                            name="data-aceitacao"
                                            type="date"
                                            value={dataAceitacao}
                                            onChange={(e) => setDataAceitacao(e.target.value)}
                                            placeholder="Selecione a data de aceitação"
                                            hasClearButton={true}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="font-16px mb-1 w-100 unselectable">Quantidade Restante</label>
                                        <Input
                                            id="quantidade-restante"
                                            name="quantidade-restante"
                                            type="number"
                                            value={quantidadeRestante}
                                            onChange={(e) => setQuantidadeRestante(e.target.value)}
                                            placeholder="Digite a quantidade restante"
                                            hasClearButton={true}
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <label className="font-16px mb-1 w-100 unselectable">Selecione o Período de Vigência</label>
                                        <DateRangeSelector
                                            onChange={({ startDate, endDate }) => {
                                                setDataInicio(startDate);
                                                setDataValidade(endDate);
                                            }}
                                        />
                                    </div>
                                </Form>
                                <div className="col-lg-4 row">
                                    <fieldset className="ns-fieldset col-6">
                                        <legend>
                                            Status da Guia
                                            <div className="form-switch">
                                                <label htmlFor={'seleciona_todos'} className="form-check-label"> </label>
                                                <input className="form-check-input switch-conflito ms-2" type="checkbox" role="switch" id={'seleciona_todos'} name="seleciona_todos" checked={selecionaTodosStatus} onChange={(e) => handleChangeSelecionaTodos(e)} />
                                            </div>
                                        </legend>
                                        <div className="col-12 d-flex flex-column px-2">
                                            {defaultStatusGuiaFilter.map(({ status }) =>
                                                <SwtchContainer key={status}>
                                                    <div className='form-check-reverse form-switch'>
                                                        <label className="form-check-label" htmlFor={status}>
                                                            {status}
                                                        </label>
                                                        <input className="form-check-input switch-conflito ms-2" type="checkbox" role="switch" id={status} name="status_guia" checked={statusGuia.find(find => find.status === status).checked} onChange={(e) => handleChangeStatusGuia(e)} />
                                                    </div>
                                                </SwtchContainer>
                                            )}
                                        </div>
                                    </fieldset>
                                    <div className='col-6 d-flex'>
                                        <div className="form-switch">
                                            <label htmlFor={'pendente_solicitacao'} className="form-check-label">Apenas Pendentes</label>
                                            <input className="form-check-input switch-conflito ms-2" type="checkbox" role="switch" id={'pendente_solicitacao'} name="pendente_solicitacao" checked={pendenteSolicitacao} onChange={(e) => { setPendenteSolicitacao(e.target.checked); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-end'>
                        {(permissoes && permissoes?.criação) &&
                            <Button className='btn-padrao mt-0 w-300px-i h-38px' id="botao-adiciona-guia" type="button" onClick={handleClickAdicionaGuia}>
                                <span className="me-2 d-flex flex-row">
                                    {iconArray['adicionar']} {iconArray['guias']}
                                </span> Adicionar Nova Guia
                            </Button>
                        }
                    </div>
                    <div className='col-12 mt-2 d-flex align-items-center'>
                        {filtrando ? 'Filtrando' : ''}
                        <TableGuias
                            source={guias}
                            callBack={handleFechaModalGuia}
                            permissoes={permissoes}
                            modalTerapiaRef={modalTerapiaRef}
                            handleOpen={handleOpenGuia}
                            loading={loading}
                        />
                    </div>
                </div>
            </BloqueiaArea>
            <TutorialBadge
                steps={
                    [
                        {
                            title: 'Bem Vindo',
                            text: <div className="d-flex flex-col">
                                <span className="font-12px font-cinza">(Aperte em próximo para prosseguir ou no X para sair)</span>
                                <span>Bem vindo! Essa é a tela de Guias do Paciente!</span>
                            </div>,
                            width: '400px'
                        },
                        { title: 'Filtro', selector: '#div-filtro-guias', text: 'Esse é o filtro. Nele você pode usar vários parâmentros, para encontrar o que você precisa.' },
                        { title: 'Nova Guia', selector: '#botao-adiciona-guia', text: 'Esse botão, você utiliza para adicionar uma nova guia.' },
                        { title: 'Tabela de Guias', selector: '#tabela-guias', text: 'Você você pode visualziar todas as guias do sistema. Se não estiver encontrar o que precisa, lembre-se de usar os filtros, e verificar todas as páginas da tabela.' },
                        { title: 'Ações', selector: '#visualiza-guia-0', text: 'Visualizar: Botão utilizado para visualizar todos os dados da guia.' },
                        { title: 'Editar Guia', selector: '#edita-guia-0', text: 'Editar: Aqui é importante atentar, que você apenas pode editar completamente a guia, no dia da inclusão, em qualquer outra situação, só é permitido editar a quantidade.' },
                        { title: 'Trava Guia', selector: '#trava-guia-0', text: 'Bloquear: Aqui você pode bloquear a guia, de forma que ela não pode ser mais usada, mesmo válida.', jumpOffSelector: true },
                        { title: 'Colunas', selector: '#guias-thead', text: 'As outra colunas, também tem informações importantes, tais quais:' },
                        { title: 'Status', selector: '#guias-col-0', text: 'Essa coluna, diz o status da guia, que pode ser Vencido (Já passou da data.), Bloqueado (Ativamente bloqueado por um usuário), Zerado (Não tem mais quantidades na guia), Espera (Guia ainda fora do periodo de vigência) e Ativo' },
                        {
                            title: 'Dados da Guia',
                            selector: [
                                '#guias-col-1',
                                '#guias-col-2',
                                '#guias-col-3',
                                '#guias-col-4',
                                '#guias-col-5'
                            ],
                            text: 'Dados da Guia: Nesse trecho você pode ver do que se trata a guia.'
                        },
                        { title: 'Quantidade', selector: '#guias-col-6', text: 'Aqui você ve a quantidade: Quanto tem, total e a porcentagem de uso da guia.' },
                        { title: 'Dias a Vencer', selector: '#guias-col-7', text: 'Aqui é em quantos dias que a guia vence.' },
                        { title: 'Ordenar Tabela', selector: '#guias-thead', text: 'Fora isso é importante dizer, que em todas as colunas você pode apertar no nome dela, para ordenar em ordem crescente e decrescente.' },
                        { title: 'Opções da Tabela', selector: '#guias-tfoot', text: 'Em baixo, você pode visualizar os controladores da tabela. Neles você vai encontrar:' },
                        { title: 'Exportar para Excel', selector: '#guias-tfoot-export', text: 'Esse botão exporta todos os dados da tabela para excel. Importante lembrar, que serão os dados filtrados, então se você aplicar um filtro na Tabela, o Excel virá com os dados filtrados.' },
                        {
                            title: 'Navegação',
                            selector: [
                                '#guias-tfoot-fast-forward',
                                '#guias-tfoot-forward',
                                '#guias-tfoot-back',
                                '#guias-tfoot-fast-back'
                            ],
                            text: 'Navega entre as páginas.'
                        },
                        { title: 'Informações de Página', selector: '#guias-tfoot-page-details', text: 'Aqui são as informações das páginas da tabela, quantas tem e um campo onde você ir diretamente à uma pagina.' },
                        { title: 'Informações de Linha', selector: '#guias-tfoot-line-details', text: 'Nesse espaço, tem todas as informações de linha, quantidade total de linhas, e quantidade de linhas por página.' },
                        { title: 'Finalização', text: 'Basicamente é isso, não esqueça que você pode sempre apertar novamente, e visualizar a etapa que te interessa.' },
                    ]
                }
                hasTopicos={true}
            />
            <ModalGuias ref={modalGuiaRef} listaGuias={guias} callBack={handleFechaModalGuia} pacienteOptions={pacienteOptions} pacienteSelecionadoOpt={pacienteSelecionadoOpt} />
        </div>
    );
    //#endregion
}