import React, { useMemo, useState } from 'react';
import { Container, Tab, List, ListItem, ListTitle } from './estilos';
import useIcons from 'context/icons';

const ListaPresenca = ({
    titulo = 'Lista de Presença',
    lista,
    colunas = [
        {
            title: 'Nome',
            dataIndex: 'username',
            key: 'username',
            width: '80%',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Paciente',
            dataIndex: 'identificador',
            key: 'id',
            width: '20%',
            render: (text) => <span>{text ? text : '-'}</span>
        }
    ]
}) => {
    const { iconArray } = useIcons();
    const [visible, setVisible] = useState(false);
    const listaDePresenca = useMemo(() => {
        return lista;
    }, [lista]);

    const toggleVisibility = () => {
        setVisible(!visible);
    };

    return (
        <Container visible={visible} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
            <Tab className={`bg-roxo-dark${visible ? ' visible' : ''}`}>
                <span>{iconArray['seta-angulo-esquerda']}</span>
            </Tab>
            <List className='bg-roxo border-color-roxo-dark'>
                <ListTitle>{titulo}</ListTitle>
                {listaDePresenca && listaDePresenca.length > 0
                    ? listaDePresenca.map((item) => {
                        return (
                            <ListItem key={item.id}>
                                {colunas.map((coluna) => {
                                    const data = item[coluna.dataIndex] ? item[coluna.dataIndex] : '-';
                                    return (
                                        <div key={coluna.key} style={{ width: coluna.width }}>
                                            {coluna.render(data)}
                                        </div>
                                    );
                                })}
                            </ListItem>
                        );
                    })
                    : <ListItem>Nenhuma precença ainda 🤷‍♀️</ListItem>
                }
            </List>
        </Container>
    );
};

export default ListaPresenca;
