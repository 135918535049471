import React from 'react';
import { Pie } from 'react-chartjs-2';

const Torta = ({
    width = '600px',
    height = '600px',
    titulo = '',
    tituloFatia = 'Votos',
    borderWidth,
    dados = [
        {
            label: 'Red',
            value: 12,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
        },
        {
            label: 'Blue',
            value: 19,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
        },
        {
            label: 'Yellow',
            value: 3,
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
        },
        {
            label: 'Green',
            value: 5,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
        },
        {
            label: 'Purple',
            value: 2,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
        },
        {
            label: 'Orange',
            value: 3,
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
        },
    ], rest }) => {
    const data = {
        title: titulo,
        labels: dados.map(info => info.label),
        datasets: [
            {
                label: tituloFatia,
                data: dados.map(info => info.value),
                backgroundColor: dados.map(info => info.backgroundColor),
                borderColor: dados.map(info => info.borderColor),
                borderWidth: borderWidth,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        width: width,
        height: height,
        plugins: {
            legend: {
                position: 'right',
                title: {
                    display: true,
                    text: tituloFatia,
                    font: {
                        size: 20,
                        family: 'Arial',
                        weight: 'bold',
                    },
                },
                labels: {
                    sort: (a, b) => a.text.localeCompare(b.text),
                    font: {
                        size: 18,
                        family: 'Arial',
                    },
                    maxWidth: 200,
                    boxWidth: 20,
                    padding: 10,
                    usePointStyle: true,
                },
            },
        },
    };

    return (
        <div className='d-flex flex-col' style={{ width, height }} {...rest}>
            {titulo && <span>{titulo}</span>}
            <Pie className='mx-auto' data={data} options={options} />
        </div>
    );
};

export default Torta;