import api from './api';
const debug_mode = false;
const debug = debug_mode;
class TerapeutaService {
    static index = () => {
        return api.get('terapeuta');
    };
    static store = (obj) => {
        /* exemplo
        {"terapeuta_id":1,"especialidade_id":1,"data":"2013-08-22","arquivo":"perspiciatis"}
        */
        return api.post('terapeuta', obj);
    };
    static show = (id) => {
        return api.get(`terapeuta/${id}`);
    };
    static update = (obj, id) => {
        const form = new FormData();
        Object.keys(obj).forEach((key) => {
            if (key === 'foto_perfil') {
                let name = obj[key].name;
                form.append(key, obj[key], name);
            } else {
                form.append(key, obj[key]);
            }
        });
        return api.put(`api/terapeuta/${id}`, obj);
    };
    static destroy = (id) => {
        return api.delete(`terapeuta/${id}`);
    };
    static getTerapeutasDisponiveisByFiltroEspecialidadeTerapia = async (especialidadeId, terapiaId, horario, dia, setTerapeutas) => {
        const params = new URLSearchParams({
            'dia': dia,
            'hora': horario,
            'with': 'terapeuta_especialidades,terapeuta_terapia',
            'filter[terapeuta_especialidades.especialidade_id]': especialidadeId,
            'filter[terapeuta_terapia.terapia_id]': terapiaId
        });
        const url = `/api/terapeuta/disponivel/${dia}/${horario}?${params.toString()}`;
        
        const response = await api.get(url);
        const terapeutas = response.data.map(d => ({
            'value': d.id,
            'label': d.nome,
        }));
        setTerapeutas(terapeutas);
    };
    /**
     * Função que retorna as classes de cor e o título baseado no status do terapeuta.
     * @param {string} status Status do terapeuta (Ativo, Bloqueado, etc.)
     * @returns {Object} Um objeto com as classes e o título correspondentes ao status.
     */
    static getStatusAttributes = (status) => {
        let attributes = {
            className: '',
            title: ''
        };

        switch (status) {
            case 'Ativo':
                attributes.className = 'font-verde';
                attributes.title = 'Terapeuta sem nenhuma pendência.';
                break;
            case 'Bloqueado':
                attributes.className = 'font-vermelho';
                attributes.title = 'Terapeuta com bloqueios em seu acesso, geralmente relacionado às evoluções.';
                break;
            default:
                attributes.className = 'font-amarelo';
                attributes.title = 'Terapeuta com acesso liberado, mas ainda com pendências.';
                break;
        }

        return attributes;
    };

}

export default TerapeutaService;
