//#region IMPROTAÇÕES
import { Form } from '@unform/web';
import { TutorialBadge } from 'components/AjudanteInicial';
import BalaoDica from 'components/Baloes/BalaoDica';
import ModalBase from 'components/ModalBase';
import WebSocketProvider from 'components/Websocket/websocket.provider.old';
import useIcons from 'context/icons';
import useListas from 'context/listas-de-selecao';
import { usePageBase } from 'context/page-base';
import { Dashboard } from 'helpers/dashboard.helper';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';
import AcessoRapido from '../../components/AcessoRapido';
import { MostradoresColoridos } from '../../components/MostradoresColoridos';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import RecadosCard from '../DashboardPadrao/RecadosCard';
import { Container, ContainerSub, GridHeader, GridRecados, Header } from './styles';
import { BsChatFill } from 'react-icons/bs';
import ListaPresenca from 'components/ListaPresenca';
import { MultiSelectSwitch } from 'components/Select/MultiSelectSwitch';
//#endregion

//#region ESTILOS
const ChatContainer = styled.div`
    // Tamanho
    width: 500px;
    height: 300px;
    // Posicionamento
    position: fixed;
    bottom: 50px;
    left: 10px;
    z-index: 5;
    // Estilo
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    // Flex
    display: flex;
    flex-direction: column;
    // Scroll
    overflow: hidden;
    // Transição
    transition: all 0.3s ease-in-out;
    transform: ${({ minimized }) => (minimized ? 'scale(0.1)' : 'scale(1)')};
    opacity: ${({ minimized }) => (minimized ? '0' : '1')};
    // Pointer events
    pointer-events: ${({ minimized }) => (minimized ? 'none' : 'auto')};
    // Espaçamento
    padding: 0;

    .barra {
        // Estilo
        background-color: #007bff;
        color: #fff;
        // Espaçamento
        padding: 5px;
        // Tipografia
        font-size: 16px;
        font-weight: bold;
        // Flex
        display: flex;
        justify-content: space-between;
        align-items: center;
        // Tamanho
        width: 100%;
    }

    .messages {
        // Flex
        flex: 1;
        // Scroll
        overflow-y: auto;
        // Espaçamento
        padding: 10px;
        // Estilo
        background-color: #f9f9f9;

        .message-group {
            // Espaçamento
            padding: 0px;
            margin: 5px 0;
            // Estilo
            background-color: #e1f5fe;
            border-radius: 4px;
            // Flex
            display: flex;
            flex-direction: column;
            // Estilo
            border: 1px solid #ddd;
            border-radius: 4px;

            .username {
                // Tamanho
                width: 100%;
                // Tipografia
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                // Espaçamento
                margin: 0px;
                padding: 5px;
                // Estilo
                border-bottom: 1px solid #ddd;
                border-radius: 4px 4px 0 0;
            }

            .message {
                // Flex
                display: flex;
                // Tamanho
                width: 100%;
                // Espaçamento
                padding: 10px;
            }

            &.greetings {
                // Estilo
                background-color: #d1c4e9;
                // Tipografia
                text-align: center;
            }

            &.message-left {
                .username {
                    // Tipografia
                    text-align: left;
                    // Estilo
                    background-color: rgb(156, 224, 255);
                }
                .message {
                    // Estilo
                    background-color: #e1f5fe;
                    // Flex
                    justify-content: flex-start;
                }
            }

            &.message-right {
                .username {
                    // Tipografia
                    text-align: right;
                    // Estilo
                    background-color: rgb(136, 226, 139);
                }
                .message {
                    // Estilo
                    background-color: #c8e6c9;
                    // Flex
                    justify-content: flex-end;
                }
            }
        }
    }

    .message-input {
        // Flex
        display: flex;
        // Espaçamento
        padding: 10px;
        // Estilo
        background-color: #f1f1f1;
        border-top: 1px solid #ddd;

        input {
            // Flex
            flex: 1;
            // Espaçamento
            padding: 10px;
            margin-right: 10px;
            // Estilo
            border: 1px solid #ddd;
            border-radius: 4px;
            outline: none;
            // Transição
            transition: border-color 0.3s;

            &:focus {
                // Estilo
                border-color: #007bff;
            }
        }

        button {
            // Espaçamento
            padding: 10px 15px;
            // Estilo
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            // Transição
            transition: background-color 0.3s;

            &:hover {
                // Estilo
                background-color: #0056b3;
            }
        }
    }
`;

const MinimizeButton = styled.button`
    // Estilo
    border: none;
    border-radius: 50%;
    background: none;
    // Tamanho
    width: 30px;
    height: 30px;
    // Flex
    display: flex;
    align-items: center;
    justify-content: center;
    // Tipografia
    font-size: 20px;
    color: #fff;
    // Cursor
    cursor: pointer;

    &:hover {
        // Tipografia
        color: #007bff;
    }
`;

const ChatBubble = styled.div`
    // Posicionamento
    position: fixed;
    bottom: 40px;
    left: 40px;
    // Tamanho
    width: 50px;
    height: 50px;
    // Estilo
    background-color: #007bff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // Flex
    display: flex;
    align-items: center;
    justify-content: center;
    // Transição
    transition: all 0.3s ease-in-out;
    transform: ${({ minimized }) => (minimized ? 'scale(1)' : 'scale(0)')};
    opacity: ${({ minimized }) => (minimized ? '1' : '0')};
    // Pointer events
    pointer-events: ${({ minimized }) => (minimized ? 'auto' : 'none')};

    &:hover {
        // Tamanhos
        font-size: 28px;
        // Estilo
        background-color: #0056b3;
        color: #8decff;
    }
`;
//#endregion

/**
 * Componente de dashboard padrão do sistema.
 * 
 * @component
 * 
 * @returns {JSX.Element} O componente de dashboard padrão.
 * 
 * @example
 * <DashboardPadrao />
 * 
 * @description
 * Este componente renderiza o dashboard padrão do sistema, exibindo informações como mostradores, recados, acesso rápido e um chat para desenvolvedores.
 * 
 * @function
 * @name DashboardPadrao
 * 
 * @requires usePageBase
 * @requires useAuth
 * @requires useIcons
 * @requires useListas
 * @requires useMemo
 * @requires useRef
 * @requires useState
 * @requires useEffect
 * @requires Dashboard
 * @requires api
 * @requires moment
 * @requires ScaleLoader
 * @requires Container
 * @requires ContainerSub
 * @requires GridHeader
 * @requires Header
 * @requires MostradoresColoridos
 * @requires GridRecados
 * @requires RecadosCard
 * @requires BalaoDica
 * @requires AcessoRapido
 * @requires WebSocketProvider
 * @requires ChatContainer
 * @requires MinimizeButton
 * @requires ChatBubble
 * @requires BsChatFill
 * @requires ModalBase
 * @requires Form
 * @requires TutorialBadge
 * 
 * @example
 * // Exemplo de uso:
 * <DashboardPadrao />
 */
export default function DashboardPadrao() {
    usePageBase({
        title: 'Dashboard Padrão',
        description: 'Dashboard padrão do sistema',
        hasTitle: false,
        hasSubmenu: false
    });
    //#region VARIAVEIS
    const { user } = useAuth();
    const { iconArray } = useIcons();
    const { fetchListas } = useListas([
        'diagnostico',
        'especialidade',
        'terapeuta',
        'status_atendimento',
        'sala',
        'plano_saude',
        'paciente:ativo|select=id.nome.identificador',
        'modalidade',
        'horarios_atendimento',
        'tipo_atendimento',
        'tipo_demanda',
        'terapia',
        'terapia_paciente',
    ]);
    const tipo_user = useMemo(() => user?.tipo_user?.toLowerCase(), [user]);
    const modalBaseTestRef = useRef();

    const [listaDePresenca, setListaDePresenca] = useState([]);
    const [dashboardStructure, setDashboardStructure] = useState(null);
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };
    // Referencias
    const multiSelectSwitchRef = useRef();
    const websocketRef = useRef();
    const [mensagem, setMensagem] = useState('');
    const [nomeUsuario, setNomeUsuario] = useState(user.name);
    const [mensagens, setMensagens] = useState([]);
    const [minimized, setMinimized] = useState(true);
    const [chatAberto, setChatAberto] = useState(false);
    //#endregion

    //#region HANDLES
    const handleOnMessage = (data) => {
        switch (data.action) {
            case 'msg':
                setMensagens((prevMessages) => [...prevMessages, data.msg]);
                return;
            case 'update_connections_list':
                setListaDePresenca(data.connections);
                return;
            default:
                setMensagens((prevMessages) => [...prevMessages, data]);
                return;
        }

    }
    const handleEnviaMensagem = (e) => {
        if (e) e.preventDefault();
        websocketRef.current.setNewMessage({
            action: 'send_message',
            message: mensagem,
            username: nomeUsuario
        });
        setMensagem('');
    };
    const handleConectaSocket = (e) => {
        if (e) e.preventDefault();
        websocketRef.current.setSocketLink(`teste?username=${nomeUsuario}`);
        websocketRef.current.setConectaComSocket(true);
    };
    //#endregion
    //#region FUNCOES
    const onLoad = async () => {
        const tipoUserUpper = tipo_user.toUpperCase();
        const dashboard = new Dashboard(tipoUserUpper, user.id);
        await dashboard.setMostradoresInformacao();

        try {
            if (dashboard.getEnableRecados()) {
                const recadoResponse = await api.get('api/recado/get/by/group/' + tipo_user);
                setRecado(recadoResponse.data);
            }
            if (dashboard.getEnableMostradores()) {
                const mostradores = dashboard.getMostradores();
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradores
                });
            }
            if (dashboard.getEnableAcessoRapido) {
                const acessoRapido = dashboard.getAcessoRapido();
                setAcessoRapido(acessoRapido);
            }
            setDashboardStructure(dashboard);
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        setMensagens(prevMessages => [...prevMessages, websocketRef?.current?.messages[websocketRef?.current?.messages.length - 1]]);
    }, [websocketRef.current?.messages]);
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
            if (tipo_user === 'desenvolvedor') {
                //fetchListas();
            }
        }
    }, [tipo_user]);
    //#endregion

    //#region HTML
    return (
        <>
            <Container className='row'>
                {!dashboardStructure &&
                    <div className='col-12 d-flex'>
                        <label className="text-center all-center w-content h-content mx-auto">
                            Carregando dashboard...<br></br><br></br>
                            <ScaleLoader color='#000' loading={true} />
                        </label>
                    </div>
                }
                <ContainerSub id='containerSub'>
                    {dashboardStructure && dashboardStructure.getEnableHeader() &&
                        <GridHeader id="header">
                            <Header>
                                <label>Bem Vindo {user?.name && user?.name}!</label>
                                <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                            </Header>
                        </GridHeader>
                    }
                    {(dashboardStructure && dashboardStructure && dashboardStructure?.getEnableMostradores()) &&
                        <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores || mostradoresColoridos.mostradores.length === 0} />
                    }
                    {dashboardStructure?.getEnableRecados() &&
                        <>
                            <GridRecados id="recados">
                                <RecadosCard title={'Recados'} recado={recado} />
                            </GridRecados>
                            <BalaoDica
                                id={'dica_recados'}
                                elementoAncora='recados'
                                apenasUmaVez={true}
                                abertoPadrao={true}
                                titulo={'Recados Importantes'}
                            >
                                Aqui você poderia visualizar os recados importantes do sistema e da clínica, sempre fique de olho 👀.
                            </BalaoDica>
                        </>
                    }
                    {acessoRapido && dashboardStructure && dashboardStructure?.getEnableAcessoRapido() &&
                        <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                    }
                </ContainerSub>
                {tipo_user === 'desenvolvedor' && <>
                    <ListaPresenca lista={listaDePresenca} />
                    <WebSocketProvider
                        ref={websocketRef}
                        liveBadgeOptions={{
                            mostra: true,
                            temTexto: false,
                            tooltip: 'Conexão com o Chat',
                        }}
                        onOpen={(openState) => setChatAberto(openState)}
                        onClose={(openState) => setChatAberto(openState)}
                        onMessage={handleOnMessage}
                    >
                        <ChatContainer minimized={minimized}>
                            <div className="barra">
                                <span></span>
                                <span>Chat</span>
                                <MinimizeButton onClick={() => setMinimized(true)}>
                                    {iconArray['seta-angulo-baixo']}
                                </MinimizeButton>
                            </div>
                            {websocketRef.current?.socketState ? <>
                                <div className="messages">
                                    {mensagens?.map((mensagem, index) => {
                                        const action = mensagem?.action;
                                        const username = mensagem?.username;
                                        const message = mensagem?.message;

                                        switch (action) {
                                            case 'send_message':
                                                return (
                                                    <div key={index} className={`message-group ${username === nomeUsuario ? 'message-right' : 'message-left'}`}>
                                                        <span className='username'>{username}</span>
                                                        <span className='message'>{message}</span>
                                                    </div>
                                                );
                                            case 'greeting':
                                                return (
                                                    <div key={index} className="message-group greetings">
                                                        {message}
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </div>
                                <form className="message-input" onSubmit={handleEnviaMensagem}>
                                    <input
                                        type="text"
                                        value={mensagem}
                                        onChange={(e) => setMensagem(e.target.value)}
                                        placeholder="Digite sua mensagem..."
                                    />
                                    <button type={'submit'} onClick={handleEnviaMensagem}>Enviar</button>
                                </form>
                            </>
                                : <div className="message">
                                    <span>Conectar ao Chat</span>
                                    <form className="message-input">
                                        <input
                                            type="text"
                                            value={nomeUsuario}
                                            onChange={(e) => setNomeUsuario(e.target.value)}
                                            placeholder="Digite seu nome de usuário..."
                                        />
                                        <button type={'button'} onClick={() => handleConectaSocket()}>Conectar</button>
                                    </form>
                                </div>}
                        </ChatContainer>
                        <ChatBubble minimized={minimized} onClick={() => setMinimized(false)}>
                            <BsChatFill size={24} />
                        </ChatBubble>
                    </WebSocketProvider>

                    <button className='btn-padrao w-content-i' onClick={() => modalBaseTestRef.current.open()}>Abrir modal teste</button>
                    <button className='btn-padrao w-content-i' onClick={() => api.post('api/notificacao/teste')}>Notificação Teste</button>

                    <ModalBase ref={modalBaseTestRef} title='Teste de modal' hasCloseButton={true} onOpen={() => alert('Abriu')} onClose={() => alert('Fechou')}>
                        <Form>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="name">Nome:</label>
                                    <input type="text" id="name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Mensagem:</label>
                                    <textarea id="message" className="form-control"></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </div>
                        </Form>
                    </ModalBase>
                </>}
            </Container>
            {dashboardStructure &&
                <TutorialBadge
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Bem vindo ao Neuro Sistema! Passaremos por algumas etapas, para você aprender como utilizar o sistema!</span>
                                </div>
                            },
                            { title: 'Informações Básicas', selector: '#header', text: 'Aqui você tem algumas informações básicas!' },
                            { title: 'Indicadores Importantes', selector: '#mostradores', text: 'Nesse local, você pode ver alguns indicadores importantes, passe o mouse por cima, para ver seus detalhes!' },
                            { title: 'Acesso Rápido', selector: '#acesso', text: 'Temos aqui alguns links de Acesso Rápido, clique para acessa-los!' },
                            { title: 'Recados Importantes', selector: '#recados', text: 'Nesse local, você pode ver alguns recados importantes, sempre se atente a isso!' },
                        ]
                    }
                    hasTopicos={true}
                    standartFinal={true}
                />
            }
        </>
    );
}