import { Form } from '@unform/web';
import styled from 'styled-components';

const Container = styled.div`
    
`;

const DraggableItem = styled.div`
    padding: 4px 15px 4px 15px;
    margin: 6px 8px 6px 8px;
    border: 1px solid black;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    label {
        cursor: pointer;
        display: inline-block;
        max-width: 962%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    input {
        cursor: pointer;
    }

    .edit-icon {
        display: none;
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &:hover .edit-icon {
        display: block;
    }
`;

const EditableInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    background: none;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const TableContainer = styled.div`
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 1rem;
    max-height: 35vh;
`;

const borderColor = '#dee2e6';

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${borderColor};
    margin-left: .25rem;
    margin-right: .25rem;
    th {
        border: 1px solid ${borderColor};
        padding: 8px;
        text-align: left;
        font-size: 13px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;

        .column-draggable {
            cursor: grab;
            margin-right: 5px;
        }

        .column-name {
            cursor: pointer;
            padding-bottom: 2px;
        }

        .column-sort {
            cursor: pointer;
            margin-left: 5px;
        }
    }
    td {
        border-left: 1px solid ${borderColor};
        border-right: 1px solid ${borderColor};
        border-bottom: none;
        border-top: none;
        padding: 8px;
        text-align: left;
        font-size: 14px;
    }
    tbody tr td:first-child {
        border-left: none;
    }
    tbody tr td:last-child {
        border-right: none;
    }
    tbody tr:nth-child(odd) {
        background-color: rgb(245, 240, 250);
    }
    tbody tr:nth-child(even) {
        background-color: rgb(235, 215, 245);
    }
`;

const RelatorioContainer = styled.div`
    display: flex;

    /* .coluna-1 {
        width: 10%;
    } */

    .coluna-2 {
        width: 100%;
    }
`;

const Linha = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 1rem;
`;

const LinhaWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    max-height: ${({ visible }) => (visible ? '500px' : '0')};
    transition: max-height .5s ease;
    overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const FormLinha = styled(Form)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
`;

const Coluna = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 1rem;
`;

const FieldsetMinimizavel = styled.fieldset`
    padding-bottom: 0;
    &.minimizado {
        .conteudo {
            height: 0;
        }
    }
    .conteudo {
        overflow: hidden;
        transition: height 0.5s ease;
        height: 370;
    }
`;
const $theme = 'purple';

const $baseColor = '#add8e6';
const $baseWidth = 400;
const $tabWidth = 25;

const SelecionarColunas = styled.div`
    // Posicionamento
    position: fixed;
    bottom: 300px;
    left: ${({ visible }) => (visible ? '0' : `-${$baseWidth}px`)};
    z-index: 1000;
    // Estilos
    border: 1px solid black;
    // Animação
    transition: left 0.1s ease-in-out;
    // Espaçamento
    padding: 0;
    margin: 0;

    .marcador {
        // Posicionamento
        position: absolute;
        right: -${$tabWidth}px;
        top: 50%;
        transform: translateY(-50%);
        // Tamanho
        width: ${$tabWidth}px;
        height: auto;
        // Estilo
        color: ${$baseColor};
        cursor: pointer;
        // Fonte
        writing-mode: vertical-lr;
        text-orientation: upright;
        letter-spacing: -7px;
        font-size: 1rem;
        // Espaçamento
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0px 5px 0px;
        // Animação
        transition: transform 0.1s ease-in-out;

        &.visible {
            span {
                transform: rotate(180deg);
            }
        }
    }

    .conteudo {
        // Espaçamento
        display: flex;
        flex-direction: column;
        padding: 0px 0px 10px 0px;
        margin: 0;
        // Estilo
        list-style: none;
        border: none;
        // Tamanho
        max-height: 300px;
        width: ${$baseWidth}px;
        // Scroll
        overflow-y: auto;
        overflow-x: hidden;

        .titulo {
            // Espaçamento
            margin: 0;
            padding: 10px;
            text-align: center;
            // Estilo
            font-size: 1.2rem;
            color: white;
            border-bottom: 1px solid ${$baseColor};
            box-shadow: 0 15px 15px rgba(0, 0, 0, .12);
            // Tamanho
            width: 100%;
            // Posicionamento
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .lista {
            // Espaçamento
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
            margin: 0;
            // Estilos
            list-style: none;
            color: white;
            border-radius: 0 0 5px 5px;
        }

    }
`;

export { DraggableItem, EditableInput, TableContainer, Table, RelatorioContainer, Linha, FormLinha, Coluna, FieldsetMinimizavel, SelecionarColunas, LinhaWrap };