import React, { useCallback, useState, useEffect, useRef, } from 'react';
import { Container } from './styles';
import { TableListaDeEspera } from './TableListaEspera';
import api from '../../../../services/api';
import PacienteListaEsperaModal from '../../../../components/Modais/NovoPacienteListaEspera';
import { AiFillCaretDown } from 'react-icons/ai';
import { FaBroom } from 'react-icons/fa';
import useTipoDemandaData from 'context/tipo-demanda';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { ModalNovoPaciente } from 'components/Modais/NovoPacienteListaEspera/styles';
import { useSnackbar } from 'context/snackbar';
import { usePageBase } from 'context/page-base';
import { FiFilter } from 'react-icons/fi';
import Select from 'components/Select';
import { Form } from '@unform/web';

export default function ListaEspera() {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Lista de Espera',
        description: 'Aqui você pode visualizar e gerenciar a lista de espera de pacientes.',
        hasTitle: true,
        routes: [
            'dashboard/{tipo_user}/agenda/listadeespera'
        ]
    })
    const snackbar = useSnackbar();
    const { tipoDemandaOptions, fetchTipoDemanda, fetchTipoDemandaOptions } = useTipoDemandaData();
    // Estados
    const [isModalNovoPacienteVisible, setIsModalNovoPacienteVisible] = useState(false);
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [filtrado, setFiltrado] = useState(false);
    // Dados da tabela
    const [listaEspera, setListaEspera] = useState([]);
    const [listaEsperaOriginal, setListaEsperaOriginal] = useState([]);
    // Modal
    const [modalInfo, setModalInfo] = useState(null);
    // Opções
    const [readOnly, setReadOnly] = useState(false);
    const [comentario, setComentario] = useState('');
    const [modalComentarioOpen, setModalComentarioOpen] = useState(false);
    /* Filtro */
    const [expand, setExpand] = useState(false);
    const [filtroWidth, setFiltroWidth] = useState();
    // Refs
    const filtroDivRef = useRef();
    /* Listas Filtros */
    const statusOptions = [
        { value: 'Aguardando', label: 'Aguardando', name: 'Aguardando' },
        { value: 'Aprovado', label: 'Aprovado', name: 'Aprovado' },
        { value: 'Desistênte', label: 'Desistênte', name: 'Desistênte' }
    ];
    const [terapiasOptions, setTerapiasOptions] = useState([]);
    const [especialidadesOptions, setEspecialidadesOptions] = useState([]);
    const [selectedTerapias, setSelectedTerapias] = useState([]);
    const [selectedEspecialidades, setSelectedEspecialidades] = useState([]);
    const [selectedTipoDemanda, setSelectedTipoDemanda] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    //#endregion

    //#region FUNCOES
    /**
     * Função assíncrona para buscar a lista de espera da API.
     * A lista de espera é ordenada pela data de criação em ordem decrescente.
     * Atualiza os estados `listaEsperaOriginal`, `listaEspera` e `loadingDadosPacientes`.
     * Em caso de erro, exibe uma mensagem de erro no snackbar e atualiza o estado `loadingDadosPacientes`.
     *
     * @async
     * @function getListaEspera
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando a operação é concluída.
     */
    const getListaEspera = async () => {
        api.get('api/lista-espera?with=paciente,tipo_demanda,lista_espera_demandas.especialidade,lista_espera_demandas.terapium,lista_espera_demandas.plano_saude,lista_espera_historicos,lista_espera_responsavels')
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setListaEsperaOriginal(data);
                setListaEspera(data);
                setLoadingDadosPacientes(false);
            })
            .catch(() => {
                snackbar.displayMessage('Erro ao buscar lista de espera', 'error');
                setLoadingDadosPacientes(false);
            });
    };
    /**
     * Filtra uma lista de espera baseada em critérios específicos de terapias, especialidades, status e nome.
     *
     * @param {Array} terapiasList - Lista de IDs de terapias a serem filtradas.
     * @param {Array} especialidadesList - Lista de IDs de especialidades a serem filtradas.
     * @param {Array} statusList - Lista de status a serem filtrados.
     * @param {string} nomeList - Nome ou parte do nome do paciente a ser filtrado.
     * 
     * O algoritmo filtra uma lista de espera original, `listaEsperaOriginal`, e cria uma nova lista contendo apenas os
     * registros que correspondem a todos os critérios fornecidos. A função realiza as seguintes verificações para cada
     * registro:
     * - Se o nome ou parte do nome do paciente (ou nome de um campo alternativo) coincide com o valor fornecido em `nome`.
     * - Se o registro contém pelo menos uma das especialidades fornecidas.
     * - Se o registro contém pelo menos uma das terapias fornecidas.
     * - Se o status do registro coincide com algum dos status fornecidos.
     * 
     * Após as verificações, apenas os registros que atendem a todos os critérios (terapia, especialidade, status e nome)
     * são adicionados à nova lista, `newLista`. Finalmente, a função atualiza a lista de espera com a nova lista filtrada
     * usando a função `setListaEspera`.
     */
    const filtrar = (terapiasList, especialidadesList, statusList, tipo_demandaList) => {
        const terapias = terapiasList.map((t) => t.value);
        const especialidades = especialidadesList.map((e) => e.value);
        const status = statusList.map((s) => s.value);
        const tipo_demanda = tipo_demandaList.map((td) => td.value);

        console.log('filtrar', terapias, especialidades, status, tipo_demanda);

        let localListaEspera = listaEsperaOriginal;
        let newLista = [];
        localListaEspera.forEach((row) => {
            let t = false;
            let e = false;
            let s = false;
            let td = false;
            if (especialidades.length === 0 && terapias.length === 0) {
                e = true;
                t = true;
            } else {
                row.lista_espera_demandas.forEach((led) => {
                    if (especialidades.length === 0) {
                        e = true;
                    } else {
                        if (especialidades.findIndex((h) => h === led.especialidade_id) >= 0) {
                            e = true;
                        }
                    }
                    if (terapias.length === 0) {
                        t = true;
                    } else {
                        if (terapias.findIndex((h) => h === led.terapia_id) >= 0) {
                            t = true;
                        }
                    }
                });
            }
            if (status.length > 0) {
                if (status.findIndex((h) => h?.toLowerCase() === row.status_nome?.toLowerCase()) >= 0) {
                    s = true;
                }
            } else {
                s = true;
            }
            if (tipo_demanda.length > 0) {
                if (tipo_demanda.findIndex((h) => h === row.tipo_demanda_id) >= 0) {
                    td = true;
                }
            } else {
                td = true;
            }
            if ((t === true && e === true && s === true && td)) {
                newLista.push(row);
            }
        });
        setListaEspera(newLista);
    };
    /**
     * Função para buscar as terapias da API e atualizar as opções de terapias.
     * Faz uma requisição GET para 'api/terapia' e mapeia os dados recebidos para o formato esperado.
     * Atualiza o estado com as opções de terapias formatadas.
     */
    const getTerapias = () => {
        api.get('api/terapia').then(({ data }) => {
            setTerapiasOptions(data.map(terapia => ({ value: terapia.id, label: terapia.terapia, name: terapia.terapia })));
        });
    };
    /**
     * Função para buscar especialidades da API e atualizar as opções de especialidades.
     * Faz uma requisição GET para 'api/especialidade' e mapeia os dados recebidos para o formato esperado.
     * Atualiza o estado com as opções de especialidades.
     */
    const getEspecialidades = () => {
        api.get('api/especialidade').then(({ data }) => {
            setEspecialidadesOptions(data.map(especialidade => ({ value: especialidade.id, label: especialidade.especialidade, name: especialidade.especialidade })));
        });
    };
    /**
     * Função que lida com o evento de filtro de data.
     * 
     * @param {Event|null} event - O evento de clique ou null.
     * Se for null, a função define o estado de expansão como falso.
     * Se não for null, verifica se o clique ocorreu fora do elemento de filtro.
     * Se o clique ocorreu fora, define o estado de expansão como falso.
     */
    const fechaFiltro = (event) => {
        if (event === null) {
            setExpand(false);
        } else {
            if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                setExpand(false);
            }
        }
    };
    /**
     * Alterna o estado de expansão do filtro.
     * 
     * Esta função inverte o valor booleano do estado `expand`,
     * alternando entre expandido e contraído.
     */
    const expandFiltro = () => {
        setExpand(!expand);
    };
    //#endregion
    //#region HANDLES
    /**
     * Fecha o modal de novo paciente e atualiza a lista de espera.
     * 
     * @function handleCloseModal
     * @returns {void}
     */
    const handleCloseModal = () => {
        setIsModalNovoPacienteVisible(false);
        getListaEspera();
    };
    /**
     * Abre o modal para adicionar um novo paciente.
     * 
     * Esta função limpa as informações do modal e define a visibilidade do modal
     * para verdadeiro, permitindo que o usuário adicione um novo paciente.
     */
    const handleOpenModalNovoPaciente = () => {
        setModalInfo('');
        setIsModalNovoPacienteVisible(true);
    };
    /**
     * Manipula a exibição do modal de edição.
     * Abre o modal para adicionar um novo paciente.
     * 
     * @function handleEditModal
     */
    const handleEditModal = useCallback(() => {
        setIsModalNovoPacienteVisible(true);
    }, []);
    /**
     * Função para filtrar os itens da lista de espera com base nos critérios selecionados.
     * Obtém as terapias, especialidades, status e tipo de demanda selecionados e aplica o filtro.
     * Define o estado de filtrado como verdadeiro após a aplicação do filtro.
     */
    const handleFiltrar = () => {
        filtrar(selectedTerapias, selectedEspecialidades, selectedStatus, selectedTipoDemanda);
        setFiltrado(true);
    };
    /**
     * Limpa todos os filtros aplicados na lista de espera.
     * 
     * Esta função redefine os filtros de terapias, especialidades, status e tipo de demanda
     * para seus estados iniciais e aplica um filtro vazio, indicando que nenhum filtro está ativo.
     * 
     * @function
     * @name handleLimpaFiltros
     */
    const handleLimpaFiltros = () => {
        setSelectedTerapias([]);
        setSelectedEspecialidades([]);
        setSelectedStatus([]);
        setSelectedTipoDemanda([]);

        filtrar([], [], [], []);
        setFiltrado(false);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (filtroDivRef.current) {
            setFiltroWidth(filtroDivRef.current.clientWidth - 10);
        }
    }, [filtroDivRef]);
    useEffect(() => {
        setLoadingDadosPacientes(true);
        getListaEspera();
        fetchTipoDemanda();
        fetchTipoDemandaOptions();
        getEspecialidades();
        getTerapias();
        document.addEventListener('mousedown', fechaFiltro);
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
            }
        });
        return () => {
            document.removeEventListener('mousedown', fechaFiltro);
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                }
            });
        };
    }, []);
    //#endregion
    //#region RENDER
    return (
        <Container>
            <div className="div-filter-style w-100per-i" ref={filtroDivRef}>
                <div className="col-12 row" onClick={() => expandFiltro()}>
                    <div className="col-10 d-flex justify-content-start">
                        <p id="blank" style={{ marginBottom: -30 }}>{expand ? 'Fechar' : 'Expandir'} Filtro</p>
                    </div>
                    <div className="col-2 pointer d-flex align-center justify-content-end">
                        {filtrado &&
                            <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros(); }} title='Conteúdo filtrado...' />
                        }
                        <AiFillCaretDown color="white" size={22} />
                    </div>
                </div>
                <div className={expand ? 'div-expand-over row' : 'some'} style={{ marginTop: '5px', marginLeft: '-12px', borderTopRightRadius: '0px', width: filtroWidth }}>
                    <Form className="row align-items-end">
                        <div className="col-lg-3 col-sm-12 d-flex">
                            <Select id="select-terapias" options={terapiasOptions} label="Terapias" isMulti name="terapias" value={selectedTerapias} onChange={setSelectedTerapias} />
                        </div>
                        <div className="col-lg-3 col-sm-12 d-flex">
                            <Select id="select-especialidades" options={especialidadesOptions} label="Especialidades" isMulti name="especialidades" value={selectedEspecialidades} onChange={setSelectedEspecialidades} />
                        </div>
                        <div className="col-lg-3 col-sm-12 d-flex">
                            <Select id="select-tipo-demanda" options={tipoDemandaOptions} label="Tipo de Demanda" isMulti name="tipo_demanda" value={selectedTipoDemanda} onChange={setSelectedTipoDemanda} />
                        </div>
                        <div className="col-lg-2 col-sm-12 d-flex">
                            <Select id="select-status" options={statusOptions} label="Status" isMulti name="status" value={selectedStatus} onChange={setSelectedStatus} />
                        </div>
                        <div className="col-1">
                            <button onClick={() => handleFiltrar()} className="btn-padrao">
                                Filtrar <FiFilter />
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            <TableListaDeEspera
                setComentario={setComentario}
                setModalComentarioOpen={setModalComentarioOpen}
                source={listaEspera}
                callback={getListaEspera}
                openModal={handleEditModal}
                setModalInfo={setModalInfo}
                modalInfo={modalInfo}
                setReadOnly={setReadOnly}
                loading={loadingDadosPacientes}
                createButton={<button className="btn-padrao" onClick={handleOpenModalNovoPaciente}>
                    Adicionar nova Espera(Demanda/Paciente)
                </button>}
            />
            {modalComentarioOpen &&
                <ModalNovoPaciente id="modal-comentario-espera" onClick={(e) => e.target.id === 'modal-comentario-espera' && setModalComentarioOpen(false)}>
                    <div className="div-modal w-30per pt-0">
                        <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                            <div className="container-fluid d-flex align-items-center justify-content-between">
                                <div></div>
                                <ModalTitulo>Comentarios</ModalTitulo>
                                <label className='btn-outline pointer text-right' onClick={() => setModalComentarioOpen(false)}>
                                    <CgClose size={22} color='red' />
                                </label>
                            </div>
                        </nav>
                        <br></br>
                        <p className="text-justify p-2">{comentario}</p>
                    </div>
                </ModalNovoPaciente>}
            {isModalNovoPacienteVisible
                && <PacienteListaEsperaModal modalInfo={modalInfo} tipoDemandaOptions={tipoDemandaOptions} callback={() => { getListaEspera(); setModalInfo(null); }} onClose={() => handleCloseModal()} readOnly={readOnly} />
            }
        </Container>
    );
    //#endregion
}