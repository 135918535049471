import { useState } from 'react';
import api from 'services/api';

const useDisponibilidadeData = () => {
    const [disponibilidadesOptions, setDisponibilidadeOptions] = useState([]);
    const [disponibilidades, setDisponibilidade] = useState([]);

    const mapDisponibilidadeData = (data) => {
        return data.map((terapeuta) => ({
            value: terapeuta.id,
            label: terapeuta.nome
        }));
    };

    const fetchDisponibilidadeData = async () => {
        try {
            const { data } = await api.get('api/terapeuta-disponibilidade?select=id,horario');
            const options = mapDisponibilidadeData(data);
            setDisponibilidadeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar disponibilidades:', error);
        }
    };

    const fetchDisponibilidadeFiltradosData = async (filtro) => {
        try {
            const { data } = await api.post(`api/terapeuta-disponibilidade/relatorio/filtrado`, filtro);
            setDisponibilidade(data);
            return data;
        } catch (error) {
            console.error('Erro ao carregar disponibilidades:', error);
        }
    }

    return { disponibilidadesOptions, disponibilidades, fetchDisponibilidadeData, mapDisponibilidadeData, fetchDisponibilidadeFiltradosData };
};

export default useDisponibilidadeData;