import styled from 'styled-components';

const BalloonIcon = styled.div`
        // Variaveis
        --light-yellow: #ffd500;
        --black-yellow: #8a8100;

        // Posicionamento
        position: relative;
        // Display
        display: inline-block;
        // Cursor
        cursor: pointer;
        // Espaçamento
        margin-right: 15px;
        // Icone
        .icone {
            color: var(--black-yellow);
        }

        .balloon-border{
            visibility: visible;
            opacity: 1;
            width: 200px;
            background-color: var(--light-yellow);
            border: 10px solid var(--black-yellow);
            color: var(--black-yellow);
            text-align: center;
            /*               T    R    B    L  */
            border-radius: 20px 20px 20px 20px;
            padding: 10px;
            position: absolute;
            z-index: 1;
            top: 128%;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 0.3s;
            &::after {
                content: '';
                position: absolute;
                bottom: calc(100% + 8px);
                left: 50%;
                transform: translateX(-50%);
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent var(--black-yellow) transparent;
            }

            transition: visibility 0.5s, opacity 0.5s, transform 0.3s, box-shadow 0.3s;

            &:hover {
                transform: translateX(-50%), scale(1.1);
            }

            transition: visibility 0.5s, opacity 0.5s, transform 0.3s, box-shadow 0.3s;
            box-shadow: 10px 10px 15px #00000039;
            .balloon{
                animation: pulse-yellow 1.5s ease-in-out;
                @keyframes pulse-yellow {
                    0% {
                        box-shadow: 0 0 0 0 rgba(255, 255, 100, 0.9);
                    }
                    70% {
                        box-shadow: 0 0 0 50px rgba(255, 255, 100, 0);
                    }
                    100% {
                        box-shadow: 0 0 0 0 rgba(255, 255, 100, 0);
                    }
                }
            }
        }

    `;

export { BalloonIcon };