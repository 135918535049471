
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  transition: background-color 1s, height 1s;
  display: flex; 
  justify-content: center;  
  height: 100vh;
  width: 100vw;
  background-color: #412651;
  align-items: start;
  overflow: hidden;
   
  form{
    transition: all 1s;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 600px;

    .welcome-div{
        color: white;
        text-align: center;
        width: 100%;
        h2{
            margin: 0;
            font-size: 2rem;
            font-family: 'Montserrat', sans-serif !important;
        }
        p{
            margin: 0;
            font-size: 1rem;
            font-family: 'Montserrat', sans-serif !important;
        }
    }

    .logo-div {
      transition: all 1s;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 290px;
      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        cursor: pointer;
        transition: width 1s;
      }
      span {
        transition: margin 1s;
      }
    }
    
    .form-group{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        input{
            width: 100%;
        }
    }

    @media (max-width: 700px) {
        width: 70%;
        height: 100%;
        .logo-div {
            height: 200px;
            img {
                max-width: 300px;
            }
        }
    }
  }
`;