import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 20px;
`;

export const TableHead = styled.thead`
    background-color: #f8f8f8;
`;

export const TableBody = styled.tbody`
    background-color: #fff;
`;

export const TableRow = styled.tr`
    border-bottom: 1px solid #f8f8f8;
`;

export const TableHeader = styled.th`
    padding: 10px;
    text-align: left;
`;

export const TableCell = styled.td`
    padding: 10px;
    text-align: left;
`;

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
`;

export const LoadingText = styled.h2`
    font-size: 20px;
`;