import { RiLock2Line } from 'react-icons/ri';

const BloqueiaArea = ({ children, bloqueado, bloqueadoTexto = 'Área bloqueada' }) => {
    const estiloBloqueado = {
        pointerEvents: 'none',
        opacity: 0.5,
        userSelect: 'none',
        cursor: 'not-allowed'
    };

    const estiloNormal = {
        pointerEvents: 'auto',
        opacity: 1,
        userSelect: 'auto'
    };

    return (
        <>
            {bloqueado && (
                <div>
                    <hr className='w-100per bg-roxo-dark font-roxo-dark'></hr>
                    <div className='d-flex align-items-center justify-content-center mb-4 position-relative bg-white w-content h-content mx-auto' style={{ marginTop: '-28px' }}>
                        <span className='ms-2 mb-1'>
                            <RiLock2Line className='font-roxo-dark' size={20} color={"purple"} />
                        </span>
                        <span className='mz-2 font-roxo-escuro'>{bloqueadoTexto}</span>
                    </div>
                </div>
            )}
            <div style={bloqueado ? estiloBloqueado : estiloNormal}>
                {children}
            </div>
            {bloqueado && (
                <div>
                    <hr className='w-100per bg-roxo-dark font-roxo-dark'></hr>
                    <div className='d-flex align-items-center justify-content-center mb-4 position-relative bg-white w-content h-content mx-auto bg-roxo' style={{ marginTop: '-28px' }}>
                        <span className='mx-1' style={{ marginTop: '-4px' }}>
                            <RiLock2Line className='font-roxo-dark' size={30} color={"purple"} />
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default BloqueiaArea;