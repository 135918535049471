import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 60%;
    margin: 0 auto;
`;

const Mensagens = styled.div`
    div {
        margin: 10px 0;
        span {
            font-weight: bold;
        }
    }
`;

const UrlDisplay = styled.div`
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
`;

const DisplaySocketState = styled.div`
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
`;

const FormConexao = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    label {
        font-weight: bold;
        margin-bottom: 5px;
    }
    button {
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    button:hover {
        background-color: #0056b3;
    }
`;

const BotaoAdicionaParametro = styled.button`
    padding: 5px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

const BotaoAdicionarConexao = styled.button`
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
`;

export {
    Container,
    Mensagens,
    UrlDisplay,
    DisplaySocketState,
    FormConexao,
    BotaoAdicionaParametro,
    BotaoAdicionarConexao
};