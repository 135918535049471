import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Public from '../../layouts/Public';
import Button from './components/Button';
import Input from './components/Input';
import { ScaleLoader } from 'react-spinners';
import { FiLock, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import { Container } from './styles';
import RecuperarSenhaModal from './RecuperarSenha/modal';
import { useAuth } from '../../context/auth';
import { useSnackbar } from '../../context/snackbar';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { ChangeCase } from 'services/ChangeCase';
import { usePageBase } from 'context/page-base';
import version from 'version';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { FaCookieBite } from 'react-icons/fa';

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

const CookieBanner = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
`;

const CookieButton = styled.button`
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
	border-radius: 5px;
`;

/**
 * Componente de Login.
 * 
 * Este componente renderiza a página de login do sistema Neuro.
 * Ele inclui um formulário para entrada de login e senha, e lida com a lógica de autenticação.
 * 
 * @component
 * 
 * @returns {JSX.Element} Retorna o componente de Login.
 * 
 * @example
 * <Login />
 * 
 * @function alteraSenha
 * @description Função assíncrona que exibe um modal para troca de senha inicial.
 * @param {Object} user - Objeto do usuário contendo informações como nome e email.
 * @param {string} [mensagemErro=null] - Mensagem de erro a ser exibida no modal.
 * @param {string} [senha=''] - Valor inicial do campo de senha.
 * @param {string} [confirma=''] - Valor inicial do campo de confirmação de senha.
 * @returns {Promise<Object>} Retorna um objeto com status e mensagem.
 * 
 * @function handleOpenModal
 * @description Função que abre o modal de recuperação de senha.
 * 
 * @function handleSubmit
 * @description Função assíncrona que lida com o envio do formulário de login.
 * @param {Object} data - Dados do formulário de login.
 * 
 * @hook usePageBase - Hook para configurar a base da página.
 * @hook useRef - Hook para criar referências para o formulário e modal.
 * @hook useNavigate - Hook para navegação.
 * @hook useSnackbar - Hook para exibir mensagens de snackbar.
 * @hook useAuth - Hook para autenticação.
 * @hook useState - Hook para gerenciar o estado de carregamento.
 */
const Login = () => {
	usePageBase({
		title: 'Login',
		description: 'Página de login do sistema Neuro.',
		hasTitle: false,
		hasSubMenu: false,
		hasNavbar: false,
	});
	const formRef = useRef(null);
	const modalRef = useRef(null);
	const navigate = useNavigate();
	const { displayMessage } = useSnackbar();
	const { signIn } = useAuth();
	const [loading, setLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showCookieBanner, setShowCookieBanner] = useState(false);

	useEffect(() => {
		const cookieConsent = Cookies.get('cookieConsent');
		if (!cookieConsent) {
			setShowCookieBanner(true);
		}
	}, []);

	const handleAcceptCookies = () => {
		Cookies.set('cookieConsent', 'true', { expires: 365 });
		setShowCookieBanner(false);
	};

	const handleRejectCookies = () => {
		setShowCookieBanner(false);
	};

	const alteraSenha = async (user, mensagemErro = null, senha = '', confirma = '') => {
		try {
			const someClass = mensagemErro ? 'some' : '';
			const result = await Swal.fire({
				title: mensagemErro ? mensagemErro : 'Trocar senha inicial!',
				text: !mensagemErro ? 'A senha de acesso que você usou, é a senha inicial. Por favor, troque-a para continuar.' : '',
				html: `
                <h4 class="${someClass}">Quer trocar agora?</h4>
                <div class="w-100 d-flex flex-col">
                    <div class="w-100 text-left">
                        <b class="${someClass}">Certifique-se de que é você:</b>
                        <p><b>Nome:</b> ${user.name}</p>
                        <p><b>Email:</b> ${user.email}</p>
                    </div>
                    <div class="w-100 form-group text-left">
                        <label class="form-label">Nova Senha:</label>
                        <input type="password" id="swal-senha" class="form-control" value="${senha}" placeholder="Digite sua nova senha"
                            onKeyUp="
                                const password = document.getElementById('swal-senha');
                                const confPassword = document.getElementById('swal-confirma');
                                const logErro = document.getElementById('log-erro');
                                if(confPassword.value) {
                                    if(password.value !== confPassword.value) {
                                        logErro.innerText = 'As senhas devem ser iguais!';
                                        logErro.style.color = 'red';
                                        password.style.borderColor = 'red';
                                        confPassword.style.borderColor = 'red';
                                    }else{
                                        logErro.innerText = '';
                                        password.style.borderColor = 'green';
                                        confPassword.style.borderColor = 'green';
                                    }
                                }else{
                                    logErro.innerText = 'Digite uma confirmação!';
                                    logErro.style.color = 'red';
                                    password.style.borderColor = '';
                                    confPassword.style.borderColor = 'red';
                                }
                            " required>
                    </div>
                    <div class="w-100 form-group text-left">
                        <label class="form-label">Confirme a Senha:</label>
                        <input type="password" id="swal-confirma" class="form-control" value="${confirma}" placeholder="Confirme sua nova senha"
                            onKeyUp="
                                const password = document.getElementById('swal-senha');
                                const confPassword = document.getElementById('swal-confirma');
                                const logErro = document.getElementById('log-erro');
                                if(confPassword.value) {
                                    if(password.value !== confPassword.value) {
                                        logErro.innerText = 'As senhas devem ser iguais!';
                                        logErro.style.color = 'red';
                                        password.style.borderColor = 'red';
                                        confPassword.style.borderColor = 'red';
                                    }else{
                                        if(password.value.length < 6) {
                                            logErro.innerText = 'A senha deve ter no mínimo 6 caracteres!';
                                            logErro.style.color = 'red';
                                            password.style.borderColor = 'red';
                                            confPassword.style.borderColor = 'red';
                                        }else{
                                            logErro.innerText = '';
                                            password.style.borderColor = 'green';
                                            confPassword.style.borderColor = 'green';
                                        }
                                    }
                                }else{
                                    logErro.innerText = 'Digite uma confirmação!';
                                    logErro.style.color = 'red';
                                    password.style.borderColor = '';
                                    confPassword.style.borderColor = 'red';
                                }
                            " required>
                    </div>
                    <p id="log-erro"></p>
                </div>
                `,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Cadastrar Nova Senha',
				cancelButtonText: 'cancelar',
			});
			if (result.isConfirmed) {
				const senha = document.getElementById('swal-senha').value;
				const confirma = document.getElementById('swal-confirma').value;
				if (!user.email_verified_at) {
					if (senha) {
						if (confirma) {
							if (senha !== confirma) {
								await alteraSenha(user, 'As senhas não são iguais, tente novamente.', senha, confirma);
								return;
							} else {
								if (senha.length < 6) {
									await alteraSenha(user, 'A senha deve ter no mínimo 6 caracteres.', senha, confirma);
									return;
								}
							}
						} else {
							await alteraSenha(user, 'Confirme a senha para continuar.', senha, confirma);
							return;
						}
					} else {
						await alteraSenha(user, 'Digite uma senha para continuar.', senha, confirma);
						return;
					}
				}
				const response = await api.post('api/trocar-senha', { id: user.id, password: senha });
				Swal.fire('Senha alterada!', 'Sua senha foi alterada com sucesso, faça login novamente.', 'success');
				return { status: 200, message: 'Senha alterada com sucesso!' };
			}
		} catch (err) {
			console.error(err);
			Swal.fire('Ops!', 'Não foi possível alterar sua senha, tente novamente.', 'error');
			return { status: 500, message: 'Não foi possível alterar sua senha.' };
		}
	};

	const handleOpenModal = useCallback(() => {
		modalRef.current.openModal();
	}, []);

	const handleSubmit = async (data) => {
		try {
			setLoading(true);
			const response = await signIn({ username: data.Login, password: data.Senha });
			const usuario = response?.data?.user;

			if (response.status === 401 || response.data.message === 'Invalid Credentials') {
				displayMessage('Usuário ou senha inválidos', 'error');
				return;
			}
			if (!response.data.user.email_verified_at) {
				if (response.data.message === 'Senha inicial, por favor troque-a.') {
					const user = response.data.user;
					await alteraSenha(user);
					return '';
				}
			}
			if (response.data.message === 'Acesso expirou') {
				Swal.fire('Acesso expirado!', 'Parece que seu acesso expirou, converse com a coordenação para receber a liberação, e a nova senha.', 'error');
				return;
			}
			if (usuario?.documentos_pendentes?.length > 0) {
				navigate(`/dashboard/${usuario.tipo_user}/documentos-pendentes/usuario`);
				return;
			}
			if (usuario) {
				if (usuario.status?.toLowerCase() === 'inativo') {
					Swal.fire('Usuário inativo!', 'Usuário inativo, entre em contato com a coordenação para ativar seu acesso.', 'error');
					return;
				}
				setIsLoggedIn(true); // Adicione esta linha
				setTimeout(() => {
					const navUrl = '/dashboard';
					const route_tipo_user = ChangeCase.toSnakeCase(usuario.tipo_user.toLowerCase());
					switch (route_tipo_user) {
						case 'terapeuta':
							const terapeutaStatus = usuario.terapeuta[0]?.status;
							const terapeutaRoutes = {
								'Bloqueado': '/terapeuta/acesso-bloqueado',
								'Ativo': '/terapeuta/',
								'Liberado': '/terapeuta/'
							};
							navigate(`${navUrl}${terapeutaRoutes[terapeutaStatus] || '/terapeuta/'}`);
							break;
						default:
							const old_route = localStorage.getItem('old_route');
							if (old_route) {
								localStorage.removeItem('old_route');
								const old_tipo_user = old_route.split('/')[1];

								if (old_tipo_user.toLowerCase() === route_tipo_user) {
									navigate(old_route);
								} else {
									navigate(`${navUrl}/${route_tipo_user}/`);
								}
							} else {
								navigate(`${navUrl}/${route_tipo_user}/`);
							}
							break;
					}
				}, 1000); // Tempo da animação em milissegundos
			}
		} catch (err) {
			console.error(err);
			displayMessage(err.message + '. Não foi possível acessar!', 'error');
		} finally {
			if (!formRef.current) return;
			setLoading(false);
		}
	};

	return (
		<Public>
			{false === true && showCookieBanner && (
				<CookieBanner>
					<p>
						<b><FaCookieBite size={28} /></b> Este site utiliza cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa <a href="/politica-de-cookies" target="_blank" style={{ color: '#fd931a' }}>política de cookies</a>.
					</p>
					<div className='w-100 d-flex justify-content-between'>
						<CookieButton className='bg-laranja-i' onClick={handleAcceptCookies}>Aceitar</CookieButton>
						<CookieButton className='bg-vermelho-i' onClick={handleRejectCookies}>Recusar</CookieButton>
					</div>
				</CookieBanner>
			)}
			<Container className={`${isLoggedIn ? 'animate' : ''}`}>
				<Form className={`login-container`} ref={formRef} onSubmit={handleSubmit}>
					<div className={`logo-div flex-row d-flex align-items-end ${isLoggedIn ? 'animate' : ''}`}>
						<a key={0} href={'/'} className='nav-logo'>
							<img onClick={() => navigate('/')} src={'/logo.png'} alt='Neuro Intensiva Logo' />
						</a>
						<span className='font-branco-i pb-4 font-branco mb-5'>
							{version()}
						</span>
					</div>
					<div className={`form-div d-flex flex-col ${isLoggedIn ? 'animate' : ''}`}>
						<div className="form-group font-branca">
							<label className="form-label">Login:</label>
							<Input name="Login" icon={FiUser} placeholder="Digite seu Login ou Email" type="text" autoComplete="username" required />
						</div>
						<div className="form-group font-branca">
							<label className="form-label">Senha:</label>
							<Input name="Senha" icon={FiLock} placeholder="Digite sua Senha" type="password" autoComplete="current-password" required />
						</div>
						<div className='mt-1 d-flex justify-content-center align-center font-branco'>
							Não consegue acessar? <label onClick={handleOpenModal} className="ns-forgot-password">Clique Aqui!</label>
						</div>
						{loading
							? <ScaleLoader css={override} size={150} color={'#fd931a'} loading={loading} />
							: <div className='d-flex justify-content-center'>
								<Button type="submit">Entrar</Button>
							</div>
						}
					</div>
				</Form>
				<RecuperarSenhaModal ref={modalRef} />
			</Container>
		</Public>
	);
};

export default Login;