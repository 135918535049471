// document: estilos.js
import styled from 'styled-components';

const $baseColor = '#add8e6';
const $baseWidth = 400;
const $tabWidth = 20;

export const Container = styled.div`
  // Posicionamento
  position: fixed;
  right: ${({ visible }) => (visible ? '0' : `-${$baseWidth}px`)};
  bottom: 65px;
  z-index: 1000;
  // Tamanho
  width: ${$baseWidth}px;
  // Animação
  transition: right 0.3s ease-in-out;
  // Estilo
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border: none;
  // Espaçamento
  margin: 0;
  padding: 0;
`;

export const Tab = styled.div`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: ${$tabWidth}px;
  height: 40px;
  color: ${$baseColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: transform 0.3s ease-in-out;

  &.visible {
    span {
      transform: rotate(180deg);
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 10px 0px 10px 0px;
  margin: 0;
  color: ${$baseColor};

  border-width: 10px;
  border-style: solid;
  border-right: none;

  max-height: 300px;
  width: ${$baseWidth}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ListTitle = styled.h3`
    margin: 0;
    padding: 10px;
    font-size: 1.2rem;
    background-color: transparent;
    color: white;
    text-align: center;
    border-bottom: 1px solid ${$baseColor};
    box-shadow: 0 15px 15px rgba(0, 0, 0, .12);

    position: sticky;
    top: 0;
    z-index: 1;
`;

export const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);

    padding: 2px 10px;
    margin: 0;
    margin-bottom: 10px;

    &:nth-child(2) {
        margin-top: 20px;
    }
`;
