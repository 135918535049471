//#region IMPORTAÇÕES
import useDisponibilidadeData from 'context/disponibilidades';
import { usePageBase } from 'context/page-base';
import useTerapeutasData from 'context/terapeuta';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import {
    Container,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableHeader,
    TableCell,
    Loading,
    LoadingText
} from './estilos';
import Select from 'components/Select';
import { Form } from '@unform/web';
import useIcons from 'context/icons';
import BloqueiaArea from 'components/BloqueiaArea';
import BlockedArea from 'components/BlockedArea';
import RelatorioBase from 'context/relatorio';
//#endregion

const RelatoriosDisponibilidades = () => {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Relatório de Disponibilidades',
        hasTitle: true,
        description: 'Tela onde é possível visualizar os disponibilidades cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/disponibilidades'
        ]
    });
    const { fetchDisponibilidadeFiltradosData } = useDisponibilidadeData();
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { iconArray } = useIcons();

    // Parâmetros
    const [carregadosDados, setCarregadosDados] = useState(false);
    const [loadingListas, setLoadingListas] = useState(true);
    // Listas
    const [horariosAtendimento, setHorariosAtendimento] = useState([]);
    const [dados, setDados] = useState([]);
    const diasSemana = [
        { value: 0, label: 'Domingo' },
        { value: 1, label: 'Segunda' },
        { value: 2, label: 'Terça' },
        { value: 3, label: 'Quarta' },
        { value: 4, label: 'Quinta' },
        { value: 5, label: 'Sexta' },
        { value: 6, label: 'Sábado' },
    ];
    const contarPor = [
        { value: 'terapia', label: 'Terapia' },
        { value: 'terapeuta', label: 'Terapeuta' },
    ];
    // Filtros
    const defaultFilters = {
        'horario_atendimento_id': null,
        'dia_semana': null,
        'terapeuta_id': null,
        'contar_por': 'disponibilidade',
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [horarioSelecionado, setHorarioSelecionado] = useState([]);
    const [diaSemanaSelecionado, setDiaSemanaSelecionado] = useState([]);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState([]);
    const [contarPorSelecionado, setContarPorSelecionado] = useState([]);
    // Relatório
    const defaultColumns = useMemo(() => [
        // 'id',
        'terapeuta_nome',
        'horario',
        'dia_semana',
        'dia_semana_nome',
        'created_at',
        'updated_at',
        'is_available',
        'total',
    ], []);
    const columnsPorTerapia = useMemo(() => [
        // 'terapia_id',
        'terapia_nome',
        'disponivel',
        'reservado',
        'indisponivel',
        'total',
    ], []);
    const columnsPorTerapeuta = useMemo(() => [
        // 'terapeuta_id',
        'terapeuta_nome',
        'disponivel',
        'indisponivel',
        'reservado',
        'total',
        'porcentagem_disponivel',
        'total',
    ], []);
    const columns = useMemo(() => {
        const contarPor = filters['contar_por'];
        switch (contarPor) {
            case 'terapia':
                return columnsPorTerapia;
            case 'terapeuta':
                return columnsPorTerapeuta;
            default:
                return defaultColumns;
        }
    }, [filters]);
    //#endregion

    //#region FUNCOES
    const fetchData = useCallback(async () => {
        try {
            const response = await fetchDisponibilidadeFiltradosData(filters);
            setDados(response);
            setCarregadosDados(true);
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
            setCarregadosDados(false);
        }
    }, [filters]);

    const getGrupos = async () => {
        try {
            const { data } = await api.get('api/horario-atendimento');
            setHorariosAtendimento(data.map((grupo) => {
                const dia_semana = diasSemana.find((dia) => dia.value === grupo.dia_semana).label;
                const horario = grupo.horario.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');

                return ({ value: grupo.id, label: `${horario} - ${dia_semana}` });
            }));
        } catch (error) {
            console.error('Erro ao carregar grupos:', error);
        }
    }

    const handleFilterChange = ({ id, value }) => {
        if (carregadosDados) {
            setCarregadosDados(false);
            setDados([]);
        };
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                [id]: value,
            };
            return newFilters;
        });
    };

    const handlePesquisar = useCallback(() => {
        if (filters) {
            fetchData();
        }
    }, [filters, fetchData]);

    const handleLimparFiltros = useCallback(() => {
        setHorarioSelecionado([]);
        setDiaSemanaSelecionado([]);
        setTerapeutaSelecionado([]);
        setContarPorSelecionado([]);
        setDados([]);
        setCarregadosDados(false);
        setFilters(defaultFilters);
    }, []);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const sortedDados = useMemo(() => {
        if (sortConfig.key) {
            return [...dados].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return dados;
    }, [dados, sortConfig]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = null;
        }
        setSortConfig({ key, direction });
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(async () => {
        setLoadingListas(true);
        await fetchTerapeutasData();
        await getGrupos();
        setCarregadosDados(false);
        setLoadingListas(false);
    }, []);
    //#endregion

    //#region RENDER
    return (
        <Container>
            <Form className='d-flex w-100 px-4 align-items-end'>
                <div className='col-3 px-2'>
                    <Select id="horarios_atendimento_id" name="filter[horarios_atendimento_id]" label="Horários de Atendimento" value={horarioSelecionado} options={horariosAtendimento} onChange={(e) => { setHorarioSelecionado(e); handleFilterChange({ ...e, id: 'horarios_atendimento_id' }) }} isDisabled={loadingListas} />
                </div>
                <div className='col-2 px-2'>
                    <Select id="dia_semana" name="filter[dia_semana]" label="Dia da Semana" options={diasSemana} value={diaSemanaSelecionado} onChange={(e) => { setDiaSemanaSelecionado(e); handleFilterChange({ ...e, id: 'dia_semana' }) }} isDisabled={loadingListas} />
                </div>
                <div className='col-3 px-2'>
                    <Select id="terapeuta_id" name="filter[terapeuta_id]" label="Terapeuta" options={terapeutasOptions} value={terapeutaSelecionado} onChange={(e) => { setTerapeutaSelecionado(e); handleFilterChange({ ...e, id: 'terapeuta_id' }) }} isDisabled={loadingListas} />
                </div>
                <div className='col-2 px-2'>
                    <Select id="contar_por" name="filter[contar_por]" label="Contar por" options={contarPor} value={contarPorSelecionado} onChange={(e) => { setContarPorSelecionado(e); handleFilterChange({ ...e, id: 'contar_por' }) }} isDisabled={loadingListas} />
                </div>
                <div className='col-1 px-2'>
                    <button type="button" className="btn-padrao w-100" onClick={handleLimparFiltros} disabled={loadingListas}>
                        {iconArray['limpar']} Limpar
                    </button>
                </div>
                <div className='col-1 px-2'>
                    <button type="button" className="btn-padrao w-100" onClick={handlePesquisar} disabled={loadingListas}>
                        {iconArray['pesquisar']} Pesquisar
                    </button>
                </div>
            </Form>
            {loadingListas ? (
                <Loading>
                    <LoadingText>Carregando listas de filtro.</LoadingText>
                    <ClipLoader color={'#000'} loading={true} size={50} />
                </Loading>
            ) :
                <BlockedArea text="Sem dados carregados para mostrar." blockStatus={!carregadosDados}>
                    {sortedDados?.length > 0
                        ? <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((col) => (
                                        <TableHeader key={col} onClick={() => handleSort(col)}>
                                            {col}
                                            {sortConfig.key === col && sortConfig.direction && (
                                                <span>{iconArray['seta-angulo-cima']}</span>
                                            )}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedDados.map((dado) => (
                                    <TableRow key={dado.id}>
                                        {columns.map((col) => (
                                            <TableCell key={col}>{dado[col]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        : "Nada pra mostrar"
                    }
                </BlockedArea>
            }
        </Container>
    );
    //#endregion
};

export default RelatoriosDisponibilidades;