//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import ControleDeExecucaoModal from 'components/Modais/Checkin';
import SelectPaciente from 'components/Select/SelectPaciente';
import { useAuth } from 'context/auth';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import Swal from 'sweetalert2';
//#endregion

/**
 * Componente de Check-In.
 * 
 * Este componente permite ao usuário realizar o check-in diário para iniciar suas atividades.
 * Utiliza hooks para gerenciar o estado e efeitos colaterais, e faz requisições assíncronas para obter dados do controle diário.
 * 
 * @component
 * @returns {JSX.Element} - Retorna o JSX do componente de Check-In.
 * 
 * @example
 * // Exemplo de uso:
 * <CheckIn />
 */
const CheckIn = () => {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Check-In',
        description: 'Realize o check-in diário para iniciar suas atividades.',
    });
    const { user } = useAuth();
    const { data } = useParams();

    // Data
    const [dataMoment, setDataMoment] = useState(moment(data));
    const dataFormatada = useMemo(() => dataMoment.format('YYYY-MM-DD'), [dataMoment]);

    // Dados
    const [pacienteOptSelecionado, setPacienteOptSelecionado] = useState(null);
    const [controleDiarioId, setControleDiarioId] = useState(null);

    // Modais
    const [modalCheckAberta, setModalCheckAberta] = useState(false);

    // Refs
    const controleDeExecucaoRef = useRef(null);
    //#endregion=
    //#region FUNCOES
    /**
     * Função assíncrona para obter o ID do controle diário.
     * 
     * @param {string|null} [data=null] - Data para filtrar o controle diário. Se não fornecida, será usada a data formatada.
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando a requisição é concluída.
     */
    const getControleDiarioId = async (data = null) => {
        let dataTeste = data === null ? dataFormatada : data;
        const url = `/api/controle-diario?filter[dia]=${dataTeste}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento`;
        await api.get(url)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario?.length < 1) {
                    return false;
                }
                controleDiario = controleDiario[controleDiario?.length - 1];
                setControleDiarioId(controleDiario?.id);
            })
            .catch(e => {
                console.error(e);
            });
    };
    /**
     * Define o paciente selecionado e abre o modal de controle de execução.
     *
     * @param {Object} paciente - O objeto do paciente selecionado.
     * @param {string} paciente.value - O valor do paciente selecionado.
     * @returns {void}
     */
    const setPaciente = (paciente) => {
        if (!controleDiarioId) {
            Swal.fire({
                title: 'Erro',
                text: 'Não foi possível encontrar o controle diário para hoje.',
                icon: 'error',
            });
            return;
        }
        setModalCheckAberta(true);
        setPacienteOptSelecionado(paciente);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (data) {
            setDataMoment(moment(data));
        }
    }, [data]);
    useEffect(() => {
        if (dataMoment && dataFormatada) {
            getControleDiarioId();
        }
    }, [dataMoment]);
    useEffect(() => {
        if (modalCheckAberta) {
            controleDeExecucaoRef.current.openModal(controleDiarioId, pacienteOptSelecionado.value);
        }
    }, [modalCheckAberta]);
    //#endregion
    //#region HTML
    return (
        <div className='m-0 p-0'>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                        <span className='texto-data-controle-diario me-2 ml-2'>Data: <b>{moment().format('DD/MM/YYYY')}</b></span>
                    </div>
                </div>
                <Form>
                    <SelectPaciente
                        label='Paciente:'
                        id='paciente_select'
                        name='paciente'
                        value={pacienteOptSelecionado}
                        setPaciente={setPaciente}
                        isDisabled={modalCheckAberta}
                    />
                </Form>
            </div>
            <br></br>
            <br></br>
            {modalCheckAberta &&
                <ControleDeExecucaoModal ref={controleDeExecucaoRef} user={user} modoIncorporado={true} onClose={() => { setPacienteOptSelecionado([]); setModalCheckAberta(false); }} isCheckIn={true} />
            }
        </div>
    );
    //#endregion
};

export default CheckIn;