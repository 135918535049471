import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import ResponsavelSalaService from '../../../../services/ResponsavelSalaService';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';

export default function TableResponsavelSala({ source, callback }) {
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        ResponsavelSalaService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
      }
    });
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Sala:',
      accessor: 'sala.sala',
    },
    {
      Header: 'Responsavel:',
      accessor: 'terapeutum.nome',
    },
    {
      Header: 'Ações',
      accessor: 'acoes',
      Cell: ({ cell }) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
          <AiOutlineDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22} />
        </div>
      ),
    },
  ], []);

  return (
    <TableDefaultPagination
      source={source}
      columns={columns}
      emptyPhrase="Nenhum responsável encontrado."
      initialState={{ pageIndex: 0, pageSize: 8 }}
      enableFooter={true}
      tableTitle="Responsáveis pela Sala"
      prefix_id="responsavel-sala"
      hoverLineMark={true}
      loading={false}
      infiniteScroll={false}
    />
  );
}
