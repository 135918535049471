import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../../context/auth';
import { FiPower, FiRefreshCw } from 'react-icons/fi';
import NotificacaoService from '../../services/NotificacaoService';
import user_default from 'assets/img_avatar.png';
import { AiOutlineUser, AiOutlineBell, AiOutlineDelete, AiFillBell, AiFillHeart, AiFillFile } from 'react-icons/ai';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/Dropdown';
import platform from 'platform';
import { ChangeCase } from 'services/ChangeCase';
import { BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import WebSocketProvider from 'components/Websocket/websocket.provider.old';

//#region ESTILOS
const NotificacaoLinha = styled.li`
    // Alinhamento
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    // Espaçamento
    margin-top: 0.5rem;

    // Tamanho
    width: 100%;
    height: 50px;

    .pill {
        // Espaçamento
        margin: 0.5rem;
        border-radius: 10px;
        padding: 0.5rem;

        // Tamanho
        width: 50px;
        height: 20px;

        // Texto
        font-size: 0.8rem;
        font-weight: bold;

        // Alinhamento
        display: flex;
        justify-content: center;
        align-items: center;

        // Posicionamento
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-40%, -70%);

        &.pill-v {
            background-color: #cacaca;
            color: #000000;
        }

        &.pill-a {
            background-color: #FF0000;
            color: white;
        }

        &.pill-m {
            background-color: #FFA500;
            color: white;
        }

        &.pill-b {
            background-color: #FFFF00;
            color: black;
        }

        &.pill-n {
            background-color: #37ff00;
            color: black;
        }
    }
`;
//#endregion

const FuncoesUsuario = ({ menu }) => {
    const isMobileView = false;
    //#region VARIAVEIS
    const verifyInMinutes = 6; // 6 minutos
    const verifyInMiliseconds = 1000 * 60 * verifyInMinutes;

    const websocketRef = useRef(null);
    const [socketState, setSocketState] = useState(false);
    const [mensagens, setMensagens] = useState([]);

    const { signOut, user } = useAuth();
    const [abreNotificacoes, setAbreNotificacoes] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacaoPendente, setNotificacaoPendente] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mostrandoOpcoesUsuario, setMostrandoOpcoesUsuario] = useState(false);
    const navigate = useNavigate();
    const isMountedRef = useRef(null);
    //#endregion

    //#region FUNCOES
    const getWindowHeight = () => {
        if (platform.os.family === 'iOS') {
            return window.screen.height;
        }
        return window.innerHeight;
    };
    const windowHeight = useMemo(() => getWindowHeight(), []);


    const abrirNotificacao = (id, titulo, conteudo, tipo, visualizado) => {
        const option = {
            title: titulo,
            html: `<div style="max-height: ${windowHeight - 400}px; overflow-y: auto; text-align: left;">${conteudo}</div>`,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            closeButtonText: 'Fechar',
            showConfirmButton: !visualizado,
            showDenyButton: !visualizado,
            showCloseButton: true,
        };

        Swal.fire(option).then((result) => {
            if (result.isConfirmed) visualizarNotificacao(id);
            else if (result.isDenied) deletarNotificacao(id);
        });
    };

    const visualizarNotificacao = async (id) => {
        setLoading(true);
        await NotificacaoService.visualiza(id).finally(() => getNotificacoes());
    };

    const deletarNotificacao = async (id) => {
        setLoading(true);
        await NotificacaoService.delete(id).finally(() => getNotificacoes());
    };

    const atualizarTitulo = (notificacaoPendente_save) => {
        const splitedFilter = document.title.split(')');
        const filtredTitle = splitedFilter[1] ? splitedFilter[1] : splitedFilter;

        if (notificacaoPendente_save > 0) {
            document.title = `(*) ${filtredTitle}`;
        } else {
            document.title = `${filtredTitle}`;
        }
    };

    const getNotificacoes = async () => {
        setLoading(true);
        if (user?.id) {
            await NotificacaoService.userIndex(user.id, 'sistema').then((response) => {
                if (isMountedRef.current) {
                    let lista_notificacoes = [];
                    let notificacaoPendente_save = 0;

                    response.data.forEach((res) => {
                        if (res && res.visualizado == null) {
                            notificacaoPendente_save++;
                        }
                        let urgenciaBg;
                        const nivel = ChangeCase.toLowerCase(res.nivel_urgencia);
                        switch (nivel) {
                            case 'alta': urgenciaBg = 'bg-vermelho-claro-i'; break;
                            case 'média': urgenciaBg = 'bg-laranja-claro-i'; break;
                            case 'baixa': urgenciaBg = 'bg-amarelo-claro-i'; break;
                            default: urgenciaBg = 'bg-cinza-claro';
                        }
                        const isPendente = res.visualizado == null;
                        lista_notificacoes.push(
                            <NotificacaoLinha key={res.id} className={`ns-dropdown-item d-inline-flex justify-content-between mt-2 border-bottom w-100per ${isPendente ? urgenciaBg : 'bg-cinza-claro'}`}>
                                <span className={`pill pill-${isPendente ? ChangeCase.toLowerCase(res.nivel_urgencia.charAt(0)) : 'v'}`}>
                                    {res.nivel_urgencia}
                                </span>
                                <div className={`ps-2`} onClick={() => abrirNotificacao(res.id, res.titulo, res.conteudo, res.tipo, res.visualizado)}>
                                    <button className='btn-outline'>{res.titulo}</button>
                                </div>
                                <div className='px-2' style={{ borderLeft: '0.5px solid rgb(50,50,50)' }}>
                                    <button onClick={() => deletarNotificacao(res.id)} className='btn-outline'>
                                        <AiOutlineDelete />
                                    </button>
                                </div>
                            </NotificacaoLinha>
                        );
                    });

                    atualizarTitulo(notificacaoPendente_save);
                    setNotificacaoPendente(notificacaoPendente_save);
                    setNotificacoes(lista_notificacoes);
                }
            }).finally(() => {
                if (isMountedRef.current) {
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    };

    const enviarNotificacao = (titulo, opcoes) => {
        try {
            if ('Notification' in window && navigator.userAgent.indexOf('iPhone') === -1 && navigator.userAgent.indexOf('iPad') === -1) {
                if (Notification.permission === 'granted') {
                    const notificacao = new Notification(titulo, opcoes);
                    notificacao.onclick = (event) => { };
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            const notificacao = new Notification(titulo, opcoes);
                            notificacao.onclick = (event) => { };
                        }
                    });
                }
            } else {
                console.warn('Notificações não são suportadas neste navegador.');
            }
        } catch (error) {
            console.error('Erro ao enviar notificação: ', error);
        }
    };

    const checkPendingNotifications = async () => {
        setLoading(true);
        if (user?.id) {
            await NotificacaoService.hasPendingNotifications(user.tipo_user).then((response) => {
                if (response.data.hasPending) {
                    setNotificacaoPendente(1); // Sempre mostrar 1
                    enviarNotificacao('Neuro Sistema', {
                        body: `Você tem notificações pendentes. Clique para vê-las 😁`,
                        icon: process.env.PUBLIC_URL + '/icone-teste-1.png'
                    });
                }
                setLoading(false);
            });
        }
    };
    //#endregion

    //#region HANDLES
    const handleLimpaNotificacoes = async () => {
        await NotificacaoService.deleteAll().finally(() => getNotificacoes());
    };

    const handleOnMessage = (data) => {
        const { action } = data;
        if (action && action === 'notification') {
            setNotificacaoPendente(1);
            enviarNotificacao('Neuro Sistema', {
                body: `Você tem notificações pendentes. Clique para vê-las 😁`,
                icon: process.env.PUBLIC_URL + '/icone-teste-1.png'
            });
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (abreNotificacoes) {
            getNotificacoes();
        }
    }, [abreNotificacoes]);

    useEffect(() => {
        if (user) {
            websocketRef?.current?.setSocketLink(`notifica?user_id=${user.id}`);
            websocketRef?.current?.setConectaComSocket(true);
        }
    }, [user]);
    useEffect(() => { // ON LOAD
        checkPendingNotifications();
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    //#endregion

    //#region RENDER
    return (
        <div className='mr-20px flex-col' style={{ minWidth: '190px' }}>
            <div className='d-flex align-center justify-content-end flex-row'>
                <div className=''>
                    <Dropdown
                        substituicao={true}
                        clickMode={true}
                        buttonOver={true}
                        openUp={isMobileView}
                        button={
                            <div className='p-0 m-0'>
                                <button className={`${abreNotificacoes ? `manual-hover manual-border-remove-s-${isMobileView ? 't' : 'b'} manual-bg-white font-preta ` : 'font-branca '}ns-bell-button p-0 no-box-shadow me-2 pop-light-blue bg-transparente ns-hover-border-transition-manual`}>
                                    {loading
                                        ? <div className='notificacaoPendente'>
                                            <p style={{ marginLeft: '15px', marginTop: '0px' }}> <ClipLoader className='back-azul' color={'white'} size={8} /> </p>
                                            <AiOutlineBell size={26} />
                                        </div>
                                        : notificacaoPendente > 0 ?
                                            <div className='notificacaoPendente'>
                                                <p style={{ marginLeft: '15px', marginTop: '0x' }}></p>
                                                <AiFillBell size={26} />
                                            </div>
                                            :
                                            <AiOutlineBell size={26} />
                                    }
                                </button>
                            </div>
                        }
                        content={
                            <div className='bg-branco' style={{
                                marginTop: isMobileView ? '0px' : '-13px',
                                marginRight: isMobileView ? '15px' : '14px',
                                marginBottom: isMobileView ? '0px' : '0px',
                                width: window.innerWidth < 700 ? '70vw' : '600px',
                                borderRadius: '15px',
                                color: '#000',
                                border: '4px solid #00728C'
                            }}>
                                <p className='ns-dropdown-menu-title w-100 d-flex justify-content-between' style={{ borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}>
                                    Notificações
                                    <span className='d-flex flex-row'>
                                        <button className='btn-outline' onClick={() => getNotificacoes()} style={{ float: 'right', marginRight: '10px' }}>
                                            <FiRefreshCw />
                                        </button>
                                        <button className='btn-outline' onClick={handleLimpaNotificacoes} style={{ float: 'left', marginLeft: '10px' }}>
                                            <BsTrash />
                                        </button>
                                    </span>
                                </p>
                                <ul className='ns-dropdown-list min-h-100px w-100per pb-2'>
                                    {(loading)
                                        ? <ClipLoader className='text-center m-auto' size={20} />
                                        : notificacoes
                                    }
                                </ul>
                            </div>
                        }
                        setMostrando={setAbreNotificacoes}
                    />
                </div>
                <div className=''>
                    <Dropdown
                        substituicao={true}
                        clickMode={true}
                        buttonOver={true}
                        openUp={isMobileView}
                        button={
                            <div style={{ margin: 0, padding: 0 }}>
                                <img className={`perfil-image btn-sair no-box-shadow pop-light-blue ns-hover-border-transition-manual${mostrandoOpcoesUsuario ? ` manual-hover manual-border-remove-s-${isMobileView ? 't' : 'b'}` : ''}`} style={{ maxWidth: '50px', maxHeight: '50px' }} src={user?.foto_perfil_full ? user?.foto_perfil_full : user_default} alt='' />
                            </div>
                        }
                        content={
                            <div className='bg-branco' style={{
                                marginTop: isMobileView ? '0px' : '-23px',
                                marginRight: isMobileView ? '8px' : '10px',
                                marginBottom: isMobileView ? '2px' : '0px',
                                width: '200px',
                                borderRadius: '15px',
                                color: '#000',
                                border: '4px solid #00728C'
                            }}>
                                <p className='ns-dropdown-menu-title ns-abrevia-texto' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
                                    {user?.name}
                                </p>
                                <ul className='ns-dropdown-list'>
                                    <li><button className='ns-dropdown-item' onClick={() => navigate('/dashboard/perfil')}><AiOutlineUser /> Perfil</button></li>
                                    <li><button className='ns-dropdown-item ns-dropdown-item-disabled'><AiFillFile /> Documentos</button></li>
                                    <li><button className='ns-dropdown-item ns-dropdown-item-disabled'><AiFillHeart /> Favoritos</button></li>
                                    <li><hr className='ns-dropdown-item-divider'></hr></li>
                                    <li> <button className='ns-dropdown-item' onClick={signOut}> <FiPower /> Sair </button> </li>
                                </ul>
                            </div>
                        }
                        setMostrando={setMostrandoOpcoesUsuario}
                    />
                </div>
            </div>
            <div className='d-flex align-center justify-center'>
                {menu}
            </div>
            <WebSocketProvider
                ref={websocketRef}
                liveBadgeOptions={{
                    mostra: false,
                    temTexto: false,
                    tooltip: 'Conexão com o Chat',
                }}
                onOpen={(openState) => setSocketState(openState)}
                onClose={(openState) => setSocketState(openState)}
                onMessage={handleOnMessage}
            />
        </div>
    );
    //#endregion
};

export default FuncoesUsuario;