import LiveBadge from 'components/LiveBadge';
import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { wsBaseUrl } from 'services/baseUrl';

/**
 * Provedor de WebSocket que gerencia a conexão e comunicação com um servidor WebSocket.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {Function} [props.onMessage] - Função chamada quando uma mensagem é recebida.
 * @param {Function} [props.onOpen] - Função chamada quando a conexão é aberta.
 * @param {Function} [props.onClose] - Função chamada quando a conexão é fechada.
 * @param {boolean} [props.hasLiveBadge=true] - Indica se o LiveBadge deve ser exibido.
 * @param {boolean} [props.reconnectWhenClose=false] - Indica se deve reconectar automaticamente quando a conexão for fechada.
 * @param {Object} [props.liveBadgeOptions] - Opções para o componente LiveBadge.
 * @param {boolean} [props.liveBadgeOptions.sincronizado=true] - Indica se o LiveBadge está sincronizado.
 * @param {boolean} [props.liveBadgeOptions.temTexto=true] - Indica se o LiveBadge deve exibir texto.
 * @param {Array} [props.liveBadgeOptions.texto=['Sincronizado', 'Dessincronizado']] - Texto a ser exibido no LiveBadge.
 * @param {string|null} [props.liveBadgeOptions.tooltip=null] - Tooltip a ser exibido no LiveBadge.
 * @param {string} [props.liveBadgeOptions.posicao='fixada'] - Posição do LiveBadge.
 * @param {boolean} [props.liveBadgeOptions.mostra=true] - Indica se o LiveBadge deve ser mostrado.
 * @param {React.ReactNode} props.children - Elementos filhos a serem renderizados dentro do provedor.
 * @param {React.Ref} ref - Referência para o componente.
 *
 * @example
 * <WebSocketProvider
 *   onMessage={(data) => console.log('Mensagem recebida:', data)}
 *   onOpen={() => console.log('Conexão aberta')}
 *   onClose={() => console.log('Conexão fechada')}
 *   hasLiveBadge={true}
 *   reconnectWhenClose={true}
 *   liveBadgeOptions={{
 *     sincronizado: true,
 *     temTexto: true,
 *     texto: ['Sincronizado', 'Dessincronizado'],
 *     tooltip: 'Status da conexão',
 *     posicao: 'fixada',
 *     mostra: true
 *   }}
 * >
 *   <YourComponent />
 * </WebSocketProvider>
 */
const WebSocketProvider = forwardRef(({
    onMessage = (data) => { },
    onOpen = (openState) => { },
    onClose = (openState) => { },
    hasLiveBadge = true,
    reconnectWhenClose = false,
    liveBadgeOptions = {
        sincronizado: true,
        temTexto: true,
        texto: ['Sincronizado', 'Dessincronizado'],
        tooltip: null,
        posicao: 'fixada',
        mostra: true
    },
    children,
    ...props
}, ref) => {
    //#region VARIAVEIS
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const [socketLink, setSocketLink] = useState(null);
    const [conectaComSocket, setConectaComSocket] = useState(false);

    const urlWS = useMemo(() => `${wsBaseUrl}/${socketLink}`, [socketLink]);
    //#endregion

    //#region FUNCOES
    /**
     * Envia uma mensagem através do socket.
     *
     * @param {any} msg - A mensagem a ser enviada. Se não for fornecida, será usada a mensagem armazenada em `newMessage`.
     */
    const sendMessage = useCallback((msg) => {
        const localNewMessage = msg || newMessage;
        if (socket && socket.readyState === WebSocket.OPEN && localNewMessage !== '' && localNewMessage !== null) {
            socket.send(JSON.stringify(localNewMessage));
            setNewMessage(null);
        }
    }, [socket, newMessage, setNewMessage]);
    //#endregion

    //#region HANDLES
    /**
     * Manipula a mensagem recebida do WebSocket.
     *
     * @param {Object} event - O evento de mensagem do WebSocket.
     * @param {Object} event.data - Os dados da mensagem recebida.
     */
    const handleMessage = useCallback((event) => {
        const data = JSON.parse(event.data);

        switch (data.action) {
            case 'ping':
                sendMessage({ action: 'pong' });
                break;
            case 'request_user_data':
                sendMessage({ action: 'user_data', data: { userId: 'user123', token: 'token123' } });
                break;
            default:
                if (data !== null) {
                    setMessages((prevMessages) => [...prevMessages, data]);
                    onMessage(data);
                }
                break;
        }
    }, [sendMessage, onMessage]);

    /**
     * Função que retorna a instância atual do socket.
     * 
     * @returns {Object} A instância atual do socket.
     */
    const handleGetSocket = useCallback(() => {
        return socket;
    }, [socket]);

    /**
     * Função de callback que é chamada quando a conexão WebSocket é aberta.
     * Define o estado do socket como verdadeiro e chama a função `onOpen` se ela estiver definida.
     *
     * @callback handleOpen
     */
    const handleOpen = useCallback(() => {
        setSocketState(true);
        if (onOpen) {
            onOpen(true);
        }
        // Enviar mensagem com dados do usuário após a conexão
        sendMessage({ action: 'user_data', data: { userId: 'user123', token: 'token123' } });
    }, [onOpen, sendMessage]);

    /**
     * Função de callback para lidar com o fechamento do WebSocket.
     * Define o estado do socket como falso e tenta reconectar se necessário.
     * Também chama a função onClose, se fornecida.
     *
     * @function handleClose
     * @returns {void}
     */
    const handleClose = useCallback(() => {
        setSocketState(false);
        if (reconnectWhenClose) {
            //handleReconnect();
        }
        if (onClose) {
            onClose(false);
        }
    }, [reconnectWhenClose, onClose, /* handleReconnect */]);

    /**
     * Função que estabelece a conexão com o WebSocket.
     * Utiliza useCallback para memorizar a função e evitar recriações desnecessárias.
     * 
     * @function handleConectaComSocket
     * @returns {void}
     * 
     * @description
     * Se o socket não estiver definido e o socketLink estiver disponível,
     * cria uma nova instância de WebSocket com a URL fornecida (urlWS).
     * Adiciona event listeners para os eventos 'open', 'close' e 'message'.
     * Define o socket criado no estado.
     * 
     * @dependencies
     * - socket: Verifica se o socket já está definido.
     * - socketLink: Verifica se o link do socket está disponível.
     * - urlWS: URL do WebSocket.
     * - handleOpen: Função para lidar com o evento 'open' do WebSocket.
     * - handleClose: Função para lidar com o evento 'close' do WebSocket.
     * - handleMessage: Função para lidar com o evento 'message' do WebSocket.
     */
    const handleConectaComSocket = useCallback(() => {
        if (!socket) {
            if (socketLink) {
                const socketLocal = new WebSocket(urlWS);

                socketLocal.addEventListener('open', handleOpen);

                socketLocal.addEventListener('close', handleClose);

                socketLocal.addEventListener('message', handleMessage);

                setSocket(socketLocal);
            }
        }
    }, [socket, socketLink, urlWS, handleOpen, handleClose, handleMessage]);

    /**
     * Função que tenta reconectar ao socket após um intervalo de 5 segundos.
     * Utiliza o hook useCallback para memorizar a função handleReconnect.
     * 
     * @function handleReconnect
     * @returns {void}
     */
    /* const handleReconnect = useCallback(() => {
        setTimeout(handleConectaComSocket, 5000);
    }, [handleConectaComSocket]); */

    /**
     * Função para desconectar o socket.
     * Fecha a conexão do socket, define o socket como null e atualiza o estado do socket para falso.
     *
     * @function
     * @name handleDisconnect
     * @memberof Websocket
     */
    const handleDisconnect = useCallback(() => {
        if (socket) {
            socket.close();
            setSocket(null);
            setSocketState(false);
        }
    }, [socket]);

    useImperativeHandle(
        ref,
        () => ({ socketState, messages, setSocketLink, setNewMessage, setConectaComSocket, sendMessage, handleDisconnect, handleGetSocket }),
        [socketState, messages, setSocketLink, setNewMessage, setConectaComSocket, sendMessage, handleDisconnect, handleGetSocket]
    );
    //#endregion

    //#region USE EFFECTS
    /**
     * Efeito que lida com a conexão e desconexão do WebSocket.
     * Adiciona event listeners para os eventos 'beforeunload' e 'unload' para desconectar o socket.
     * 
     * @effect
     * @name handleUnload
     * @memberof Websocket
     * @returns {void}
     * 
     * @dependencies
     * - socketState: Estado do socket.
     * - handleDisconnect: Função para desconectar o socket.
     * 
     * @description
     * Se o socket estiver definido, adiciona event listeners para os eventos 'beforeunload' e 'unload'.
     * Quando o evento 'beforeunload' ou 'unload' é acionado, chama a função handleDisconnect.
     * 
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload|WindowEventHandlers.onbeforeunload}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunload|WindowEventHandlers.onunload}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/beforeunload_event|WindowEventHandlers.beforeunload_event}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/unload_event|WindowEventHandlers.unload_event}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers|WindowEventHandlers}
     * 
    **/
    useEffect(() => {
        if (socketState) {
            const handleUnload = () => {
                handleDisconnect();
            };

            window.addEventListener('beforeunload', handleUnload);
            window.addEventListener('unload', handleUnload);

            return () => {
                window.removeEventListener('beforeunload', handleUnload);
                window.removeEventListener('unload', handleUnload);
            };
        }
    }, [socketState, handleDisconnect]);

    /**
     * Efeito que lida com a conexão com o WebSocket.
     * Se `conectaComSocket` for verdadeiro, chama a função `handleConectaComSocket`.
     * 
     * @effect
     * @name handleConectaComSocket
     * @memberof Websocket
     * @returns {void}
     * 
     * @dependencies
     * - conectaComSocket: Indica se deve conectar com o socket.
     * - handleConectaComSocket: Função para conectar com o socket.
     * 
     * @description
     * Se `conectaComSocket` for verdadeiro, chama a função `handleConectaComSocket`.
     * 
     * @see {@link https://reactjs.org/docs/hooks-effect.html|React useEffect}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WebSocket|WebSocket}
     * 
    **/
    useEffect(() => {
        if (conectaComSocket) {
            handleConectaComSocket();
        }
    }, [conectaComSocket, handleConectaComSocket]);

    /**
     * Efeito que lida com a mensagem a ser enviada.
     * Se `newMessage` for diferente de nulo, chama a função `sendMessage`.
     * 
     * @effect
     * @name sendMessage
     * @memberof Websocket
     * @returns {void}
     * 
     * @dependencies
     * - newMessage: Mensagem a ser enviada.
     * - sendMessage: Função para enviar a mensagem.
     * 
     * @description
     * Se `newMessage` for diferente de nulo, chama a função `sendMessage`.
     * 
     * @see {@link https://reactjs.org/docs/hooks-effect.html|React useEffect}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/send|WebSocket.send()}
     * 
    **/
    useEffect(() => {
        if (newMessage === null) return;
        sendMessage();
    }, [newMessage, sendMessage]);
    //#endregion

    //#region RENDER
    return (
        <>
            {children}
            <LiveBadge sincronizado={socketState} {...liveBadgeOptions} />
        </>
    );
    //#endregion
});

export default WebSocketProvider;