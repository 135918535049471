import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { useAuth } from '../../../context/auth';
import { getLegendaAgendamento } from '../../../helpers/agenda.helper';
import api from '../../../services/api';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import LegendaTerapeuta from './Modal/ModalLegendas';
import { CenterDivStyle, Container, ContainerSub, FormField, LegendaStyle } from './styles';
import { usePageBase } from 'context/page-base';
import { BsQuestion } from 'react-icons/bs';
import { useSnackbar } from 'context/snackbar';
import Swal from 'sweetalert2';

const diasDaSemanaArray = [
    {
        value: 1,
        label: 'Segunda'
    },
    {
        value: 2,
        label: 'Terça'
    },
    {
        value: 3,
        label: 'Quarta'
    },
    {
        value: 4,
        label: 'Quinta'
    },
    {
        value: 5,
        label: 'Sexta'
    },
];

function TerapeutaAgenda() {
    usePageBase({
        title: 'Agenda Semanal',
        description: 'Visualize a agenda semanal de atendimentos.',
        hasTitle: true,
        hasSubmenu: false,
        routes: [
            'dashboard/terapeuta/agenda',
        ]
    });
    const [modalLegenda, setModalLegenda] = useState(false);
    const [agendamentos, setAgendamentos] = useState([]);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);

    const [terapeuta, setTerapeuta] = useState({});
    const [atendimentoId, setAtendimentoId] = useState();
    const [horarios, setHorarios] = useState([]);

    const diaSemanaIndex = moment().weekday();
    const { user } = useAuth();
    const snackbar = useSnackbar();

    // Estilos
    const styles = useMemo(() => ({
        selected: {
            backgroundColor: ' #00B19D',
            color: '#fff',
        },
        th: {
            //Posição
            position: 'sticky',
            top: 0,
            zIndex: 2,
            //Estilos
            backgroundColor: ' #EAEAEA',
            color: '#9586AC',
            boxShadow: '0 0 3px 1px silver',
            borderRadius: 10,
            borderColor: '#F5F7FA',
            borderStyle: 'solid',
            //Tamanho
            height: 50,
            minWidth: 100,
            //Alinhamento
            padding: 5,
            textAlign: 'center',
        },
        thHora: {
            position: 'sticky',
            top: 0,
            zIndex: 2,

            backgroundColor: ' #EAEAEA',
            color: '#9586AC',
            boxShadow: '0 0 3px 1px silver',
            borderRadius: 10,
            borderColor: '#F5F7FA',
            borderStyle: 'solid',

            height: 50,
            minWidth: 80,

            padding: 5,
            textAlign: 'center',
        },
        tdHora: {
            position: 'sticky',
            left: 0,
            zIndex: 1,

            width: 80,
            height: 80,

            backgroundColor: '#DFCDF8',
            color: '#9586AC',
            boxShadow: '0 0 2px 1px silver',
            borderRadius: 10,
            borderColor: '#F5F7FA',
            borderStyle: 'solid',

            padding: 5,
            textAlign: 'center',
        },
        tdVazio: {
            backgroundColor: '#fff',
            padding: 5,
            width: 100,
            height: 80,
            boxShadow: '0 0 3px 1px silver',
            borderRadius: 10,
            borderColor: '#F5F7FA',
            borderStyle: 'solid',
        },
        tdOcupado: {
            backgroundColor: '#35D058',
            color: '#fff',
            padding: 0,
            width: 100,
            boxShadow: '0 0 3px 1px silver',
            height: 80,
            borderRadius: 10,
            borderColor: '#F5F7FA',
            borderStyle: 'solid',
            cursor: 'pointer',
        },
        presencial: { backgroundColor: '#35D058' },
        online: { backgroundColor: '#3B9E52' },
        domiciliar: { backgroundColor: '#075C1A' },
        conflitoTerapeuta: { backgroundColor: '#E98232' },
        conflitoPaciente: { backgroundColor: '#F94181' },
        avaliacao: { backgroundColor: '#EED10F' },
        reposicao: { backgroundColor: '#C901E8' },
        reserva: { backgroundColor: '#2781DA' },
        substituicao: { backgroundColor: '#e16ff2' },
        reposicao_feita: { backgroundColor: '#afafaf' },
    }), [diaSemanaIndex]);

    const getAgendaTerapeuta = (teraData) => {
        api.get(`/api/agendamento?with=estacao,modalidade&filter[terapeuta_id]=${teraData.id}`)
            .then(({ data }) => {
                data = data.map(atendimento => {
                    atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                    let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                    atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                    return atendimento;
                });
                setAgendamentos(data);
            })
            .catch(error => {
                if (user.email === 'carlos@neurointensiva.com') {
                    Swal.fire({
                        title: 'Erro ao carregar dados do terapeuta.',
                        text: 'O terapeuta não foi encontrado:' + error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
                console.error('Error fetching agenda terapeuta:', error);
                snackbar.displayMessage('Erro ao carregar agenda do terapeuta.', 'error');
            });
    };

    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1')
            .then(({ data }) => {
                setHorarios(data);
            })
            .catch(error => {
                if (user.email === 'carlos@neurointensiva.com') {
                    Swal.fire({
                        title: 'Erro ao carregar dados do terapeuta.',
                        text: 'O terapeuta não foi encontrado:' + error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
                console.error('Error fetching agenda horarios:', error);
                snackbar.displayMessage('Erro ao carregar horários.', 'error');
            });
    };

    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };

    const getAgendaEHorarios = () => {
        api.get('/api/agendamento/getByTerapeutaId/' + user.terapeuta[0].id)
            .then(({ data }) => {
                const { agendamentos, horarios } = data;
                setAgendamentos(agendamentos);
                setHorarios(horarios);
            })
            .catch(error => {
                if (user.email === 'carlos@neurointensiva.com') {
                    Swal.fire({
                        title: 'Erro ao carregar agenda e horarios.',
                        text: 'O terapeuta não foi encontrado:' + error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
                console.error('Error fetching agenda e horarios:', error);
                snackbar.displayMessage('Erro ao carregar agenda e horários.', 'error');
            });
    };

    const carregaTerapeuta = () => {
        api.get('api/terapeuta/dados/add/' + user.terapeuta[0].id)
            .then(async (response) => {
                let terapias_disponiveis;
                await api.get('api/terapeuta-terapia?with=terapium&filter[terapeuta_id]=' + user.terapeuta[0].id)
                    .then(({ data }) => {
                        terapias_disponiveis = data;
                    })
                    .catch(error => console.error('Error fetching terapeuta terapias:', error));
                if (terapias_disponiveis !== undefined && terapias_disponiveis !== null) {
                    terapias_disponiveis = terapias_disponiveis.map((value) => {
                        return value.terapium.terapia;
                    });
                }
                let obj = { ...user.terapeuta[0], ...response.data[0], terapias_disponiveis };
                setTerapeuta(obj);
            })
            .catch(error => {
                if (user.email === 'carlos@neurointensiva.com') {
                    Swal.fire({
                        title: 'Erro ao carregar dados do terapeuta.',
                        text: 'O terapeuta não foi encontrado:' + error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
                console.error('Error fetching data:', error);
                snackbar.displayMessage('Erro ao carregar dados do terapeuta.', 'error');
            });
    };

    useEffect(() => {
        carregaTerapeuta();
        setTerapeuta(user.terapeuta[0]);
        getAgendaEHorarios();
    }, []);

    return (
        <React.Fragment>
            <Container>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <button onClick={e => setModalLegenda(true)} style={LegendaStyle}>
                        <BsQuestion size={30} /> Legenda
                    </button>
                </div>
                <div className='overflow-auto' style={{ maxWidth: '100vw !important', maxHeight: '70vh', }}>
                    <table style={{ overflow: 'visible' }}>
                        <thead>
                            <tr>
                                <th style={styles.thHora}>Horário</th>
                                {diasDaSemanaArray.map(dia => (
                                    <th key={dia.value} style={{
                                        ...styles.th,
                                        ...diaSemanaIndex === dia.value ? styles.selected : {}
                                    }}>{dia.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {horarios.map((horario, horarioIdx) => {
                                const horasExtenso = horario.horario;
                                return (
                                    <tr key={`linha-horario-${horarioIdx}`}>
                                        <td style={styles.tdHora}>{horasExtenso.slice(0, 5)}</td>
                                        {diasDaSemanaArray.map(dia => {
                                            let atendimentoEncontrado = agendamentos.filter(atendimento => atendimento.dia_semana === dia.value && atendimento.horario === horasExtenso);
                                            if (atendimentoEncontrado.length > 0) {
                                                atendimentoEncontrado = atendimentoEncontrado[0];
                                                return (
                                                    <td onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                        style={{ ...styles.tdOcupado, ...styles[getLegendaAgendamento(atendimentoEncontrado, agendamentos)] }}>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                flexDirection: 'row',
                                                            }}>
                                                            Sala {atendimentoEncontrado.sala}<br></br>
                                                            Estação {atendimentoEncontrado.estacao}<br></br>
                                                            {atendimentoEncontrado.paciente_identificador}
                                                        </span>
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td style={styles.tdVazio} key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {/* 
                            <div className='d-flex flex-column align-items-center justify-content-center col-sm-12 col-lg-3'>
                                <div className='d-flex flex-lg-column flex-sm-row p-4 bg-white rounded default-box'>
                                    <div className='col-sm-6 col-lg-12 d-flex justify-content-center pe-3 align-center'>
                                        <img src={user.terapeuta[0].foto_perfil_full === null ? user : user.terapeuta[0].foto_perfil_full} height={150} width={150} className='perfil-image' alt='Foto de Perfil.' />
                                    </div>
                                    <div className='col-sm-12 col-sm-6 d-flex flex-col'>
                                        <span>
                                            <label style={{ color: '#707070', fontWeight: 'bold' }}>
                                                Nome:
                                            </label> {terapeuta.nome}
                                        </span>
                                        <span>
                                            <label style={{ color: '#707070', fontWeight: 'bold' }}>
                                                Terapias:
                                            </label>
                                            {(terapeuta.terapias_disponiveis !== null && terapeuta.terapias_disponiveis && terapeuta.terapias_disponiveis.length > 0) &&
                                                <ul>
                                                    {terapeuta.terapias_disponiveis.map((value) => {
                                                        return <li>{value}</li>;
                                                    })}
                                                </ul>
                                            }
                                        </span>
                                        <span>
                                            <label style={{ color: '#707070', fontWeight: 'bold' }}>
                                                Faltas:
                                            </label> {terapeuta.faltas90}
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                </div>
                {isModalDetalhesDoAtendimentoVisible
                    ? <DetalhesDoAgendamento agendamentoId={atendimentoId} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                    : (<></>)
                }
                {modalLegenda && <LegendaTerapeuta onClose={e => setModalLegenda(false)} />}
            </Container>
        </React.Fragment>
    );
}
export default TerapeutaAgenda;