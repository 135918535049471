import React, { useState, useEffect, useCallback } from 'react';
import RelatorioBase from 'context/relatorio';
import useEspecialidadesData from 'context/especialidade';
import usePacienteData from 'context/paciente';
import usePlanoSaudeData from 'context/plano-saude';
import useTerapiasData from 'context/terapia';
import { usePageBase } from 'context/page-base';

const RelatoriosPacientes = () => {
    usePageBase({
        title: 'Relatório de Pacientes',
        description: 'Tela onde é possível visualizar os pacientes cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/pacientes'
        ]
    });

    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const { pacientes: pacientesOriginal, fetchPacientesFiltrados } = usePacienteData();
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const statusOptions = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Ferias', label: 'Ferias' },
    ];
    const columns = [
        'nome',
        'email',
        'cpf',
        'status',
        'responsaveis',
        'terapias',
        'especialidades',
        'planos_saude'
    ];
    const columnsAndTypes = [
        { id: 'nome', label: 'Nome', type: 'text' },
        { id: 'email', label: 'Email', type: 'text' },
        { id: 'cpf', label: 'CPF', type: 'text' },
        { id: 'status', label: 'Status', type: 'text' },
        { id: 'responsaveis', label: 'Responsáveis', type: 'text' },
        { id: 'terapias', label: 'Terapias', type: 'text' },
        { id: 'especialidades', label: 'Especialidades', type: 'text' },
        { id: 'planos_saude', label: 'Planos de Saúde', type: 'text' },
    ];

    const [filters, setFilters] = useState(null);

    const fetchData = useCallback(async () => {
        const response = await fetchPacientesFiltrados(filters.reduce((acc, filter) => ({ ...acc, [filter.id]: filter.value }), {}));
        return response;
    }, [filters]);

    useEffect(() => {
        if (especialidadesOptions && terapiasOptions && planoSaudeOptions) {
            setFilters([
                { id: 'nome', label: 'Nome', value: '', type: 'text' },
                { id: 'email', label: 'Email', value: '', type: 'text' },
                { id: 'cpf', label: 'CPF', value: '', type: 'text' },
                { id: 'status', label: 'Status', value: '', type: 'multi_select', options: statusOptions },
                { id: 'especialidade_id', label: 'Especialidade', value: '', type: 'select', options: especialidadesOptions },
                { id: 'terapia_id', label: 'Terapia', value: '', type: 'select', options: terapiasOptions },
                { id: 'plano_saude_id', label: 'Plano de Saúde', value: '', type: 'select', options: planoSaudeOptions },
                { id: 'paciente_id', label: 'Paciente', value: '', type: 'select', options: pacientesOriginal },
            ]);
        }
    }, [especialidadesOptions, terapiasOptions, planoSaudeOptions]);

    useEffect(() => {
        fetchEspecialidades();
        fetchTerapias();
        fetchPlanoSaude();
    }, []);

    return (
        <RelatorioBase
            fetchData={fetchData}
            columns={columns}
            columnsAndTypes={columnsAndTypes}
            filters={filters}
            setFilters={setFilters}
        />
    );
};

export default RelatoriosPacientes;