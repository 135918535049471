import styled from 'styled-components';

export const SizeContainer = styled.div`
    margin: 0;
    padding: 5px 2.5px 0 2.5px;
`;
export const AssinaturaContainer = styled.div`
    font-family: Arial, sans-serif;
    padding: 0;
    padding-bottom: -15px;
    text-align: center;
    border: 1px solid black;
    height: 115px;

    &.terapeuta {
        margin-right: 5px;
    }
`;

export const Data = styled.p`
    margin: 0;
`;

export const AssinaturaImagem = styled.img`
    width: 100%;
    height: auto;
    min-height: 56.25px;
    object-fit: contain;
`;

export const LinhaAssinatura = styled.hr`
    width: 50%;
    margin: 20px auto;
    border-top: 1px solid black;

    &.terapeuta {
        width: 100%;
        margin: 0px auto 20px auto;
    }
`;

export const Nome = styled.p`
    margin-top: -18px;
    font-weight: bold;
    font-size: 10px;

    &.terapeuta {
        padding: 0 5px;
    }
`;
