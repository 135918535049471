import { useAuth } from 'context/auth';
import React, { useEffect, useRef, useState } from 'react';
import SimplePeer from 'simple-peer';

const AudioCall = () => {
  const { user } = useAuth();
  const [isCalling, setIsCalling] = useState(false);
  const [isReceivingCall, setIsReceivingCall] = useState(false);
  const [callerSignal, setCallerSignal] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const socketRef = useRef();
  const peerRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    socketRef.current = new WebSocket('ws://localhost:8008');

    socketRef.current.onopen = () => {
      socketRef.current.send(JSON.stringify({ type: 'login', name: user.name }));
    };

    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'userList':
          setUsers(data.users);
          break;
        case 'callUser':
          setIsReceivingCall(true);
          setCallerSignal(data.signal);
          break;
        default:
          break;
      }
    };

    return () => {
      socketRef.current.close();
    };
  }, [user.name]);

  const callUser = (name) => {
    setIsCalling(true);
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      audioRef.current.srcObject = stream;

      peerRef.current = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: stream
      });

      peerRef.current.on('signal', data => {
        socketRef.current.send(JSON.stringify({ type: 'callUser', signal: data, name }));
      });

      peerRef.current.on('stream', stream => {
        audioRef.current.srcObject = stream;
      });
    });
  };

  const acceptCall = () => {
    setIsReceivingCall(false);
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      audioRef.current.srcObject = stream;

      peerRef.current = new SimplePeer({
        initiator: false,
        trickle: false,
        stream: stream
      });

      peerRef.current.on('signal', data => {
        socketRef.current.send(JSON.stringify({ type: 'acceptCall', signal: data, name: user.name }));
      });

      peerRef.current.signal(callerSignal);

      peerRef.current.on('stream', stream => {
        audioRef.current.srcObject = stream;
      });
    });
  };

  return (
    <div>
      <h2>Usuários Conectados</h2>
      <ul>
        {users.map((user, index) => (
          <li key={index}>
            {user} <button onClick={() => callUser(user)}>Chamar</button>
          </li>
        ))}
      </ul>
      {isCalling && <p>Chamando...</p>}
      {isReceivingCall && (
        <div>
          <p>Recebendo chamada...</p>
          <button onClick={acceptCall}>Aceitar</button>
        </div>
      )}
      <audio ref={audioRef} autoPlay />
    </div>
  );
};

export default AudioCall;