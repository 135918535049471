import { useState } from 'react';
import api from 'services/api';

const useUsuariosData = () => {
    const [usuariosOptions, setUsuariosOptions] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const mapUsuariosData = (data) => {
        return data.map((terapeuta) => ({
            value: terapeuta.id,
            label: terapeuta.nome
        }));
    };

    const fetchUsuariosData = async () => {
        try {
            const { data } = await api.get('api/user?select=id,nome');
            const options = mapUsuariosData(data);
            setUsuariosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar usuarios:', error);
        }
    };

    const fetchUsuariosFiltradosData = async (filtro) => {
        try {
            const { data } = await api.post(`api/user/relatorio/filtrado`, filtro);
            setUsuarios(data);
            return data;
        } catch (error) {
            console.error('Erro ao carregar usuarios:', error);
        }
    }

    return { usuariosOptions, usuarios, fetchUsuariosData, mapUsuariosData, fetchUsuariosFiltradosData };
};

export default useUsuariosData;