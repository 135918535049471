import React, { useState } from 'react';
import { Container, FormConexao, UrlDisplay, BotaoAdicionaParametro, DisplaySocketState, Mensagens, BotaoAdicionarConexao } from './estilos';
import { useAuth } from 'context/auth';
import WebSocketProvider from 'components/Websocket/websocket.provider.old';
import { CgAdd, CgRemove } from 'react-icons/cg';

const TesteWebsocket = () => {
    const { user } = useAuth();
    const [conexoes, setConexoes] = useState([]);
    const [novaConexao, setNovaConexao] = useState({ canal: '', plus: '', mensagens: [], novaMensagem: '', socketState: false });
    const [parametros, setParametros] = useState([
        {
            nome_parametro: 'user_id',
            parametro: '',
        }
    ]);
    const [parametrosMensagem, setParametrosMensagem] = useState([{ nome_parametro_msg: '', parametro_msg: '' }]);

    const handleOnMessage = (index, data) => {
        const novasConexoes = [...conexoes];
        novasConexoes[index].mensagens.push(data);
        setConexoes(novasConexoes);
    };

    const handleConectar = (index) => {
        const novasConexoes = [...conexoes];
        const url = `${novasConexoes[index].canal}${parametros[index]?.map(map=>{
            if (map.parametro) {
                return `&${map.nome_parametro}=${map.parametro}`;
            }
            return '';
        })}${novasConexoes[index].plus}`;
        novasConexoes[index].websocketRef.current.setSocketLink(url);
        novasConexoes[index].websocketRef.current.setConectaComSocket(true);
        setConexoes(novasConexoes);
    };

    const handleDesconectar = (index) => {
        const novasConexoes = [...conexoes];
        novasConexoes[index].websocketRef.current.setConectaComSocket(false);
        novasConexoes[index].websocketRef.current.handleDisconnect(false);
        setConexoes(novasConexoes);
    };

    const handleEnviarMensagem = (index) => {
        const novasConexoes = [...conexoes];
        const mensagem = parametrosMensagem.reduce((msg, param) => {
            msg[param.nome_parametro_msg] = param.parametro_msg;
            return msg;
        }, {});
        if (Object.keys(mensagem).length) {
            novasConexoes[index].websocketRef.current.sendMessage(mensagem);
            setParametrosMensagem([{ nome_parametro_msg: '', parametro_msg: '' }]);
            setConexoes(novasConexoes);
        }
    };

    const adicionarConexao = () => {
        setConexoes([...conexoes, { ...novaConexao, websocketRef: { current: null } }]);
        setNovaConexao({ canal: '', plus: '', mensagens: [], novaMensagem: '', socketState: false });
    };

    const adicionarParametroMensagem = () => {
        setParametrosMensagem([...parametrosMensagem, { nome_parametro_msg: '', parametro_msg: '' }]);
    };

    return (
        <Container>
            <BotaoAdicionarConexao type="button" className='w-300px-i' onClick={adicionarConexao}>Adicionar Conexão</BotaoAdicionarConexao>
            {conexoes.map((conexao, index) => (
                <fieldset key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                    <WebSocketProvider
                        ref={conexao.websocketRef}
                        liveBadgeOptions={{
                            mostra: true,
                            temTexto: true,
                            tooltip: 'Conexão com o Chat',
                        }}
                        onOpen={(openState) => {
                            const novasConexoes = [...conexoes];
                            novasConexoes[index].socketState = openState;
                            setConexoes(novasConexoes);
                        }}
                        onClose={(openState) => {
                            const novasConexoes = [...conexoes];
                            novasConexoes[index].socketState = openState;
                            setConexoes(novasConexoes);
                        }}
                        onMessage={(data) => handleOnMessage(index, data)}
                    />

                    <UrlDisplay>
                        {`${conexao.canal}${parametros.map((param, index) => {
                            if (param.parametro) {
                                return `${index === 0 ? '?' : '&'}${param.nome_parametro}=${param.parametro}`;
                            }
                            return '';
                        })}`}
                    </UrlDisplay>

                    <FormConexao>
                        <input
                            type="text"
                            name="canal"
                            id="canal"
                            placeholder="Digite o canal"
                            value={conexao.canal}
                            onChange={(e) => {
                                const novasConexoes = [...conexoes];
                                novasConexoes[index].canal = e.target.value;
                                setConexoes(novasConexoes);
                            }}
                        />
                        <BotaoAdicionaParametro className='w-300px-i' type={'button'} onClick={() => setParametros([...parametros, {
                            nome_parametro: '',
                            parametro: '',
                        }])}>
                            <CgAdd /> Parâmetro
                        </BotaoAdicionaParametro>
                        {parametros && parametros.map((parametro, parametroIndex) => (
                            <div key={parametroIndex}>
                                <input
                                    type="text"
                                    placeholder="Nome do parâmetro"
                                    value={parametro.nome_parametro}
                                    onChange={(e) => {
                                        const novosParametros = [...parametros];
                                        novosParametros[parametroIndex].nome_parametro = e.target.value;
                                        setParametros(novosParametros);
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Valor do parâmetro"
                                    value={parametro.parametro}
                                    onChange={(e) => {
                                        const novosParametros = [...parametros];
                                        novosParametros[parametroIndex].parametro = e.target.value;
                                        setParametros(novosParametros);
                                    }}
                                />
                                <BotaoAdicionaParametro type={'button'} onClick={() => {
                                    const novosParametros = [...parametros];
                                    novosParametros.splice(parametroIndex, 1);
                                    setParametros(novosParametros);
                                }}>
                                    <CgRemove />
                                </BotaoAdicionaParametro>
                            </div>
                        ))}
                        <div className='d-flex flex-row gap-10'>
                            <button type="button" onClick={() => handleConectar(index)}>Conectar</button>
                            <button type="button" onClick={() => handleDesconectar(index)}>Desconectar</button>
                        </div>
                        <hr className='w-100'></hr>
                        <button type="button" onClick={adicionarParametroMensagem}><CgAdd /> Parâmetro de Mensagem</button>
                        {parametrosMensagem.map((param, paramIndex) => (
                            <div key={paramIndex}>
                                <input
                                    type="text"
                                    placeholder="Nome do parâmetro"
                                    value={param.nome_parametro_msg}
                                    onChange={(e) => {
                                        const novosParametros = [...parametrosMensagem];
                                        novosParametros[paramIndex].nome_parametro_msg = e.target.value;
                                        setParametrosMensagem(novosParametros);
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Valor do parâmetro"
                                    value={param.parametro_msg}
                                    onChange={(e) => {
                                        const novosParametros = [...parametrosMensagem];
                                        novosParametros[paramIndex].parametro_msg = e.target.value;
                                        setParametrosMensagem(novosParametros);
                                    }}
                                />
                                <BotaoAdicionaParametro type={'button'} onClick={() => {
                                    const novosParametros = [...parametrosMensagem];
                                    novosParametros.splice(paramIndex, 1);
                                    setParametrosMensagem(novosParametros);
                                }}>
                                    <CgRemove />
                                </BotaoAdicionaParametro>
                            </div>
                        ))}
                        <button type="button" onClick={() => handleEnviarMensagem(index)}>Enviar</button>
                    </FormConexao>

                    <DisplaySocketState>
                        {conexao.socketState ? <p>Conectado</p> : <p>Desconectado</p>}
                    </DisplaySocketState>

                    <Mensagens>
                        {conexao.mensagens.map((msg, msgIndex) => (
                            JSON.stringify(msg) !== '{}' ? <div key={msgIndex}><span>{msg.user_id}</span> - {msg.message}</div> : null
                        ))}
                    </Mensagens>
                </fieldset>
            ))}
        </Container>
    );
};

export default TesteWebsocket;