import React, { useEffect, useRef, useState, useCallback, forwardRef, } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import ReactQuill from 'react-quill';

import { Container, Error } from './styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import useIcons from 'context/icons';
import { useSnackbar } from 'context/snackbar';

/**
 * Componente de entrada de dados com suporte a ícones, rótulos e estilos personalizados.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.name - Nome do campo de entrada.
 * @param {string} props.title - Título do campo de entrada.
 * @param {string} props.label - Rótulo do campo de entrada.
 * @param {Object} [props.containerStyle={}] - Estilo personalizado para o contêiner do campo de entrada.
 * @param {Object} [props.upperContainerStyle={}] - Estilo personalizado para o contêiner superior do campo de entrada.
 * @param {string} [props.labelClassName=''] - Classe CSS personalizada para o rótulo do campo de entrada.
 * @param {React.ElementType} [props.icon] - Ícone a ser exibido no campo de entrada.
 * @param {boolean} [props.removeStlyes=false] - Flag para remover estilos padrão do campo de entrada.
 * @param {React.ReactNode} [props.sideContent] - Conteúdo adicional a ser exibido ao lado do campo de entrada.
 * @param {string} [props.upperContainerClassName] - Classe CSS personalizada para o contêiner superior do campo de entrada.
 * @param {string} [props.containerClassName] - Classe CSS personalizada para o contêiner do campo de entrada.
 * @param {boolean} [props.required] - Flag para indicar se o campo de entrada é obrigatório.
 * @param {Object} props.rest - Outras propriedades a serem passadas para o campo de entrada.
 * @param {React.Ref} ref - Referência para o campo de entrada.
 * 
 * @returns {JSX.Element} Componente de entrada de dados.
 */
const Input = forwardRef(({
  name,
  title,
  label,
  containerStyle = {},
  upperContainerStyle = {},
  labelClassName = '',
  icon: Icon,
  removeStlyes = false,
  sideContent,
  upperContainerClassName,
  containerClassName,
  hasClearButton = false,
  required,
  ...rest
}, ref) => {
  //#region VARIAVEIS
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const { iconArray } = useIcons();
  const snackbar = useSnackbar();
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  //#endregion
  //#region ESTILOS
  const sideContainerStyle = sideContent ? { padding: '14px 0px 14px 14px' } : {};
  containerStyle = removeStlyes
    ? {}
    : {
      ...containerStyle,
      ...sideContent ? sideContainerStyle : {},
      marginTop: 0,
      backgroundColor: rest.disabled ? '#ececec' : '#FFF',
    };
  const sideContentStyle = sideContent ? { width: '80%' } : {};
  const styleInput = removeStlyes
    ? {}
    : {
      ...rest.style,
      ...sideContentStyle,
      color: rest.disabled ? '#545454' : '#000',
      borderRadius: '8px'
    };
  //#endregion
  //#region HANDLES
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (rest.disabled) {
      setShowCopyButton(true);
    }
    setShowClearButton(true);
  }, [rest.disabled]);

  const handleMouseLeave = useCallback(() => {
    setShowCopyButton(false);
    setShowClearButton(false);
  }, []);

  const handleCopyContent = useCallback(() => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value);
      snackbar.displayMessage('Conteúdo copiado para a área de transferência!');
    }
  }, []);

  const handleClearContent = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setIsFilled(false);
      rest.onChange && rest.onChange({ target: { value: '' } });
    }
  }, []);
  //#endregion
  //#region USE EFFECT
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  //#endregion
  //#region HTML
  return (
    <div ref={ref} className={upperContainerClassName} style={!upperContainerStyle ? { display: 'flex', flexDirection: 'column', width: '100%' } : upperContainerStyle}>
      {(!rest.noLabel && (title || label)) && <label className={labelClassName} style={{ fontWeight: 'negrito', marginTop: 10, fontSize: 13 }}><b style={{ color: 'orange' }}>{required ? '*' : ''}</b>{title || label}</label>}
      <Container
        className={containerClassName}
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid='input-container'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {Icon && <Icon size={20} />}
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          style={styleInput}
          autoComplete='off'
          required={required}
          {...rest}
        />
        {showCopyButton && (
          <button type={'button'} onClick={handleCopyContent} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            {iconArray['copiar']} {/* Usar o primeiro ícone do array */}
          </button>
        )}
        {hasClearButton && (
          <button type={'button'} onClick={handleClearContent} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            {iconArray['limpar']}
          </button>
        )}
        {sideContent ? sideContent : null}
        {error && (
          <Error title={error}>
            <FiAlertCircle color='#c53030' size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
  //#endregion
});

Input.displayName = 'Input';

const InputPlusButton = ({ name, title, containerStyle = {}, upperContainerStyle = {}, removeStlyes = false, button, sideContent, ...rest }) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const sideContainerStyle = sideContent ? { padding: '14px 0px 14px 14px' } : {};
  containerStyle = removeStlyes
    ? {}
    : {
      ...containerStyle,
      ...sideContent ? sideContainerStyle : {},
      marginTop: 0,
      backgroundColor: rest.disabled ? '#ececec' : '#FFF',
    };
  const sideContentStyle = sideContent ? { width: '80%' } : {};
  const styleInput = removeStlyes
    ? {}
    : {
      ...rest.style,
      ...sideContentStyle,
      paddingLeft: '10px',
      color: rest.disabled ? '#545454' : '#000',
      borderRadius: '8px'
    };

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div style={!upperContainerStyle ? { display: 'flex', flexDirection: 'column', width: '100%' } : upperContainerStyle}>
      {!rest.noLabel && <label style={{ fontWeight: 'negrito', marginTop: 10, fontSize: 13 }}>{title}</label>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        className='input-group p-0 m-0'
        data-testid='input-container'
      >
        <input
          className='position-relative flex-1-1-auto w-1per min-w-0'
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          style={styleInput}
          autoComplete='off'
          {...rest}
        />
        <div className='input-group-text' style={{ margin: '0px', padding: '0px ', height: '40px', borderLeft: 'none' }}>
          {button}
        </div>
        {sideContent ? sideContent : null}
        {error && (
          <Error title={error}>
            <FiAlertCircle color='#c53030' size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
};

function SimpleInput({ name, id, labelClass, labelStyle = { fontWeight: 'negrito', marginTop: '10px', fontSize: '13px' }, containerClass, containerStyle, style = { marginLeft: '15px' }, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    rest.title
      ? <div className={containerClass} style={containerStyle}>
        <label htmlFor={id} className={labelClass} style={labelStyle}>{rest.title}</label>
        <input id={id} ref={inputRef} defaultValue={defaultValue} style={style} autoComplete='off' {...rest} />
      </div>
      : <input id={id} ref={inputRef} defaultValue={defaultValue} style={style} autoComplete='off' {...rest} />
  );
}

function TextAreaInput({ name, title = null, rows = 6, classDiv,
  containerStyle = {
    display: 'flex', flexDirection: 'column', width: '100%'
  },
  labelStyle = {
    fontWeight: 'negrito', marginTop: 10, fontSize: 13
  },
  style = {
    borderRadius: '8px'
  },
  ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className={classDiv} style={containerStyle}>
        {title && <label htmlFor={name} style={labelStyle}>{title}</label>}
        <textarea name={name} ref={inputRef} defaultValue={defaultValue} style={style} rows={rows} {...rest} />
      </div>
    </>
  );
}

function RichTextEditor({ name, title = null, classDiv,
  containerStyle = {
    display: 'grid',
    flexDirection: 'column',
    width: '100%'
  },
  labelStyle = {
    fontWeight: 'negrito',
    marginTop: 10,
    fontSize: 13
  },
  ...rest
}) {
  //#region VARIAVEIS
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  //#endregion

  const modules = {
    toolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }],  // Tamanho da fonte
      ['bold', 'italic', 'underline', 'strike'],        // Estilos de texto
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],         // Indentação
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']                                         // Limpar formatação
    ],
  };

  const formats = [
    'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'indent',
    'align',
    'link', 'image'
  ];

  //#region HTML
  return (
    <div className={classDiv} style={{ ...containerStyle, marginBottom: 40 }}>
      {title && <label htmlFor={name} style={labelStyle}>{title}</label>}
      <ReactQuill
        ref={inputRef}
        defaultValue={defaultValue}
        modules={modules}
        formats={formats}
        {...rest}
      />
    </div>
  );
  //#endregion
}

function InputArquivo({ name, title = 'Anexar Documento', path, handleChange = () => { }, handleDeleteUpload = () => { }, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const UploadIcon = {
    border: 'none',
    borderRadius: 8,
  };

  const LabelUpload = {
    color: '#7340BD',
    cursor: 'pointer'
  };

  const Upload = {
    color: '#7340BD',
    cursor: 'pointer',

    display: 'flex',
    height: 25,
  };

  const Delete = {
    color: '#FFFFFF',
    cursor: 'pointer',
    backgroundColor: '#7340BD',

    height: 30,
    marginLeft: 10,
    borderRadius: 20,
    padding: '2px 8px',
    display: 'flex',
    justifyContent: 'center',
  };

  const InputUpload = {
    borderRadius: 8,
    height: 30,
    display: 'none'
  };

  const ShowStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 20
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div data-testid='input-container'>
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', }}>
        <div style={{ marginLeft: 5, marginRight: 10, }}>
          <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
        </div>
        <div style={{ flexDirection: 'column' }}>
          <label htmlFor={name} style={LabelUpload}>{title}
            <input
              type='file'
              id={name}
              style={InputUpload}
              onChange={handleChange}
              defaultValue={defaultValue}
              ref={inputRef}
              autoComplete='off'
              {...rest}
            />
          </label>
        </div>
      </div>
      <div style={ShowStyle}>
        {Array.isArray(path)
          ? path.map((item, index) => {
            return <span key={`arquivo:${index}`} style={Upload}>{item}
              <div>
                <span style={Delete} onClick={handleDeleteUpload}> x </span>
              </div>
            </span>;
          })
          : path && <span style={Upload}>{path}
            <div>
              <span style={Delete} onClick={handleDeleteUpload}> x </span>
            </div>
          </span>
        }
      </div>

      {error && (
        <Error title={error}>
          <FiAlertCircle color='#c53030' size={20} />
        </Error>
      )}
    </div>
  );
}
export default Input;
export { SimpleInput, TextAreaInput, RichTextEditor, InputArquivo, InputPlusButton };