import { Form } from '@unform/web';
import Button from 'components/Button';
import CarregandoRelatorio from 'components/Carregamento/Relatorio/carregando-relatorio';
import GraficoEmBarra from 'components/Graficos/Barra';
import Torta from 'components/Graficos/Torta';
import Input from 'components/Input';
import ModalBase from 'components/ModalBase';
import Select from 'components/Select';
import { MultiSelect } from 'components/Select/MultiSelect';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import React, { cloneElement, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsPrinter, BsSearch } from 'react-icons/bs';
import { CgExport } from 'react-icons/cg';
import { FaBroom, FaFile, FaFileCsv, FaFilePdf, FaPencilAlt } from 'react-icons/fa';
import { MdDragHandle } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';
import { ClipLoader } from 'react-spinners';
import { ChangeCase } from 'services/ChangeCase';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import useIcons from './icons';
import { useSnackbar } from './snackbar';
import { DraggableItem, EditableInput, TableContainer, Table, RelatorioContainer, Linha, Coluna, FormLinha, FieldsetMinimizavel, SelecionarColunas, LinhaWrap } from './relatorio-styles';

//#region ESTILOS
//#endregion

/**
 * Componente base para exibição e exportação de relatórios.
 *
 * @component
 * @param {Object} props - As propriedades do componente.
 * @param {Function} props.fetchData - Função para buscar os dados do relatório.
 * @param {Array<string>} [props.columns=[]] - Lista de colunas disponíveis para o relatório.
 * @param {Array<Object>} [props.filters=[]] - Lista de filtros aplicáveis ao relatório.
 * @param {Function} props.setFilters - Função para definir os filtros do relatório.
 * @param {ReactNode} props.extraOptions - Opções extras a serem exibidas no relatório.
 * @param {number|null} [props.limiteLinhasPadrao=null] - Limite padrão de linhas por página no relatório.
 * @param {string} [props.nomeRelatorioPadrao='relatorio'] - Nome padrão do relatório.
 * @returns {JSX.Element} O componente de relatório.
 */
const RelatorioBase = ({
    fetchData,
    columns = [],
    columnsSelectedByDefault = false,
    filters = [],
    setFilters,
    extraOptions,
    preExtraOptions,
    limiteLinhasPadrao = null,
    nomeRelatorioPadrao = 'relatorio'
}) => {
    //#region VARIAVEIS
    // Hooks
    const snackbar = useSnackbar();
    const { iconArray } = useIcons();
    // Refs
    const baixandoDocsRef = useRef(null);
    const toExportRef = useRef(null);
    // State
    const [dadosRelatorio, setDadosRelatorio] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(columns.reduce((acc, col) => ({ ...acc, [col]: columnsSelectedByDefault }), {}));
    const [columnOrder, setColumnOrder] = useState(columns);
    const [nomeArquivo, setNomeArquivo] = useState(nomeRelatorioPadrao);
    const [limiteLinhasDocumento, setLimiteLinhasDocumento] = useState(limiteLinhasPadrao);
    const [columnNames, setColumnNames] = useState(columns.reduce((acc, col) => ({ ...acc, [col]: col }), {}));
    const [editingColumn, setEditingColumn] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [colunasVisible, setColunasVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [carregadoDados, setCarregadoDados] = useState(false);
    const [visibleFilters, setVisibleFilters] = useState(true);

    // State para gráficos
    const [graphConfigs, setGraphConfigs] = useState([]);//{ id: Date.now(), type: 'bar', columns: [], data: null, title: '' }
    const graficOptions = [
        { label: 'Barra', value: 'bar' },
        { label: 'Pizza', value: 'pie' },
    ];
    const columnOptions = useMemo(() => columns.map(col => ({ label: col, value: col })), [columns]);

    // Memoized
    const sortedDadosRelatorio = React.useMemo(() => {
        let sortableItems = [...dadosRelatorio];
        if (sortConfig.key !== null && sortConfig.direction !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [dadosRelatorio, sortConfig]);
    //#endregion
    //#region FUNCOES
    /**
     * Função para solicitar a ordenação de uma lista com base em uma chave específica.
     * Alterna a direção da ordenação entre 'ascending' (crescente), 'descending' (decrescente) e null.
     *
     * @param {string} key - A chave pela qual a lista deve ser ordenada.
     */
    const requestSort = React.useCallback((key) => {
        let direction = 'ascending';
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'ascending') {
                direction = 'descending';
            } else if (sortConfig.direction === 'descending') {
                direction = null;
            }
        }
        setSortConfig({ key, direction });
    }, [sortConfig]);
    /**
     * Exporta o conteúdo referenciado para impressão.
     * 
     * Esta função substitui temporariamente o conteúdo do corpo do documento
     * pelo conteúdo referenciado, aciona a impressão e, em seguida, restaura
     * o conteúdo original do corpo do documento.
     * 
     * @function
     */
    const exportToPrint = React.useCallback(() => {
        const printContents = toExportRef.current.outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }, []);
    /**
     * Exporta o conteúdo referenciado como uma imagem PNG.
     * Utiliza a função `toPng` para converter o conteúdo em uma URL de dados de imagem.
     * Cria um link temporário para download da imagem gerada.
     *
     * @function
     * @name exportToImage
     */
    const exportToImage = React.useCallback(() => {
        toPng(toExportRef.current).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `${nomeArquivo}.png`;
            link.href = dataUrl;
            link.click();
        });
    }, [nomeArquivo]);
    /**
     * Exporta os dados do relatório para um arquivo Excel.
     * 
     * Este método cria um novo livro de trabalho Excel e adiciona os dados do relatório a ele.
     * Se o número de linhas exceder o limite especificado, ele cria uma nova página.
     * 
     * @async
     * @function exportToExcel
     * @returns {Promise<void>} Uma promessa que é resolvida quando o arquivo Excel é salvo.
     * 
     * @param {number} [limiteLinhasDocumento] - O número máximo de linhas por página no documento Excel.
     * @param {string} nomeArquivo - O nome base do arquivo Excel a ser salvo.
     * @param {Array<Object>} dadosRelatorio - Os dados do relatório a serem exportados.
     * @param {Array<string>} columnOrder - A ordem das colunas a serem exportadas.
     * @param {Object} selectedColumns - Um objeto que indica quais colunas foram selecionadas para exportação.
     */
    const exportToExcel = React.useCallback(async () => {
        let currentPage = 1;
        let currentRow = 0;
        let wb = XLSX.utils.book_new();
        let wsData = [
            columnOrder.filter(col => selectedColumns[col]).map(col => col.charAt(0).toUpperCase() + col.slice(1))
        ];

        dadosRelatorio.forEach((item, index) => {
            if (limiteLinhasDocumento && limiteLinhasDocumento > 0 && currentRow >= limiteLinhasDocumento) {
                const ws = XLSX.utils.aoa_to_sheet(wsData);
                XLSX.utils.book_append_sheet(wb, ws, `${nomeArquivo} - Página ${currentPage}`);
                XLSX.writeFile(wb, `${nomeArquivo}(${currentPage}).xlsx`);
                wb = XLSX.utils.book_new();
                wsData = [
                    columnOrder.filter(col => selectedColumns[col]).map(col => col.charAt(0).toUpperCase() + col.slice(1))
                ];
                currentPage++;
                currentRow = 0;
            }

            const row = [];
            columnOrder.forEach(col => {
                if (selectedColumns[col]) {
                    row.push(item[col]);
                }
            });
            wsData.push(row);
            currentRow++;
        });

        if (wsData.length > 1) {
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, `${nomeArquivo} (${currentPage})`);
            XLSX.writeFile(wb, `${nomeArquivo}(${currentPage}).xlsx`);
        }
    }, [columnOrder, selectedColumns, dadosRelatorio, limiteLinhasDocumento, nomeArquivo]);
    /**
     * Exporta os dados do relatório para arquivos CSV.
     * 
     * Esta função exporta os dados do relatório em múltiplos arquivos CSV, se necessário,
     * com base no limite de linhas por documento especificado. Cada arquivo CSV gerado
     * será nomeado com um sufixo de página.
     * 
     * @async
     * @function exportToCSV
     * @returns {Promise<void>} Uma promessa que é resolvida quando a exportação é concluída.
     * 
     * @example
     * exportToCSV();
     * 
     * @global
     * @constant {number} currentPage - A página atual do CSV sendo gerado.
     * @constant {number} currentRow - A linha atual do CSV sendo gerado.
     * @constant {string} csvContent - O conteúdo do CSV sendo gerado.
     * 
     * @param {Array} columnOrder - A ordem das colunas a serem exportadas.
     * @param {Object} selectedColumns - As colunas selecionadas para exportação.
     * @param {Object} columnNames - Os nomes das colunas.
     * @param {Array} dadosRelatorio - Os dados do relatório a serem exportados.
     * @param {number} limiteLinhasDocumento - O limite de linhas por documento CSV.
     * @param {string} nomeArquivo - O nome base do arquivo CSV.
     * @param {function} saveAs - Função para salvar o arquivo CSV.
     */
    const exportToCSV = React.useCallback(async () => {
        let currentPage = 1;
        let currentRow = 0;
        let csvContent = columnOrder
            .filter(col => selectedColumns[col])
            .map(col => columnNames[col].charAt(0).toUpperCase() + columnNames[col].slice(1))
            .join(",") + "\n";

        dadosRelatorio.forEach((item, index) => {
            if (limiteLinhasDocumento && limiteLinhasDocumento > 0 && currentRow >= limiteLinhasDocumento) {
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                saveAs(blob, `${nomeArquivo}(${currentPage}).csv`);
                csvContent = columnOrder
                    .filter(col => selectedColumns[col])
                    .map(col => columnNames[col].charAt(0).toUpperCase() + columnNames[col].slice(1))
                    .join(",") + "\n";
                currentPage++;
                currentRow = 0;
            }

            const row = columnOrder
                .filter(col => selectedColumns[col])
                .map(col => item[col])
                .join(",");
            csvContent += row + "\n";
            currentRow++;
        });

        if (csvContent.length > 1) {
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${nomeArquivo}(${currentPage}).csv`);
        }
    }, [columnOrder, selectedColumns, columnNames, dadosRelatorio, limiteLinhasDocumento, nomeArquivo]);
    /**
     * Exporta o conteúdo referenciado para um arquivo PDF.
     *
     * As opções de exportação incluem:
     * - jsPDF: Define a unidade de medida, formato e orientação do PDF.
     * - html2canvas: Configurações para a conversão de HTML para canvas, incluindo escala, uso de CORS e logging.
     * - image: Define o tipo e a qualidade da imagem.
     * - output: Nome do arquivo de saída.
     *
     * @function
     */
    const exportToPdf = React.useCallback(() => {
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${nomeArquivo}.pdf`
        };
        html2PDF(toExportRef.current, newOptions);
    }, [nomeArquivo]);

    /**
     * Exporta o conteúdo HTML referenciado para um arquivo Word (.docx).
     *
     * @function
     * @name exportToWord
     * @returns {void}
     */
    const exportToWord = React.useCallback(() => {
        const htmlString = toExportRef.current.innerHTML;
        const converted = htmlDocx.asBlob(htmlString);
        saveAs(converted, `${nomeArquivo}.docx`);
    }, [nomeArquivo]);
    //#endregion
    //#region HANDLES
    /**
     * Manipulador de evento para selecionar ou desmarcar todas as colunas.
     * 
     * @param {Object} e - O objeto do evento.
     * @param {Object} e.target - O alvo do evento.
     * @param {boolean} e.target.checked - Indica se a caixa de seleção está marcada.
     */
    const handleChangeSelecionaTodasColunas = React.useCallback((e) => {
        const { checked } = e.target;
        setSelectedColumns((prevSelectedColumns) => {
            const newSelectedColumns = {};
            for (const key in prevSelectedColumns) {
                newSelectedColumns[key] = checked;
            }
            return newSelectedColumns;
        });
    }, []);
    /**
     * Limpa todos os filtros e redefine os dados do relatório.
     *
     * @param {Event} e - O evento de clique do botão.
     */
    const handleLimparFiltros = React.useCallback((e) => {
        setFilters(filters.map(filter => ({ ...filter, value: '' })));
        setDadosRelatorio([]);
        setCarregadoDados(false);
        setVisibleFilters(true);
    }, [filters]);
    /**
     * Função assíncrona que lida com o envio de dados.
     * 
     * @param {Event} e - O evento de envio.
     * @throws {Error} Se a função fetchData não estiver definida.
     */
    const handleSubmit = React.useCallback(async (e) => {
        setLoading(true);
        try {
            if (fetchData) {
                fetchData().then(data => {
                    setDadosRelatorio(data);
                    setLoading(false);
                    setCarregadoDados(true);
                    setVisibleFilters(false);
                });
            } else {
                setDadosRelatorio([]);
                setLoading(false);
                throw new Error('fetchData function is required');
            }
        } catch (error) {
            setDadosRelatorio([]);
            setLoading(false);
            snackbar.displayMessage('Erro ao buscar dados', 'error');
        }
    }, [fetchData, snackbar]);
    /**
     * Função chamada ao término de uma operação de arrastar e soltar.
     *
     * @param {Object} result - O resultado da operação de arrastar e soltar.
     * @param {Object} result.source - A origem do item arrastado.
     * @param {number} result.source.index - O índice do item na posição original.
     * @param {Object} result.destination - O destino do item arrastado.
     * @param {number} result.destination.index - O índice do item na nova posição.
     */
    const handleDragEnd = React.useCallback((result) => {
        if (!result.destination) return;

        const selectedItems = columnOrder.filter(col => selectedColumns[col]);
        const items = Array.from(selectedItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const newColumnOrder = columnOrder.map(col => {
            if (selectedColumns[col]) {
                return items.shift();
            }
            return col;
        });

        setColumnOrder(newColumnOrder);
    }, [columnOrder, selectedColumns]);
    /**
     * Função para exportar documentos em diferentes formatos.
     * 
     * @param {string} type - O tipo de documento a ser exportado. Pode ser 'pdf', 'csv', 'excel', 'word', 'image' ou 'print'.
     * @returns {void}
     */
    const handleExportDocument = React.useCallback(async (type) => {
        if (nomeArquivo === '') {
            Swal.fire({
                title: 'Nome do arquivo não pode ser vazio',
                icon: 'error'
            });
            return;
        }
        baixandoDocsRef.current.open();
        switch (type) {
            case 'pdf':
                exportToPdf();
                break;
            case 'csv':
                exportToCSV();
                break;
            case 'excel':
                exportToExcel();
                break;
            case 'word':
                exportToWord();
                break;
            case 'image':
                exportToImage();
                break;
            case 'print':
                exportToPrint();
                break;
            default:
                break;
        }
        baixandoDocsRef.current.close();
    }, [nomeArquivo, exportToPdf, exportToCSV, exportToExcel, exportToWord, exportToImage, exportToPrint]);
    /**
     * Manipula a mudança do nome do arquivo, removendo caracteres proibidos.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O valor do nome do arquivo.
     */
    const handleChangeNomeArquivo = React.useCallback((e) => {
        const forbiddenChars = /[<>:"/\\|?*]/g;
        const sanitizedValue = e.target.value.replace(forbiddenChars, '');
        setNomeArquivo(sanitizedValue);
    }, []);
    /**
     * Manipula a mudança do limite de linhas do documento.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O valor do limite de linhas.
     */
    const handleChangeLimiteLinhasDocumento = React.useCallback((e) => {
        const { value } = e.target;
        setLimiteLinhasDocumento(value);
    }, []);
    /**
     * Manipula a mudança de estado das colunas habilitadas.
     *
     * @param {Object} e - O evento de mudança.
     * @param {string} e.target.id - O ID da coluna que foi alterada.
     * @param {boolean} e.target.checked - O estado de seleção da coluna.
     */
    const handleChangeHabilitaColuna = React.useCallback((e) => {
        const { id, checked } = e.target;
        setSelectedColumns((prevSelectedColumns) => ({
            ...prevSelectedColumns,
            [id]: checked
        }));
    }, []);
    /**
     * Função para definir a coluna que está sendo editada.
     *
     * @param {Object} col - Objeto que representa a coluna a ser editada.
     */
    const handleEditColumnName = React.useCallback((col) => {
        setEditingColumn(col);
    }, []);
    /**
     * Função para lidar com a mudança do nome da coluna.
     *
     * @param {Object} e - O evento de mudança.
     * @param {string} col - O nome da coluna a ser alterada.
     */
    const handleChangeColumnName = React.useCallback((e, col) => {
        setColumnNames((prevColumnNames) => ({
            ...prevColumnNames,
            [col]: e.target.value
        }));
    }, []);
    /**
     * Função chamada quando ocorre o evento de "blur" em um nome de coluna.
     * Define a coluna em edição como nula.
     */
    const handleBlurColumnName = React.useCallback(() => {
        setEditingColumn(null);
    }, []);
    /**
     * Manipula a mudança dos filtros.
     *
     * @param {Object} e - O evento de mudança.
     * @param {string} e.target.id - O ID do filtro que está sendo alterado.
     * @param {string} e.target.value - O novo valor do filtro.
     */
    const handleChangeFilters = React.useCallback((e) => {
        if (carregadoDados) setDadosRelatorio([]);
        const { id, value } = e.target;
        setFilters((prevFilters) =>
            prevFilters.map((filter) =>
                filter.id === id ? { ...filter, value: value } : filter
            )
        );
    }, [carregadoDados]);
    const sumarizaDados = (dados, colunas) => {
        const contagem = {};
        dados.forEach(item => {
            const key = colunas;
            const value = item[key];
            if (contagem[key]) {
                if (contagem[key][value]) {
                    contagem[key][value]++;
                } else {
                    contagem[key][value] = 1;
                }
            } else {
                contagem[key] = { [value]: 1 };
            }
        });
        return contagem;
    };

    const handleAddGraph = () => {
        setGraphConfigs([...graphConfigs, { id: Date.now(), type: 'bar', columns: [], data: null }]);
    };

    const handleRemoveGraph = (id) => {
        setGraphConfigs(graphConfigs.filter(config => config.id !== id));
    };

    const handleGenerateGraph = (id) => {
        const config = graphConfigs.find(config => config.id === id);
        const contagem = sumarizaDados(dadosRelatorio, config.columns);
        const labels = [];
        const data = [];
        const backgroundColor = [];
        const borderColor = [];
        const pieData = [];

        Object.keys(contagem).forEach(key => {
            Object.keys(contagem[key]).forEach(subKey => {
                const label = `${key}: ${subKey}`;
                const value = contagem[key][subKey];
                const bgColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`;
                const brColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`;

                labels.push(label);
                data.push(value);
                backgroundColor.push(bgColor);
                borderColor.push(brColor);

                pieData.push({
                    label,
                    value,
                    backgroundColor: bgColor,
                    borderColor: brColor,
                });
            });
        });

        const newGraphData = config.type === 'bar' ? {
            title: config?.title,
            labels,
            datasets: [{
                label: 'Contagem',
                data,
                backgroundColor,
                borderColor,
                borderWidth: 1
            }]
        } : pieData;
        setGraphConfigs(graphConfigs.map(cfg => cfg.id === id ? { ...cfg, data: newGraphData, minimizado: true } : cfg));
    };

    const handleChangeGraphType = (id, type) => {
        setGraphConfigs(graphConfigs.map(cfg => cfg.id === id ? { ...cfg, type } : cfg));
    };

    const handleChangeGraphColumns = (id, columns) => {
        setGraphConfigs(graphConfigs.map(cfg => cfg.id === id ? { ...cfg, columns } : cfg));
    };

    const renderGraph = (config) => {
        if (!config.data) return null;
        if (config.type === 'bar') {
            return <GraficoEmBarra data={config.data} options={{ responsive: true }} />;
        } else if (config.type === 'pie') {
            return <Torta width='800px' height='200px' titulo={config?.title} tituloFatia={ChangeCase.toTitleCase(config.columns)} dados={config.data} />;
        }
        return null;
    };
    //#endregion
    //#region USE EFFECTS
    //#endregion
    //#region RENDER
    return (
        <div>
            <ModalBase width={'300px'} backgroundOpacity={0.2} ref={baixandoDocsRef} title='Carregando documentos.' hasCloseButton={true}>
                Baixando documentos...
                <ClipLoader color={'#123abc'} loading={true} size={50} />
            </ModalBase>
            <Form onSubmit={handleSubmit} className='d-flex flex-row flex-wrap gy-4 p-4'>
                <fieldset className={`ns-fieldset col-${preExtraOptions ? '8' : '12'}`}>
                    <legend className='pointer' onClick={() => setVisibleFilters(prev => !prev)}>Filtros
                        <span className='h-auto w-auto p-0 m-0' style={{ transform: visibleFilters ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}>
                            {cloneElement(iconArray['seta-angulo-baixo'], { size: 20, style: { margin: 0 } })}
                        </span>
                    </legend>
                    <LinhaWrap visible={visibleFilters}>
                        {(filters?.length !== undefined && filters.length > 0)
                            ? filters.map((filter, index) => {
                                const type = filter.type || 'text';
                                const options = filter.options || [];
                                const value = filter.value || '';
                                const id = filter.id || '';
                                switch (type) {
                                    case 'select':
                                        return (
                                            <div key={index} className='col-lg-3 col-sm-12 px-2'>
                                                <Select id={id} name={id} label={filter.label} value={value} onChange={(e) => handleChangeFilters(e)} options={options} />
                                            </div>
                                        );
                                    case 'multi_select':
                                        return (
                                            <div key={index} className='col-lg-3 col-sm-12 px-2'>
                                                <label>{filter.label}</label>
                                                <MultiSelect
                                                    id={id}
                                                    name={id}
                                                    options={options}
                                                    value={value}
                                                    onChange={(e) => handleChangeFilters(e)}
                                                    display="chip"
                                                    optionLabel="label"
                                                />
                                            </div>
                                        );
                                    case 'checkbox':
                                        return (
                                            <div key={index} className='col-lg-3 col-sm-12 px-2 d-flex align-items-bottom' id={`filter_${id}`}>
                                                <div className='ns-checkbox reverse'>
                                                    <label htmlFor={id}>
                                                        {filter.label}
                                                    </label>
                                                    <input type="checkbox" id={id} name={id} checked={value} onChange={(e) => handleChangeFilters(e)} />
                                                </div>
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div key={index} className='col-lg-3 col-sm-12 px-2'>
                                                <Input type="text" title={filter.label} label={filter.label} id={id} name={id} value={value} onChange={(e) => handleChangeFilters(e)} />
                                            </div>
                                        );
                                }
                            })
                            : <div className='col-12'>Nenhum filtro disponível</div>
                        }
                    </LinhaWrap>
                </fieldset>
                {preExtraOptions &&
                    <fieldset className='ns-fieldset col-4'>
                        <legend>Opções Extra</legend>
                        {preExtraOptions}
                    </fieldset>
                }
                <Linha className='col-12 justify-between'>
                    <Button type="button" onClick={handleLimparFiltros} className={`broom-sweep w-300px-i`}>
                        <FaBroom size={30} type="button" className="icone" /> Limpar Filtros
                    </Button>
                    <Button type="submit" className={'searching w-300px-i'}>
                        <BsSearch className='icone' size={30} /> Pesquisar
                    </Button>
                </Linha>
            </Form>
            <CarregandoRelatorio loading={loading} />
            {
                !loading && (dadosRelatorio && dadosRelatorio.length > 0)
                    ? (
                        <RelatorioContainer className='d-flex'>
                            <SelecionarColunas visible={colunasVisible} onMouseEnter={() => setColunasVisible(true)} onMouseLeave={() => setColunasVisible(false)}>
                                <div className={`marcador${colunasVisible ? ' visivel' : ''} bg-roxo`}>
                                    <span>{iconArray['seta-angulo-esquerda']}</span>
                                    COLUNAS
                                </div>
                                <div className='conteudo bg-roxo'>
                                    <span className='titulo bg-roxo-dark'>Selecionar Colunas
                                        <span className='ms-2 mt-1'>
                                            <input type='checkbox' id='select_all' name='select_all' defaultChecked={Object.values(selectedColumns).every(Boolean)} onChange={(e) => handleChangeSelecionaTodasColunas(e)} />
                                        </span>
                                    </span>
                                    <div className="lista bg-roxo-dark2">
                                        {columnOrder.length > 0 ? columnOrder.map((col, index) => (
                                            <DraggableItem key={col} className="form-check-reverse form-switch" title={col}>
                                                <span className='d-flex align-items-center justify-content-between w-100 px-2'>
                                                    <FaPencilAlt className="edit-icon font-roxo" onClick={() => handleEditColumnName(col)} />
                                                    {editingColumn === col ? (
                                                        <EditableInput
                                                            type="text"
                                                            value={columnNames[col]}
                                                            onChange={(e) => handleChangeColumnName(e, col)}
                                                            onBlur={handleBlurColumnName}
                                                            autoFocus
                                                        />
                                                    ) : (
                                                        <label className="form-check-label" htmlFor={col} style={{ textDecoration: selectedColumns[col] ? 'none' : 'line-through' }}>
                                                            {columnNames[col]}
                                                        </label>
                                                    )}
                                                    <input className="form-check-input switch-roxo ms-2" type="checkbox" role="switch" id={col} checked={selectedColumns[col]} onChange={handleChangeHabilitaColuna} />
                                                </span>
                                            </DraggableItem>
                                        )) : <div className='col-12'>Nenhuma coluna disponível</div>}
                                    </div>
                                </div>
                            </SelecionarColunas>
                            <div ref={toExportRef} className='coluna-2'>
                                <div className='w-100per d-flex px-3'>
                                    <Coluna className='col-6'>
                                        {extraOptions &&
                                            <fieldset className="ns-fieldset mx-1">
                                                <legend>Opções de Extra</legend>
                                                {extraOptions}
                                            </fieldset>
                                        }
                                        <Linha>
                                            <fieldset className="ns-fieldset col-6">
                                                <legend>Detalhes de Exportação</legend>
                                                <div className='d-flex flex-wrap flex-col justify-center'>
                                                    <FormLinha>
                                                        <div className='col-12 px-2 mb-2'>
                                                            <Input id="nome_arquivo" name="nome_arquivo" className="w-100per" label="Nome do Arquivo" value={nomeArquivo} onChange={handleChangeNomeArquivo} maxLength={28} icon={FaFile} />
                                                        </div>
                                                        <div className='col-12 px-2'>
                                                            <Input id="limite_linhas" name="limite_linhas" type="number" className="w-100per" label="Limite de Linhas por Página" value={limiteLinhasDocumento} onChange={handleChangeLimiteLinhasDocumento} />
                                                        </div>
                                                    </FormLinha>
                                                </div>
                                            </fieldset>
                                            <fieldset className="ns-fieldset col-6">
                                                <legend>Opções de Exportação</legend>
                                                <Linha className='justify-content-between mb-4'>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('print')}>
                                                        <BsPrinter size={30} />Imprimir
                                                    </Button>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('image')}>
                                                        <CgExport size={30} />Imagem
                                                    </Button>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('pdf')}>
                                                        <FaFilePdf size={30} />PDF
                                                    </Button>
                                                </Linha>
                                                <Linha className='justify-content-between mb-4'>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('excel')}>
                                                        <RiFileExcel2Fill size={30} />Excel
                                                    </Button>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('word')}>
                                                        <FaFilePdf size={30} />Word
                                                    </Button>
                                                    <Button className='d-flex flex-column align-items-center mx-1' style={{ minWidth: '100px' }} onClick={() => handleExportDocument('csv')}>
                                                        <FaFileCsv size={30} />CSV
                                                    </Button>
                                                </Linha>
                                            </fieldset>
                                        </Linha>
                                    </Coluna>
                                    <div className='col-3'>
                                        <fieldset className="ns-fieldset mx-1">
                                            <legend>Informações</legend>
                                            <div className='d-flex'>
                                                {filters.findIndex(filter => (filter.value !== null && filter.value !== '')) !== -1 &&
                                                    <div className='d-grid col-6'>
                                                        Filtros Utilizados:
                                                        <ul>
                                                            {filters.filter(filter => (filter.value !== null && filter.value !== '')).map((filter, index) => (
                                                                <li key={index}>
                                                                    {filter.label}: {filter.value}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                }
                                                <div className='col-6'>
                                                    Quantidade de Registros: {dadosRelatorio.length}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className='col-3'>
                                        <fieldset className="ns-fieldset mx-1">
                                            <legend className='align-items-center'>
                                                <Linha className='align-items-center m-0'> Gerar Graficos </Linha>
                                                <button type={'button'} className={'btn-icone d-flex h-40px w-fit-content-i p-0 m-0 ms-2 text-center align-items-center'} onClick={handleAddGraph}>
                                                    {cloneElement(iconArray['adicionar'], { size: 22 })}
                                                </button>
                                            </legend>
                                            {graphConfigs.map((config, index) => (
                                                <>
                                                    <br></br>
                                                    <FieldsetMinimizavel className={`ns-fieldset${config.minimizado ? ' minimizado' : ''}`} id={`fs-graph:${index}`} key={config.id}>
                                                        <legend className='align-items-center'>
                                                            <button type={'button'} className={'btn-icone d-flex h-40px w-fit-content-i p-0 m-0 text-center align-items-center'} onClick={() => setGraphConfigs(graphConfigs.map(cfg => cfg.id === config.id ? { ...cfg, minimizado: !cfg.minimizado } : cfg))}>
                                                                {config.minimizado ? iconArray['seta-angulo-baixo'] : iconArray['seta-angulo-cima']}
                                                            </button>
                                                            <Linha className='align-items-center m-0'>
                                                                Grafico {index + 1}
                                                                <button type={'button'} className={'btn-icone d-flex h-40px w-fit-content-i p-0 m-0 ms-2 text-center align-items-center'} onClick={() => handleRemoveGraph(config.id)}>
                                                                    {cloneElement(iconArray['remover'], { size: 22 })}
                                                                </button>
                                                            </Linha>
                                                        </legend>
                                                        <FormLinha key={config.id} className='conteudo'>
                                                            <div className='col-12'>
                                                                <Input
                                                                    id={`graph_title_${config.id}`}
                                                                    name={`graph_title_${config.id}`}
                                                                    label="Título do Gráfico"
                                                                    value={config.title}
                                                                    onChange={(e) => setGraphConfigs(graphConfigs.map(cfg => cfg.id === config.id ? { ...cfg, title: e.target.value } : cfg))}
                                                                />
                                                            </div>
                                                            <div className='col-12'>
                                                                <Select
                                                                    id={`graph_type_${config.id}`}
                                                                    name={`graph_type_${config.id}`}
                                                                    label="Tipo de Gráfico"
                                                                    options={graficOptions}
                                                                    value={graficOptions.find(find => find.value === config.type)}
                                                                    onChange={(e) => handleChangeGraphType(config.id, e.value)}
                                                                    placeholder="Selecione um tipo para o gráfico"
                                                                />
                                                            </div>
                                                            <div className='col-12'>
                                                                <Select
                                                                    id={`graph_columns_${config.id}`}
                                                                    name={`graph_columns_${config.id}`}
                                                                    label="Colunas para Gráfico"
                                                                    options={columnOptions}
                                                                    value={columnOptions.find(({ value }) => value === config.columns)}
                                                                    onChange={(e) => handleChangeGraphColumns(config.id, e.value)}
                                                                    placeholder="Selecione as colunas para o gráfico"
                                                                />
                                                            </div>
                                                            <Button type={'button'} className={'col-12 h-40px mt-4'} onClick={() => handleGenerateGraph(config.id)}>
                                                                <VscGraph className='me-2' size={25} /> Gerar Gráfico
                                                            </Button>
                                                        </FormLinha>
                                                    </FieldsetMinimizavel>
                                                </>
                                            ))}
                                        </fieldset>
                                    </div>
                                </div>
                                <fieldset className="ns-fieldset mx-1">
                                    <legend>Gráficos</legend>
                                    <Linha>
                                        {graphConfigs.map(config => renderGraph(config))}
                                    </Linha>
                                </fieldset>
                                {Object.values(selectedColumns).some(find => find === true)
                                    ? (<TableContainer>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="columns" direction="horizontal">
                                                {(provided) => {
                                                    const enabledColumns = columnOrder.filter(col => selectedColumns[col]);
                                                    return (
                                                        <Table ref={provided.innerRef} {...provided.droppableProps}>
                                                            <thead key='header'>
                                                                <tr key='header-row'>
                                                                    {enabledColumns.length > 0 ? enabledColumns.map((col, index) => (
                                                                        <Draggable key={col} draggableId={col} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <th
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    style={{
                                                                                        ...provided.draggableProps.style,
                                                                                        backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                                                                        opacity: snapshot.isDragging ? 0.5 : 1
                                                                                    }}
                                                                                    onClick={() => requestSort(col)}
                                                                                >
                                                                                    <span className='d-flex justify-content-between align-items-center flex-nowrap'>
                                                                                        <span className='column-draggable' {...provided.dragHandleProps}>
                                                                                            <MdDragHandle size={22} />
                                                                                        </span>
                                                                                        <span className='column-name'>
                                                                                            {columnNames[col]}
                                                                                        </span>
                                                                                        <span className='column-sort'>
                                                                                            {sortConfig.key === col ? (sortConfig.direction === 'ascending' ? iconArray['seta-cima'] : sortConfig.direction === 'descending' ? iconArray['seta-baixo'] : '') : null}
                                                                                        </span>
                                                                                    </span>
                                                                                </th>
                                                                            )}
                                                                        </Draggable>
                                                                    )) : <th>Nenhuma coluna disponível</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody key='body'>
                                                                {sortedDadosRelatorio.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            {enabledColumns.length > 0 ? enabledColumns.map(col => <td key={col}>{item[col]}</td>) : <td>Nenhuma coluna disponível</td>}
                                                                        </tr>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </tbody>
                                                        </Table>
                                                    );
                                                }}
                                            </Droppable>
                                        </DragDropContext>
                                    </TableContainer>)
                                    : <span className='ns-sem-registros-0'>Selecione ao menos uma coluna para mostrar o relatório.</span>
                                }
                            </div>
                        </RelatorioContainer>
                    )
                    : <span className='ns-sem-registros'>Sem registros para exibir</span>
            }
        </div >
    );
    //#endregion
};

export default RelatorioBase;