import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import api from '../../../services/api';
import { Container, Modal } from '../styles';
import Select from '../../Select';
import Input from '../../Input';
import { useSnackbar } from '../../../context/snackbar';
import TextArea from '../../TextArea';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

export default function NovoAtendimentoModal({id = 'modal', controleDiarioId, onClose, modalData, atendimentos, setHouveInclusaoAtendimento = ()=>{}}){
/*===============================*/
//#region VARIÁVEIS==============*/
/*===============================*/
    const debug_mode = true;
    const [terapias, setTerapias] = useState([]);
    const [terapeutas, setTerapeutas] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [planosSaude, setPlanosSaude] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [tipoAtendimento, setTipoAtendimento] = useState([]);
    const [horas, setHoras] = useState([]);
    const [estacoes, setEstacoes] = useState([]);
    const [salas, setSalas] = useState([]);
    const [data, setData] = useState(moment().format('YYYY-MM-DD'));

    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [pacienteOptions, setPacienteOptions] = useState([]);
    const [horaSelecionada, setHoraSelecionada] = useState(null);
    const [horaFinal, setHoraFinal] = useState(null);
    const [salaSelecionada, setSalaSelecionada] = useState(null);
    const [estacaoSelecionada, setEstacaoSelecionada] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [terapiaSelecionado, setTerapiaSelecionada] = useState(null);
    const [especialidadeSelecionado, setEspecialidadeSelecionada] = useState(null);
    const [modalidadeSelecionado, setModalidadesSelecionada] = useState(null);
    const [tipoAgendamentoSelecionado, setTipoAgendamentoSelecionado] = useState(null);
    const [reservaSelecionado, setReservaSelecionada] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [idControleDiario, setIdControleDiario] = useState();

    const formRef = useRef(null);
    const snackbar = useSnackbar();
    let url_atual = useLocation();
    const reservaOptions = [
        { value: true, label: 'Sim', reserva: 1 },
        { value: false, label: 'Não', reserva: 0 },
    ];
//#endregion
/*===============================*/
//#region HANDLEs================*/
/*===============================*/
    const handleSubmit = async (submitData) => {
        if (planoSaudeSelecionado === null || pacienteSelecionado === null || terapeutaSelecionado === null || terapiaSelecionado === null || especialidadeSelecionado === null || modalidadeSelecionado === null || tipoAgendamentoSelecionado === null || tipoAgendamentoSelecionado === null || submitData.comentario === '' || salaSelecionada === null || estacaoSelecionada === null || horaSelecionada === null) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            try {
                if (modalData === null || modalData === undefined) {
                    const obj = {
                        'paciente_id': pacienteSelecionado.value,
                        'paciente_id_plano_saude': planoSaudeSelecionado.value,
                        'terapeuta_id': terapeutaSelecionado.value,
                        'terapia_id': terapiaSelecionado.value,
                        'especialidade_id': especialidadeSelecionado.value,
                        'sugestao_recepcao': null,
                        'sugestao_terapeuta': null,
                        'modalidade_id': modalidadeSelecionado.value,
                        'tipo_atendimento_id': tipoAgendamentoSelecionado.value,
                        'reserva': reservaSelecionado === null ? 0 : reservaSelecionado.value,
                        'data_atendimento_inicial': moment(data + 'T' + horaSelecionada.value).format('YYYY-MM-DD HH:mm'),
                        'data_atendimento_final': moment(data + 'T' + horaFinal).format('YYYY-MM-DD HH:mm'),
                        'comentario': submitData.comentario,
                        'sala_id': salaSelecionada.value,
                        'estacao_id': estacaoSelecionada.value,
                        'controle_diario_id': controleDiarioId === null ? idControleDiario : controleDiarioId === undefined ? idControleDiario : controleDiarioId,
                    };
                    const response = await api.post('api/atendimento', obj).then((e) => {
                        snackbar.displayMessage('Atendimento criado com sucesso', 'success');
                        setHouveInclusaoAtendimento();
                        onClose();
                    });
                } else {
                    const obj ={
                        'paciente_id': pacienteSelecionado.value,
                        'paciente_id_plano_saude': planoSaudeSelecionado.value,
                        'terapeuta_id': terapeutaSelecionado.value,
                        'terapia_id': terapiaSelecionado.value,
                        'especialidade_id': especialidadeSelecionado.value,
                        'modalidade_id': modalidadeSelecionado.value,
                        'tipo_atendimento_id': tipoAgendamentoSelecionado.value,
                        'reserva': reservaSelecionado.value,
                        'data_atendimento_inicial': moment(data + 'T' + horaSelecionada.value).format('YYYY-MM-DD HH:mm'),
                        'data_atendimento_final': moment(data + 'T' + horaFinal).format('YYYY-MM-DD HH:mm'),
                        'comentario': submitData.comentario,
                        'sala_id': salaSelecionada.value,
                        'estacao_id': estacaoSelecionada.value,
                        'controle_diario_id': controleDiarioId === null ? idControleDiario : controleDiarioId === undefined ? idControleDiario : controleDiarioId,
                    };
                    const response = await api.put('api/atendimento', obj, modalData.id).then((e) => {
                        snackbar.displayMessage('Atendimento criado com sucesso', 'success');
                        setHouveInclusaoAtendimento();
                        onClose();
                    });
                }
            } catch (error) {
                snackbar.displayMessage('Houve um erro ao criar o atendimento, tente novamente', 'error');
                console.error(error);
            }
        };
    };
    const handleChangeSala = (e) => {
        getEstacoes(e.value);
        setSalaSelecionada(e);
    };
    const handleChangeHorario = (e) => {
        setHoraSelecionada(e);
        setPacienteSelecionado(null);
        getPacientes();
    };
    const handleChangePaciente = (e) => {
        const pacienteData = pacientes.find(paciente => paciente.id === e.value);
        loadTerapiasEspecialidades(pacienteData, planoSaudeSelecionado);
        setPacienteSelecionado(e);
        setTerapiaSelecionada(null);
        setEspecialidadeSelecionada(null);
        setPlanoSaudeSelecionado(null);
    };
    const handleChangePlano = (e) => {
        setPlanoSaudeSelecionado(e);
    };
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
//#endregion
/*===============================*/
//#region FUNCOEs================*/
/*===============================*/
    const getEstacoes = (salaId) => {
        api.get(`api/estacao?filter[sala_id]=${salaId}`).then(response => {
            const selectEstacao = response.data.map(d => ({
                'value': d.id,
                'label': d.estacao,
            }));
            setEstacoes(selectEstacao);
        });
    };
    const getSalas = () => {
        api.get('api/sala').then(response => {
            const selectSalas = response.data.map(d => ({
                'value': d.id,
                'label': d.sala,
            }));
            setSalas(selectSalas);
        });
    };
    const getHoras = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(response => {
            let horarios = response.data;
            horarios = horarios.map(horario => {
                let horaAtual = moment(horario.horario.split('T')[1], 'HH:mm');
                return {
                    value: horaAtual.format('HH:mm'),
                    label: horaAtual.format('HH:mm') + ' - ' + horaAtual.add(50, 'minutes').format('HH:mm'),
                    hour: moment.utc(horario.horario)
                };
            });
            setHoras(horarios);
        });
    };
    const getControleDiarioId = () => {
        api.get(`/api/controle-diario?filter[dia]=${data}`)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario.length === 0) {
                    return;
                }
                controleDiario = controleDiario[controleDiario.length - 1];
                setIdControleDiario(controleDiario);
            });
    };
    const getTerapeutas = (especialidade_id) => {
        const params = new URLSearchParams({
            dia: data,
            hora: horaSelecionada.value
        });
        api.get(`api/terapeuta/disponivel/${data}/${horaSelecionada.value}?${params.toString()}`).then(({ data }) => {
            let terapeutaOptions = [];
            data.forEach((terapeuta) => {
                let validaHora = false;
                let adendoNome = '';
                let date = moment();
                let hour = moment(date.format('YYYY-MM-DD ')+ horaSelecionada.value);
                let horario = horaSelecionada.value;
                if (terapeuta.terapeuta_disponibilidades.length > false) {
                    for (let index = 0; index < terapeuta.terapeuta_disponibilidades.length; index++) {
                        const el = terapeuta.terapeuta_disponibilidades[index];
                        const dia_semana = moment(date).day();
                        let p = el.horario.split('T')[1].split(':');
                        const horario_teste = p[0] + ':' + p[1] + ':' + p[2].split('.')[0];
                        if (el.dia_semana === dia_semana) {
                            if (horario_teste === horario+':00') {
                                validaHora = true;
                            }
                        }
                    }
                }
                if (terapeuta.terapeuta_especialidades.length > false) {
                    terapeuta.terapeuta_especialidades.forEach((especialidade) => {
                        if (especialidade.especialidade_id === especialidade_id && terapeutaOptions.find(id => id.value === terapeuta.id) === undefined) {
                            if (checkAgendamentos(terapeuta.id, hour) === false) {
                                /* terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome + (terapeuta.status==='Ativo')?'':'('+terapeuta.status+')', }); */
                                if (terapeuta.status === 'Férias') {
                                    adendoNome = ' (Férias)';
                                } else if (terapeuta.status === 'Inativo') {
                                    adendoNome = ' (Inativo)';
                                } else if (terapeuta.status === 'Afastado') {
                                    adendoNome = ' (Afastado)';
                                }
                            } else {
                                adendoNome = ' (Ocupado)';
                            }
                            if (!validaHora) {
                                adendoNome = ' (Indisponível)';
                            }
                            terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome + adendoNome, });
                        } else {
                            if (terapeutaOptions.find(id => id.value === terapeuta.id) === undefined) {
                                if (checkAgendamentos(terapeuta.id, hour) === false) {
                                    /* terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome + (terapeuta.status==='Ativo')?'':'('+terapeuta.status+')', }); */
                                    if (terapeuta.status === 'Férias') {
                                        adendoNome = ' (Férias)';
                                    } else if (terapeuta.status === 'Inativo') {
                                        adendoNome = ' (Inativo)';
                                    } else if (terapeuta.status === 'Afastado') {
                                        adendoNome = ' (Afastado)';
                                    }
                                } else {
                                    adendoNome = ' (Ocupado)';
                                }
                                if (!validaHora) {
                                    adendoNome = ' (Indisponível)';
                                }
                                terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome + adendoNome, });
                            }
                        }
                    });
                } else {
                    if (terapeuta.status === 'Férias') {
                        adendoNome = ' (Férias)';
                    } else if (terapeuta.status === 'Inativo') {
                        adendoNome = ' (Inativo)';
                    } else if (terapeuta.status === 'Afastado') {
                        adendoNome = ' (Afastado)';
                    }
                    if (!validaHora) {
                        adendoNome = ' (Indisponível)';
                    }
                    terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome + adendoNome, });
                }
            });
            setTerapeutas(terapeutaOptions);
        });
    };
    const checkAgendamentos = (terapeuta_id, horario) => {
        let isTerapeuta = false;
        atendimentos.forEach((atendimento) => {
            if (atendimento.terapeuta_id === terapeuta_id && atendimento.data_atendimento_inicial === horario._i) isTerapeuta = true;
        });
        return isTerapeuta;
    };
    const getModalidades = () => {
        api.get('api/modalidade').then(({ data }) => {
            let modalidadeOptions = [];
            data.forEach((modalidade) => {
                modalidadeOptions.push({ value: modalidade.id, label: modalidade.modalidade });
            });
            setModalidades(modalidadeOptions);
        });
    };
    const getPlanos = () => {
        api.get('api/plano-saude').then(({ data }) => {
            let planoSaudeOptions = [];
            data.forEach((planoSaude) => {
                planoSaudeOptions.push({ value: planoSaude.id, label: planoSaude.plano });
            });
            setPlanosSaude(planoSaudeOptions);
        });
    };
    const checkAgendamentosPaciente = (paciente_id, horario) => {
        let isPaciente = false;
        atendimentos.forEach((agendamento) => {
            if (agendamento.paciente_id === paciente_id && agendamento.data_atendimento_inicial === horario._i) isPaciente = true;
        });
        return isPaciente;
    };
    const getPacientes = () => {
        api.get(`api/paciente?with=terapia_pacientes.terapium,terapia_pacientes.especialidade&/disponivel/${data}/${horaSelecionada.value}`).then(({ data }) => {
            let pacienteOptions = [];
            data.forEach((paciente) => {
                if (checkAgendamentosPaciente(paciente.id, horaSelecionada.hour) === false) {
                    if (paciente.status === 'Férias') {
                        pacienteOptions.push({ value: paciente.id, label: paciente.nome + ' (Férias)', });
                    } else if (paciente.status === 'Inativo') {
                        pacienteOptions.push({ value: paciente.id, label: paciente.nome + ' (Inativo)', });
                    } else if (paciente.status === 'Afastado') {
                        pacienteOptions.push({ value: paciente.id, label: paciente.nome + ' (Afastado)', });
                    } else {
                        pacienteOptions.push({ value: paciente.id, label: paciente.nome, });
                    }
                } else {
                    pacienteOptions.push({ value: paciente.id, label: paciente.nome + ' (Indisponível)', });
                }
            });
            setPacienteOptions(pacienteOptions);
            setPacientes(data);
        });
    };
    const getTipoAtendimento = () => {
        api.get('api/tipo-atendimento').then(({ data }) => {
            let tipoAtendimentoOptions = [];
            data.forEach((tipoAtendimento) => {
                tipoAtendimentoOptions.push({ value: tipoAtendimento.id, label: tipoAtendimento.tipo_atendimento });
            });
            setTipoAtendimento(tipoAtendimentoOptions);
        });
    };
    const loadTerapiasEspecialidades = (paciente) => {
        if (paciente !== null) {
            let terapiasList = [];
            let especialidadesList = [];
            if (paciente.terapia_pacientes.length > 0) {
                paciente.terapia_pacientes.forEach((terapia) => {
                    terapiasList.push({ value: terapia.terapium.id, label: terapia.terapium.terapia });
                    especialidadesList.push({ value: terapia.especialidade.id, label: terapia.especialidade.especialidade });
                });
            }
            setTerapias(terapiasList);
            setEspecialidades(especialidadesList);
        } else {
            snackbar.displayMessage('Por favor selecione o paciente e plano de saúde!', 'warning');
        }
    };
    const getDefaultPaciente = async (pacientes) => {
        if (modalData === null || modalData === undefined) return;
        const paciente = pacientes.filter(data => {
            return data.label === modalData.paciente.nome;
        })[0];
        setPacienteSelecionado(paciente);
    };
    const getDefaultPlano = (planos) => {
        if (modalData === null || modalData === undefined) return;
        const plano = planos.filter(data => {
            return data.label === modalData.paciente.plano_saude.plano;
        })[0];
        setPlanoSaudeSelecionado(plano);
    };
    const getDefaultHorario = (horarios) => {
        if (modalData === null || modalData === undefined) return;
        let horarioData = moment(modalData.data_atendimento_inicial.split('T')[1], 'HH:mm');
        const horario = horarios.filter(data => {
            return data.value === horarioData.format('HH:mm');
        })[0];
        setHoraSelecionada(horario);
    };
    const getDefaultTerapiaEspecialidade = (especialidades, terapias) => {
        if (modalData === null || modalData === undefined) return;
        const terapia = terapias.filter(data => {
            return data.value === modalData.terapia_id;
        })[0];
        const especialidade = especialidades.filter(data => {
            return data.value === modalData.especialidade_id;
        })[0];
        setEspecialidadeSelecionada(especialidade);
        setTerapiaSelecionada(terapia);
    };
    const getDefaultTerapeuta = (terapeutas) => {
        if (modalData === null || modalData === undefined) return;
        const terapeuta = terapeutas.filter(data => {
            return data.value === modalData.terapeuta_id;
        })[0];
        setTerapeutaSelecionado(terapeuta);
    };
    const getDefaultModalidade = (modalidades) => {
        if (modalData === null || modalData === undefined) return;
        const modalidade = modalidades.filter(data => {
            return data.value === modalData.modalidade_id;
        })[0];
        setModalidadesSelecionada(modalidade);
    };
    const getDefaultSala = (salas) => {
        if (modalData === null || modalData === undefined) return;
        const sala = salas.filter(data => {
            return data.value === modalData.modalidade_id;
        })[0];
        handleChangeSala(sala);
    };
    const getDefaultTipoAtendimento = (tipos_atendimento) => {
        if (modalData === null || modalData === undefined) return;
        const tipo = tipos_atendimento.filter(data => {
            return data.value === modalData.tipo_atendimento_id;
        })[0];
        setTipoAgendamentoSelecionado(tipo);
    };
    const getDefaultReserva = (reservas) => {
        if (modalData === null || modalData === undefined) return;
        const reserva = reservas.filter(data => {
            return data.reserva === modalData.reserva;
        })[0];
        setReservaSelecionada(reserva);
    };
    const getDefaultEstacao = (estacao) => {
        if (modalData === null || modalData === undefined) return;
        const estacaoSelected = estacao.filter(data => {
            return data.value === modalData.estacao_id;
        })[0];
        setEstacaoSelecionada(estacaoSelected);
    };
    const getDefaultData = async () => {
        getDefaultPaciente(pacientes).then(() => {
            getDefaultHorario(horas);
            getDefaultPlano(planosSaude);
            getDefaultTerapiaEspecialidade(especialidades, terapias);
            getDefaultModalidade(modalidades);
            getDefaultTipoAtendimento(tipoAtendimento);
            getDefaultReserva(reservaOptions);
            getDefaultSala(salas);
        });
    };
    const functionThatSubmitsForm = () => {
        formRef.current.submitForm();
    };
//#endregion
/*===============================*/
//#region USE EFFECTs============*/
/*===============================*/
    useEffect(() => {
        const loadDadosIniciais = async () => {
            getControleDiarioId();
            getTipoAtendimento();
            getPlanos();
            getModalidades();
            getSalas();
            getHoras();
        };
        loadDadosIniciais();
    }, []);
    useEffect(() => {
        getDefaultData();
    }, [horas]);
    useEffect(() => {
        getDefaultTerapeuta(terapeutas);
    }, [terapeutas]);
    useEffect(() => {
        getDefaultEstacao(estacoes);
    }, [estacoes]);
    useEffect(() => {
        getControleDiarioId();
    }, [data]);
    useEffect(() => {
        if (data == null || especialidadeSelecionado == null || terapiaSelecionado == null || horaSelecionada === null) return;
        else getTerapeutas(especialidadeSelecionado.value, terapiaSelecionado.value, horaSelecionada.value);
    }, [horaSelecionada, terapiaSelecionado, especialidadeSelecionado]);
    useEffect(() => {
        if (horas != [] && horaSelecionada !== null && horaSelecionada !== undefined) getPacientes();
    }, [horaSelecionada]);
//#endregion
    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <div className='div-modal'>
                <div className='d-flex justify-between align-items-center'>
                    <label></label>
                    <label className='titulo-modal-18'>
                        {(modalData === null)
                            ? 'Adicionar Atendimento'
                            : ((modalData === undefined)
                                ? 'Adicionar Atendimento'
                                : 'Alterar Atendimento')
                        }
                    </label>
                    <label className='font-12px' onClick={() => onClose()}>X</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div>
                        <Select className='col-12' label='Nome Paciente:' name='paciente' options={pacienteOptions} onChange={e => handleChangePaciente(e)} value={pacienteSelecionado} />
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12'>
                                <Select label='Terapia:' name='Terapia' options={terapias} onChange={e => setTerapiaSelecionada(e)} value={terapiaSelecionado} />
                            </div>
                            <div className='col-lg-6 col-sm-12'>
                                <Select label='Especialidade:' name='Especialidade' options={especialidades} onChange={e => setEspecialidadeSelecionada(e)} value={especialidadeSelecionado} />
                            </div>
                        </div>
                        <div className='col-lg-12 col-sm-12'>
                            <Select label='Plano de saúde:' name='plano_saude' options={planosSaude} onChange={e => handleChangePlano(e)} value={planoSaudeSelecionado} />
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12'>
                                <Select label='Terapeuta:' name='Terapeuta' options={terapeutas} onChange={setTerapeutaSelecionado} value={terapeutaSelecionado} />
                            </div>
                            <div className='col-lg-6 col-sm-12'>
                                <Select label='Modalidade:' name='Modalidade' options={modalidades} onChange={setModalidadesSelecionada} value={modalidadeSelecionado} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12'>
                                <Select label='Tipo de Atendimento:' name='tipoAtendimento' options={tipoAtendimento} onChange={setTipoAgendamentoSelecionado} value={tipoAgendamentoSelecionado} />
                            </div>
                            <div className='col-lg-6 col-sm-12'>
                                {url_atual.pathname.split('/')[3] === 'controlediario' ? '' :
                                    <Select label='Reserva:' name='reserva' options={reservaOptions} onChange={setReservaSelecionada} value={reservaSelecionado} />
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-12'>
                                <Select name='horario' label={'Hora Inicial:'} options={horas}
                                    onChange={(e) => {
                                        setHoraSelecionada(e);
                                        setHoraFinal(moment.utc(e.hour._i).add(50, 'Minutes').format('HH:mm'));
                                    }} value={horaSelecionada === null ? '' : horaSelecionada} />
                            </div>
                            <div className='col-lg-2 col-sm-12'>
                                <Input name='horario' title={'Hora Final:'} type='time' onChange={e => setHoraFinal(e.target.value)} value={horaFinal === null ? '' : horaFinal} />
                            </div>
                            {url_atual.pathname.split('/')[3] === 'controlediario'
                                ? <div className='col-lg-3 col-sm-12'>
                                    <Input type={'date'} value={data} name={'data'} title={'Data:'} disabled />
                                </div>
                                : <div className='col-lg-3 col-sm-12'>
                                    <Input type={'date'} defaultValue={data} name={'data'} title={'Data:'} onChange={e => setData(e.target.value)} disabled/>
                                </div>
                            }
                            <div className='col-lg-2 col-sm-12'>
                                <Select label='Sala:' name='sala' options={salas} onChange={(e) => handleChangeSala(e)} value={salaSelecionada} />
                            </div>
                            <div className='col-lg-2 col-sm-12'>
                                <Select label='Estação:' name='estacao' options={estacoes} onChange={setEstacaoSelecionada} value={estacaoSelecionada} />
                            </div>
                        </div>
                    </div>
                    <TextArea style={{ height: '10%' }} title='Comentário:' name='comentario' defaultValue={modalData === null ? '' : modalData === undefined ? '' : modalData.comentario} />
                    <div className='col-12 d-flex mt-10px justify-content-between'>
                        <button className='btn-padrao' onClick={e => onClose()}> Cancelar </button>
                        <button type='submit' className='btn-padrao'>
                            {modalData === null ? 'Adicionar' : modalData === undefined ? 'Adicionar' : 'Alterar'}
                        </button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}