//#region IMPORTAÇÕES
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { ChangeCase } from 'services/ChangeCase';
//#endregion

const AuthContext = createContext({});

/**
 * Provedor de Autenticação.
 * Este componente fornece contexto de autenticação para a aplicação.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Elementos filhos que serão renderizados dentro do provedor.
 *
 * @returns {JSX.Element} O provedor de autenticação.
 */
const AuthProvider = ({ children }) => {
	//#region VARIAVEIS
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasDocumentosPendentes, setHasDocumentosPendentes] = useState(false);
	const [hasDocumentosObrigatoriosPendentes, setHasDocumentosObrigatoriosPendentes] = useState(false);
	const [documentosPendentes, setDocumentosPendentes] = useState([]);
	const [data, setData] = useState(() => {
		try {
			const access_token = sessionStorage.getItem('@Neuro:access_token');
			const refresh_token = sessionStorage.getItem('@Neuro:access_token');
			const user = sessionStorage.getItem('@Neuro:user');

			if (access_token && user) {
				api.defaults.headers.authorization = `Bearer ${access_token}`;
				return { access_token, refresh_token, user: JSON.parse(user) };
			}
		} catch (error) {
			sessionStorage.clear();
			console.error(error);
		}
		return {};
	});
	//#endregion

	//#region FUNCOES
	/**
	 * Verifica se existe algum documento pendente para o usuário.
	 * Faz uma requisição para a API e armazena os documentos pendentes no sessionStorage.
	 */
	const verificaExisteDocumentoPendente = () => {
		try {
			api.get('/api/user/documento-administrativo/pendente')
				.then(({ data }) => {
					setDocumentosPendentesSessionStorage(data);
				});
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Realiza o logout do usuário.
	 * Remove os dados do usuário do sessionStorage e redireciona para a página inicial.
	 */
	const signOut = useCallback(async () => {
		try {
			await api.post('/api/logout');
			sessionStorage.removeItem('@Neuro:user');
			sessionStorage.removeItem('@Neuro:access_token');
			sessionStorage.removeItem('@Neuro:docs_pending');
			sessionStorage.removeItem('@Neuro:refresh_token');
			setIsAuthenticated(false);
			setData({});
			navigate('/');
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Armazena os documentos pendentes no sessionStorage.
	 * @param {Array} documentos - Lista de documentos pendentes.
	 */
	function setDocumentosPendentesSessionStorage(documentos) {
		try {
			if (typeof documentos === 'object') {
				const documentosObrigatorios = documentos.filter(documento => documento.tipo_documento === 'obrigatorio');
				setHasDocumentosObrigatoriosPendentes(documentosObrigatorios.length > 0);
				setHasDocumentosPendentes(documentos.length > 0);
				if (documentos.length > 0) {
					sessionStorage.setItem('@Neuro:docs_pending', JSON.stringify(documentos));
					return;
				}
				sessionStorage.removeItem('@Neuro:docs_pending');
			}
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Realiza o login do usuário.
	 * Faz uma requisição para a API de login e armazena os tokens e dados do usuário no sessionStorage.
	 * @param {Object} param0 - Objeto contendo username e password.
	 * @returns {Object} response - Resposta da API.
	 */
	const signIn = useCallback(async ({ username, password }) => {
		try {
			const response = await api.post('api/login', {
				'email': username, 'password': password,
			});

			if (response?.status === 404 || response?.status === 401 || response?.data?.message === 'Invalid Credentials' || response?.data?.message === 'Acesso expirou') {
				return response;
			}

			const { refresh_token, access_token, user } = response?.data;

			setIsAuthenticated(true);

			// Limpa a sessão
			sessionStorage.clear();
			sessionStorage.clear();

			sessionStorage.setItem('@Neuro:access_token', access_token);
			sessionStorage.setItem('@Neuro:refresh_token', refresh_token);
			api.defaults.headers.authorization = `Bearer ${access_token}`;
			const newUser = { ...user, route_tipo_user: ChangeCase.toSnakeCase(user.tipo_user.toLowerCase()) };
			sessionStorage.setItem('@Neuro:user', JSON.stringify(newUser));

			setData({ access_token, user: newUser });

			setDocumentosPendentesSessionStorage(user?.documentos_pendentes);
			return response;
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Verifica se o usuário possui uma permissão específica.
	 * @param {number} Id - ID da permissão.
	 * @returns {boolean} - Retorna true se o usuário possui a permissão, caso contrário false.
	 */
	const getPermissionID = (Id) => {
		try {
			const filtraPagos = data?.user?.permissions?.filter((permissao) => permissao.id === Number(Id));
			return filtraPagos.length > 0;
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Altera o tipo de usuário.
	 * Atualiza o tipo de usuário no sessionStorage e adiciona a lista de permissões.
	 * @param {string} tipo_user - Novo tipo de usuário.
	 */
	const changeTipoUser = useCallback((tipo_user) => {
		try {
			let user = JSON.parse(sessionStorage.getItem('@Neuro:user'));
			const permissoes_por_grupo = api.get('api/group?with=permission')
				.then((response) => response.data);
			user = {
				...user,
				tipo_user: tipo_user,
				permissoes_por_grupo: permissoes_por_grupo
			};
			sessionStorage.setItem('@Neuro:user', JSON.stringify(user));
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Função para coletar permissões do usuário com base na URL atual.
	 *
	 * @returns {Object|Array} Um objeto contendo as permissões locais do usuário ou uma lista de permissões.
	 * @throws {Error} Se ocorrer um erro durante a coleta das permissões.
	 */
	const coletaPermissoes = () => {
		try {
			const usuario = data.user;
			const permissaoAtual = data?.user?.permissoes_por_grupo[0]?.permissions?.find((find) => window.location.pathname.split(usuario.tipo_user.toLowerCase())[1] === find.link && find.linkable && !find.sublinkable);
			const localPermissoes = [];
			if (permissaoAtual?.permissions) {
				permissaoAtual?.permissions?.forEach((permissao) => {
					const nomePermissao = permissao.name.split('.');
					localPermissoes[nomePermissao[1]] = true;
				});
				return localPermissoes;
			}
			const permissoes = data.user.permissoes_por_grupo[0].permissions;
			for (let index = 0; index < permissoes.length; index++) {
				const permissao = permissoes[index];
				const nomePermissao = permissao.name.split('.');
				let local = window.location.pathname.split('/')[3];
				if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
					localPermissoes[nomePermissao[1]] = true;
				}
			}
			return permissoes;
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Função para coletar permissões do usuário com base na URL atual.
	 *
	 * @returns {Object|Array} Um objeto contendo as permissões locais do usuário ou uma lista de permissões.
	 * @throws {Error} Se ocorrer um erro durante a coleta das permissões.
	 */
	const coletaPermissoesPagina = () => {
		try {
			const usuario = data.user;
			const splitedLocations = window.location.pathname.split(usuario.route_tipo_user)[1].split('/');
			const paginaAtual = splitedLocations[1];
			const permissoesAtuais = data?.user?.permissions?.filter((filter) => (String(filter.link).includes(paginaAtual)) && (filter.linkable === null || Boolean(filter.linkable) === false));
			const localPermissoes = [];
			if (permissoesAtuais) {
				permissoesAtuais?.forEach((permissao) => {
					const nomePermissao = permissao.name.split('.');
					switch (nomePermissao.length) {
						case 3:
							localPermissoes[nomePermissao[1]] = { [nomePermissao[2]]: true };
							break;
						default:
							localPermissoes[nomePermissao[1]] = true;
							break;
					}
				});
				return localPermissoes;
			} else {
				return [];
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Função para coletar permissões do usuário com base na URL atual.
	 *
	 * @returns {Object|Array} Um objeto contendo as permissões locais do usuário ou uma lista de permissões.
	 * @throws {Error} Se ocorrer um erro durante a coleta das permissões.
	 */
	const coletaPermissoesAsync = async () => {
		try {
			const usuario = data.user;
			const permissaoAtual = data?.user?.permissoes_por_grupo[0]?.permissions?.find((find) => window.location.pathname.split(usuario.tipo_user.toLowerCase())[1] === find.link && find.linkable && !find.sublinkable);
			const localPermissoes = [];
			if (permissaoAtual?.permissions) {
				permissaoAtual?.permissions?.forEach((permissao) => {
					const nomePermissao = permissao.name.split('.');
					localPermissoes[nomePermissao[1]] = true;
				});
				return localPermissoes;
			}
			const permissoes = data.user.permissoes_por_grupo[0].permissions;
			for (let index = 0; index < permissoes.length; index++) {
				const permissao = permissoes[index];
				const nomePermissao = permissao.name.split('.');
				let local = window.location.pathname.split('/')[3];
				if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
					localPermissoes[nomePermissao[1]] = true;
				}
			}
			return permissoes;
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Função para coletar permissões completas do usuário.
	 *
	 * @returns {Object|Array} Retorna um objeto com permissões locais ou uma lista de permissões.
	 * @throws {Error} Lança um erro caso ocorra algum problema durante a execução.
	 */
	const coletaPermissoesFull = () => {
		try {
			const usuario = data.user;
			const localPermissoes = [];
			const listagemDePermissoes = usuario.permissions || usuario.permissoes_por_grupo[0].permissions;
			const local = window.location.pathname.split('/')[3];
			const sublink_id = listagemDePermissoes.find(
				(find) => {
					return `/${local}` === find.link && find.linkable && !find.sublinkable
				});
			if (listagemDePermissoes) {
				const permissoes = usuario.permissions;
				for (let index = 0; index < permissoes.length; index++) {
					const permissao = permissoes[index];
					const nomePermissao = permissao.name.split('.');
					const local = window.location.pathname.split('/')[3];
					if (permissao.linkable && permissao.sublinkable && permissao.sublink_id === sublink_id.id) {
						localPermissoes.push({ ...permissao });
					}
				}
				return localPermissoes;
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Atualiza os dados do usuário.
	 * Armazena os novos dados do usuário no sessionStorage.
	 * @param {Object} user - Objeto contendo os dados do usuário.
	 */
	const updateUser = useCallback((user) => {
		try {
			sessionStorage.setItem('@Neuro:user', JSON.stringify(user));
			setData({
				token: data.token, user,
			});
		} catch (error) {
			console.error(error);
		}
	}, [setData, data.token]);
	//#endregion

	//#region USE EFFECT
	useEffect(() => {
		try {
			if (!hasDocumentosObrigatoriosPendentes) return;
			const usuario = data.user;
			navigate(`/dashboard/${usuario.tipo_user.toLowerCase()}/documentos-pendentes/usuario/${usuario.id}`);
		} catch (error) {
			console.error(error);
		}
	}, [hasDocumentosObrigatoriosPendentes]);
	useEffect(() => {
		setIsAuthenticated(sessionStorage.getItem('@Neuro:access_token') !== null && sessionStorage.getItem('@Neuro:access_token') !== undefined);
	}, []);
	//#endregion

	//#region RETURN
	return (
		<AuthContext.Provider
			value={{
				user: data.user,
				signIn,
				signOut,
				updateUser,
				isAuthenticated,
				setIsAuthenticated,
				setData,
				getPermissionID,
				changeTipoUser,
				documentosPendentes,
				hasDocumentosPendentes,
				hasDocumentosObrigatoriosPendentes,
				verificaExisteDocumentoPendente,
				coletaPermissoes,
				coletaPermissoesFull,
				coletaPermissoesAsync,
				coletaPermissoesPagina
			}}
		>
			{children}
		</AuthContext.Provider>
	);
	//#endregion
};

//#region USE
/**
 * Hook que retorna o contexto de autenticação.
 *
 * @returns {Object} O contexto de autenticação.
 */
const useAuth = () => {
	//#region RETURN
	return useContext(AuthContext);
	//#endregion
}
//#endregion

export { AuthProvider, useAuth };
