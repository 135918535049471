import React from 'react';
import { AssinaturaContainer, Data, AssinaturaImagem, LinhaAssinatura, Nome, SizeContainer } from './styles';

/**
 * Componente AssinaturaA4
 * 
 * @param {Object} props - Propriedades do componente
 * @param {number|null} [props.width=null] - Largura do componente
 * @param {string} [props.tipo='clinica'] - Tipo de assinatura ('clinica' ou 'terapeuta')
 * @param {string} [props.nome='NEURO INTENSIVA - 24.928.949/0001-14'] - Nome a ser exibido abaixo da linha de assinatura
 * @param {string|null} [props.assinaturaImg=null] - URL da imagem da assinatura
 * @param {Object} [props.rest] - Outras propriedades adicionais
 * 
 * @returns {JSX.Element} - Elemento JSX que representa a assinatura
 */
const AssinaturaA4 = ({ className, width = null, tipo = 'clinica', nome = 'NEURO INTENSIVA - 24.928.949/0001-14', add, assinaturaImg = null, ...rest }) => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).toUpperCase();

    return (
        <SizeContainer className={`${className}`} width={width} {...rest}>
            <AssinaturaContainer className={`${tipo === 'terapeuta' ? 'terapeuta' : ''}`} width={width}>
                {tipo !== 'terapeuta' && (
                    <>
                        <Data>CURITIBA, {formattedDate}</Data>
                        <br />
                    </>
                )}
                {assinaturaImg && (
                    <AssinaturaImagem src={assinaturaImg} alt="Assinatura" />
                )}
                <LinhaAssinatura className={tipo === 'terapeuta' ? 'terapeuta' : ''} />
                <Nome className={tipo === 'terapeuta' ? 'terapeuta' : ''}>{nome}{add ? <><br></br>{add}</> : <></>}</Nome>
            </AssinaturaContainer>
        </SizeContainer>
    );
};

export default AssinaturaA4;