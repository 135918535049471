import Button from 'components/Button';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import TituloPagina from 'components/TituloPagina';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import api from 'services/api';
import { ModalNotificacao } from './ModalNotificacao';

/**
 * Componente de Notificações.
 * 
 * @component
 * @returns {JSX.Element} Componente de Notificações.
 * 
 * @example
 * <Notificacoes />
 * 
 * @description
 * Este componente exibe uma lista de notificações com várias colunas, incluindo usuário, título, nível de urgência, tipo, modo de envio, visualizado e uma opção para visualizar a notificação.
 * 
 * @function
 * @name Notificacoes
 * 
 * @property {Array} notificacoes - Estado que armazena a lista de notificações.
 * @property {Function} setNotificacoes - Função para atualizar o estado das notificações.
 * @property {Object} modalNotificacaoRef - Referência para o modal de notificação.
 * @property {Array} columns - Configuração das colunas da tabela de notificações.
 * 
 * @function getNotificacao
 * @description Função assíncrona para buscar as notificações da API.
 * 
 * @function handleVisualizar
 * @description Função para exibir uma notificação em um modal de visualização.
 * @param {Object} notificacao - Objeto de notificação a ser visualizado.
 * 
 * @useEffect
 * @description Hook de efeito que chama a função getNotificacao ao montar o componente.
 * 
 * @returns {JSX.Element} Retorna o componente de Notificações.
 */
export default function Notificacoes() {
    //#region VARIAVEIS
    const [notificacoes, setNotificacoes] = useState([]);
    const modalNotificacaoRef = useRef();
    const columns = useMemo(() => [
        {
            Header: 'Usuário',
            accessor: 'user.name',
            filterable: true,
        },
        {
            Header: 'Título',
            accessor: 'titulo',
            filterable: true,
        },
        {
            Header: 'Nível de Urgência',
            accessor: 'nivel_urgencia',
            filterable: true,
        },
        {
            Header: 'Tipo',
            accessor: 'tipo',
            filterable: true,
        },
        {
            Header: 'Modo de Envio',
            accessor: 'modo_envio',
            filterable: true,
        },
        {
            Header: 'Visualizado',
            accessor: 'visualizado',
            filterable: true,
            Cell: ({ value }) => (value ? 'Sim' : 'Não'),
        },
        {
            Header: 'Visualizar',
            accessor: 'id',
            filterable: true,
            Cell: ({ row }) => (
                <BsEye className='pointer' size={25} onClick={() => handleVisualizar(row.original)} />
            ),
        }
    ], []);
    //#endregion
    //#region FUNCOES
    const getNotificacao = async () => {
        try {
            const { data } = await api.get('api/notificacao?with=user');
            setNotificacoes(data);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion
    //#region HANDLES
    const handleVisualizar = (notificacao) => {
        modalNotificacaoRef.current.open(notificacao);
    };
    const handleNotificacaoTeste = async () => {
        try {
            await api.post('api/notificacao/teste');
            getNotificacao();
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        getNotificacao();
    }, []);
    //#endregion
    //#region RENDER
    return (
        <>
            <TituloPagina>Notificações</TituloPagina>
            <div className='container'>
                <TableDefaultPagination columns={columns} source={notificacoes} />
                <Button onClick={() => modalNotificacaoRef.current.open(null)}>Enviar Notificações</Button>
                <Button onClick={handleNotificacaoTeste}>Notificação Teste</Button>
                <ModalNotificacao ref={modalNotificacaoRef} getNotificacao={getNotificacao} />
            </div>
        </>
    );
    //#endregion
};