import React, { cloneElement } from 'react';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { AiOutlineEdit, AiOutlineEye, AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai';
import moment from 'moment';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useSnackbar } from '../../../../context/snackbar';
import { Tooltip } from 'react-tooltip';
import { diferencaEntreDatas } from 'helpers/data.helper';
import { depois } from 'helpers/data.helper';
import { hoje } from 'helpers/data.helper';
import { antes } from 'helpers/data.helper';
import useIcons from 'context/icons';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import styled from 'styled-components';
import { FaPencilAlt } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import useGuiaData from 'context/guia';

/**
 * Componente de tabela para exibição de guias.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Array} props.source - Fonte de dados para a tabela.
 * @param {Function} props.setData - Função para definir os dados da guia selecionada.
 * @param {Object} props.permissoes - Permissões do usuário para ações na tabela.
 * @param {Function} props.handleOpen - Função para abrir a guia em modo de visualização ou edição.
 * @param {Function} props.callBack - Função de callback para ser chamada após uma ação.
 * @param {React.Element} props.createButton - Botão para criar uma nova guia.
 * @param {boolean} props.loading - Indicador de carregamento dos dados.
 *
 * @returns {JSX.Element} Componente de tabela com paginação.
 */
export function TableGuias({
    source,
    setData,
    permissoes,
    handleOpen,
    callBack,
    loading
}) {

    //#region VARIAVEIS
    // Hooks
    const snackbar = useSnackbar();
    const { iconArray } = useIcons();
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    const agora = hoje();
    const { handleValidadata } = useGuiaData();

    // Estados
    const [salvando, setSalvando] = React.useState(false);
    // Estados de edição
    const [editedGuias, setEditedGuias] = React.useState([]);
    const [editingValidity, setEditingValidity] = React.useState(null);
    //#endregion
    //#region HANDLES
    /**
     * Abre a guia para visualização ou edição.
     *
     * @param {Object} data - Dados da guia.
     * @param {boolean} view - Indica se é modo de visualização ou edição.
     */
    const handleOpenGuia = React.useCallback((data, view) => {
        setData(data);
        handleOpen(view, data);
    }, [setData, handleOpen]);

    /**
     * Altera o status da guia (Ativo/Bloqueado).
     *
     * @param {string} status - Novo status da guia.
     * @param {Object} data - Dados da guia.
     */
    const handleStatusGuia = React.useCallback((status, data) => {
        if (status === 'Ativo') {
            let inicio = moment(data.inicio).utc();
            let validade = moment(data.validade).utc();
            if (validade.isBefore(moment())) {
                return Swal.fire({
                    title: 'A guia já venceu, não é possivel reativa-la!',
                    html: 'Vigência de: ' + inicio.format('DD/MM/YYYY') + ' até: ' + validade.format('DD/MM/YYYY'),
                    icon: 'error',
                    timer: 2000
                });
            }
        }
        let texto = (status === 'Bloqueado') ? 'bloquear' : 'desbloquear';
        Swal.fire({
            title: `Deseja mesmo ${texto} essa guia?`,
            html: status === 'Bloqueado'
                ? 'Ao <b>bloquear</b> essa guia, ela não podera ser usada pelo Controle Diário para confirmar o atendimento.'
                : 'Ao <b>desbloquear</b> essa guia, você esta ciente, que ela podera ser usada para confirmar atendimentos.',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonAriaLabel: 'Cancelar'
        }).then(response => {
            if (response.isConfirmed) {
                if (data.status !== 'Vencido') {
                    api.patch('api/guia/' + data.id + '?id=' + data.id, { status: status })
                        .then(() => {
                            snackbar.displaMessage('Guia alterada com sucesso!.', 'success');
                        })
                        .catch(e => {
                            console.error(e);
                            snackbar.displaMessage('Não foi possível bloquear guia.', 'error');
                        })
                        .finally(() => {
                            callBack();
                        });
                } else {
                    Swal.fire('Não foi possível mudar status!', 'A guia em questão esta vencida, não é necessário bloquea-la.', 'info');
                }
            }
        });
    }, [snackbar, callBack]);

    /**
     * Salva as alterações feitas nas guias.
     */
    const handleSave = React.useCallback(() => {
        setSalvando(true);
        try {
            if (editedGuias.length === 0) {
                snackbar.displayMessage('Nenhuma guia foi alterada.', 'info');
                setSalvando(false);
                return;
            }
            api.post('api/guia/atualiza/varios', { guias: editedGuias })
                .then(() => {
                    snackbar.displayMessage('Guias atualizadas com sucesso!', 'success');
                    setSalvando(false);
                    setEditedGuias([]);
                    callBack();
                })
                .catch(e => {
                    setSalvando(false);
                    console.error(e);
                    snackbar.displayMessage('Não foi possível atualizar as guias.', 'error');
                });
        } catch (error) {
            setSalvando(false);
            console.error(error);
            snackbar.displayMessage('Ocorreu um erro inesperado.', 'error');
        }
    }, [editedGuias, snackbar, callBack]);

    /**
     * Habilita o modo de edição da validade da guia.
     *
     * @param {number} id - ID da guia.
     */
    const handleEditValidity = React.useCallback((id) => {
        setEditingValidity(id);
    }, []);

    /**
     * Salva a nova validade da guia e desabilita o modo de edição.
     *
     * @param {number} id - ID da guia.
     */
    const handleConfirmEditionValidity = (id) => {
        function edita(id, value) {
            setEditedGuias(prev => {
                const existing = prev.find(g => g.id === id);
                if (existing) {
                    return prev.map(g => g.id === id ? { ...g, validade: value } : g);
                } else {
                    return [...prev, { id, validade: value }];
                }
            });
            setEditingValidity(null);
        }
        const value = document.getElementById(`validade-${id}`).value;
        const guia = source.find(g => g.id === id);
        const conflito = handleValidadata(guia.historicos, guia.inicio, value);

        if (conflito) {
            Swal.fire({
                title: 'Conflito de validade com outra guia.',
                text: 'Deseja realmente salvar a alteração?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    edita(id, value);
                }
            });
            return;
        }

        edita(id, value);
    };
    //#endregion
    React.useEffect(() => {
        if (!editedGuias.length) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [editedGuias, navigator]);
    const columns = React.useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell }) => {
                    const status = cell.value;
                    const pendenteSolicitacao = cell.row.original.pendente_solicitacao;
                    let color = '';
                    switch (status) {
                        case 'Ativo':
                            color = 'text-success';
                            break;
                        case 'Bloqueado':
                            color = 'text-warning';
                            break;
                        case 'Vencido':
                            color = 'text-danger';
                            break;
                        case 'Pendente':
                            color = 'text-warning';
                            break;
                        default:
                            color = 'text-primary';
                            break;
                    }
                    return (
                        <span className={color}>
                            {status}
                            {pendenteSolicitacao === 1 && <span className="ms-2 text-danger">&#x25CF;</span>}
                        </span>
                    );
                },
                filterable: true,
            },
            {
                Header: 'Paciente',
                accessor: 'nome',
                Cell: ({ cell }) => {
                    return (
                        <div className="w-300px">
                            {cell.row.original.nome}
                        </div>
                    );
                },
                filterable: true,
            },
            {
                Header: 'Terapia',
                accessor: 'terapia',
                filterable: true,
            },
            {
                Header: 'Especialidade',
                accessor: 'especialidade',
                filterable: true,
            },
            {
                Header: 'Plano de Saúde',
                accessor: 'nome_reduzido',
                filterable: true,
            },
            {
                Header: 'Valor (R$)',
                accessor: 'valor',
                Cell: ({ cell }) => {
                    return cell.row.original.valor ? cell.row.original.valor : ' - ';
                },
                filterable: true,
            },
            {
                Header: 'Quant. Liberada',
                accessor: 'quantidade_liberada',
                filterable: true,
            },
            {
                Header: 'Quant. Feita',
                accessor: 'quantidade_feita',
                filterable: true,
            },
            {
                Header: 'Saldo',
                accessor: 'Saldo',
                filterable: true,
                Cell: ({ cell }) => {
                    return cell.row.original.quantidade_liberada - cell.row.original.quantidade_feita;
                }
            },
            /*
            {
                Header: 'Quantidade',
                Cell: ({ cell }) => {
                    const ql = Number(cell.row.original.quantidade_liberada);
                    const qf = Number(cell.row.original.quantidade_feita);
                    const saldo = ql - qf;
                    const porcentagem = 100 - Math.round(saldo * 100 / ql);
                    const stautsInterpolation =
                        (porcentagem < 50)
                            ? 'success'
                            : (porcentagem < 80)
                                ? 'warning'
                                : 'danger';
                    const status = `bg-${stautsInterpolation}`;
                    return (
                        <div className="w-200px h-46px p-1" style={{ verticalAlign: 'middle', alignContent: 'center', alignItems: 'center' }}>
                            {ql === 0
                                ? 'Sem Quantidade'
                                : <>
                                    <p className="p-0" style={{ position: 'absolute', margin: '15px 100px', transform: 'translate(-50%, -50%)' }}>
                                        {ql} / {qf}
                                    </p>
                                    <div data-tooltip-id={'liberado-usado-saldo-' + cell.row.original.id} className="progress h-30px" role="progressbar" aria-label="Porcentagem restante" aria-valuenow={porcentagem} aria-valuemin="0" aria-valuemax="100">
                                        <div className={`progress-bar ${status}`} style={{ width: `${porcentagem}%` }}>{porcentagem}%</div>
                                    </div>
                                </>}
                            <Tooltip id={'liberado-usado-saldo-' + cell.row.original.id} type='dark' effect='float' style={{ width: '180px' }} className="d-grid border-radius-10px justify-content-between flex-wrap">
                                <span className="w-100 text-left">Quantidade Liberada:<b className="text-right">{cell.row.original.quantidade_liberada}</b></span>
                                <span className="w-100 text-left">Quantidade Usada:<b className="text-right">{cell.row.original.quantidade_feita}</b></span>
                                <span className="w-100 text-left">Quantidade Disponível:<b className="text-right">{saldo}</b></span>
                            </Tooltip>
                        </div>
                    );
                }
            }, */
            {
                Header: 'Data de Solicitação',
                accessor: 'solicitacao',
                //filterType: 'data',
                filterable: false,
                Cell: ({ cell }) => cell.value ? moment.utc(cell.value).format('DD/MM/YYYY') : ' - ',
            },
            {
                Header: 'Data de Aceitação',
                accessor: 'aceitacao',
                //filterType: 'data',
                filterable: false,
                Cell: ({ cell }) => cell.value ? moment.utc(cell.value).format('DD/MM/YYYY') : ' - ',
            },
            {
                Header: 'Data de Início',
                accessor: 'inicio',
                //filterType: 'data',
                filterable: false,
                Cell: ({ cell }) => cell.value ? moment.utc(cell.value).format('DD/MM/YYYY') : ' - ',
            },
            {
                Header: 'Data de Validade',
                accessor: 'validade',
                //filterType: 'data',
                filterable: false,
                Cell: ({ cell }) => {
                    const isEditable = cell.row.original.identificador_geral !== null || cell.row.original.plano_saude_id === 2;
                    const value = editedGuias.find(g => g.id === cell.row.original.id)?.validade ?? cell.value;
                    return (
                        <ValidityContainer>
                            {editingValidity === cell.row.original.id && isEditable ? (
                                <>
                                    <EditableInput
                                        id={`validade-${cell.row.original.id}`}
                                        type="date"
                                        defaultValue={moment.utc(value).format('YYYY-MM-DD')}
                                        autoFocus
                                    />
                                    <button className='btn-outline font-roxo' onClick={(e) => handleConfirmEditionValidity(cell.row.original.id)}>
                                        {iconArray['conferir']}
                                    </button>
                                </>
                            ) : (
                                <>
                                    <span>{moment.utc(value).format('DD/MM/YYYY')}</span>
                                    {isEditable && <FaPencilAlt className="edit-icon" onClick={() => handleEditValidity(cell.row.original.id)} />}
                                </>
                            )}
                        </ValidityContainer>
                    );
                },
            },
            {
                Header: 'Dias a Vencer',
                Cell: ({ cell }) => {
                    let status = cell.row.original.status;
                    let classe = '';
                    if (status === 'Vencido') {
                        classe = 'line-select-red';
                    } else {
                        if (status === 'Bloqueado') {
                            classe = 'line-select-yellow';
                        }
                    }
                    const diferenca = diferencaEntreDatas(agora, cell.row.original.validade) - 1;
                    const emVigencia = depois(new Date(agora), new Date(cell.row.original.inicio)) && antes(new Date(agora), new Date(cell.row.original.validade));
                    return (
                        <div className={classe}>
                            <span> {emVigencia ? diferenca : 0} </span>
                        </div>
                    );
                },
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        (
                            <div id={`acoes-guia-${cell.row.id}`} className="d-flex align-center justify-center">
                                <AiOutlineEye id={`visualiza-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleOpenGuia(cell.row.original, false)} title="Abrir detalhes da guia." />
                                {permissoes['edição'] &&
                                    <AiOutlineEdit id={`edita-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleOpenGuia(cell.row.original, true)} title="Editar guia." />
                                }
                                {permissoes['bloqueio'] ?
                                    cell.row.original.status === 'Bloqueado'
                                        ? <AiOutlineUnlock id={`trava-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleStatusGuia('Ativo', cell.row.original)} title="Desbloquear guia." />
                                        : <AiOutlineLock id={`trava-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleStatusGuia('Bloqueado', cell.row.original)} title="Bloquear guia." />
                                    : <></>
                                }
                            </div>
                        )
                    );
                }
            }
        ]
    );
    const exportColumns = React.useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Paciente',
                accessor: 'nome'
            },
            {
                Header: 'Terapia',
                accessor: 'terapia'
            },
            {
                Header: 'Especialidade',
                accessor: 'especialidade'
            },
            {
                Header: 'Plano de Saúde',
                accessor: 'nome_reduzido'
            },
            {
                Header: 'Valor (R$)',
                accessor: 'valor'
            },
            {
                Header: 'Quantidade Feita',
                accessor: 'quantidade_feita'
            },
            {
                Header: 'Quantidade Liberada',
                accessor: 'quantidade_liberada'
            },
            {
                Header: 'Data de Início',
                accessor: 'inicio',
                Cell: ({ cell }) => moment.utc(cell.value).format('DD/MM/YYYY')
            },
            {
                Header: 'Data de Validade',
                accessor: 'validade',
                Cell: ({ cell }) => moment.utc(cell.value).format('DD/MM/YYYY')
            },
            {
                Header: 'Dias a Vencer',
                Cell: ({ cell }) => {
                    const diferenca = diferencaEntreDatas(agora, cell.row.original.validade) - 1;
                    const emVigencia = depois(new Date(agora), new Date(cell.row.original.inicio)) && antes(new Date(agora), new Date(cell.row.original.validade));
                    return (
                        <div>
                            <span> {emVigencia ? diferenca : 0} </span>
                        </div>
                    );
                },
            }
        ]
    );

    return (
        <>
            <TableDefaultPagination
                prefix_id={'guias'}
                id="tabela-guias"
                thead_id="tabela-thead-de-guias"
                tbody_id="tabela-tbody-de-guias"
                columns={columns}
                exportColumns={exportColumns}
                source={source}
                searchBar={true}
                tableTitle="Listagem de Guias"
                loading={loading}
                createButton={
                    <button className={`btn-padrao w-300px ${editedGuias?.length ? ' btn-marca-vermelha font-laranja' : ''}`} onClick={handleSave} disabled={salvando}>
                        {salvando
                            ? <>
                                <ClipLoader color={'#fff'} size={20} loading={true} /> Salvando...
                            </>
                            : <>{cloneElement(iconArray['salvar'], { className: "me-2" })} Salvar Guias</>
                        }
                    </button>
                }
            />
        </>
    );
}

const EditableInput = styled.input`
    border: none;
    border-bottom: 1px solid black;
    background: none;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const ValidityContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .edit-icon {
        display: none;
        position: absolute;
        right: 10px;
        cursor: pointer;
    }

    &:hover .edit-icon {
        display: block;
    }
`;