//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import { TutorialBadge } from 'components/AjudanteInicial';
import Button from 'components/Button';
import Carregamento from 'components/Carregamento';
import Input, { SimpleInput } from 'components/Input';
import ComentariosModal from 'components/Modais/Comentarios';
import AgendaPacienteModal from 'components/Modais/Pacientes/AgendaModal';
import Select from 'components/Select';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { useAuth } from 'context/auth';
import useEspecialidadesData from 'context/especialidade';
import useIcons from 'context/icons';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import usePlanoSaudeData from 'context/plano-saude';
import { useSnackbar } from 'context/snackbar';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTerapeutasData from 'context/terapeuta';
import useTerapiasData from 'context/terapia';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { AiOutlineClear } from 'react-icons/ai';
import { BsCheck, BsEye, BsFilter, BsSearch } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { FaBroom, FaSave } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import ReactModal from 'react-modal';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';
import TablePeriodosPaciente from './table-preiodos-paciente';
import AgendaModalTerapeuta from 'components/Modais/Terapeuta/AgendaModal';
import BalaoDica from 'components/Baloes/BalaoDica';
import useRealce from 'context/realce';
import useTipoAtendimentosData from 'context/tipo-atendimento';
//#endregion

/**
 * Componente RelatorioFinanceiro
 *
 * Este componente é responsável por exibir e gerenciar as pendências de pagamento dos atendimentos.
 * Ele permite filtrar, visualizar e atualizar informações relacionadas aos atendimentos.
 *
 * @component
 * @example
 * return (
 *   <RelatorioFinanceiro />
 * )
 *
 * @returns {JSX.Element} O componente FinanceiroAtendimentos.
 *
 * @description
 * O componente utiliza diversos hooks e funções para gerenciar o estado e as operações relacionadas aos atendimentos.
 * Ele inclui filtros para buscar atendimentos com base em diferentes critérios, como data, tipo de atendimento, status, terapeuta, paciente, entre outros.
 * Também permite a atualização de campos específicos dos atendimentos, como número de nota fiscal, status de execução e status financeiro.
 *
 * @see usePageBase
 * @see useSnackbar
 * @see useAuth
 * @see useIcons
 * @see useTipoAtendimentosData
 * @see useStatusAtendimentosData
 * @see useTerapeutasData
 * @see useEspecialidadesData
 * @see useTerapiasData
 * @see usePlanoSaudeData
 * @see usePacienteData
 * @see React.useContext
 * @see useRef
 * @see useState
 * @see useMemo
 * @see useCallback
 * @see useEffect
 *
 * @property {Object} pageBase - Configurações da página base, incluindo título, descrição e rotas.
 * @property {string} title - Título da tela.
 * @property {Object} snackbar - Objeto para exibir mensagens de feedback.
 * @property {Object} user - Informações do usuário autenticado.
 * @property {Function} getIcon - Função para obter ícones.
 * @property {Object} navigator - Objeto de navegação.
 * @property {Object} modalComentariosRef - Referência para o modal de comentários.
 * @property {Object} modalAgendaPacienteRef - Referência para o modal de agenda do paciente.
 * @property {boolean} modalInicialIsOpen - Estado que indica se o modal inicial está aberto.
 * @property {Array} tipoAtendimentosOptions - Opções de tipos de atendimento.
 * @property {Array} statusAtendimentosOptions - Opções de status de atendimento.
 * @property {Array} terapeutasOptions - Opções de terapeutas.
 * @property {Array} especialidadesOptions - Opções de especialidades.
 * @property {Array} terapiasOptions - Opções de terapias.
 * @property {Array} planoSaudeOptions - Opções de planos de saúde.
 * @property {Array} pacienteOptions - Opções de pacientes.
 * @property {Array} statusDeAssinaturaOptions - Opções de status de assinatura.
 * @property {Array} statusLiberacaoOptions - Opções de status de liberação.
 * @property {Array} statusExecucao - Opções de status de execução.
 * @property {Array} statusExecucaoParticular - Opções de status de execução para atendimentos particulares.
 * @property {Array} periodos - Opções de períodos.
 * @property {Array} diasDaSemana - Dias da semana.
 * @property {Object} filters - Filtros aplicados aos atendimentos.
 * @property {boolean} filtroAlterado - Estado que indica se o filtro foi alterado.
 * @property {boolean} dataAlterado - Estado que indica se a data foi alterada.
 * @property {Array} atendimentos - Lista de atendimentos.
 * @property {Array} atendimentosAlterados - Lista de atendimentos alterados.
 * @property {Array} atendimentosOriginal - Lista original de atendimentos.
 * @property {boolean} salvando - Estado que indica se os dados estão sendo salvos.
 * @property {boolean} loading - Estado que indica se os dados estão sendo carregados.
 * @property {boolean} pendenteDeSave - Estado que indica se há alterações pendentes de salvar.
 * @property {Object} okFinanceiroFill - Objeto para preencher o campo "OK Financeiro".
 * @property {Object} statusExecucaoParticularFill - Objeto para preencher o campo "Status de Execução Particular".
 * @property {string} numeroNFFill - Valor para preencher o campo "Número NF".
 * @property {Function} filtrar - Função para filtrar a lista de atendimentos.
 * @property {Function} carregarAtendimentos - Função para carregar atendimentos com base nos filtros.
 * @property {Function} preencherCampo - Função para preencher campos específicos dos atendimentos.
 * @property {Function} handleOpenModalAgendaPaciente - Função para abrir o modal de agenda do paciente.
 * @property {Function} handleRealcaMouseEnterLeaveCell - Função para manipular a entrada e saída do mouse sobre uma célula.
 * @property {Function} handleClickConsultar - Função para lidar com o clique no botão de consulta.
 * @property {Function} handleSalva - Função para salvar as alterações nos atendimentos financeiros.
 * @property {Function} handleHover - Função para manipular o evento de hover em elementos DOM.
 * @property {Function} handleChangeTableInputs - Função para manipular as mudanças nos inputs da tabela.
 * @property {Function} handleLimpaFiltro - Função para limpar todos os filtros de pesquisa.
 * @property {Function} handleLimpaParametros - Função para limpar os parâmetros de data e os arrays de atendimentos.
 * @property {Function} handleChangeFilters - Função para manipular a mudança de filtros.
 * @property {Function} handleVisualizaAtendimento - Função para manipular a abertura do modal de comentários com os dados do atendimento.
 * @property {Array} columns - Colunas da tabela de atendimentos.
 */
const FinanceiroAtendimentos = () => {
    const pageBase = usePageBase({
        title: 'Atendimentos',
        description: 'Aqui você pode visualizar e gerenciar as pendências de pagamento dos atendimentos.',
        routes: [
            'dashboard/[modulo]/financeiro/relatorio'
        ]
    })
    //#region VARIÁVEIS

    // Informações da Tela
    // Outros
    const snackbar = useSnackbar();

    // Realce
    const { handleRealcaMouseEnterLeaveCell } = useRealce();

    // Usuário
    const { user } = useAuth();

    // Ícones
    const { getIcon } = useIcons();

    // Navegação
    const { navigator } = React.useContext(UNSAFE_NavigationContext);

    // Referências
    const modalComentariosRef = useRef();
    const modalAgendaPacienteRef = useRef();
    const modalAgendaTerapeutaRef = useRef();
    const balaoDicaRefs = [useRef(null), useRef(null)];

    // Modal Inicial
    const [modalInicialIsOpen, setModalInicialIsOpen] = useState(true);

    //#region Listas
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const memotipoAtendimentosOptions = useMemo(() => tipoAtendimentosOptions, [tipoAtendimentosOptions]);

    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const memostatusAtendimentosOptions = useMemo(() => statusAtendimentosOptions, [statusAtendimentosOptions]);

    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const memoterapeutasOptions = useMemo(() => terapeutasOptions, [terapeutasOptions]);

    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const memoespecialidadesOptions = useMemo(() => especialidadesOptions, [especialidadesOptions]);

    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const memoterapiasOptions = useMemo(() => terapiasOptions, [terapiasOptions]);

    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const memoplanoSaudeOptions = useMemo(() => planoSaudeOptions, [planoSaudeOptions]);

    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const memopacienteOptions = useMemo(() => pacienteOptions, [pacienteOptions]);

    const statusDeAssinaturaOptions = useMemo(() => [
        { value: null, label: 'vazio' },
        { value: 0, label: 'Pendente' },
        { value: 1, label: 'Assinado' }
    ], []);

    const [numeroNFList, setNumeroNFList] = useState([]);

    const statusLiberacaoOptions = useMemo(() => [
        { value: null, label: 'vazio' },
        { value: 0, label: <CgClose className='font-vermelho' size={22} /> },
        { value: 1, label: <BsCheck className='success' size={30} /> }
    ], []);

    const statusExecucao = useMemo(() => [
        { value: null, label: 'vazio' },
        { value: 'pendente', label: 'Pendente' },
        { value: 'executado', label: 'Executado' },
        { value: 'executado-sce', label: 'Executado SCE' },
        { value: 'nao', label: 'Não Executado' },
        { value: 'pago', label: 'Pago' }
    ], []);

    const statusExecucaoParticular = useMemo(() => [
        { value: null, label: 'vazio' },
        { value: 'pendente', label: 'Pendente' },
        { value: 'pago', label: 'Pago' }
    ], []);

    const periodos = useMemo(() => [
        { value: 'manha', label: 'Manhã' },
        { value: 'tarde', label: 'Tarde' }
    ], []);

    const diasDaSemana = [
        { value: 1, label: 'Segunda-feira' },
        { value: 2, label: 'Terça-feira' },
        { value: 3, label: 'Quarta-feira' },
        { value: 4, label: 'Quinta-feira' },
        { value: 5, label: 'Sexta-feira' },
        { value: 6, label: 'Sábado' },
        { value: 7, label: 'Domingo' }
    ];
    const statusEvolucaoOptions = [
        { value: 'Pendente', label: 'Pendente' },
        { value: 'Realizada', label: 'Realizada' },
        { value: 'Glosa Auditoria', label: 'Glosa Auditoria' },
        { value: 'Glosa Aceita', label: 'Glosa Aceita' },
        { value: 'Glosa Negada', label: 'Glosa Negada' },
        { value: 'Glosa Interna', label: 'Glosa Interna' }
    ];
    //#endregion

    //#region Dados de Filtro
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [tipoAtendimentoId, setTipoAtendimentoId] = useState('');
    const [statusAtendimentoId, setStatusAtendimentoId] = useState('');
    const [terapeutaId, setTerapeutaId] = useState('');
    const [liberado, setLiberado] = useState('');
    const [executado, setExecutado] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [diaSemana, setDiaSemana] = useState('');
    const [especialidadeId, setEspecialidadeId] = useState('');
    const [terapiaId, setTerapiaId] = useState('');
    const [pacienteId, setPacienteId] = useState('');
    const [statusDeAssinatura, setStatusDeAssinatura] = useState(null);
    const [planoSaudeId, setPlanoSaudeId] = useState('');
    const [planoSaudeIdInicial, setPlanoSaudeIdInicial] = useState(null);
    const [statusEvolucao, setStatusEvolucao] = useState([]);
    const [okFinanceiro, setOkFinanceiro] = useState('');
    const [numeroNF, setNumeroNF] = useState('');
    const [modoCriacao, setModoCriacao] = useState('');
    const defaultFilters = {
        data_inicio: '',
        data_fim: '',
        tipo_atendimento_id: '',
        status_atendimento_id: '',
        terapeuta_id: '',
        liberado: '',
        executado: '',
        periodo: '',
        dia_semana: '',
        paciente_id: '',
        especialidade_id: '',
        terapia_id: '',
        plano_saude_id: '',
        status_de_assinatura: null,
        ok_financeiro: '',
        status_evolucao: '',
        numero_nf: '',
        modo_criacao: ''
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [filtroAlterado, setFiltroAlterado] = useState(false);
    const [dataAlterado, setDataAlterado] = useState(false);
    //#endregion

    //#region Tabela
    const [atendimentos, setAtendimentos] = useState([]);
    const memoAtendimentos = useMemo(() => atendimentos, [atendimentos]);
    const [atendimentosAlterados, setAtendimentosAlterados] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const memoAtendimentosOriginal = useMemo(() => atendimentosOriginal, [atendimentosOriginal]);
    const [salvando, setSalvando] = useState(false);
    const [loading, setloading] = useState(false);
    const [pendenteDeSave, setPendenteDeSave] = useState(false);

    const [okFinanceiroFill, setOkFinanceiroFill] = useState({});
    const [statusExecucaoParticularFill, setStatusExecucaoParticularFill] = useState({});
    const [numeroNFFill, setNumeroNFFill] = useState('');
    //#endregion

    // Estilos Customizados
    const override = `
        display: block;
        margin-top: 8px;
        margin-left: 4px;
        border-color: orange;
    `;
    const customStyles = {
        content: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: 'fit-content',
            width: '40vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            zIndex: '1050',
        },
    };

    //#endregion
    //#region FUNCOES
    /**
     * Filtra a lista de atendimentos com base nos filtros aplicados.
     *
     * Esta função aplica os filtros fornecidos pelo formulário aos atendimentos listados.
     * A filtragem é realizada com base nas seguintes condições:
     * - Data de início e fim do atendimento
     * - Tipo de atendimento
     * - Status de atendimento
     * - Terapeuta responsável pelo atendimento
     * - Status de liberação
     * - Status de execução (pendente, executado, pago)
     * - Paciente relacionado ao atendimento
     * - Especialidade do terapeuta
     * - Terapia realizada no atendimento
     * - Plano de saúde utilizado
     * - Status de assinatura do atendimento (pendente ou assinado)
     *
     * @param {Array} data - A lista de atendimentos que será filtrada.
     * @return {void}
     */
    const filtrar = useCallback((data = null) => {
        const atendimentosLocal = data ? data : memoAtendimentosOriginal;
        const nfs = [...new Set(atendimentosLocal.map((item) => item.numero_nf))];
        setNumeroNFList(nfs);
        const filteredAtendimentos = atendimentosLocal.filter((item) => {
            const matchesTipoAtendimentoId = filters.tipo_atendimento_id?.length > 0
                ? filters.tipo_atendimento_id.find((find) => Number(item.tipo_atendimento_id) === Number(find.value))
                : true;

            const matchesStatusAtendimentoId = filters.status_atendimento_id?.length > 0
                ? filters.status_atendimento_id.find((find) => Number(item.sugestao_recepcao_original) === Number(find.value))
                : true;

            const matchesTerapeutaId = filters.terapeuta_id?.length > 0
                ? filters.terapeuta_id.find((find) => Number(item.terapeuta_id) === Number(find.value))
                : true;

            const matchesPacienteId = filters.paciente_id?.length > 0
                ? filters.paciente_id.find((find) => Number(item.paciente_id) === Number(find.value))
                : true;

            const matchesLiberado = filters.liberado
                ? item.liberado === filters.liberado
                : true;

            const matchesExecutado = filters.executado
                ? String(item.executado).toUpperCase() === String(filters.executado).toUpperCase()
                : true;

            const matchesEspecialidadeId = filters.especialidade_id?.length > 0
                ? filters.especialidade_id.find((find) => Number(item.especialidade_id) === Number(find.value))
                : true;

            const matchesTerapiaId = filters.terapia_id?.length > 0
                ? filters.terapia_id.find((find) => Number(item.terapia_id) === Number(find.value))
                : true;

            const matchesDiaSemana = filters.dia_semana?.length > 0
                ? filters.dia_semana.find((find) => item.paciente.periodos.find((find2) => String(find2.dia_semana).toUpperCase() === String(find.label).toUpperCase()))
                : true;

            const matchesPeriodo = filters.periodo?.length > 0
                ? filters.periodo.find((find) => item.paciente.periodos.find((find2) => find2?.periodo?.includes(find.label)))
                : true;

            const matchesPlanoSaudeId = filters.plano_saude_id?.length > 0
                ? filters.plano_saude_id.find((find) => Number(item.plano_saude_id) === Number(find.value))
                : true;

            const matchesStatusDeAssinatura = filters.status_de_assinatura !== null
                ? item.assinado === filters.status_de_assinatura
                : true;
            const matchesStatusEvolucao = filters.status_evolucao
                ? item.status_evolucao === filters.status_evolucao
                : true;
            const matchesOkFinanceiro = filters.ok_financeiro !== ''
                ? item.ok_financeiro === filters.ok_financeiro
                : true;

            const matchesNumeroNF = filters.numero_nf !== ''
                ? item.numero_nf === filters.numero_nf
                : true;

            const matchesModoCriacao = filters.modo_criacao.length > 0
                ? filters.modo_criacao.find(find => item.modo_criacao_nome === find.value)
                : true;

            return (
                matchesTipoAtendimentoId &&
                matchesStatusAtendimentoId &&
                matchesTerapeutaId &&
                matchesLiberado &&
                matchesExecutado &&
                matchesPacienteId &&
                matchesEspecialidadeId &&
                matchesTerapiaId &&
                matchesPlanoSaudeId &&
                matchesDiaSemana &&
                matchesPeriodo &&
                matchesStatusDeAssinatura &&
                matchesStatusEvolucao &&
                matchesOkFinanceiro &&
                matchesNumeroNF &&
                matchesModoCriacao
            );
        });
        setAtendimentos(filteredAtendimentos);
        setFiltroAlterado(false);
    }, [filters, memoAtendimentosOriginal]);
    /**
     * Função assíncrona para carregar atendimentos com base em filtros específicos.
     * Define o estado de carregamento como verdadeiro, limpa a lista de atendimentos,
     * configura os filtros e faz uma requisição POST para obter os dados filtrados.
     * Após receber os dados, atualiza a lista de atendimentos original, aplica os filtros,
     * define o estado de carregamento como falso e marca que os dados não foram alterados.
     *
     * @async
     * @function carregarAtendimentos
     * @returns {Promise<void>} Uma promessa que resolve quando os atendimentos são carregados.
     */
    const carregarAtendimentos = useCallback(async () => {
        setloading(true);
        setAtendimentos([]);
        const carregaFiltros = {
            data_inicio: dataInicio,
            data_fim: dataFim,
            tipo_atendimento_id: '',
            status_atendimento_id: '',
            terapeuta_id: '',
            liberado: '',
            executado: '',
            paciente_id: '',
            especialidade_id: '',
            terapia_id: '',
            plano_saude_id: planoSaudeIdInicial,
            status_de_assinatura: '',
            duplica_alteracoes: false,
            with: 'paciente_periodos'
        };
        const response = await api.post('api/atendimento/relatorio/filtrado/puro', carregaFiltros);
        const data = response?.data;
        setAtendimentosOriginal(data);
        filtrar(data);
        setloading(false);
        setDataAlterado(false);
    }, [dataInicio, dataFim, planoSaudeIdInicial, filtrar]);
    /**
     * Preenche o campo especificado com o valor correspondente e atualiza os atendimentos.
     *
     * @param {string} campo - O nome do campo a ser preenchido. Pode ser 'numero_nf', 'status_execucao_particular' ou 'ok_financeiro'.
     * @param {Array} memoAtendimentos - A lista de atendimentos memorizada.
     * @returns {void}
     */
    const preencherCampo = useCallback((campo) => {
        let valor;
        let newAtendimentos = memoAtendimentos;
        switch (campo) {
            case 'numero_nf':
                valor = numeroNFFill;
                if (valor === undefined) return '';
                newAtendimentos = newAtendimentos.map((atendimento) => {
                    const element = document.getElementById(`numero_nf=${atendimento.id}`);
                    if (element) {
                        if ((element.value === '' || element.value === null && (atendimento.numero_nf === null || atendimento.numero_nf === ''))) {
                            element.value = valor;
                            atendimento.numero_nf = valor;
                        }
                    } else {
                        if (atendimento.numero_nf === null || atendimento.numero_nf === '') {
                            atendimento.numero_nf = valor;
                        }
                    }
                    return atendimento;
                });
                setAtendimentos(newAtendimentos);
                setNumeroNFFill('');
                setAtendimentosAlterados(newAtendimentos);
                break;
            case 'status_execucao_particular':
                valor = statusExecucaoParticularFill?.value;
                if (valor === undefined) return '';
                newAtendimentos = newAtendimentos.map((atendimento) => {
                    if (atendimento.nome_reduzido.toUpperCase() === 'PARTICULAR') {
                        atendimento.executado = valor;
                        return atendimento;
                    }
                    return atendimento;
                })
                setAtendimentos(newAtendimentos);
                setStatusExecucaoParticularFill({});
                setAtendimentosAlterados(newAtendimentos);
                break;
            case 'ok_financeiro':
                valor = okFinanceiroFill?.value;
                if (valor === undefined) return '';
                newAtendimentos.map((atendimento) => {
                    const element = document.getElementById(`ok_financeiro=${atendimento.id}`);
                    if (element) {
                        element.checked = valor;
                    }
                    atendimento.ok_financeiro = valor;
                    return atendimento;
                })
                setAtendimentos(newAtendimentos);
                setOkFinanceiroFill({});
                setAtendimentosAlterados(newAtendimentos);
                break;
            default:
                return;
        }
        setPendenteDeSave(true);
    }, [numeroNFFill, statusExecucaoParticularFill, okFinanceiroFill, memoAtendimentos]);
    //#endregion
    //#region HANDLES
    /**
     * Abre o modal de agenda do paciente.
     *
     * @param {number} paciente_id - O ID do paciente. Se for undefined, a função não faz nada.
     */
    const handleOpenModalAgendaPaciente = useCallback((paciente_id) => {
        if (paciente_id === undefined) return;

        if (modalAgendaPacienteRef.current === null) return;

        modalAgendaPacienteRef.current.openModal(paciente_id);
    }, []);
    /**
     * Abre o modal de agenda do paciente.
     *
     * @param {number} paciente_id - O ID do paciente. Se for undefined, a função não faz nada.
     */
    const handleOpenModalAgendaTerapeuta = useCallback((terapeuta_id) => {
        if (terapeuta_id === undefined) return;

        if (modalAgendaTerapeutaRef.current === null) return;

        modalAgendaTerapeutaRef.current.openModal(terapeuta_id);
    }, []);
    /**
     * Função assíncrona que lida com o clique no botão de consulta.
     * Verifica se os parâmetros de data foram fornecidos e, se não, exibe um alerta ao usuário.
     * Se o usuário confirmar a consulta sem parâmetros de data, verifica se há alterações não salvas.
     * Se o usuário confirmar a consulta e não houver alterações não salvas, fecha o modal e carrega os atendimentos.
     *
     * @async
     * @function handleClickConsultar
     * @returns {Promise<void>}
     */
    const handleClickConsultar = useCallback(async () => {
        let passa = true;
        if (!dataInicio && !dataFim) {
            await Swal.fire({
                title: 'Tem certeza?',
                icon: 'warning',
                html: 'Nenhum parâmetro de data foi adicionado à consulta, o que significa, que todos os atendimentos seram consultados. Isso pode demorar um pouco...',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Consutlar mesmo assim',
                cancelButtonText: 'Retornar e adicionar parâmetros'
            }).then(async (response) => {
                if (pendenteDeSave && response.isConfirmed) {
                    passa = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
                } else {
                    passa = response.isConfirmed;
                }
            });
        }
        if (passa) {
            setModalInicialIsOpen(false);
            carregarAtendimentos();
        }
    }, [dataInicio, dataFim, planoSaudeIdInicial, pendenteDeSave]);
    /**
     * Função para salvar as alterações nos atendimentos financeiros.
     * Define o estado de salvando como verdadeiro e faz uma requisição PUT para atualizar os atendimentos alterados.
     * Em caso de sucesso, exibe uma mensagem de sucesso, recarrega os atendimentos, define o estado de pendente de salvar como falso e define o estado de salvando como falso.
     * Em caso de erro, define o estado de salvando como falso, exibe uma mensagem de erro e loga o erro no console.
     */
    const handleSalva = useCallback(() => {
        Swal.fire({
            title: 'Tem certeza?',
            icon: 'warning',
            html: 'Deseja realmente salvar as alterações?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar'
        }).then(async (response) => {
            if (response.isConfirmed) {
                setSalvando(true);
                api.put('api/atendimento/atualiza/financeiro', atendimentosAlterados)
                    .then((response) => {
                        if (response.status === 200) {
                            snackbar.displayMessage('Atendimentos atualziados com sucesso!', 'success');
                            carregarAtendimentos();
                            setPendenteDeSave(false);
                            setSalvando(false);
                        }
                    }).catch((error) => {
                        setSalvando(false);
                        console.error(error);
                        snackbar.displayMessage('Não foi possível atualizar os atendimentos.', 'error');
                    });
            }
        })
    }, [atendimentosAlterados, carregarAtendimentos, snackbar]);
    const handleKeyDown = useCallback((event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            if (pendenteDeSave) {
                handleSalva();
            } else {
                Swal.fire({
                    title: 'Nenhuma alteração para salvar!',
                    icon: 'info',
                    showConfirmButton: true,
                    confirmButtonText: 'OK'
                });
            }
        }
    }, [pendenteDeSave, handleSalva]);
    /**
     * Manipula o evento de hover para elementos DOM com base no modo fornecido.
     *
     * @param {string} modo - O modo que determina o estilo a ser aplicado. Pode ser 'consultar' ou 'filtrar'.
     * @param {boolean} [enter=true] - Indica se o evento é de entrada (true) ou saída (false) do hover.
     */
    /**
     * Manipula o evento de hover em elementos DOM com base no modo especificado.
     *
     * @param {string} modo - O modo que define o estilo dos elementos. Pode ser 'consultar' ou 'filtrar'.
     * @param {boolean} [enter=true] - Indica se o evento é de entrada (true) ou saída (false) do hover.
     * @returns {void}
     */
    const handleHover = useCallback((modo, enter = true) => {
        const doms = document.getElementsByName(`parametros-${modo}`);
        const borda = modo === 'consultar' ? 'ffc500' : modo === 'filtrar' ? '00d1ff' : '00d1ff';
        const fundo = modo === 'consultar' ? 'fff5d1' : modo === 'filtrar' ? 'd3f7ff' : 'd3f7ff';
        if (enter) {
            doms.forEach((dom) => {
                dom.style.borderColor = `#${borda}`;
                dom.style.backgroundColor = `#${fundo}`;
            });
        } else {
            doms.forEach((dom) => {
                dom.style.borderColor = '#dee2e6';
                dom.style.backgroundColor = 'transparent';
            });
        }
    }, []);
    /**
     * Manipula as mudanças nos inputs da tabela.
     * 
     * @function
     * @param {Object} event - O evento de mudança do input.
     * @param {Object} event.target - O alvo do evento.
     * @param {string} event.target.id - O ID do alvo do evento, no formato 'key=id'.
     * @param {boolean|string} event.target.checked - O valor booleano do checkbox, se o key for 'ok_financeiro'.
     * @param {string} event.target.value - O valor do input, se o key não for 'ok_financeiro'.
     * 
     * @throws {Error} - Lança um erro se ocorrer algum problema durante a execução.
     */
    const handleChangeTableInputs = useCallback((event) => {
        try {
            //variaveis
            const splitedId = event.target.id.split('=');
            const key = String(splitedId[0]);
            const id = Number(splitedId[1]);
            const value = key === 'ok_financeiro' ? Boolean(event.target.checked) : String(event.target.value);
            //Atualiza os Atendimentos
            let listaAF = atendimentosAlterados;
            const afIndex = listaAF.findIndex(find => find.id === id);
            if (afIndex !== -1) {
                listaAF[afIndex] = {
                    ...listaAF[afIndex],
                    [key]: value
                };
            } else {
                const newFinded = {
                    id: id,
                    [key]: value
                };
                listaAF.push(newFinded);
            }

            //returns
            setAtendimentosAlterados(listaAF);
            setPendenteDeSave(listaAF.length > 0);
        } catch (error) {
            console.error(error);
        }
    }, [atendimentosAlterados]);
    /**
     * Limpa todos os filtros de pesquisa, redefinindo os estados relacionados aos filtros para seus valores iniciais.
     * 
     * @function
     * @name handleLimpaFiltro
     */
    const handleLimpaFiltro = useCallback(() => {
        unstable_batchedUpdates(() => {
            setTipoAtendimentoId('');
            setStatusAtendimentoId('');
            setTerapeutaId('');
            setLiberado('');
            setExecutado('');
            setPeriodo('');
            setDiaSemana('');
            setEspecialidadeId('');
            setTerapiaId('');
            setPacienteId('');
            setStatusDeAssinatura(null);
            setPlanoSaudeId('');
            setStatusEvolucao('');
            setOkFinanceiro('');
            setNumeroNF('');
            setModoCriacao('');
            setFilters(defaultFilters);
        });
    }, []);
    /**
     * Limpa os parâmetros de data e os arrays de atendimentos.
     *
     * @param {boolean} [limpa_parametro=true] - Indica se os parâmetros de data devem ser limpos.
     */
    const handleLimpaParametros = useCallback((limpa_parametro = true) => {
        if (limpa_parametro) {
            setDataInicio('');
            setDataFim('');
            setPlanoSaudeIdInicial(null);
        }
        setAtendimentosOriginal([]);
        setAtendimentos([]);
    }, []);
    /**
     * Manipula a mudança de filtros de acordo com o evento recebido.
     * Atualiza o estado correspondente com o valor selecionado e limpa parâmetros quando necessário.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.id - O id do alvo do evento.
     * @param {string} e.target.name - O nome do alvo do evento.
     * @param {string} e.target.value - O valor do alvo do evento.
     */
    const handleChangeFilters = useCallback((e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value.value !== undefined ? e.target.value.value : e.target.value;

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                handleLimpaParametros(false);
                break;
            case 'data_fim':
                setDataFim(value);
                handleLimpaParametros(false);
                break;
            case 'tipo_atendimento_id':
                setTipoAtendimentoId(memotipoAtendimentosOptions.find(option => option.value === value));
                break;
            case 'status_atendimento_id':
                setStatusAtendimentoId(memostatusAtendimentosOptions.find(option => option.value === value));
                break;
            case 'terapeuta_id':
                setTerapeutaId(memoterapeutasOptions.find(option => option.value === value));
                break;
            case 'liberado':
                setLiberado(statusLiberacaoOptions.find(option => option.value === value));
                break;
            case 'executado':
                setExecutado(statusExecucao.find(option => option.value === value));
                break;
            case 'periodo':
                setPeriodo(periodos.find(option => option.value === value));
                break;
            case 'dia_semana':
                setDiaSemana(diasDaSemana.find(option => option.value === value));
                break;
            case 'especialidade_id':
                setEspecialidadeId(memoespecialidadesOptions.find(option => option.value === value));
                break;
            case 'terapia_id':
                setTerapiaId(memoterapiasOptions.find(option => option.value === value));
                break;
            case 'plano_saude_id':
                setPlanoSaudeId(memoplanoSaudeOptions.find(option => option.value === value));
                break;
            case 'paciente_id':
                setPacienteId(memopacienteOptions.find(option => option.value === value));
                break;
            case 'status_de_assinatura':
                setStatusDeAssinatura(statusDeAssinaturaOptions.find(option => option.value === value));
                break;
            case 'status_evolucao':
                setStatusEvolucao(e.target.value);
                break;
            case 'ok_financeiro':
                setOkFinanceiro(e.target.value);
                break;
            case 'numero_nf':
                setNumeroNF(e.target.value);
                break;
            case 'modo_criacao':
                setModoCriacao(e.target.value);
                break;
            default:
                break;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, [memotipoAtendimentosOptions, memostatusAtendimentosOptions, memoterapeutasOptions, memoespecialidadesOptions, memoterapiasOptions, memoplanoSaudeOptions, memopacienteOptions]);
    /**
     * Manipula a abertura do modal de comentários com os dados do atendimento.
     *
     * @param {Object} atendimento - Objeto contendo os dados do atendimento.
     * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
     * @param {number} atendimento.paciente_id - ID do paciente.
     * @param {string} atendimento.paciente_identificador - Identificador do paciente.
     * @param {string} atendimento.paciente_nome - Nome do paciente.
     * @param {string} atendimento.terapia_nome - Nome da terapia.
     * @param {string} atendimento.especialidade_nome - Nome da especialidade.
     * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
     * @param {Object} atendimento.terapia - Objeto contendo informações da terapia.
     * @param {string} atendimento.terapia.terapia - Nome da terapia.
     * @param {Object} atendimento.especialidade - Objeto contendo informações da especialidade.
     * @param {string} atendimento.especialidade.especialidade - Nome da especialidade.
     * @param {string} atendimento.sala - Sala do atendimento.
     * @param {Object} atendimento.estacao - Objeto contendo informações da estação.
     * @param {string} atendimento.estacao.estacao - Nome da estação.
     * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
     * @param {Object} atendimento.tipo_atendimento - Objeto contendo informações do tipo de atendimento.
     * @param {string} atendimento.tipo_atendimento.tipo_atendimento - Tipo de atendimento.
     * @param {number} atendimento.id - ID do atendimento.
     * @param {string} atendimento.sugestao_terapeuta_name - Nome da sugestão do terapeuta.
     * @param {string} atendimento.sugestao_recepcao_original_name - Nome da sugestão da recepção.
     * @param {number} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
     * @param {number} atendimento.sugestao_recepcao_original - Sugestão da recepção.
     * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
     * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
     * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
     * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
     * @param {string} atendimento.comentario - Comentário do atendimento.
     * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
     * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
     * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
     */
    const handleVisualizaAtendimento = useCallback((atendimento) => {
        let dados = [];
        dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
        dados['paciente'] = `(${atendimento.paciente_id}) ${atendimento.paciente_identificador}`;
        dados['paciente_nome'] = `${atendimento.paciente_nome}`;
        dados['paciente_id'] = atendimento.paciente_id;
        dados['terapia'] = atendimento.terapia_nome;
        dados['especialidade'] = atendimento.especialidade_nome;
        dados['plano_saude'] = atendimento.nome_reduzido;
        dados['terapia'] = atendimento.terapia.terapia;
        dados['especialidade'] = atendimento.especialidade.especialidade;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao.estacao;
        dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['modalidade'] = atendimento.modalidade.modalidade;
        dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['id'] = atendimento.id;
        dados['terapeuta_status'] = atendimento.sugestao_terapeuta_name;
        dados['recepcao_status'] = atendimento.sugestao_recepcao_original_name;
        const status_recep = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_terapeuta);
        dados['terapeuta_status_nome'] = status_recep === undefined ? '-' : status_recep.label;
        const status_terap = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_recepcao_original);
        dados['recepcao_status_nome'] = status_terap === undefined ? '-' : status_terap.label;
        dados['liberado'] = atendimento.liberado;
        dados['checkin'] = atendimento.checkin;
        dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
        dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
        dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
        dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
        dados['comentario'] = atendimento.comentario;
        dados['executado'] = atendimento.executado;
        dados['codigo_1'] = atendimento.codigo_1;
        dados['codigo_2'] = atendimento.codigo_2;
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    }, []);
    //#endregion
    //#region COLUNAS DA TABELA
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell }) => {
                    const editavel = cell.row.original.modo_criacao_nome !== 'EDITADO';
                    const mdCriacao = cell.row.original.modo_criacao_nome?.charAt(0);
                    let cor = '';
                    switch (mdCriacao) {
                        case 'A':
                            cor = 'bg-verde-dark font-branca';
                            break;
                        case 'E':
                            cor = 'bg-cinza-claro';
                            break;
                        case 'M':
                            cor = 'bg-roxo-dark font-branca';
                            break;
                        default:
                            cor = 'bg-cinza-claro';
                            break;
                    }

                    return (<div className='d-flex justify-content-between align-items-center'>
                        <span>  </span>
                        <span> {cell.value} </span>
                        <span className={`d-flex align-items-center justify-content-center ${cor}`} style={{ borderRadius: '50%', border: '1px solid purple', padding: '2px', width: 25, height: 25, marginRight: -12 }}>
                            {mdCriacao}
                        </span>
                    </div >)
                }
            },
            {
                Header: 'Data Hora Atendimento',
                accessor: 'data_do_atendimento',
                Cell: ({ cell }) => {
                    const horario = cell.row.original.hora_atendimento;
                    const sliced = horario.slice(0, -3);
                    return (
                        <span className="h-100"
                            onMouseEnter={() => handleRealcaMouseEnterLeaveCell(['data_inicio', 'data_fim'])}
                            onMouseLeave={() => handleRealcaMouseEnterLeaveCell(['data_inicio', 'data_fim'], false)}
                        >
                            {cell.row.original.data_do_atendimento || 'vazio'}
                            <br></br>
                            {sliced || 'vazio'}
                        </span>
                    );
                }
            },
            {
                Header: 'Paciente',
                accessor: 'paciente_nome',

                Cell: ({ cell }) => (
                    <div className="text-nowrap h-100 d-flex align-items-center justify-content-between"
                        onMouseEnter={() => handleRealcaMouseEnterLeaveCell('paciente_id')}
                        onMouseLeave={() => handleRealcaMouseEnterLeaveCell('paciente_id', false)}>
                        <span></span>
                        <span className='ns-abrevia-texto'>
                            {cell.value}
                        </span>
                        {cell.value
                            ? <button type={'button'} className='btn-icone w-content-i h-content-i font-preta' onClick={() => handleOpenModalAgendaPaciente(cell.row.original.paciente_id)}>
                                {getIcon('agenda')}
                            </button>
                            : null
                        }
                    </div>
                ),
            },
            {
                Header: 'Períodos',
                accessor: 'paciente_periodos',
                Cell: ({ cell }) => {
                    return (<TablePeriodosPaciente cell={cell} diasDaSemana={diasDaSemana} handleRealcaMouseEnterLeaveCell={handleRealcaMouseEnterLeaveCell} />);
                }
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta_nome',
                Cell: ({ cell }) => (
                    <div className="text-nowrap h-100 d-flex align-items-center justify-content-between"
                        onMouseEnter={() => handleRealcaMouseEnterLeaveCell('terapeuta_id')}
                        onMouseLeave={() => handleRealcaMouseEnterLeaveCell('terapeuta_id', false)}>
                        <span></span>
                        <span className='ns-abrevia-texto'>
                            {cell.value}
                        </span>
                        {cell.value
                            ? <button type={'button'} className='btn-icone w-content-i h-content-i font-preta' onClick={() => handleOpenModalAgendaTerapeuta(cell.row.original.terapeuta_id)}>
                                {getIcon('agenda')}
                            </button>
                            : null
                        }
                    </div>
                ),
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_atendimento',
                Cell: ({ cell }) => (
                    <div
                        onMouseEnter={() => handleRealcaMouseEnterLeaveCell('tipo_atendimento_id')}
                        onMouseLeave={() => handleRealcaMouseEnterLeaveCell('tipo_atendimento_id', false)}
                    >
                        {cell.value}
                    </div>
                ),
            },
            {
                Header: <span>
                    <span> Terapia </span><br></br>
                    <span> Especialidade </span><br></br>
                    <span> Plano </span>
                </span>,

                accessor: 'terapia',
                Cell: ({ cell }) => (
                    <div className="w-150px h-100 font-12px all-center"
                        onMouseEnter={() => handleRealcaMouseEnterLeaveCell(['plano_saude_id', 'especialidade_id', 'terapia_id'])}
                        onMouseLeave={() => handleRealcaMouseEnterLeaveCell(['plano_saude_id', 'especialidade_id', 'terapia_id'], false)}
                    >
                        {cell?.row?.original?.terapia}<br></br>
                        {cell?.row?.original?.especialidade}<br></br>
                        {cell?.row?.original?.nome_reduzido}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'sugestao_recepcao_original_name',
                Cell: ({ cell }) => <span className="h-100"
                    onMouseEnter={() => handleRealcaMouseEnterLeaveCell('status_atendimento_id')}
                    onMouseLeave={() => handleRealcaMouseEnterLeaveCell('status_atendimento_id', false)}
                >
                    {cell.value ? cell.value : 'vazio'}
                </span>

            },
            {
                Header: 'Liberação',
                accessor: 'liberado',
                Cell: ({ cell }) => <span className="m-0 p-0 h-100 all-center"
                    onMouseEnter={() => handleRealcaMouseEnterLeaveCell('liberado')}
                    onMouseLeave={() => handleRealcaMouseEnterLeaveCell('liberado', false)}
                >
                    {statusLiberacaoOptions?.find((find) => find?.value === cell?.value)?.label || 'vazio'}
                </span>

            },
            {
                Header: 'Assinado',
                Cell: ({ cell }) => <span className="m-0 p-0 h-100 all-center"
                    onMouseEnter={() => handleRealcaMouseEnterLeaveCell('status_de_assinatura')}
                    onMouseLeave={() => handleRealcaMouseEnterLeaveCell('status_de_assinatura', false)}
                >
                    {statusDeAssinaturaOptions?.find((find) => find?.value === cell?.value)?.label || 'vazio'}
                </span>
            },
            {
                Header: 'Status de Evolução',
                accessor: 'status_evolucao_nome',
                Cell: ({ cell }) => {
                    return <span className="m-0 p-0 h-100 all-center"
                        onMouseEnter={() => handleRealcaMouseEnterLeaveCell('status_evolucao')}
                        onMouseLeave={() => handleRealcaMouseEnterLeaveCell('status_evolucao', false)}
                    >
                        {statusEvolucaoOptions?.find((find) => find?.value === cell?.value)?.label || 'vazio'}
                    </span>
                }
            },
            {
                Header: 'Valor (R$)',
                accessor: 'valor_terapia',
                Cell: ({ cell }) => {
                    return <span className="m-0 p-0 h-100 all-center">
                        {cell.row.original.nome_reduzido.toUpperCase() === 'PARTICULAR' ?
                            <SimpleInput
                                id={`valor_terapia=${cell.row.original.id}`}
                                name={`valor_terapia=${cell.row.original.id}`}
                                defaultValue={cell.value}
                                onBlur={(e) => handleChangeTableInputs(e)}
                                type="number"
                                step="0.01"
                                min="0"
                                max="999999"
                                className="form-control m-0"
                            />
                            : `R$ ${cell.value ? Number(cell.value)?.toFixed(2) : 'vazio'}`
                        }
                    </span>
                }
            },
            {
                Header: 'Código Terapia',
                accessor: 'codigo_terapia'
            },
            {
                Header: 'Freq. Pagamento',
                accessor: 'frequencia_pagamento_nome'
            },
            {
                Header: 'OK Financeiro',
                accessor: 'ok_financeiro',
                Cell: ({ cell }) => {
                    const editavel = cell.row.original.modo_criacao_nome !== 'EDITADO';
                    if (editavel) {
                        return (
                            <span className="text-center d-flex justify-content-center align-items-center form-check form-switch h-100"
                                onMouseEnter={() => handleRealcaMouseEnterLeaveCell('ok_financeiro')}
                                onMouseLeave={() => handleRealcaMouseEnterLeaveCell('ok_financeiro', false)}
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`ok_financeiro=${cell.row.original.id}`}
                                    defaultChecked={Boolean(cell?.value)}
                                    onChange={(e) => handleChangeTableInputs(e)}
                                />
                            </span>
                        );
                    } else {
                        return <span>Alterado</span>
                    }
                }
            },
            {
                Header: 'Execução',
                accessor: 'executado',

                Cell: ({ cell }) => {
                    const editavel = cell.row.original.modo_criacao_nome !== 'EDITADO';
                    if (editavel) {
                        if (cell.row.original.nome_reduzido.toUpperCase() === 'PARTICULAR') {
                            return <span className='w-200px h-100'
                                onMouseEnter={() => handleRealcaMouseEnterLeaveCell('executado')}
                                onMouseLeave={() => handleRealcaMouseEnterLeaveCell('executado', false)}
                            >
                                <Select id={`executado=${cell.row.original.id}`}
                                    name={'execucao[]'}
                                    options={statusExecucaoParticular}
                                    onChange={(e) => handleChangeTableInputs({ target: { id: `executado=${cell.row.original.id}`, name: 'execucao[]', value: e.value } })}
                                    defaultValue={statusExecucaoParticular?.find((find) => find?.value === cell?.value)}
                                />
                            </span>
                        } else {
                            return <span className='w-200px h-100'
                                onMouseEnter={() => handleRealcaMouseEnterLeaveCell('executado')}
                                onMouseLeave={() => handleRealcaMouseEnterLeaveCell('executado', false)}
                            >
                                {statusExecucao?.find((find) => find?.value === cell?.value)?.label || 'vazio'}
                            </span>;
                        }
                    } else {
                        return <div className="m-0 p-0 w-200px h-100 all-center">Alterado</div>;
                    }
                }
            },
            {
                Header: 'Nº NF',
                accessor: 'numero_nf',

                Cell: ({ cell }) => {
                    const editavel = cell.row.original.modo_criacao_nome !== 'EDITADO';
                    if (editavel) {
                        return <div className="m-0 p-0 w-200px h-100 all-center">
                            <input className="form-control m-0" type="text" placeholder="Digite o numero da NF"
                                id={`numero_nf=${cell.row.original.id}`} defaultValue={cell.value}
                                onBlur={(e) => handleChangeTableInputs(e)} />
                        </div>;
                    } else {
                        return <div className="m-0 p-0 w-200px h-100 all-center">Alterado</div>;
                    }
                }
            },
            {
                Header: 'Detalhes',
                accessor: 'comentario',
                Cell: ({ cell }) => {
                    return <button className="btn-icone hl-laranja" onClick={() => handleVisualizaAtendimento(cell.row.original)}>
                        <BsEye size={22} />
                    </button>;
                }
            },
        ], [statusDeAssinaturaOptions, statusExecucao, statusLiberacaoOptions, memoAtendimentos]);
    const exportColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'M',
                accessor: 'modo_criacao_nome',
                Cell: ({ cell }) => cell.value?.charAt(0)
            },
            {
                Header: 'Data Atendimento',
                accessor: 'data_do_atendimento'
            },
            {
                Header: 'Hora Atendimento',
                accessor: 'hora_atendimento'
            },
            {
                Header: 'Paciente',
                accessor: 'paciente_nome',
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta_nome'
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_atendimento'
            },
            {
                Header: 'Terapia',
                accessor: 'terapia',
            },
            {
                Header: 'Especialidade',
                accessor: 'especialidade',
            },
            {
                Header: 'Plano de Saúde',
                accessor: 'nome_reduzido',
            },
            {
                Header: 'Status',
                accessor: 'sugestao_recepcao_original_name'

            },
            {
                Header: 'Liberação',
                accessor: 'liberado',

            },
            {
                Header: 'Assinado',
                accessor: 'assinado',
                Cell: ({ cell }) => cell.value ? 'Sim' : 'Não'
            },
            {
                Header: 'Status de Evolução',
                acessor: 'status_evolucao_nome'
            },
            {
                Header: 'Valor Terapia (R$)',
                accessor: 'valor_terapia'
            },
            {
                Header: 'Código Terapia',
                accessor: 'codigo_terapia'
            },
            {
                Header: 'OK Financeiro',
                accessor: 'ok_financeiro',
                Cell: ({ cell }) => cell.value ? 'OK' : '-'
            },
            {
                Header: 'Execução',
                accessor: 'executado'
            },
            {
                Header: 'Nº NF',
                accessor: 'numero_nf'
            }
        ], [statusDeAssinaturaOptions, statusExecucao, statusLiberacaoOptions]);
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        if (!pendenteDeSave) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [pendenteDeSave, navigator]);
    useEffect(() => {
        if (pendenteDeSave === true) {
            if (balaoDicaRefs[0].current) {
                balaoDicaRefs[0].current.open();
            }
            if (balaoDicaRefs[1].current) {
                balaoDicaRefs[1].current.open();
            }
        }
        const handleBeforeUnload = (event) => {
            if (pendenteDeSave) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pendenteDeSave]);
    useEffect(() => {//FILTER
        if (!filtroAlterado) {
            setFiltroAlterado(true);
        }
    }, [filters]);
    useEffect(() => {//FILTER
        if (!dataAlterado) {
            setDataAlterado(true);
        }
    }, [dataInicio, dataFim]);
    useEffect(() => {//ON PENDENTE DE SAVE
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [pendenteDeSave]);
    useEffect(() => {//ON LOAD
        fetchTipoAtendimentosData();
        fetchStatusAtendimentosData();
        fetchTerapeutasData();
        fetchEspecialidades();
        fetchTerapias();
        fetchPlanoSaude();
        fetchPacienteOptionsData(['nome', 'identificador'], false);
    }, []);
    //#endregion
    //#region HTML
    return (
        <>
            <div className="row p-0 m-0">
                <Form id="div-filtro" className='col-12 row flex-wrap p-0 m-0'>
                    <fieldset id="fieldset-periodo" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-8">
                        <legend className="mb-0">Parâmetros Iniciais</legend>
                        <div className='row'>
                            <div className='col-lg-4 col-sm-12' id={'filter_data_plano_saude_id'}>
                                <Select id="plano_saude_id" name="plano_saude_id" label="Plano Saude:" title="Plano Saude:" isMulti={false} options={memoplanoSaudeOptions} isClearable={true} value={planoSaudeId}
                                    onChange={(e) => {
                                        setPlanoSaudeIdInicial(e.value ? e.value : null);
                                        handleChangeFilters({ target: { id: 'plano_saude_id', name: 'plano_saude_id', value: e } });
                                    }}
                                />
                            </div>
                            <div className='col-lg-4 col-sm-12' id={'filter_data_inicio'}>
                                <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                            </div>
                            <div className='col-lg-4 col-sm-12 d-flex' id={'filter_data_fim'}>
                                <div className="w-90per">
                                    <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                                </div>
                                <div className="mt-1">
                                    <button type={'button'} className="btn-icone mt-4" onClick={handleLimpaParametros}><AiOutlineClear className="icone" size={30} /></button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-terapeuta" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-2">
                        <legend className="mb-0">Terapeuta</legend>
                        <div className='row'>
                            <div className='col-12' id={'filter_terapeuta_id'}>
                                <Select id="terapeuta_id" name="terapeuta_id" label="Terapeuta:" title="Terapeuta:" options={memoterapeutasOptions} isClearable={true} isMulti={true} value={terapeutaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapeuta_id', name: 'terapeuta_id', value: e } })} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-paciente" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-2">
                        <legend className="mb-0">Paciente</legend>
                        <div className='row'>
                            <div className='col-12' id={'filter_paciente_id'}>
                                <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={memopacienteOptions} isClearable={true} isMulti={true} value={pacienteId} onChange={(e) => handleChangeFilters({ target: { id: 'paciente_id', name: 'paciente_id', value: e } })} />
                            </div>
                        </div>
                    </fieldset>
                    <div className='row'>
                        <div className='col-lg-2 col-sm-12' id={'filter_status_atendimento_id'}>
                            <Select id="status_atendimento_id" name="status_atendimento_id" label="Status de Atendimento:" title="Status de Atendimento:" isMulti={true} options={memostatusAtendimentosOptions} isClearable={true} value={statusAtendimentoId} onChange={(e) => handleChangeFilters({ target: { id: 'status_atendimento_id', name: 'status_atendimento_id', value: e } })} />
                        </div>
                        <div className='col-lg-1 col-sm-12' id={'filter_liberado'}>
                            <Select id="liberado" name="liberado" label="Status de Liberação:" title="Status de Liberação:" options={statusLiberacaoOptions} isClearable={true} value={liberado} onChange={(e) => handleChangeFilters({ target: { id: 'liberado', name: 'liberado', value: e } })} />
                        </div>
                        <div className='col-lg-3 col-sm-12' id={'filter_executado'}>
                            <Select id="executado" name="executado" label="Status de Execução:" title="Status de Execução:" options={statusExecucao} isClearable={true} value={executado} onChange={(e) => handleChangeFilters({ target: { id: 'executado', name: 'executado', value: e.value } })} />
                        </div>
                        <div className='col-lg-2 col-sm-12' id={'filter_ok_financeiro'}>
                            <Select id="ok_financeiro" name="ok_financeiro" label="OK Financeiro:" title="OK Financeiro:" options={[
                                { value: '', label: 'Todos' },
                                { value: 1, label: 'Sim' },
                                { value: 0, label: 'Não' }
                            ]} isClearable={true} value={okFinanceiro} onChange={(e) => {
                                handleChangeFilters({ target: { id: 'ok_financeiro', name: 'ok_financeiro', value: e } });
                            }} />
                        </div>
                        <div className='col-lg-2 col-sm-12' id={'filter_numero_nf'}>
                            <Input type="text" title="Número NF:" label="Número NF:" id="numero_nf" name="numero_nf" value={numeroNF} onChange={handleChangeFilters} list="numeroNFList" />
                            <datalist id="numeroNFList">
                                {numeroNFList.map((nf, index) => (
                                    <option key={index} value={nf} />
                                ))}
                            </datalist>
                        </div>
                        <div className='col-lg-2 col-sm-12' id={'filter_status_evolucao'}>
                            <Select id="status_evolucao" name="status_evolucao" label="Status de Evolução:" title="Status de Evolução:" options={statusEvolucaoOptions} isClearable={true} isMulti={true} value={statusEvolucao} onChange={(e) => handleChangeFilters({ target: { id: 'status_evolucao', name: 'status_evolucao', value: e } })}
                            />
                        </div>
                        <div className='col-lg-3 col-sm-12' id={'filter_especialidade_id'}>
                            <Select id="especialidade_id" name="especialidade_id" label="Especialidade:" title="Especialidade:" isMulti={true} options={memoespecialidadesOptions} isClearable={true} value={especialidadeId} onChange={(e) => handleChangeFilters({ target: { id: 'especialidade_id', name: 'especialidade_id', value: e } })} />
                        </div>
                        <div className='col-lg-3 col-sm-12' id={'filter_terapia_id'}>
                            <Select id="terapia_id" name="terapia_id" label="Terapia:" title="Terapia:" isMulti={true} options={memoterapiasOptions} isClearable={true} value={terapiaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapia_id', name: 'terapia_id', value: e } })} />
                        </div>
                    </div>
                    <div className="col-12 mt-2 d-flex flex-row justify-content-between">
                        <div className='d-flex justify-content-start w-400px'>
                            <Button type="button" className={'w-fit-content-i me-2 rodando-hover'} onMouseEnter={() => handleHover('consultar', true)} onMouseLeave={() => handleHover('consultar', false)} onClick={() => carregarAtendimentos()}>
                                <FiRefreshCcw className='icone me-2' size={22} /> Atualizar
                            </Button>
                            <Button className={`w-fit-content-i searching${dataAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onMouseEnter={() => handleHover('consultar', true)} onMouseLeave={() => handleHover('consultar', false)} onClick={() => { handleClickConsultar(); }}>
                                <BsSearch size={22} className="icone me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                        {(loading || salvando) &&
                            <div className='p-0 m-0 mx-auto'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                    <BsSearch className='icone manual-searching' size={30} />
                                </div>
                            </div>
                        }
                        <div className='d-flex justify-content-end w-400px'>
                            <Button className={`w-fit-content-i${filtroAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onMouseEnter={() => handleHover('filtrar', true)} onMouseLeave={() => handleHover('filtrar', false)} onClick={() => filtrar()}>
                                <BsFilter className='icone me-2' size={30} /> Filtrar
                            </Button>
                            <Button type={'button'} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} onMouseEnter={() => handleHover('filtrar', true)} onMouseLeave={() => handleHover('filtrar', false)} onClick={handleLimpaFiltro} >
                                <FaBroom size={30} type="button" className="icone me-2" /> Limpar Filtros
                            </Button>
                        </div>
                    </div>
                </Form>
                <div className="col-12 p-0 m-0 d-flex justify-content-between align-items-end mt-4">
                    <span className='w-200px-i ps-4 pb-2'>
                        Atendimentos: {memoAtendimentos.length}
                    </span>
                    <div className='d-flex flex-col align-items-end'>
                        <b className="font-roxo font-20px col-12 text-center">Listagem de Atendimentos</b>
                    </div>
                    <Button id="botao-salvar" className={`w-200px-i h-content-i ${salvando ? 'cursor-block' : 'cursor-pointer'}${pendenteDeSave ? ' btn-marca-vermelha' : ''}`} type={'button'} onClick={() => handleSalva()} disabled={salvando}>
                        <FaSave className={`me-2 ${pendenteDeSave ? 'font-laranja' : ''}`} size={22} /> Salvar
                    </Button>
                </div>
                <Form className="col-12 p-0 m-0">
                    <TableDefaultPagination
                        source={memoAtendimentos}
                        columns={columns}
                        exportColumns={exportColumns}
                        tableTitle={pageBase.title}
                        prefix_id={'pendencias-pagamento'}
                        infiniteScroll={false}
                        initialState={{ pageIndex: 0, pageSize: 100 }}
                        exportButton={true}
                        fixedHeader={true}
                        fixedFooter={true}
                        searchBar={true}
                        style={{ tableContainer: { height: '37vh' } }}
                        createButton={
                            <Form className="col-12 mt-2 d-flex flex-row justify-content-center" id='preenchedores'>
                                <div className='mx-2 p-0 d-flex flex-row align-items-end w-300px-i'>
                                    <Select
                                        id={`preencher_ok_financeiro`}
                                        name={`preencher_ok_financeiro`}
                                        label="Preencher OK Financeiro:"
                                        options={[
                                            { value: 1, label: 'Sim' },
                                            { value: 0, label: 'Não' }
                                        ]}
                                        placeholder="Selecione o status"
                                        onChange={(e) => setOkFinanceiroFill(e)}
                                        value={okFinanceiroFill}
                                    />
                                    <Button
                                        className="w-content-i h-content-i"
                                        type={'button'}
                                        onClick={() => preencherCampo('ok_financeiro')}>
                                        {getIcon('preencher', 30)}
                                    </Button>
                                </div>
                                <div className="mx-2 p-0 d-flex flex-row align-items-end w-300px-i">
                                    <Select
                                        id={`preencher_status_execucao`}
                                        name={`preencher_status_execucao`}
                                        label="Preencher Status de Execução:"
                                        options={statusExecucaoParticular}
                                        placeholder="Selecione o status"
                                        onChange={(e) => setStatusExecucaoParticularFill(e)}
                                        value={statusExecucaoParticularFill}
                                    />
                                    <Button
                                        className="w-content-i h-content-i"
                                        type={'button'}
                                        onClick={() => preencherCampo('status_execucao_particular')}>
                                        {getIcon('preencher', 30)}
                                    </Button>
                                </div>
                                <div className="mx-2 p-0 d-flex flex-row align-items-end w-300px-i">
                                    <Input
                                        id={`preencher_numero_nf_preencher`}
                                        name={`preencher_numero_nf_preencher`}
                                        label="Preencher Nº NF:"
                                        type="text"
                                        placeholder="Digite o numero da NF para Preencher"
                                        onChange={(e) => setNumeroNFFill(e.target.value)}
                                        value={numeroNFFill}
                                    />
                                    <Button
                                        className="w-content-i h-content-i"
                                        type={'button'}
                                        onClick={() => preencherCampo('numero_nf')}>
                                        {getIcon('preencher', 30)}
                                    </Button>
                                </div>
                            </Form>
                        }
                    />
                </Form>
                {loading && <Carregamento />}
                <TutorialBadge
                    enabled={!modalInicialIsOpen && !loading}
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Seja bem vindo à tela de pendências de pagamento.</span>
                                </div>
                            },
                            { title: 'Básicos', text: 'O básico que você precisa saber sobre essa tela, é que todos os atendimentos são consultados na base de dados ao abrir a tela.' },
                            { title: 'Filtros', selector: '#div-filtro', text: 'Essa é a area do filtro. Aqui você pode filtrar os atendimentos já consultados.' },
                            {
                                title: 'Preenchedores em Massa', selector: '#preenchedores', text: <span>span
                                    Aqui você encontrará uma opção mais rápida de preencher os campos editáveis da listagem de Atendimentos.<br></br>
                                    A única coisa que você precisa fazer é digitar no campo desejado, e apertar o botão, isso preencherá todos os atendimentos que estão filtrados.
                                </span>
                            },
                            {
                                title: 'Coluna de Períodos',
                                selector: [
                                    '#pendencias-pagamento-col-3',
                                    '#pendencias-pagamento-col-3-row-0',
                                    '#pendencias-pagamento-col-3-row-1',
                                    '#pendencias-pagamento-col-3-row-2',
                                ],
                                text: <span>
                                    Essa coluna é uma coluna que permite o usuário visualizar os dias e períodos em que o paciente possue agendamento na clínica.
                                </span>
                            },
                            {
                                title: 'Agendas',
                                selector: [
                                    '#pendencias-pagamento-col-2',
                                    '#pendencias-pagamento-col-4',
                                    '#pendencias-pagamento-col-2-row-0',
                                    '#pendencias-pagamento-col-2-row-1',
                                    '#pendencias-pagamento-col-4-row-0',
                                    '#pendencias-pagamento-col-4-row-1',
                                ],
                                text: <span>
                                    Nessas colunas, você pode observar o nome do terapeuta e do paciente. Junto ao nome, você pode notar um botão de agenda {getIcon('agenda')}, que ao ser clicado, abrirá a agenda do terapeuta ou do paciente.
                                </span>
                            },
                            { title: 'Finalização', text: <span>É basicamente isso, se você ainda tiver dúvidas, pode apertar novamente no botão de tutorial no canto inferior da tela, ou se preferir, <a href={`dashboard/${user.tipo_user}/documentacao`}>acesse nossa documentação</a>!</span> },
                        ]
                    }
                    hasTopicos={true}
                />
                <ComentariosModal ref={modalComentariosRef} getAgendaDia={carregarAtendimentos}></ComentariosModal>
            </div >
            <ReactModal id={'modal-inicial'} style={customStyles} isOpen={modalInicialIsOpen}
                onRequestClose={() => { }} afterOpenModal={() => { }} appElement={document.getElementById('root')} transparent
                shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}
            >
                <div className="row">
                    <h4 className="col-12 font-roxo text-center">Parâmetros Iniciais</h4>
                    <p className="col-12 text-justify">Selecionando duas datas, o todos os atendimentos com datas entre esse período sera retornado. Caso apenas uma das datas sejam selecionadas apenas os atendimentos daquela data em específico será retornado.</p>
                    <Form className="col-12 row">
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                        </div>
                        <div className='col-12' id={'filter_plano_saude_id'}>
                            <Select id="plano_saude_id" name="plano_saude_id" label="Plano Saude:" title="Plano Saude:" isMulti={false} options={memoplanoSaudeOptions} isClearable={true} value={planoSaudeId}
                                onChange={(e) => {
                                    setPlanoSaudeIdInicial(e.value);
                                    handleChangeFilters({ target: { id: 'plano_saude_id', name: 'plano_saude_id', value: e } });
                                }}
                            />
                        </div>
                        <div className="col-12 text-right d-flex justify-content-end mt-4">
                            <Button className="w-fit-content-i searching mt-4" type={'button'} onClick={() => handleClickConsultar()}>
                                <BsSearch size={22} className="me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                    </Form>
                </div>
            </ReactModal>
            <AgendaPacienteModal ref={modalAgendaPacienteRef} />
            <AgendaModalTerapeuta ref={modalAgendaTerapeutaRef} />
            <BalaoDica ref={balaoDicaRefs[0]} id={'dica_relatorio_financeiro_salvar'} elementoAncora={'botao-salvar'} abertoPadrao={false} apenasUmaVez={true} >
                O salvamento dos dados pode ser feito com o comando <span className='ns-span-comando'>CTRL + S</span>
            </BalaoDica>
            <BalaoDica ref={balaoDicaRefs[1]} id={'dica_relatorio_financeiro_preenchedores'} elementoAncora={'preenchedores'} abertoPadrao={false} apenasUmaVez={true} >
                Note que você pode preencher os dados de forma massiva. Apenas digite no campo que você deseja preencher, e precione o botão de preencher. Note que preencherá todos os atendimentos da tela.
                <br></br>
                Importante salientar que atendimentos com valor já preenchido não serão sobrescritos. Apenas atendimentos com valor vazio serão preenchidos.
            </BalaoDica>
        </>
    );
    //#endregion
};

export default FinanceiroAtendimentos;