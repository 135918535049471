//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Calendario from 'components/Calendario';
import Dropdown from 'components/Dropdown';
import ListaPresenca from 'components/ListaPresenca';
import ControleDeExecucaoModal from 'components/Modais/Checkin';
import ComentariosModal from 'components/Modais/Comentarios';
import SolicitacaoDeAlteracaoModal from 'components/Modais/Terapeuta/SolicitacaoModal';
import Select from 'components/Select';
import WebSocketProvider from 'components/Websocket/websocket.provider.old';
import { AtendimentoReposicaoProvider } from 'context/atendimentoReposicao';
import { useAuth } from 'context/auth';
import useControleDiario from 'context/controle-diario';
import useHorarioAtendimentoData from 'context/horario-atendimento';
import useIcons from 'context/icons';
import useModalidadeData from 'context/modalidade';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import usePlanoSaudeData from 'context/plano-saude';
import useSalaData from 'context/sala';
import { useSnackbar } from 'context/snackbar';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import moment from 'moment';
import { cloneElement, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { AiFillCaretDown, AiFillFilter, AiOutlineClear, AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsArrowDownLeft, BsBell, BsBellFill, BsBellSlash, BsSearch } from 'react-icons/bs';
import { FaBroom, FaUser, FaUserCog, FaUserMd, FaUserTie } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { IoMdOpen } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import SolicitacaoDeAlteracaoService from 'services/SolicitacaoDeAlteracaoService';
import Swal from 'sweetalert2';
import AdicionarAgendamento from '../../../TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import AtendimentoModal from '../../../TerapeutaDashboard/StatusDiario/AtendimentoModal';
import { ContagemPendentes } from '../../../TerapeutaDashboard/TerapeutaPacientes/styles';
import ConferirConflitos from './Modal/ConferirConflitos';
import Conflitos from './Modal/Conflitos';
import LegendaDefault from './Modal/Legenda';
import Responsavel from './Modal/Responsavel';
import SelfLibVirtualizedTable from './selflib-virtualized-tbody';
import { DivContentDisplay, linhasSolicitacao } from './styles';
import './styles.css';
//#endregion

/**
 * Componente de Controle Diário.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {boolean} props.minimizedSubNavbar - Indica se a subnavbar está minimizada.
 * @param {boolean} [props.modoIncorporado=false] - Indica se o modo incorporado está ativado.
 * @param {string} [props.modoDeConsulta='normal'||'status-pendentes'||'check-ins-pendentes'||'execucoes-pendentes'||'terapeuta-pendente'||'consultar-execucoes'||'erros-conflitos'||[]] - Define o modo de consulta da listagem de atenidmentos.
 * @param {Object} [props.funcoesHabilitadas] - Funções habilitadas no componente.
 * @param {boolean} [props.funcoesHabilitadas.filtro=true] - Indica se o filtro está habilitado.
 * @param {boolean} [props.funcoesHabilitadas.navegacao=true] - Indica se a navegação está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.salvar=true] - Indica se a função de salvar está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.carregar=true] - Indica se a função de salvar está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.novo_atendimento=true] - Indica se a função de novo atendimento está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.solicitacoes_alteracao=true] - Indica se a função de solicitações de alteração está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.filtro_solicitacoes_alteracao=true] - Indica se a função de filtro de solicitações de alteração está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.atualizacao=true] - Indica se a função de atualização está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.baixar_excel=true] - Indica se a função de baixar excel está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.legenda=true] - Indica se a função de legenda está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.botao_alteracao=true] - Indica se a função de botão de alteração está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.controle_de_execucao=true] - Indica se a função de controle de execução está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.altera_sugestao_recepcao=true] - Indica se a função de alterar sugestão de recepção está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.altera_liberado=true] - Indica se a função de alterar liberado está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.coluna_p=true] - Indica se a função de coluna P está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.paciente_filtro_inline=true] - Indica se a função de filtro inline de paciente está habilitada.
 * @param {Object} [props.funcoesHabilitadas.modal_alteracao_opcoes] - Opções de alteração habilitadas.
 * @param {boolean} [props.funcoesHabilitadas.modal_alteracao_opcoes.aprova=true] - Indica se a opção de aprovar está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.modal_alteracao_opcoes.reprova=true] - Indica se a opção de reprovar está habilitada.
 * @param {boolean} [props.funcoesHabilitadas.modal_alteracao_opcoes.cria=true] - Indica se a opção de criar está habilitada.
 * @param {Object} [props.filtrosHabilitados] - Filtros habilitados no componente.
 * @param {boolean} [props.filtrosHabilitados.terapeuta=true] - Indica se o filtro de terapeuta está habilitado.
 * @param {boolean} [props.filtrosHabilitados.paciente=true] - Indica se o filtro de paciente está habilitado.
 * @param {boolean} [props.filtrosHabilitados.status_atendimento=true] - Indica se o filtro de status de atendimento está habilitado.
 * @param {boolean} [props.filtrosHabilitados.plano_saude=true] - Indica se o filtro de plano de saúde está habilitado.
 * @param {boolean} [props.filtrosHabilitados.status_execucao=true] - Indica se o filtro de status de execução está habilitado.
 */
const ControleDiario = ({
    dataSelecionada = null,
    minimizedSubNavbar,
    modoIncorporado = false,
    modoDeConsulta = 'normal',
    outsidePacienteOptions = null,
    funcoesHabilitadas = {
        filtro: true,
        navegacao: true,
        salvar: true,
        carregar: true,
        novo_atendimento: true,
        solicitacoes_alteracao: true,
        filtro_solicitacoes_alteracao: true,
        atualizacao: true,
        baixar_excel: true,
        legenda: true,
        botao_alteracao: true,
        controle_de_execucao: true,
        altera_sugestao_recepcao: true,
        altera_liberado: true,
        coluna_p: true,
        paciente_filtro_inline: true,
        modal_alteracao_opcoes: {
            aprova: true,
            reprova: true,
            cria: true,
        }
    },
    filtrosHabilitados = {
        terapeuta: true,
        paciente: true,
        status_atendimento: true,
        plano_saude: true,
        status_execucao: true
    }
}, ref) => {
    usePageBase({
        title: 'Controle Diário',
        description: 'Listagem dos atendimentos diários.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario'
        ],
        conditionalPageBase: !modoIncorporado
    });
    //#region VARIÁVEIS
    // Estilos
    const override = `
        display: block;
        margin-top: 8px;
        margin-left: 4px;
        border-color: orange;
    `;
    const aparenciaBotaoAcao = {
        nao_salvo: {
            backgroundColor: '#35CF58',
            cursor: 'pointer',
            color: 'white'
        },
        salvo: {
            habilitado: {
                backgroundColor: 'lightgrey',
                cursor: 'not-allowed',
                color: 'black'
            },
            nao_habilitado: {
                backgroundColor: 'red',
                cursor: 'context-menu',
                color: 'white'
            }
        }
    }

    // Navegação
    const { navigator } = useContext(UNSAFE_NavigationContext);

    // Hooks
    const usuario = useAuth();
    const { controleDiario, fetchControleDiario, salvarControleDiario, funcaoDataMap, setControleDiario, conferirConflitosModalRef } = useControleDiario();
    const { iconArray } = useIcons();

    /*==MODO TELA==*/
    const modoDoAtendimento = 'agrupado.nao';//'agrupado.horario'
    const modoTela = useMemo(() => usuario.user.tipo_user.toLowerCase(), [usuario]);

    /*==MENSAGEM==*/
    const snackbar = useSnackbar();
    const [permissoes, setPermissoes] = useState([]);

    /*==PACIENTE==*/
    const [pacienteOptionsList, setPacienteOptionsList] = useState(null);
    const { pacientes, pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const { salaEstacaoOptions, fetchSalaEstacaoData } = useSalaData();
    const { horarioAtendimentoComFimOptions, fetchHorarioAtendimentoComFimData } = useHorarioAtendimentoData();
    const { modalidadesOptions, fetchModalidadeData } = useModalidadeData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const executadoOptions = [
        { value: 'vazio', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
        { value: 'pendente', label: 'Pendente' },
        { value: 'executado', label: 'Executado' },
        { value: 'executado-sce', label: 'SCE' },
        { value: 'nao', label: 'Não Executado' },
        { value: 'pago', label: 'Pago' }
    ];

    /*==OUTRAS==*/
    function customPacienteFilter(option, inputValue) {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (pacienteOptionsList.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };
    /*==ESTADO==*/
    const [daysMark, setDaysMark] = useState({});
    const [quantidadeAtendimentos, setQuantidadeAtendimentos] = useState(0);
    const [quantidadeConflitos, setQuantidadeConflitos] = useState(0);
    const [quantidadeErros, setQuantidadeErros] = useState(0);
    const [quantidadeSucessos, setQuantidadeSucessos] = useState(0);
    const [contagemSolicitacoesPendentes, setContagemSolicitacoesPendentes] = useState(0);
    const [filtraSolicitacoesPendentes, setFiltraSolicitacoesPendentes] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const containerHeight = useMemo(() => windowHeight - 200, [windowHeight]);
    const [enableEdit, setEnableEdit] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [isFiltred, setIsFiltred] = useState(false);
    const [solicitacoesDeAlteracao, setSolicitacoesDeAlteracao] = useState(null);
    const [btnSolicitacaoHover, setBtnSolicitacaoHover] = useState(false);
    const [btnNotificacaoHover, setBtnNotificacaoHover] = useState(false);
    const [quantidadeDeAtualizacoes, setQuantidadeDeAtualizacoes] = useState(0);
    const [listaDePresenca, setListaDePresenca] = useState([]);
    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
    const [isAtendimentoEditavel, setIsAtendimentoEditavel] = useState(false);
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    const [pacienteEmExecucaoList, setPacienteEmExecucaoList] = useState([]);
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(null);
    const [expand, setExpand] = useState(false);
    const [terapeutasList, setTerapeutasList] = useState([]);
    const [statusAtendimento, setStatusAtendimento] = useState(null);
    const [tipoAtendimentos, setTipoAtendimentos] = useState(null);
    const [statusSelecionado, setStatusSelecionado] = useState(null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [habilitaSucesso, setHabilitaSucesso] = useState(true);
    const [habilitaErros, setHabilitaErros] = useState(true);
    const [habilitaConflitos, setHabilitaConflitos] = useState(true);
    const [statusSelecionadoOpt, setStatusSelecionadoOpt] = useState(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState(null);
    const [statusExecucao, setStatusExecucao] = useState(null);
    const [statusExecucaoOpt, setStatusExecucaoOpt] = useState(null);
    const [atendimentoModalSelecionado, setAtendimentoModalSelecionado] = useState(null);
    const [houveAlteracaoStatusEdicao, setHouveAlteracaoStatusEdicao] = useState(false);
    const [houveInclusaoAtendimento, setHouveInclusaoAtendimento] = useState(false);
    const [controleDiarioId, setControleDiarioId] = useState(null);
    const [scrollTopInfo, setScrollTopInfo] = useState({ scrollTop: 0, scrollLeft: 0 });
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const [atendimentosNormal, setAtendimentosNormal] = useState([]);
    const [dataMoment, setDataMoment] = useState(null);
    const dataFormatada = useMemo(() => dataMoment ? dataMoment.format('YYYY-MM-DD') : null, [dataMoment]);
    const dataNome = useMemo(() => dataMoment ? dataMoment.format('dddd - DD/MM/YYYY') : null, [dataMoment]);
    const [carregandoControleDiario, setCarregandoControleDiario] = useState(false);
    const [carregandoAtendimentos, setCarregandoAtendimentos] = useState(false);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [testandoDia, setTestandoDia] = useState(false);
    const [temDiaCarregado, setTemDiaCarregado] = useState(null);
    const [alteracoesPendentes, setAlteracoesPendentes] = useState([]);

    /*==REFS==*/
    const websocketRef = useRef();
    const modalComentariosRef = useRef();
    const filtroDivRef = useRef();
    const pendenciasRef = useRef();
    const calendarioRef = useRef();
    const solicitacaoDeAlteracaoModalRef = useRef();
    const checkInModalRef = useRef(null);
    const [modalCheckinVisible, setModalCheckinVisible] = useState(false);
    const [pacienteSelecionadoCheckin, setPacienteSelecionadoCheckin] = useState(null);

    /*==Opções do select==*/
    const opcoesLiberado = [
        { value: '', label: 'vazio' },
        { value: '0', label: '❌' },
        { value: '1', label: '✅' }
    ];

    //COLUNAS
    const tableHeaders = useMemo(() => [
        { key: 'l1', className: 'cd-col-0', title: 'Hora', label: 'Hora', visivel: true },
        { key: 'l2', className: 'cd-col-1', title: 'Nome curto do terapeuta.', label: 'Terapeuta', visivel: true },
        { key: 'l3', className: 'cd-col-2', title: 'Terapia e Especialidade do atendimento.', label: 'Terapia <br /> Especialidade', visivel: true },
        { key: 'l4', className: 'cd-col-3', title: 'Local para criar, aprovar ou reprovar atendimento.', label: 'Alteração', visivel: true },
        { key: 'l5', className: 'cd-col-4', title: 'Identificador do paciente.', label: 'Paciente', visivel: true },
        { key: 'l6', className: 'cd-col-5', title: 'Sala e a estação do atendimento.', label: 'Sala <br /> Estação', visivel: true },
        { key: 'l7', className: 'cd-col-6', title: 'Define qual a modalidade e o tipo do atendimento.', label: 'Tipo e Modalidade', visivel: true },
        { key: 'l8', className: 'cd-col-7', title: 'Status que o terapeuta sugeriu para o atendimento.', label: 'Status <br /> Terapeuta', visivel: true },
        { key: 'l9', className: 'cd-col-8', title: 'Define o status que o atendimento vai receber.', label: 'Status', visivel: true },
        { key: 'l10', className: 'cd-col-9', title: 'Define se a guia foi liberada pelo plano.', label: 'Liberado', visivel: true },
        { key: 'l11', className: 'cd-col-10', title: 'Define se o atendimento foi executado no plano.', label: 'Execução', visivel: true },
        { key: 'l12', className: 'cd-col-11', title: 'Comentário registrado no atendimento.', label: '', visivel: true },
        { key: 'l13', className: 'cd-col-12', title: 'Cor que define alguma incompatibilidade no no atendimento.', label: 'P', visivel: funcoesHabilitadas['coluna_p'] }
    ], []);
    //#endregion
    //#region FUNCOES
    const filtrar = useCallback(async () => {
        const resultado = atendimentosOriginal.reduce((acc, atendimento) => {
            // Teste de cada condição para atendimento
            const paciente_id = Number(atendimento.paciente_id);
            const terapeuta_id = Number(atendimento.terapeuta_id);
            const plano_saude_id = Number(atendimento.plano_saude_id);
            const status_recepcao = Number(atendimento.sugestao_recepcao);
            const status_execucao = atendimento.executado;
            //#region FILTRO
            // Teste de cada condição para atendimento.
            const status = (statusSelecionado === null || Number(statusSelecionado) === status_recepcao);
            const paciente = (pacienteSelecionado === null || Number(pacienteSelecionado) === paciente_id);
            const terapeuta = (terapeutaSelecionado === null || Number(terapeutaSelecionado) === terapeuta_id);
            const planoSaude = (planoSaudeSelecionado === null || Number(planoSaudeSelecionado) === plano_saude_id);
            const executado = (statusExecucao === null || statusExecucao === status_execucao);
            //#endregion
            //#region CONFLITOS e ERROS
            // Teste de conflitos e erros.
            const erro = atendimento?.conflitos.liberado_realizado_sem_codigo || atendimento?.conflitos.liberado_realizado_sem_codigo2 || atendimento?.conflitos.realizado_nao_liberado || atendimento?.conflitos.sem_execucao /* || atendimento?.conflitos.sem_guia */ || atendimento?.conflitos.sem_liberacao || atendimento?.conflitos.sem_status || atendimento?.conflitos.sem_terapeuta;
            const conflito = atendimento?.conflitos.horario_paciente || atendimento?.conflitos.sala_terapeuta || atendimento?.conflitos.executado_nao_realizado;

            let erroConflitoTest = false;
            if (habilitaSucesso && !erro && !conflito) erroConflitoTest = true;
            if (habilitaErros && erro) erroConflitoTest = true;
            if (habilitaConflitos && conflito) erroConflitoTest = true;

            let solicitacoesPendentes = false;
            if (filtraSolicitacoesPendentes) {
                solicitacoesPendentes = atendimento.solicitacao_de_alteracaos?.some(filter => filter.status === 'Aguardando') || false;
            } else if (filtraSolicitacoesPendentes === false) {
                solicitacoesPendentes = !atendimento.solicitacao_de_alteracaos?.some(filter => filter.status === 'Aguardando');
            } else {
                solicitacoesPendentes = true;
            }

            const result = status && paciente && terapeuta && planoSaude && executado && erroConflitoTest && solicitacoesPendentes;
            if (result) {
                if (erro) acc.qtdeErros++;
                if (conflito) acc.qtdeConflitos++;
                if (!erro && !conflito) acc.qtdeSucessos++;
                acc.qtdeAtendimento++;
                acc.newAtendimentosList.push(atendimento);
            }
            //#endregion
            return acc;
        }, {
            newAtendimentosList: [],
            qtdeAtendimento: 0,
            qtdeSucessos: 0,
            qtdeConflitos: 0,
            qtdeErros: 0
        });
        setQuantidadeAtendimentos(resultado.qtdeAtendimento);
        setQuantidadeSucessos(resultado.qtdeSucessos);
        setQuantidadeErros(resultado.qtdeErros);
        setQuantidadeConflitos(resultado.qtdeConflitos);
        setAtendimentos(modoDoAtendimento === 'agrupado.horario' ? agrupaPorHorario(resultado.newAtendimentosList) : resultado.newAtendimentosList);
    }, [atendimentosOriginal, statusSelecionado, statusExecucao, pacienteSelecionado, terapeutaSelecionado, planoSaudeSelecionado, habilitaSucesso, habilitaErros, habilitaConflitos, filtraSolicitacoesPendentes]);
    /* SOCKET */
    /**
     * Função que envia um array de informações para atualizar todos os conectados no Controle Diário.
     * @param {Array} data Data para ser enviada ao socket, e atualizar todas as telas.
     */
    const atualizaSocket = useCallback(() => {
        if (socketState && websocketRef.current) {
            const data = { action: 'contador' };

            websocketRef.current.sendMessage(data);
            websocketRef.current.sendMessage({ action: 'checkin', paciente_id: 'null' });
        } else {
            console.error('Socket is not open or is null');
        }
    }, [socketState, websocketRef]);
    const atualizaExecutandoList = (pessoa, setPacienteEmExecucaoList) => {
        setPacienteEmExecucaoList((oldList) => {
            let newList = [...oldList];
            if (pessoa.action === 'new_connection') {
                const index = newList.findIndex(p => p.id === pessoa.id);
                if (index !== -1) {
                    newList[index] = pessoa;
                } else {
                    newList.push(pessoa);
                }
            } else if (pessoa.action === 'update_connections_list') {
                newList = pessoa.connections ? pessoa.connections : [];
            } else if (pessoa.action === 'disconnect') {
                newList = newList.filter(filter => filter.id !== pessoa.id);
            }
            return newList;
        });
    };
    const selecionaData = async (dataSaveLocal) => {
        setDataMoment(dataSaveLocal);
        adicionarParametroNaUrl('data', dataSaveLocal.format('YYYY-MM-DD'));
    };
    /* ==AGENDA== */
    /**
     * Agrupa os dados de atendimento por horário e conta solicitações pendentes.
     *
     * @param {Array} data - Array de objetos de atendimento.
     * @param {number} data[].hora_atendimento - Hora do atendimento.
     * @param {number} data[].terapeuta_id - ID do terapeuta.
     * @param {string} data[].terapeuta_nome - Nome do terapeuta.
     * @param {number} data[].id - ID do atendimento.
     * @param {Array} data[].solicitacao_de_alteracaos - Array de solicitações de alteração.
     * @param {string} data[].solicitacao_de_alteracaos[].status - Status da solicitação de alteração.
     * @returns {Object} Objeto contendo os dados agrupados por horário e a contagem de solicitações pendentes.
     * @returns {Array} return.data2 - Array de objetos agrupados por horário.
     * @returns {string} return.data2[].hora - Hora do atendimento.
     * @returns {Array} return.data2[].atendimentos - Array de atendimentos para a hora específica.
     * @returns {number} return.localContagemSolicitacoesPendentes - Contagem de solicitações pendentes.
     */
    const agrupaPorHorario = (data) => {
        let atendimentoPorHorario = [];
        let data2 = [];
        let localContagemSolicitacoesPendentes = 0;
        for (let index = 0; index < data.length; index++) {
            const el = data[index];
            const nextEl = data[index + 1];
            const horaAgora = el.hora_atendimento;
            const horaDepois = (nextEl !== undefined) ? nextEl.hora_atendimento : false;
            if (horaDepois) {
                if (horaAgora === horaDepois) {
                    atendimentoPorHorario.push(el);
                } else {
                    atendimentoPorHorario.push(el);
                    data2.push({
                        hora: horaAgora,
                        atendimentos: atendimentoPorHorario
                    });
                    atendimentoPorHorario = [];
                }
            } else {
                atendimentoPorHorario.push(el);
                data2.push({
                    hora: horaAgora,
                    atendimentos: atendimentoPorHorario
                });
            }

            if (el.solicitacao_de_alteracaos && el.solicitacao_de_alteracaos.filter(filter => filter.status === 'Aguardando').length > 0) {
                localContagemSolicitacoesPendentes = localContagemSolicitacoesPendentes + 1;
            }
        }
        return data2;
    };
    /**
     * Função para obter os agendamentos do controle diário.
     * 
     * @function
     * @param {number|null} [controleid=null] - ID do controle diário. Se for null, será utilizado o controleDiarioId do estado.
     * @param {boolean} [socketSend=false] - Indica se deve enviar dados via socket.
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando os agendamentos são obtidos e processados.
     * 
     * @example
     * getAgendamentosControleDiario(123, true);
     * 
     * @description
     * Esta função obtém os agendamentos do controle diário, processa os dados recebidos e atualiza o estado do componente com as informações necessárias.
     * 
     * - Define os estados de carregamento.
     * - Obtém o elemento DOM da tabela de controle e armazena as informações de scroll.
     * - Obtém o ID do controle diário.
     * - Faz uma chamada ao serviço AtendimentoService para obter os dados dos agendamentos.
     * - Processa os dados recebidos, incluindo a verificação de conflitos e a definição de cores e últimas alterações.
     * - Ordena os dados por horário de atendimento.
     * - Atualiza os estados do componente com os dados processados.
     * - Lida com erros e define o estado de carregamento como falso em caso de falha.
     */
    const getAtendimentosControleDiario = useCallback(async (controleid = null) => {
        setCarregandoAtendimentos(true);
        setAlgoCarregando(true);
        try {
            const scrollDom = document.getElementById('tabela-controle');
            if (scrollDom) {
                const { scrollTop, scrollLeft } = scrollDom;
                setScrollTopInfo({ scrollTop: scrollTop, scrollLeft: scrollLeft });
            }
            const id = (controleid === null) ? controleDiarioId : controleid;
            fetchControleDiario(dataMoment, setControleDiarioId, setTemDiaCarregado, setCarregandoControleDiario);
            let filters = undefined;
            switch (modoDeConsulta) {
                case 'terapeuta-pendente':
                    filters = 'POS-terapeuta_id=NULL,liberado=null||liberado=0';
                    break;
                case 'status-pendentes':
                    filters = 'sugestao_recepcao=NULL,liberado=1||0';
                    break;
                case 'check-ins-pendentes':
                    filters = 'liberado=null,atrasado=15min';
                    break;
                case 'execucoes-pendentes':
                    filters = "liberado=1,executado=NULL||pendente";
                    break;
                case 'consultar-execucoes':
                    filters = "executado=executado||executado-sce";
                    break;
                default:
                    if (typeof modoDeConsulta === 'object')
                        filters = modoDeConsulta.join(',');
                    break;
            }
            if (modoDeConsulta === 'erros-conflitos') {
                AtendimentoService.confereValidadeAtendimentos(id, false, true)
                    .then((response) => {
                        const { atendimentos_errados } = response.data;
                        return funcaoDataMap(atendimentos_errados,
                            setTerapeutasList,
                            setAtendimentosNormal,
                            setAtendimentosOriginal,
                            setAlgoCarregando,
                            setCarregandoAtendimentos,
                            setQuantidadeDeAtualizacoes,
                            setTemDiaCarregado
                        );
                    });
            } else {
                if (!(modoDeConsulta !== 'normal' && filters === undefined)) {
                    AtendimentoService.getByControleDiarioId(id, undefined, undefined, undefined, filters)
                        .then((response) => {
                            return funcaoDataMap(response,
                                setTerapeutasList,
                                setAtendimentosNormal,
                                setAtendimentosOriginal,
                                setAlgoCarregando,
                                setCarregandoAtendimentos,
                                setQuantidadeDeAtualizacoes,
                                setTemDiaCarregado
                            );
                        });
                }
            }
        } catch (error) {
            setCarregandoAtendimentos(false);
            console.error(error);
        }
    }, [controleDiarioId]);
    const expandFiltro = (e) => {
        if (e.target === document.getElementById('div-expande-filtro')) {
            if (expand) {
                fechaFiltro(null);
            } else {
                abreFiltro();
            }
        }
    };
    const abreFiltro = () => {
        setExpand(true);
    };
    const fechaFiltro = (event) => {
        try {
            if (!event) {
                setExpand(false);
            } else {
                if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                    setExpand(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    function adicionarParametroNaUrl(chave, valor) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function removerParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Remove o parâmetro
        parametros.delete(chave);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function recuperarParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Retorna o valor do parâmetro
        return parametros.get(chave);
    }
    //#endregion
    //#region HANDLES
    const handleChangeEnableEdit = useCallback(() => {
        Swal.fire({
            title: 'Deseja mesmo habilitar a edição?',
            text: 'O processo de edição é muito delicado, e exige atenção. Depois de habilitado, é obrigatório salva-lo para sair.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Habilitar',
            cancelButtonText: 'Cancelar'
        }).then((e) => {
            if (e.isConfirmed) {
                setEnableEdit(true);
                Swal.fire('Edição Habilitada!', 'A edição do Controle Diário foi habilitada com sucesso!', 'success');
            }
        }
        );
    }, []);
    const handleCloseModalControleDeExecucao = useCallback((insideSocket = null, localControleDiarioId) => {
        if (localControleDiarioId) {
            getAtendimentosControleDiario(localControleDiarioId);
        }
        setModalCheckinVisible(false);
    }, [getAtendimentosControleDiario, socket]);
    const handleOpenModalAtendimento = useCallback((atendimento, editavel = true) => {
        setIsAtendimentoEditavel(editavel);
        setIsModalAtendimentoOpen(true);
        setAtendimentoModalSelecionado(atendimento);
    }, []);
    function handleOpenModalPendencias(conflitos) {
        pendenciasRef.current.openModal(conflitos);
    }
    const handleLimpaFiltros = () => {
        setTerapeutaSelecionado(null);
        setTerapeutaSelecionadoOpt(null);
        setStatusSelecionado(null);
        setStatusSelecionadoOpt(null);
        setPacienteSelecionado(null);
        setPacienteSelecionadoOpt(null);
        setPlanoSaudeSelecionado(null);
        setPlanoSaudeSelecionadoOpt(null);
        setStatusExecucao(null);
        setStatusExecucaoOpt(null);
        setFiltraSolicitacoesPendentes(null);
    };
    const handleFiltraPaciente = (paciente_id) => {
        setPacienteSelecionado(paciente_id);
        setPacienteSelecionadoOpt(pacienteOptionsList.filter((el) => Number(el.value) === Number(paciente_id)));
    };
    const handleOpenSolicitacaoDeAlteracao = (atendimento = null) => {
        if (solicitacaoDeAlteracaoModalRef.current) {
            solicitacaoDeAlteracaoModalRef.current.open(atendimento);
        }
    };
    /* ==MODAL CONTROLE DE EXECUÇÃO== */
    const handleOpenModalCheckin = (paciente_id) => {
        setPacienteSelecionadoCheckin(paciente_id);
        setModalCheckinVisible(true);
    };
    useEffect(() => {
        if (modalCheckinVisible) {
            const paciente_id = pacienteSelecionadoCheckin;
            handleLimpaFiltros();
            setPacienteSelecionado(paciente_id);
            setPacienteSelecionadoOpt(pacienteOptionsList.filter((el) => Number(el.value) === Number(paciente_id)));
            if (checkInModalRef.current) {
                const identificador = pacienteOptionsList.filter((el) => Number(el.value) === Number(paciente_id))[0].identificador;
                if (websocketRef.current) {
                    websocketRef.current.sendMessage({ action: 'checkin', paciente_id: paciente_id, identificador: identificador });
                }
                checkInModalRef.current.openModal(controleDiarioId, paciente_id);
            }
        } else {
            setPacienteSelecionadoCheckin(null);
            atualizaSocket();
        }
    }, [modalCheckinVisible]);
    /* ==MODAL COMENTARIOS== */
    const handleModalComentarios = (dados) => {
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    };
    const handleChangeHabilitaSucesso = (event) => {
        setHabilitaSucesso(event.target.checked);
    };
    const handleChangeHabilitaErros = (event) => {
        setHabilitaErros(event.target.checked);
    };
    const handleChangeHabilitaConflitos = (event) => {
        setHabilitaConflitos(event.target.checked);
    };
    const handleClickDay = (day, month, year) => {
        let dia = moment.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
        let dataSaveLocal = moment.utc(dia);
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const handleToggleCalendario = () => {
        setIsCalendarioVisible(!isCalendarioVisible);
    };
    const handleFechaCalendario = (event) => {
        try {
            if (event === null) {
                setIsCalendarioVisible(false);
            } else {
                if (calendarioRef.current && !calendarioRef.current.contains(event.target)) {
                    setIsCalendarioVisible(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleExportToExcelControle = () => {
        api.get('api/controle-diario/exportar-para-excel/' + controleDiarioId).then(({ data }) => {
            // Crie um link para baixar o arquivo
            const link = document.createElement('a');
            link.href = data.file;
            link.download = `atendimentos_${controleDiarioId}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.error(error);
            snackbar.displayMessage('Erro ao exportar controle diário para excel!', 'error');
        });
    };
    const handleFiltraSolicitacaoPendente = () => {
        if (filtraSolicitacoesPendentes === null) {
            setFiltraSolicitacoesPendentes(true);
        } else {
            if (filtraSolicitacoesPendentes === false) {
                setFiltraSolicitacoesPendentes(null);
            } else {
                if (filtraSolicitacoesPendentes === true) {
                    setFiltraSolicitacoesPendentes(false);
                }
            }
        }
    };
    const handleVerSolicitacao = (solicitacao) => {
        SolicitacaoDeAlteracaoService.visualizar(solicitacao);
    };
    const handleAbreSolicitacaoDeAlteracao = (solicitacao) => {
        SolicitacaoDeAlteracaoService.aprovaReprova(solicitacao,
            () => {
                Swal.fire('Solicitação de alteração aprovada com sucesso!', '', 'success');
                getAtendimentosControleDiario(controleDiarioId);
                atualizaSocket();
            },
            () => {
                Swal.fire('Solicitação de alteração reprovada com sucesso!', '', 'success');
                getAtendimentosControleDiario(controleDiarioId);
                atualizaSocket();
            },
            () => {
                let dataSaveLocal = dataMoment;
                selecionaData(dataSaveLocal);
            }
        );
    };
    const handleChangeAtendimentoLiberado = useCallback(async (e, atendimentoId) => {
        const atualizaLiberado = (atendimentoId, value) => {
            setAtendimentos((prevAtendimentos) => {
                const index = prevAtendimentos.findIndex((atendimento) => atendimento.id === atendimentoId);
                if (index !== -1) {
                    const updated = [...prevAtendimentos];
                    updated[index].liberado = String(value);
                    return updated;
                } else {
                    console.error(`Atendimento não encontrado: ${atendimentoId}`);
                    return prevAtendimentos
                }
            });
            setAlteracoesPendentes((prev) => {
                const index = prev.findIndex((item) => item.id === atendimentoId);
                if (index !== -1) {
                    const updated = [...prev];
                    updated[index].liberado = String(value);
                    return updated;
                } else {
                    return [...prev, { id: atendimentoId, liberado: String(value) }];
                }
            });
        };

        e = e.target ? e.target : e;
        atualizaLiberado(atendimentoId, e.value);
    }, [dataMoment, controleDiarioId, snackbar, getAtendimentosControleDiario, atualizaSocket, socket]);
    const handleChangeStatusRecepcao = useCallback(async (e, atendimentoId) => {
        const atualizaStatus = (atendimentoId, status) => {
            setAtendimentos((prevAtendimentos) => {
                const index = prevAtendimentos.findIndex((atendimento) => atendimento.id === atendimentoId);
                if (index !== -1) {
                    const updated = [...prevAtendimentos];
                    updated[index].sugestao_recepcao = status;
                    return updated;
                } else {
                    console.error(`Atendimento não encontrado: ${atendimentoId}`);
                    return prevAtendimentos
                }
            });
            setAlteracoesPendentes((prev) => {
                const index = prev.findIndex((item) => item.id === atendimentoId);
                if (index !== -1) {
                    const updated = [...prev];
                    updated[index].sugestao_recepcao = status;
                    return updated;
                } else {
                    return [...prev, { id: atendimentoId, sugestao_recepcao: status }];
                }
            });
            setAlteracoesPendentes((prev) => [...prev, { id: atendimentoId, sugestao_recepcao: status }]);
        };

        e = e.target ? e.target : e;
        atualizaStatus(atendimentoId, e.value);
    }, [dataMoment, getAtendimentosControleDiario, controleDiarioId, snackbar, selecionaData, atualizaSocket, socket]);
    /*NAVEGADORES de DATA*/
    const handleClickNextDay = () => {
        let dataSaveLocal = dataMoment.add(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = dataMoment.subtract(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const salvarAlteracoesPendentes = async () => {
        if (alteracoesPendentes.length > 0) {
            try {
                await api.put('/api/atendimento/atualiza/varios', { atendimentos: alteracoesPendentes })
                    .then(() => {
                        snackbar.displayMessage('Alterações salvas com sucesso!', 'success');
                        setAlteracoesPendentes([]);
                        getAtendimentosControleDiario(controleDiarioId);
                        atualizaSocket();
                    })
                    .catch((error) => {
                        snackbar.displayMessage('Erro ao salvar alterações!', 'error');
                        console.error(error);
                    });
            } catch (error) {
                snackbar.displayMessage('Erro ao salvar alterações!', 'error');
                console.error(error);
            }
        } else {
            snackbar.displayMessage('Não há alterações pendentes para salvar.', 'info');
        }
    };
    //#endregion
    //#region GETTERS
    /**
     * Função que define o status do atendimento via useState.
     * @returns {void}
     */
    const getStatusAtendimento = async () => {
        await api.get('/api/status-atendimento')
            .then(response => {
                let statusList = response.data;
                statusList = statusList.map(status => {
                    return {
                        value: status.id,
                        label: status.status,
                        slug: status.slug,
                    };
                });
                setStatusAtendimento(statusList);
            });
    };
    /**
     * Função que define o tipo do atendimento via useState.
     * @returns {void}
     */
    const getTipoAtendimento = async () => {
        await api.get('/api/tipo-atendimento')
            .then(response => {
                let tiposList = response.data;
                tiposList = tiposList.map(status => {
                    return {
                        value: status.id,
                        label: status.tipo_atendimento
                    };
                });
                setTipoAtendimentos(tiposList);
            });
    };
    /**
     * Função que define o status selecionado pelo valor.
     * @param {Array} statusList Array com a lista de status
     * @param {Number} value 
     * @returns {*}
     */
    const getStatusSelecionadoByValue = (statusList, value) => {
        if (!statusList || statusList.length < 1 || statusList === undefined || statusList === null) return;
        return statusList.filter(status => status.value === value)[0] || null;
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        if (alteracoesPendentes.length === 0 || alteracoesPendentes.length === undefined) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [alteracoesPendentes]);
    useEffect(() => {
        if (controleDiarioId) {
            getAtendimentosControleDiario(controleDiarioId);
        }
    }, [controleDiarioId]);
    useEffect(() => {
        setContagemSolicitacoesPendentes(solicitacoesDeAlteracao?.filter(filter => filter.status === 'Aguardando')?.length ? solicitacoesDeAlteracao.length : 0);
    }, [solicitacoesDeAlteracao]);
    const handleOnMessage = (data) => {
        try {
            /* Verifica se tem data */
            if (data) {
                /* Verifica se tem id do paciente */
                if (data.action === 'contador') {
                    /* Se não tiver id do paciente, significa que não veio do Controle
                    de Execução, então deve apenas adicionar ao contador de atualizaç~eos. */
                    const newQuantidade = Number(quantidadeDeAtualizacoes) + 1;
                    setQuantidadeDeAtualizacoes(newQuantidade);
                } else {
                    setListaDePresenca(data.connections);
                    /* Chama a função que atualiza a lista */
                    atualizaExecutandoList(data, setPacienteEmExecucaoList);
                }
            }
        } catch (error) {
            console.error('Erro ao parsear a mensagem: ', error);
        }
    };
    useEffect(() => {/* WEBSOCKET */
        if (!socket) {
            if (controleDiario && controleDiario.id && controleDiario?.salvo === null && usuario) {
                if (!websocketRef.current) {
                    return null;
                }
                const params = `controle_diario?controle_diario_id=${controleDiario.id}&username=${usuario?.user?.name}&user_id=${usuario?.user?.id}`;

                websocketRef?.current?.setSocketLink(params);
                websocketRef?.current?.setConectaComSocket(true);


                setSocket(websocketRef?.current?.handleGetSocket());
            }
        }
    }, [controleDiario, socket, usuario, websocketRef]);
    useEffect(() => {
        setAlgoCarregando(true);
        try {
            if (!houveAlteracaoStatusEdicao || !houveInclusaoAtendimento) return;
            getAtendimentosControleDiario(controleDiarioId);
            setHouveAlteracaoStatusEdicao(false);
            setHouveInclusaoAtendimento(false);
            setAlgoCarregando(true);
        } catch (error) {
            console.error(error);
        }
    }, [houveAlteracaoStatusEdicao, houveInclusaoAtendimento]);
    const dataMomentObserver = useCallback(async () => {
        if (dataMoment) {
            setTestandoDia(true);
            try {
                fetchControleDiario(dataMoment, setControleDiarioId, setTemDiaCarregado, setCarregandoControleDiario);
            } catch (error) {
                console.error(error);
            } finally {
                setTestandoDia(false);
            }
        }
    }, [dataMoment]);
    useEffect(() => {
        dataMomentObserver();
    }, [dataMoment]);
    useEffect(() => {
        setAlgoCarregando(true);
        if (atendimentosOriginal && atendimentosOriginal.length > 0) {
            filtrar().then(() => {
                let ele = document.getElementById('tabela-controle');
                if (ele) {
                    ele.scrollTop = scrollTopInfo?.scrollTop;
                    ele.scrollLeft = scrollTopInfo?.scrollLeft;
                }
                setAlgoCarregando(false);
            });
            if (planoSaudeSelecionado || pacienteSelecionado || statusSelecionado || statusExecucao || terapeutaSelecionado || filtraSolicitacoesPendentes !== null) {
                setIsFiltred(true);
            } else {
                setIsFiltred(false);
            }
        } else {
            setAtendimentos([]);
            setAlgoCarregando(false);
        }
    }, [terapeutaSelecionado, pacienteSelecionado, planoSaudeSelecionado, statusSelecionado, statusExecucao, atendimentosOriginal, habilitaErros, habilitaConflitos, habilitaSucesso, pacienteEmExecucaoList, filtraSolicitacoesPendentes]);
    useEffect(() => {
        if (controleDiario && usuario) {
            setIsEditable(true);
        }
    }, [enableEdit, usuario, controleDiario]);
    useEffect(() => {/* ON LOAD */
        if (pacienteOptions && !outsidePacienteOptions) {
            setPacienteOptionsList(pacienteOptions);
        }
    }, [pacienteOptions]);
    useEffect(() => {/* ON LOAD */
        try {
            const permissoesDaPagina = usuario.coletaPermissoesPagina();
            setPermissoes(permissoesDaPagina);
            const dataFromUrl = recuperarParametroDaUrl('data');
            if (dataFromUrl) {
                selecionaData(moment.utc(dataFromUrl));
            } else {
                selecionaData(moment.utc());
            }
            if (!modoIncorporado) {
                api.get('api/controle-diario/is/nao/salvo').then(({ data }) => {
                    if (data.length > 0) {
                        const naoSalvo = data[0];
                        const carrergado = moment.utc(naoSalvo.carregado).format('DD/MM/YYYY');
                        const dataTeste = dataFromUrl ? moment.utc(dataFromUrl).format('DD/MM/YYYY') : moment.utc().format('DD/MM/YYYY');
                        const dataHoje = moment.utc().format('DD/MM/YYYY');
                        if (carrergado !== dataTeste && carrergado !== dataHoje) {
                            Swal.fire({
                                title: 'Existe um dia que não foi salvo',
                                text: 'Por favor, confira os dias anteriores para salva-lo, e tente novamente! \n \n Dia: ' + moment.utc(naoSalvo.dia).format('DD/MM/YYYY'),
                                icon: 'error',
                                timer: 10000
                            });
                            const day = moment.utc(naoSalvo.dia).format('YYYY-MM-DD');
                            const newDayMark = [];
                            newDayMark[day] = 'Dia não salvo, clique aqui e salve-o.';
                            setDaysMark(newDayMark);
                        }
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
            getStatusAtendimento();
            getTipoAtendimento();
            if (outsidePacienteOptions) {
                setPacienteOptionsList(outsidePacienteOptions);
            } else {
                fetchPacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
            }
            fetchSalaEstacaoData();
            fetchHorarioAtendimentoComFimData(moment.utc(dataFromUrl).weekday());
            fetchModalidadeData();
            fetchPlanoSaude();
            fetchTipoAtendimentosData();
        } catch (error) {
            console.error(error);
        }
        /* VERIFICADORES DE CLICK */
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Adiciona o listener de redimensionamento da janela
        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', (e) => {
            fechaFiltro(e);
            handleFechaCalendario(e);
        });
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
                handleFechaCalendario();
            }
        });
        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', (e) => {
                fechaFiltro(e);
                handleFechaCalendario(e);
            });
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                    handleFechaCalendario();
                }
            });
        };
    }, []);
    //#endregion

    //#region HTML
    return (
        <AtendimentoReposicaoProvider>
            <div style={{ padding: '0px 0px 0px 50px' }}>
                <div className='d-flex align-center justify-center w-100per mt-1 flex-col'>
                    <div className='div-controlediario-cabecalho'>
                        {temDiaCarregado === true ?
                            funcoesHabilitadas['filtro'] ?
                                <div className='div-filter-style'>
                                    <div ref={filtroDivRef} style={{ position: 'relative', width: '100%' }}>
                                        <div id='div-expande-filtro' className="w-100per pointer" onClick={(e) => expandFiltro(e)}>
                                            <div className='d-flex align-items-center' style={{ display: 'flex' }} onClick={(e) => abreFiltro()}>
                                                <p id="blank" style={{ marginBottom: -30 }}>
                                                    {expand ? 'Fechar' : 'Expandir'} Filtro
                                                </p>
                                            </div>
                                            <div className='pointer flex-nowrap d-flex flex-row align-items-center' style={{ marginLeft: '86%' }}>
                                                {isFiltred
                                                    ? <FaBroom color="yellow" id="botao-limpa-filtro" size={32} onClick={(e) => { handleLimpaFiltros(); }} title='Conteúdo filtrado...' />
                                                    : <div style={{ width: '25px', height: '25px' }}></div>
                                                }
                                                <AiFillCaretDown onClick={(e) => expand ? fechaFiltro() : abreFiltro()} id='botao-expande-filtro' color="white" size={26} style={{ marginLeft: '10px' }} />
                                            </div>
                                        </div>
                                        <Form className='div-expand-over' style={expand ? { width: '80%' } : { display: 'none' }}>
                                            <div style={DivContentDisplay} className="row">
                                                {filtrosHabilitados['terapeuta'] &&
                                                    <div className={`col-${filtrosHabilitados['status_atendimento'] ? '6' : '12'}`}>
                                                        <label className="label-filtro">Terapeuta:</label>
                                                        <div className="d-flex justify-around align-center w-100per">
                                                            <Select id={'terapeutas'} name={'terapeutas'} options={terapeutasList} value={terapeutaSelecionadoOpt}
                                                                onChange={(e) => { setTerapeutaSelecionado(e.value); setTerapeutaSelecionadoOpt({ value: e.value, label: e.label }); }} />
                                                            <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                                setTerapeutaSelecionado(null);
                                                                setTerapeutaSelecionadoOpt(null);
                                                            }} size={25} />
                                                        </div>
                                                    </div>
                                                }
                                                {filtrosHabilitados['status_atendimento'] &&
                                                    <div className={`col-${filtrosHabilitados['terapeuta'] ? '6' : '12'}`}>
                                                        <label className="label-filtro">Status de Atendimento:</label>
                                                        <div className="d-flex justify-around align-center">
                                                            <Select
                                                                name="status"
                                                                options={statusAtendimento}
                                                                value={statusSelecionadoOpt}
                                                                onChange={(e) => {
                                                                    setStatusSelecionado(e.value);
                                                                    setStatusSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                            <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                                setStatusSelecionado(null);
                                                                setStatusSelecionadoOpt(null);
                                                            }} size={25} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div style={DivContentDisplay} className="row">
                                                {filtrosHabilitados['paciente'] &&
                                                    <div className={`col-${filtrosHabilitados['plano_saude'] ? '6' : '12'}`}>
                                                        <label className="label-filtro">Paciente:</label>
                                                        <div className="d-flex justify-around align-center">
                                                            <Select
                                                                name={'pacientes'}
                                                                options={pacienteOptionsList}
                                                                value={pacienteSelecionadoOpt}
                                                                filterOption={customPacienteFilter}
                                                                onChange={(e) => {
                                                                    setPacienteSelecionado(e.value);
                                                                    setPacienteSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                            <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                                setPacienteSelecionado(null);
                                                                setPacienteSelecionadoOpt(null);
                                                            }} size={25} />
                                                        </div>
                                                    </div>
                                                }
                                                {filtrosHabilitados['plano_saude'] &&
                                                    <div className={`col-${filtrosHabilitados['paciente'] ? '6' : '12'}`}>
                                                        <label className="label-filtro">Plano de Saúde:</label>
                                                        <div className="d-flex justify-around align-center">
                                                            <Select
                                                                name={'planosSaude'}
                                                                options={planoSaudeOptions}
                                                                value={planoSaudeSelecionadoOpt}
                                                                onChange={(e) => {
                                                                    setPlanoSaudeSelecionado(e.value);
                                                                    setPlanoSaudeSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                            <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                                setPlanoSaudeSelecionado(null);
                                                                setPlanoSaudeSelecionadoOpt(null);
                                                            }} size={25} />
                                                        </div>
                                                    </div>
                                                }
                                                {filtrosHabilitados['status_execucao'] &&
                                                    <div className={`col-12`}>
                                                        <label className="label-filtro">Status Execucao:</label>
                                                        <div className="d-flex justify-around align-center">
                                                            <Select
                                                                name={'statusExecucao'}
                                                                options={executadoOptions}
                                                                value={statusExecucaoOpt}
                                                                onChange={(e) => {
                                                                    setStatusExecucao(e.value);
                                                                    setStatusExecucaoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                            <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                                setStatusExecucao(null);
                                                                setStatusExecucaoOpt(null);
                                                            }} size={25} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                : <div></div>
                            : <></>
                        }
                        <div className={temDiaCarregado === true ? 'div-seletor-style' : 'div-seletor-style-solo'}>
                            {funcoesHabilitadas['navegacao'] &&
                                <>
                                    <div className='w-100per d-flex flex-col'>
                                        <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                                            <button className='btn-icone'>
                                                <AiOutlineLeft className='pointer' color='black' size={22} onClick={() => handleClickPrevDay()} />
                                            </button>
                                            <span className='texto-navegacao me-2 ml-2' onClick={(e) => handleToggleCalendario()}>{`${dataNome.charAt(0).toUpperCase() + dataNome.slice(1)}`}</span>
                                            <button className='btn-icone'>
                                                {moment.utc().format('YYYY-MM-DD') !== dataFormatada ?
                                                    <AiOutlineRight className='pointer' color='black' size={22} onClick={() => handleClickNextDay()} />
                                                    : <AiOutlineRight color='white' size={22} />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div ref={calendarioRef} className={isCalendarioVisible ? 'div-expand-over calendar-div p-0 w-fit-content-i bg-white' : 'some'}>
                                        <div className='w-100 d-flex justify-content-end'>
                                            <button className='btn-icone' onClick={(e) => handleToggleCalendario()}>
                                                <AiOutlineClose color='red' size={22} />
                                            </button>
                                        </div>

                                        <Calendario dateLimit={true} style={{ borderTop: 'none' }} daysMark={daysMark} handleClickDay={handleClickDay} maxDate={moment.utc().format('YYYY-MM-DD')} selectedDate={dataFormatada}></Calendario>
                                    </div>
                                </>
                            }
                        </div>
                        {temDiaCarregado === true &&
                            <div className='div-buttons-style'>
                                {/* 
                                {funcoesHabilitadas['salvar'] &&
                                    <button
                                        disabled
                                        className='btn-padrao w-200px h-38px m-2'
                                        style={controleDiario?.salvo === null
                                            ? aparenciaBotaoAcao['nao_salvo']
                                            : (usuario?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || usuario?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                                                ? aparenciaBotaoAcao['salvo']['habilitado']
                                                : aparenciaBotaoAcao['salvo']['nao_habilitado']
                                        }
                                        onClick={() => { controleDiario?.salvo === null && salvarControleDiario(false, atendimentosOriginal, controleDiarioId, usuario.user.id); }}>
                                        {controleDiario?.salvo === null ? 'Salvar' : 'Salvo'}
                                    </button>
                                }
                                */}
                                {(carregandoAtendimentos)
                                    ? <span className='text-center d-flex flex-col justify-content-center align-center'>
                                        <div className='d-flex flex-row w-fit-content text-center align-items-center'>
                                            <ScaleLoader css={override} color={'#fd931a'} />
                                            <BsSearch className='icone manual-searching' size={30} />
                                        </div>
                                    </span>
                                    : <></>
                                }
                                <button className={`btn-padrao me-2 h-38px ${alteracoesPendentes.length > 0 ? 'font-laranja btn-marca-vermelha' : ''}`} onClick={salvarAlteracoesPendentes}>
                                    {cloneElement(iconArray['salvar'], { size: 18, style: { marginRight: '5px' } })}
                                    Salvar Atendimentos
                                </button>
                                {permissoes && permissoes['novo_atendimento'] && funcoesHabilitadas['novo_atendimento'] &&
                                    <button className='btn-padrao turquesa w-200px h-38px m-2' type='button' onClick={() => setIsModalAdicionarAtendimentoVisible(true)}>
                                        {cloneElement(iconArray['adicionar'], { style: { marginRight: '10px' } })} Novo Atendimento
                                    </button>
                                }
                                {/* LISTAGEM DE SOLICITAÇÕES DE ALTERAÇÃO */}
                                {funcoesHabilitadas['solicitacoes_alteracao'] &&
                                    <Dropdown
                                        clickMode={true}
                                        buttonOver={true}
                                        substituicao
                                        button={
                                            <button className='btn-padrao bg-transparente h-38px me-2' title='Filtrar por solicitações pendentes.' type='button' style={{ backgroundColor: '#7441BF', position: 'relative', zIndex: 1000 }} data-bs-toggle="dropdown" aria-expanded="false"
                                                onMouseEnter={(e) => setBtnSolicitacaoHover(true)}
                                                onMouseLeave={(e) => setBtnSolicitacaoHover(false)}>
                                                {contagemSolicitacoesPendentes > 0 &&
                                                    <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px 20px 0 0', transform: 'translateX(30px)' }}>
                                                        {contagemSolicitacoesPendentes}
                                                    </ContagemPendentes>
                                                }
                                                <BsBell style={{ margin: 0 }} />
                                                <ContagemPendentes className={`d-flex justify-content-center align-items-center${btnSolicitacaoHover && ' bg-laranja-i'}`} type="button" style={{ position: 'absolute', margin: '30px 100px 0 0', transform: 'translateX(30px)', backgroundColor: '#7441BF' }}>
                                                    <BsArrowDownLeft style={{
                                                        margin: 0,
                                                    }} />
                                                </ContagemPendentes>
                                            </button>
                                        }
                                        content={
                                            <ul className="card w-400px h-auto max-h-500px p-4 me-4 list-unstyled" style={{ position: 'relative' }}>
                                                <li className='d-flex justify-content-between align-items-center pt-2' key={'solicitacao:title-controle-diario:' + controleDiario.id}>
                                                    <h6 className='font-preta p-0 m-0'>Solicitações de Alteração</h6>
                                                    <button className='btn-icone p-0 m-0' type={'button'} onClick={() => handleOpenSolicitacaoDeAlteracao()}>
                                                        <IoAddCircleOutline size={30} />
                                                    </button>
                                                </li>
                                                <li><hr className='w-100'></hr></li>
                                                {solicitacoesDeAlteracao?.length > 0 &&
                                                    solicitacoesDeAlteracao.map((solicitacao, index) => {
                                                        let corSolicitacao = solicitacao.status.toLowerCase();
                                                        return <li className='ps-2' key={'solicitacao:' + index + '-controle_diario:' + controleDiarioId}>
                                                            <button
                                                                type="button"
                                                                className={`w-100 d-flex justify-content-between btn btn-${corSolicitacao === 'aguardando' ? 'warning' : corSolicitacao === 'aprovado' ? 'success' : 'danger'}`}
                                                                onClick={() => corSolicitacao === 'aguardando' ? handleAbreSolicitacaoDeAlteracao(solicitacao) : handleVerSolicitacao(solicitacao)}
                                                                style={linhasSolicitacao[corSolicitacao]}>
                                                                <span className='w-100 d-flex justify-content-between'>
                                                                    <span>
                                                                        Envio: {moment(solicitacao.created_at).format('HH:mm')}
                                                                        <span className='ms-2'>
                                                                            {solicitacao?.user?.tipo_user?.toLowerCase() === 'terapeuta'
                                                                                ? <FaUserMd size={28} />
                                                                                : solicitacao?.user?.tipo_user?.toLowerCase() === 'agendamento'
                                                                                    ? <FaUserTie size={28} />
                                                                                    : solicitacao?.user?.tipo_user?.toLowerCase() === 'administrador' || solicitacao?.user?.tipo_user?.toLowerCase() === 'desenvolvedor'
                                                                                        ? <FaUserCog size={28} />
                                                                                        : <FaUser size={28} />
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                    {solicitacao.horarios_atendimento &&
                                                                        <p className='p-0 m-0'>
                                                                            Horario: {moment.utc(solicitacao.horarios_atendimento.horario).format('HH:mm')}
                                                                        </p>
                                                                    }
                                                                </span>
                                                                <IoMdOpen size={20} className='ml-20px' />
                                                            </button>
                                                        </li>;
                                                    })
                                                }
                                            </ul>
                                        }
                                    />
                                }
                                {funcoesHabilitadas['filtro_solicitacoes_alteracao'] &&
                                    <button className='btn-padrao h-38px me-2' title='Filtrar por solicitações pendentes.' type='button' style={{ backgroundColor: '#7441BF' }}
                                        onClick={(e) => handleFiltraSolicitacaoPendente()}
                                        onMouseEnter={(e) => setBtnNotificacaoHover(true)}
                                        onMouseLeave={(e) => setBtnNotificacaoHover(false)}>
                                        {filtraSolicitacoesPendentes === true
                                            ? <BsBellFill />
                                            : filtraSolicitacoesPendentes === false
                                                ? <BsBellSlash />
                                                : <BsBell />
                                        }
                                        <ContagemPendentes className={btnNotificacaoHover ? 'bg-laranja-i' : ''} type="button" style={{ position: 'absolute', margin: '30px 100px 0 0', transform: 'translateX(30px)', backgroundColor: '#7441BF' }}>
                                            {filtraSolicitacoesPendentes === true || filtraSolicitacoesPendentes === false
                                                ? <AiFillFilter />
                                                : <FiFilter />
                                            }
                                        </ContagemPendentes>
                                    </button>
                                }
                                {funcoesHabilitadas['atualizacao'] &&
                                    <button className='btn-padrao h-38px me-2' title='Atualiza Controle Diário.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => { getAtendimentosControleDiario(controleDiarioId); }}>
                                        {quantidadeDeAtualizacoes > 0 &&
                                            <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px -30px 0 0' }}>
                                                {quantidadeDeAtualizacoes}
                                            </ContagemPendentes>
                                        }
                                        {cloneElement(iconArray['atualizar'], { size: 18, className: `rotacao-padrao${algoCarregando ? ' rodando' : ''}` })}
                                    </button>
                                }
                                {funcoesHabilitadas['baixar_excel'] &&
                                    <button className='btn-padrao h-38px me-2' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => handleExportToExcelControle()}>
                                        {iconArray['baixar']}
                                    </button>
                                }
                                <button className='btn-padrao h-38px help me-2' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={e => setIsModalLegendaVisible(true)}>
                                    ?
                                </button>
                            </div>
                        }
                    </div>
                    {carregandoControleDiario
                        ? <span className='text-center d-flex flex-col justify-content-center align-center'>
                            Encontrando Controle Diário...
                            <div className='d-flex flex-row w-fit-content text-center align-items-center'>
                                <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                <BsSearch className='icone manual-searching' size={30} />
                            </div>
                        </span>
                        : !carregandoControleDiario && !temDiaCarregado &&
                        <div className="justify-center w-100per align-center text-center p-4 font-vermelho">
                            Não existe controle diário carregado.
                        </div>
                    }
                    {temDiaCarregado === true
                        && <SelfLibVirtualizedTable
                            user={usuario.user}
                            funcoesHabilitadas={funcoesHabilitadas}
                            tableClassName={'table-controle-diario'}
                            tableHeader={() => (
                                <tr>
                                    {tableHeaders.map(header => {
                                        if (header.visivel) {
                                            return (
                                                <th key={header.key} className={header.className} title={header.title} dangerouslySetInnerHTML={{ __html: header.label }} />
                                            )
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </tr>
                            )}
                            tableFooter={() => (
                                <tr>
                                    <th colSpan={12} className='td-footer d-flex justify-content-center align-items-center flex-wrap p-0 m-0 ps-4 pe-4'>
                                        <div className='d-flex'>
                                            <span className='p-0 m-0 me-2 d-flex align-items-center'>Total:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeAtendimentos}
                                                </b>
                                            </span>
                                            <div className="form-check-reverse form-switch me-2">
                                                <label className="form-check-label" htmlFor="apenas-sucesso-input" style={{ textDecoration: habilitaSucesso ? 'none' : 'line-through' }}>
                                                    <span className='font-sucesso p-0 m-0'>Sucessos</span>:
                                                    <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                        {quantidadeSucessos}
                                                    </b>
                                                </label>
                                                <input className="form-check-input switch-sucesso" type="checkbox" role="switch" id="apenas-sucesso-input" checked={habilitaSucesso} onChange={(e) => handleChangeHabilitaSucesso(e)} />
                                            </div>
                                            <div className="form-check-reverse form-switch me-2 ms-2" style={{ color: '' }}>
                                                <label className="form-check-label" htmlFor="apenas-erros-input" style={{ textDecoration: habilitaErros ? 'none' : 'line-through' }}>
                                                    <span className='font-pendente p-0 m-0'>Erros</span>:
                                                    <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                        {quantidadeErros}
                                                    </b>
                                                </label>
                                                <input className="form-check-input switch-erro" type="checkbox" role="switch" id="apenas-erros-input" checked={habilitaErros} onChange={(e) => handleChangeHabilitaErros(e)} />
                                            </div>
                                            <div className="form-check-reverse form-switch me-2 ms-2">
                                                <label className="form-check-label" htmlFor="apenas-conflitos-input" style={{ textDecoration: habilitaConflitos ? 'none' : 'line-through' }}>
                                                    <span className='font-laranja p-0 m-0'>Conflitos</span>:
                                                    <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                        {quantidadeConflitos}
                                                    </b>
                                                </label>
                                                <input className="form-check-input switch-conflito" type="checkbox" role="switch" id="apenas-conflitos-input" checked={habilitaConflitos} onChange={(e) => handleChangeHabilitaConflitos(e)} />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            )}
                            atendimentos={atendimentos}
                            minimizedSubNavbar={minimizedSubNavbar}
                            windowHeight={windowHeight}
                            AtendimentoService={AtendimentoService}
                            statusAtendimento={modoDeConsulta === 'terapeuta-pendente' ? statusAtendimento?.filter(status => status.value === 9) : statusAtendimento}
                            getStatusSelecionadoByValue={getStatusSelecionadoByValue}
                            permissoes={permissoes}
                            handleAbreSolicitacaoDeAlteracao={handleAbreSolicitacaoDeAlteracao}
                            handleVerSolicitacao={handleVerSolicitacao}
                            linhasSolicitacao={linhasSolicitacao}
                            handleOpenModalAtendimento={handleOpenModalAtendimento}
                            isEditable={isEditable}
                            handleOpenSolicitacaoDeAlteracao={handleOpenSolicitacaoDeAlteracao}
                            usuario={usuario}
                            pacienteSelecionado={pacienteSelecionado}
                            handleFiltraPaciente={handleFiltraPaciente}
                            controleDiario={controleDiario}
                            handleOpenModalCheckin={handleOpenModalCheckin}
                            handleChangeStatusRecepcao={handleChangeStatusRecepcao}
                            opcoesLiberado={opcoesLiberado}
                            handleChangeAtendimentoLiberado={handleChangeAtendimentoLiberado}
                            pacienteEmExecucaoList={pacienteEmExecucaoList}
                            handleOpenModalPendencias={handleOpenModalPendencias}
                            handleModalComentarios={handleModalComentarios}
                        />
                    }
                </div>
            </div>
            <ListaPresenca lista={listaDePresenca} />
            {/* Modal de Controle de Execução */}
            {modalCheckinVisible &&
                <ControleDeExecucaoModal
                    ref={checkInModalRef}
                    user={usuario.user}
                    controleDiarioSocket={socket}
                    onClose={handleCloseModalControleDeExecucao}
                    isCheckIn={modoDeConsulta === 'check-ins-pendentes'}
                    listaPresenca={listaDePresenca}
                />
            }
            {/* Modal de Visualização de Conflitos e Erros do Atendimento */}
            <Conflitos ref={pendenciasRef} />
            {/* Modal de Confirmação de Conflitos do Atendimento */}
            <ConferirConflitos ref={conferirConflitosModalRef} salvarControleDiario={() => { salvarControleDiario(true, atendimentosOriginal, controleDiarioId, usuario.user.id); }} />
            {/* Modal de Legenda */}
            {isModalLegendaVisible && <LegendaDefault onClose={() => setIsModalLegendaVisible(false)} legendaMode={'controle-diario'} />}
            {/* Modal do Responsavel da Sala */}
            {isModalResponsavelVisible && <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />}
            {/* Modal de Visualização e Edição do Comentário */}
            <ComentariosModal ref={modalComentariosRef} atualizaSocket={() => atualizaSocket()} getAgendaDia={getAtendimentosControleDiario} />
            {/* Modal de Novo Atendimento */}
            {isModalAdicionarAtendimentoVisible
                && <AdicionarAgendamento
                    onClose={() => { setIsModalAdicionarAtendimentoVisible(false); }}
                    date={dataMoment.format('YYYY-MM-DD')}
                    agendamentos={atendimentosNormal}
                    updateAgenda={() => getAtendimentosControleDiario(controleDiarioId)}
                    controleDiarioId={controleDiarioId}
                    atualizaSocket={() => atualizaSocket()}
                    incluirAtendimento={true}
                />
            }
            {/* Modal de Alteração do Atendimento */}
            {isModalAtendimentoOpen
                && <AtendimentoModal
                    // Ids
                    atendimentoId={atendimentoModalSelecionado.id}
                    controleDiarioId={controleDiarioId}
                    // Listas
                    statusAtendimento={statusAtendimento}
                    tipoAtendimentoOptions={tipoAtendimentosOptions}
                    horarioAtendimentoOptions={horarioAtendimentoComFimOptions}
                    modalidadesOptions={modalidadesOptions}
                    estacaoOptions={salaEstacaoOptions}
                    atendimentos={atendimentosOriginal}
                    pacientes={pacientes}
                    pacienteOptionsOriginal={pacienteOptionsList}
                    // Atendimento
                    atendimento={atendimentoModalSelecionado}
                    // Opções à habilitar
                    modal_alteracao_opcoes={funcoesHabilitadas['modal_alteracao_opcoes']}
                    // Funções
                    setHouveAlteracaoStatusEdicao={() => setHouveAlteracaoStatusEdicao(true)}
                    onClose={() => [setIsModalAtendimentoOpen(false), getAtendimentosControleDiario()]}
                    isAtendimentoEditavel={isAtendimentoEditavel}
                    atualizaSocket={() => atualizaSocket()}
                    // Hooks
                    snackbar={snackbar} />
            }
            <SolicitacaoDeAlteracaoModal ref={solicitacaoDeAlteracaoModalRef} user_id={usuario.user.id} controle_diario_id={controleDiarioId} onClose={() => {
                getAtendimentosControleDiario(controleDiarioId);
                atualizaSocket();
            }} solicitacaoMode={usuario?.user?.tipo_user?.toLowerCase()} />
            <WebSocketProvider
                ref={websocketRef}
                liveBadgeOptions={{
                    mostra: true,
                    temTexto: false,
                    tooltip: 'Conectado ao Controle Diário',
                }}
                onOpen={(openState) => setSocketState(openState)}
                onClose={(openState) => setSocketState(openState)}
                onMessage={handleOnMessage}
            />
        </AtendimentoReposicaoProvider >
    );
    //#endregion
}

export default forwardRef(ControleDiario);