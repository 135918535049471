import { HomeIcon } from 'components/Header/styles';
import { cloneElement, useEffect, useState } from 'react';
import { AiFillFilter, AiFillHome, AiFillProject, AiOutlineApartment, AiOutlineClear, AiOutlineClockCircle, AiOutlineCloseCircle, AiOutlineDelete, AiOutlineException, AiOutlineExclamationCircle, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineForm, AiOutlinePlayCircle, AiOutlineProject, AiOutlineSetting, AiOutlineShareAlt, AiOutlineSnippets, AiOutlineSolution } from 'react-icons/ai';
import { BsArrowBarDown, BsBell, BsBellFill, BsBellSlash, BsBellSlashFill, BsCalendar, BsCalendarDay, BsCheck, BsCheck2All, BsCheck2Square, BsCheckAll, BsDownload, BsEye, BsFullscreen, BsGrid, BsListCheck, BsPencil, BsQuestionCircle, BsSearch, BsWallet, BsWallet2, BsXSquare } from 'react-icons/bs';
import { CgBlock, CgChart, CgClose } from 'react-icons/cg';
import { FaAngleDoubleDown, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDoubleUp, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaBroom, FaCalendarAlt, FaFileCsv, FaHeartbeat, FaSave, FaUser, FaUserAlt, FaUserMd, FaUserTie, FaWallet } from 'react-icons/fa';
import { FiCopy, FiDownload, FiFilter, FiRefreshCcw } from 'react-icons/fi';
import { IoAddCircleOutline, IoRemoveCircleOutline, IoWallet } from 'react-icons/io5';
import { MdDragHandle, MdEmail, MdOutlineAssignment, MdOutlineDriveFolderUpload, MdOutlineFeedback, MdPerson, MdPersonOff, MdQueryStats } from 'react-icons/md';

const CalendarioDia = ({ size, className, rest }) => (
    <div className={'div-icone-calendario-dia ' + className} {...rest}>
        <BsCalendar size={size} />
        <span>{String(new Date().getDate()).padStart(2, '0')}</span>
    </div>
);

const Preencher = ({ size, rest }) => (
    <div className='div-icone-preencher' {...rest}>
        <FiCopy size={(Number(size) / 2)} />
        <BsArrowBarDown size={Number(size) / 2} />
    </div>
);

const useIcons = (outSideIconSize = 20) => {
    //#region VARIAVEIS
    const [iconSize, setIconSize] = useState(20);
    const [iconsList, setIconsList] = useState([]);

    // Array mapeando os ícones
    const iconArray = {
        'adicionar': <IoAddCircleOutline size={iconSize} />,
        'agenda': <FaCalendarAlt size={iconSize} />,
        'analise': <MdQueryStats size={iconSize} />,
        'apartamento': <AiOutlineApartment size={iconSize} />,
        'arrastavel': <MdDragHandle size={iconSize} />,
        'atendimentos': <AiOutlineClockCircle size={iconSize} />,
        'atualizar': <FiRefreshCcw size={iconSize} />,
        'baixar': <BsDownload size={iconSize} />,
        'baixar1': <FiDownload size={iconSize} />,
        'demo': <AiOutlinePlayCircle size={iconSize} />,
        'calendario-dia': <CalendarioDia size={iconSize} />,
        'carteira': <FaWallet size={iconSize} />,
        'compartilhar': <AiOutlineShareAlt size={iconSize} />,
        'config': <AiOutlineSetting size={iconSize} />,
        'conferir': <BsCheck size={iconSize} />,
        'copiar': <FiCopy size={iconSize} />,
        'decrescente': <BsQuestionCircle size={iconSize} />,
        'deletar': <AiOutlineDelete size={iconSize} />,
        'deseleciona-quadrado': <BsXSquare size={iconSize} />,
        'dica': <BsQuestionCircle size={iconSize} />,
        'dupla-seta-angulo-baixo': <FaAngleDoubleDown size={iconSize} />,
        'dupla-seta-angulo-cima': <FaAngleDoubleUp size={iconSize} />,
        'dupla-seta-angulo-direita': <FaAngleDoubleRight size={iconSize} />,
        'dupla-seta-angulo-esquerda': <FaAngleDoubleLeft size={iconSize} />,
        'editar': <BsPencil size={iconSize} />,
        'email': <MdEmail size={iconSize} />,
        'exclamacao-circulo': <AiOutlineExclamationCircle size={iconSize} />,
        'excluir': <AiOutlineDelete size={iconSize} />,
        'fechar': <CgClose size={iconSize} />,
        'fechar-circulo': <AiOutlineCloseCircle size={iconSize} />,
        'filtrar': <FiFilter size={iconSize} />,
        'filtrado': <AiFillFilter size={iconSize} />,
        'formulario': <AiOutlineForm size={iconSize} />,
        'fragmentos': <AiOutlineSnippets size={iconSize} />,
        'fullscreen': <BsFullscreen size={iconSize} />,
        'funcionario': <FaUserTie size={iconSize} />,
        'graficos': <CgChart size={iconSize} />,
        'grade': <BsGrid size={iconSize} />,
        'guias': <AiOutlineException size={iconSize} />,
        'home': <AiFillHome size={iconSize} />,
        'importar': <MdOutlineDriveFolderUpload size={iconSize} />,
        'limpar': <AiOutlineClear size={iconSize} />,
        'limpar-geral': <FaBroom size={iconSize} />,
        'listaespera': <BsListCheck size={iconSize} />,
        'nao-observando': <AiOutlineEyeInvisible size={iconSize} />,
        'notificacao': <BsBellFill size={iconSize} />,
        'observando': <AiOutlineEye size={iconSize} />,
        'paciente': <FaUserAlt size={iconSize} />,
        'pendencias': <MdOutlineDriveFolderUpload size={iconSize} />,
        'perguntar': <BsQuestionCircle size={iconSize} />,
        'pesquisar': <BsSearch size={iconSize} />,
        'plano': <FaHeartbeat size={iconSize} />,
        'preencher': <Preencher size={iconSize} />,
        'projeto': <AiOutlineProject size={iconSize} />,
        'recados': <MdEmail size={iconSize} />,
        'relatorio': <FaFileCsv size={iconSize} />,
        'relatorios': <MdOutlineAssignment size={iconSize} />,
        'remover': <IoRemoveCircleOutline size={iconSize} />,
        'salvar': <FaSave size={iconSize} />,
        'seleciona-quadrado': <BsCheck2Square size={iconSize} />,
        'seleciona-todos': <BsCheckAll size={iconSize} />,
        'seleciona-todos2': <BsCheck2All size={iconSize} />,
        'seta-angulo-baixo': <FaAngleDown size={iconSize} />,
        'seta-angulo-cima': <FaAngleUp size={iconSize} />,
        'seta-angulo-direita': <FaAngleRight size={iconSize} />,
        'seta-angulo-esquerda': <FaAngleLeft size={iconSize} />,
        'seta-baixo': <FaArrowDown size={iconSize} />,
        'seta-cima': <FaArrowUp size={iconSize} />,
        'seta-direita': <FaArrowRight size={iconSize} />,
        'seta-esquerda': <FaArrowLeft size={iconSize} />,
        'sino': <BsBell size={iconSize} />,
        'sino-cheio': <BsBellFill size={iconSize} />,
        'sino-cheio-cortado': <BsBellSlashFill size={iconSize} />,
        'sino-cortado': <BsBellSlash size={iconSize} />,
        'solicitacoes': <MdOutlineFeedback size={iconSize} />,
        'solucao': <AiOutlineSolution size={iconSize} />,
        'status-diario': <AiFillProject size={iconSize} />,
        'terapeuta': <FaUserMd size={iconSize} />,
        'usuario': <FaUser size={iconSize} />,
        'usuario-ativo': <MdPerson size={iconSize} />,
        'usuario-inativo': <MdPersonOff size={iconSize} />,
        'visualizar': <BsEye size={iconSize} />,
        'default-icon': <HomeIcon size={iconSize} />,
    };
    //#endregion

    //#region FUNCOES
    // Função para retornar o ícone correspondente baseado no valor
    const getIcon = (icon, size = iconSize, no_default = false) => {
        if (no_default) {
            if (iconArray[icon]) {
                return typeof iconArray[icon] === 'function' ? iconArray[icon](size) : cloneElement(iconArray[icon], { size });
            } else {
                return <CgBlock size={size} />;
            }
        } else {
            return typeof iconArray[icon] === 'function' ? iconArray[icon](size) : cloneElement(iconArray[icon] || <AiFillHome size={size} />, { size });
        }
    };

    // Função para retornar uma lista de ícones com seus valores
    const getIconsList = () => {
        const iconEntries = Object.entries(iconArray).map(([value, label]) => ({
            label,
            value
        }));
        setIconsList(iconEntries);
        return iconEntries;
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        if (outSideIconSize) {
            setIconSize(outSideIconSize);
        }
    }, [outSideIconSize]);

    useEffect(() => {
        getIconsList();
    }, [iconSize]);
    //#endregion

    //#region RETURN
    return { getIcon, getIconsList, iconsList, iconArray };
    //#endregion
};

export default useIcons;