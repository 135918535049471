import { antes, depois } from 'helpers/data.helper';
import { useState } from 'react';
import api from 'services/api';

const useGuiaData = () => {
    const [guias, setGuias] = useState([]);
    const [guiasOriginal, setGuiasOriginal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const mapGuiaOptions = (data) => {
        return data.map((guia) => ({
            value: guia.id,
            label: guia.identificador_geral
        }));
    }

    const mapGuiaSaldo = (data) => {
        return data.map((guia) => ({
            ...guia,
            Saldo: guia.quantidade_liberada - guia.quantidade_feita
        }));
    }

    const fetchGuiaData = async (
        withParams = 'paciente,terapia,especialidade',
        selectParams = 'guia.*,pendente_solicitacao,paciente.nome,terapia.terapia,especialidade.especialidade,terapia_paciente.plano_saude.nome_reduzido',
        filterParams = ''
    ) => {
        setLoading(true);
        try {
            const queryParams = [];
            if (withParams) queryParams.push(`with=${withParams}`);
            if (selectParams) queryParams.push(`select=${selectParams}`);
            if (filterParams) {
                queryParams.push(`${filterParams}`);
            }
            const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
            const { data } = await api.get(`api/guia/lista/simples${queryString}`);
            const sortedData = data.sort((a, b) => {
                if (a.nome < b.nome) return -1;
                if (a.nome > b.nome) return 1;
                return 0;
            });
            const mappedWithSaldo = mapGuiaSaldo(sortedData);
            setGuias(mappedWithSaldo);
            setGuiasOriginal(mappedWithSaldo);
            setLoaded(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Erro ao carregar guias:', error);
        }
    };

    /**
     * Verifica se o período de vigência está completamente fora de qualquer período de validade no histórico.
     *
     * @param {Array} historicosDaGuia - Lista de históricos da guia.
     * @param {string} inicio - Data de início da vigência.
     * @param {string} validade - Data de validade da vigência.
     * @returns {boolean} Retorna true se não houver conflito, ou seja, se o período estiver antes ou depois de todos os períodos no histórico. Retorna false se houver algum conflito.
     */
    const handleValidadata = (historicosDaGuia, inicio, validade) => {
        const tInicio = inicio;
        const tFim = validade;
        let conflito = false;

        if (historicosDaGuia && historicosDaGuia.length > 0) {
            historicosDaGuia.forEach((historico) => {
                const hInicio = historico.inicio;
                const hFim = historico.validade;
                const invalidoInicio = (depois(tInicio, hInicio) && antes(tInicio, hFim));
                const invalidoFim = (depois(tFim, hInicio) && antes(tFim, hFim));
                const dentro = (depois(tInicio, hInicio) && antes(tFim, hFim));
                const emVolta = (antes(tInicio, hInicio) && depois(tFim, hFim));
                if (invalidoInicio || invalidoFim || dentro || emVolta) {
                    conflito = true;
                }
            });
        }
        return conflito;
    };

    return { guias, fetchGuiaData, mapGuiaData: mapGuiaOptions, handleValidadata, guiasOriginal, setGuias, setGuiasOriginal, loading, loaded, setLoaded };
};

export default useGuiaData;