import React, { useEffect, useMemo, useState } from 'react';
import { usePageBase } from 'context/page-base';
import * as XLSX from 'xlsx';
import { useSnackbar } from 'context/snackbar';
import { InputArquivo } from 'components/Input';
import { Form } from '@unform/web';
import api from 'services/api';
import styled from 'styled-components';
import useIcons from 'context/icons';
import { FaEdit } from 'react-icons/fa';
import { AutoSizer, Column, Table as VirtualizedTable } from 'react-virtualized';
import 'react-virtualized/styles.css';
import useTerapiasData from 'context/terapia';
import useEspecialidadesData from 'context/especialidade';
import usePlanoSaudeData from 'context/plano-saude';
import { ChangeCase } from 'services/ChangeCase';

//#region ESTILOS
const Modal = styled.div`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: 5% auto;
    padding: 0px;
    border: 1px solid #888;
    width: 80%;
    flex-direction: column;
    height: 80%;
    max-height: 600px;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
`;

const ModalHeader = styled.div`
    background-color: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    position: sticky;
    top: 0;
    width: 100%;
`;

const ModalBody = styled.div`
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    height: auto;
`;

const LinhaTabelas = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .titulo {
        font-size: 20px;
        font-weight: bold;
    }
`;

const ContainerMatchTable = styled.div`
    width: 20%;
    margin-right: 20px;
    padding: 10px;
`;

const ContainerTable = styled.div`
    width: 80%;
    margin-right: 20px;
    padding: 10px;
`;

const MatchTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    
    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        position: relative;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #4CAF50;
        color: white;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #f2f2f2;
    }

    input {
        width: 100%;
        border: none;
        background-color: transparent;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
    }

    select {
        width: 100%;
        border: none;
        background-color: transparent;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
    }

    .list-unstyled {
        padding: 0;
        max-height: 100px;
        overflow-y: auto;
        display: none;

        &.mostrando {
            display: block;
        }
    }

    .mostra-lista {
        border: none;
        border-radius: 5px;
        color: #ffffff;
        background-color: #4CAF50;
        cursor: pointer;
        font-size: 14px;
        margin-top: 5px;
    }

    .edit-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
        display: none;
    }

    th:hover .edit-icon, td:hover .edit-icon {
        display: inline;
    }

    .edit-input {
        display: none;
    }

    .editing .edit-input {
        display: inline;
    }

    .editing .edit-text {
        display: none;
    }

    .confirm-button {
        display: none;
    }

    .editing .confirm-button {
        display: inline;
    }
`;

const Table = styled.div`
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;

    .ReactVirtualized__Table__headerRow {
        background-color: #4CAF50;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
    }

    .ReactVirtualized__Table__row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
    }

    .ReactVirtualized__Table__headerColumn,
    .ReactVirtualized__Table__rowColumn {
        flex: 1;
        padding: 8px;
        text-align: left;
    }

    .ReactVirtualized__Table__row:nth-child(even) {
        background-color: #f2f2f2;
    }

    .ReactVirtualized__Table__row:hover {
        background-color: #f2f2f2;
    }
`;
//#endregion

const GuiasImportar = () => {
    //#region VARIAVEIS

    // Hooks
    usePageBase({
        title: "Importar Guias",
        description: "Importar guias de exames",
        routes: [
            'dashboard/{tipo_user}/guias/importar'
        ]
    });
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const { iconArray } = useIcons();
    const snackbar = useSnackbar();

    // Referencias
    const tableRef = React.createRef();

    // States
    const [selectedMatches, setSelectedMatches] = useState({});
    const [selectedList, setSelectedList] = useState({});
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showValues, setShowValues] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [tempHeader, setTempHeader] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    // Listas Estatias
    const verdadeiroFalso = [
        { label: 'Verdadeiro', value: 'true' },
        { label: 'Falso', value: 'false' }
    ];

    // Mapeador das Listas
    const listMap = {
        terapias: terapiasOptions,
        especialidades: especialidadesOptions,
        planoSaude: planoSaudeOptions,
        verdadeiroFalso: verdadeiroFalso
    };

    // Memoized values
    const tabelaPos = useMemo(() => {
        const element = tableRef.current;
        const elementPos = element ? element.getBoundingClientRect().top : null;
        if (elementPos) {
            return window.innerHeight - elementPos - 50;
        }
        return 500;
    }, [tableRef]);

    //#endregion

    //#region HANDLES
    const handleMatchChange = (index, value) => {
        setSelectedMatches(prev => ({
            ...prev,
            [index]: value
        }));
    };

    const handleListChange = (index, list) => {
        setSelectedList(prev => ({
            ...prev,
            [index]: list
        }));
        setSelectedMatches(prev => ({
            ...prev,
            [index]: ''
        }));
    };

    const handleFile = (e) => {
        const file = e.target.files[0];
        setFile(file);

        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const headers = jsonData[0].map((header, index) => {
                setShowValues(prev => {
                    const newShowValues = [...prev];
                    newShowValues[index] = false;
                    return newShowValues;
                });
                const formatedHeader = ChangeCase.toUpperCase(header).replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, '');
                return ({
                    Header: formatedHeader,
                    accessor: formatedHeader
                });
            });

            const rows = jsonData.slice(1).map((row) => {
                const rowData = {};
                row.forEach((cell, index) => {
                    rowData[headers[index].accessor] = cell;
                });
                return rowData;
            });

            setColumns(headers);
            setData(rows);
        };
        reader.readAsBinaryString(file);
    };

    const handleColumnChange = (index, newHeader) => {
        const newColumns = [...columns];
        newColumns[index].Header = newHeader;
        setColumns(newColumns);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            await api.post("/guia/cadastra/varios", formData);
            snackbar.displayMessage("Guias importadas com sucesso!", 'success');
        } catch (error) {
            snackbar.displayMessage("Erro ao importar guias", 'error');
        }
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        if (!modalOpen) {
            const updatedData = data.map(row => {
                const newRow = { ...row };
                Object.keys(selectedMatches).forEach(index => {
                    const list = selectedList[index];
                    const match = selectedMatches[index];
                    if (list && match) {
                        const option = listMap[list].find(option => option.value === match);
                        if (option) {
                            newRow[`col${index}`] = option.label;
                        }
                    }
                });
                return newRow;
            });
            setData(updatedData);
        }
    };

    const handleShowValues = (index) => {
        setModalContent(data.map(row => {
            const accessor = columns[index].accessor;
            return row[accessor];
        }).filter((value, i, self) => self.indexOf(value) === i));
        setModalOpen(true);
    };

    const confirmSelection = () => {
        const updatedData = data.map(row => {
            const newRow = { ...row };
            Object.keys(selectedMatches).forEach(index => {
                const list = selectedList[index];
                const match = selectedMatches[index];
                if (list && match) {
                    const option = listMap[list].find(option => option.value === match);
                    if (option) {
                        const accessor = columns[index].accessor;
                        newRow[accessor] = option.label;
                    }
                }
            });
            return newRow;
        });
        setData(updatedData);
        setModalOpen(false);
    };
    //#endregion

    //#region FUNCOES
    const startEditing = (index, currentHeader) => {
        setEditingIndex(index);
        setTempHeader(currentHeader);
    };

    const confirmEdit = (index) => {
        handleColumnChange(index, tempHeader);
        setEditingIndex(null);
    };

    const cancelEdit = () => {
        setEditingIndex(null);
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => { // ON LOAD
        fetchTerapias();
        fetchEspecialidades();
        fetchPlanoSaude();
    }, []);
    //#endregion

    //#region RENDER
    return (
        <>
            <Form>
                <div className='d-flex w-100 align-items-center'>
                    <fieldset className='ns-fieldset'>
                        <legend>Importar Guias</legend>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <InputArquivo
                                id="file"
                                name="file"
                                label="Selecione o arquivo"
                                onChange={handleFile}
                            />
                        </div>
                    </fieldset>
                    <fieldset className='ns-fieldset'>
                        <legend>Opções</legend>
                        <button className='btn-padrao w-300px' type={'button'} disabled={file === null} onClick={handleSubmit}>
                            {iconArray['importar']}Importar
                        </button>
                    </fieldset>
                </div>
                {file
                    ?
                    <LinhaTabelas className='d-flex'>
                        <ContainerMatchTable>
                            <span className='titulo'>Opções de Importação</span>
                            <MatchTable>
                                <thead>
                                    <tr>
                                        <th>Coluna</th>
                                        <th>Valores E Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns.map((column, index) => {
                                        const isEditing = editingIndex === index;
                                        return (
                                            <tr key={index} className={isEditing ? 'editing' : ''}>
                                                <td>
                                                    <span className="edit-text">{column.Header}</span>
                                                    <input type="text" className="edit-input" value={tempHeader} onChange={(e) => setTempHeader(e.target.value)} />
                                                    <FaEdit className="edit-icon" onClick={() => startEditing(index, column.Header)} />
                                                    {isEditing && <>
                                                        <button type="button" className="confirm-button" onClick={() => confirmEdit(index)}>Confirmar</button>
                                                        <button type="button" className="cancel-button" onClick={() => cancelEdit()}>Cancelar</button>
                                                    </>}
                                                </td>
                                                <td>
                                                    <button type="button" className="mostra-lista" onClick={() => handleShowValues(index)}>
                                                        {iconArray['visualizar']} Mostrar
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </MatchTable>
                        </ContainerMatchTable>
                        <ContainerTable ref={tableRef} id='tabela-pos'>
                            <span className='titulo'>Tabela de Importação</span>
                            {(data && data.length > 0 && columns && columns.length > 0) && (
                                <Table>
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <VirtualizedTable
                                                width={width}
                                                height={tabelaPos}
                                                headerHeight={40}
                                                rowHeight={30}
                                                rowCount={data.length}
                                                rowGetter={({ index }) => data[index]}
                                            >
                                                {columns.map((column, index) => (
                                                    <Column
                                                        key={index}
                                                        label={column.Header}
                                                        dataKey={column.accessor}
                                                        width={width / columns.length}
                                                    />
                                                ))}
                                            </VirtualizedTable>
                                        )}
                                    </AutoSizer>
                                </Table>
                            )}
                        </ContainerTable>
                    </LinhaTabelas>
                    : <span>
                        Selecione algum arquivo
                    </span>
                }
            </Form>
            <Modal id='modal' isOpen={modalOpen} onClick={(e) => e.target.id === 'modal' && toggleModal()}>
                <ModalContent>
                    <ModalHeader onClick={toggleModal}>Valores e Matches</ModalHeader>
                    <ModalBody>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Valor</th>
                                    <th>Qual Lista Usar</th>
                                    <th>Valor de Match</th>
                                </tr>
                            </thead>
                            {modalContent.map((value, valueIndex) => (
                                <tr key={valueIndex}>
                                    <th> {value} </th>
                                    <td>
                                        <select className='form-select' onChange={(e) => handleListChange(valueIndex, e.target.value)} value={selectedList[valueIndex] || ''}>
                                            <option value="">Selecione a lista</option>
                                            <option value="terapias">Terapias</option>
                                            <option value="especialidades">Especialidades</option>
                                            <option value="planoSaude">Plano de Saúde</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div className='d-flex flex-row flex-nowrap w-100'>
                                            <select className='form-select' onChange={(e) => handleMatchChange(valueIndex, e.target.value)} value={selectedMatches[valueIndex] || ''}>
                                                <option value="">Selecione</option>
                                                {(selectedList[valueIndex] === 'terapias' ? terapiasOptions : selectedList[valueIndex] === 'especialidades' ? especialidadesOptions : planoSaudeOptions).map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <button type="button" className="btn-padrao w-fit-content-i" onClick={() => handleMatchChange(valueIndex, '')}>{iconArray['limpar']}</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                        <button type="button" className="btn-padrao w-fit-content-i" onClick={confirmSelection}>Confirmar</button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
    //#endregion
}

export default GuiasImportar;